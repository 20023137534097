// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper Functions
import {
  formatOrderType,
  get,
  getDeviceTypeId,
  getTimestamp,
  jsonCopy,
  getArray,
} from "../../_common/helpers";
import {
  getCartItemByKey,
  formatCartForApi,
  getCartSubtotal,
  formatMerchantRewards,
  getOccurrence,
  removeDuplicates,
  checkPriceChanges,
  consolidateComboChildItems,
} from "../../_common/CartHelpers";
import { checkOutOfStockAddons } from "../../_common/MenuHelpers";
import { getTimeAmPm, getTrueBusinessDate } from "../../Dashboard/DashboardOrder/dateHelpers";
import {
  isOrderTimePassed,
  updateLocationData,
  isOrderTimeBeforeOpeningTime,
  isOrderTimeBlocked,
  isLateOrderAttempted,
  isStoreClosedToday,
} from "../../_common/PaymentHelpers";
import { getDayOfWeekFromIndex, getMonthFromIndex } from "../Locations/helpers/isStoreOpenOrClosed";
import { onCheckout, onRemoveProductFromCart } from "../../_common/helpers/dataLayerHelpers";
import {
  cleanUpItemsListBasedOnQuantityForCart,
  consolidateAndSumQuantities,
  separateItemsList,
} from "../Menu/helpers/orderingHelpers";
import {
  onCheckoutXtreme,
  onRemoveProductFromCartXtreme,
} from "../../_common/helpers/xtremePushHelper";

// API helper functions
import { CallApi1307 } from "./CallApi1307";

// Contexts
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import OrderTypeContext from "../OrderTypeContext";
import StoreContext from "../StoreContext";
import CartContext from "./CartContext";
import { MenuContext } from "../Menu/MenuContext";
import OrderTimeContext from "../OrderTimeContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import UserRoleContext from "../../App/UserRoleContext";

// UI components
import { CustomerInfo } from "./CustomerInfo";
import { useModal } from "../../_common/Modal/useModal";
import { Modal } from "../../_common/Modal/Modal";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { CartOrderDetailsSummary } from "./CartOrderDetailsSummary";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { AdditionalDisclaimer } from "../../_common/AdditionalDisclaimer";
import { CartItemsCollapsable } from "./CartItemsCollapsable";
import PaymentCollapsable from "../Payment/PaymentCollapsable";
import { CartSummaryCollapsable } from "./CartSummaryCollapsable";
import CartIndicator from "../../_common/CartIndicator/CartIndicator";
import ConfirmationEmailCollapsable from "./ConfirmationEmail/ConfirmationEmailCollapsable";
//import { SuggestedItemsCollapsable } from "./SuggestedItems/SuggestedItemsCollapsable";
import UberDirectDeliverySettings from "./UberDirect/UberDirectDeliverySettings";

// CSS
import "./Cart.css";
import { SuggestedItemsOrderLevel } from "./SuggestedItemsOrderLevel";

export const CartCheckout = (props) => {
  const { isOnCheckoutPage } = props;

  const history = useHistory();
  const appSettings = useContext(AppSettingsContext);
  //const brandingContext = useContext(BrandingContext);
  const userRoleContext = useContext(UserRoleContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const storeContext = useContext(StoreContext);
  const cartData = useContext(CartContext);
  const menuContext = useContext(MenuContext);
  const orderTypeContext = useContext(OrderTypeContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const orderTimeContext = useContext(OrderTimeContext);

  const { isModal, toggleModal, closeAnimation } = useModal();
  const [modalSettings, setModalSettings] = useState(null);
  const deviceWidth = useWindowSize().width;

  const [itemsWithOutOfStockAddons, setItemsWithOutOfStockAddons] = useState(null);
  const [isQuantityUpdated, setIsQuantityUpdated] = useState(false);
  const [storedPromoCode, setStoredPromoCode] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [error1307, setError1307] = useState("");
  const [orderSummaryData, setOrderSummaryData] = useState(null);
  const [items, setItems] = useState(null);
  const [storedRewards, setStoredRewards] = useState(null);

  // States for validating the order time
  const [isLateOrderAttempt, setIsLateOrderAttempt] = useState(false);
  const [isPassedOrderTime, setIsPassedOrderTime] = useState(false);
  const [isOrderTimeBeforeOpening, setIsOrderTimeBeforeOpening] = useState(false);
  const [nextAvailableOrderTime, setNextAvailableOrderTime] = useState(null);
  const [orderTimeIsBlocked, setOrderTimeIsBlocked] = useState(false);
  const [isStoreClosed, setIsStoreClosed] = useState(false);

  const [locallyUpdatingCartData, setLocallyUpdatingCartData] = useState(false);
  const [isCalling1307, setIsCalling1307] = useState(false); //for showing the loader when 1307 is being called
  const [isTaxIncluded, setIsTaxIncluded] = useState(null);
  const [isRemovingAllItems, setIsRemovingAllItems] = useState(false); //to trigger remove all item dialog modal
  const [isCartFAB, setIsCartFAB] = useState(true);
  const [cardRewards, setCardRewards] = useState(null);
  const [isInvalidRewards, setIsInvalidReward] = useState(null);
  const [showCheckoutButton, setShowCheckoutButton] = useState(true);
  const [storedRewardsDetails, setStoredRewardsDetails] = useState(null);
  const [isMinDeliverySpendingMet, setIsMinDeliverySpendingMet] = useState(true);

  //States for validating the Items in the cart based on the latest pulled menu
  const [priceIsChanged, setPriceIsChanged] = useState(null);
  const [itemsAreInvalid, setItemsAreInvalid] = useState(null);
  const [api1307OutOfStockItem, setApi1307OutOfStockItem] = useState(null);

  //States related to suggestedItems
  const suggestedItemsGotGeneratedRef = useRef(false);

  const [showOrderSuggestedItemsDialog, setShowOrderSuggestedItemsDialog] = useState(false);
  const [orderSuggestedItems, setOrderSuggestedItems] = useState(null);
  const [aiOrderSuggestedItems, setAIOrderSuggestedItems] = useState(null);
  const [isAnySuggestedItemAddedToCart, setIsAnySuggestedItemAddedToCart] = useState(false);

  const userStatus = userRoleContext.status;
  const skin = merchantConfigContext.skin;
  const activeStore = storeContext.activeOrderStore;
  const activeStoreConfig = activeStore.vexilorConfig;
  const deliveryMinSpendThreshold = isNaN(activeStoreConfig["minimum_spend_amount"])
    ? 0
    : Number(activeStoreConfig["minimum_spend_amount"]);

  const activeOrderType = orderTypeContext && orderTypeContext.value;

  const { state } = useLocation();

  const itemsOutOfStockAddonsMessage =
    appLabels["order"]["cart-removing-items-with-out-of-stock-addons-message"];

  const itemOutOfStockAddonsMessage =
    appLabels["order"]["cart-removing-item-with-out-of-stock-addons-message"];

  const merchantRewards = formatMerchantRewards(merchantConfigContext.merchant.I14);

  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";

  const itemsArray = getArray(items);
  const containerModifier = !itemsArray.length ? " cartItem__container--empty-cart" : "";
  const addMoreItems = (
    <button
      className="link-wrapper--add-items button--primary button--primary-with-border button--primary-small button"
      onClick={() => {
        history.push("/online-ordering/menu", { from: "cart" });
      }}
      type="button"
    >
      {appLabels["order"]["add-more-items"]}
    </button>
  );
  useEffect(() => {
    localforage.removeItem(skin + "__activeItemForModifying");
    sessionStorage.removeItem(skin + "__autoOpenCCForm");
  }, []);

  useEffect(() => {
    if (cartData.value && cartData.value.length > 0) {
      cleanUpItemsListBasedOnQuantityForCart(
        separateItemsList(jsonCopy(cartData.value)),
        cartData,
        isAddonQuantityEnabled
      );
    }
  }, []);

  useEffect(() => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__activeItemInCustomizationModel");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
    window.location.href = window.location.href.replace("?customization-modal", "");
  }, []);

  /** When a minimum delivery amount is set
   * validate by comparing the minimum amount set to subtotal - any discount that was applied (coupon, rewards, discount codes)
   */
  useEffect(() => {
    if (orderSummaryData && orderSummaryData !== "reset" && activeOrderType === "delivery") {
      const cartSubtotal = getCartSubtotal(orderSummaryData);
      const totalDiscounts = orderSummaryData.discountTotal;
      const appliedCouponValue = !!coupon ? coupon.balance : 0;
      const threshold = cartSubtotal - totalDiscounts - appliedCouponValue;

      if (threshold < deliveryMinSpendThreshold) {
        setIsMinDeliverySpendingMet(false);
      } else {
        setIsMinDeliverySpendingMet(true);
      }
    }
  }, [orderSummaryData, deliveryMinSpendThreshold, activeOrderType]);

  useEffect(() => {
    if (
      menuContext &&
      menuContext.apiData &&
      cartData &&
      cartData.value &&
      cartData.value.length > 0
    ) {
      // Get the fresh 1301 menu data from context for checking item_count for addons
      const menu = menuContext.apiData;

      const outOfStockAddons = []; // list of all the items with out of stock addons that don't meet the minimum quantity requirements

      cartData.value.forEach((cartItem) => {
        let item;

        // Check whether category or subcategory-based menu
        if (menu[`category-${cartItem.category}`]) {
          item =
            menu[`category-${cartItem.category}`].subcategories[
              `subcategory-${cartItem.subcategory}`
            ]["items"][`item-${cartItem.id}`];
        } else if (menu[`subcategory-${cartItem.subcategory}`]) {
          item = menu[`subcategory-${cartItem.subcategory}`]["items"][`item-${cartItem.id}`];
        }

        if (cartItem.addonGroups && Object.keys(cartItem.addonGroups).length > 0) {
          //set the isSelected key for addons in 'item' based on selected addons from 'cartItem'
          Object.keys(cartItem.addonGroups).forEach((addonGroupKey) => {
            const addonGroupItems = cartItem.addonGroups[addonGroupKey].items;
            Object.keys(addonGroupItems).forEach((itemKey) => {
              const addon = addonGroupItems[itemKey];
              if (addon.isSelected && item) {
                item.addonGroups[addonGroupKey].items[itemKey].isSelected = true;
                item.addonGroups[addonGroupKey].items[itemKey].quantity = addon.quantity || 1;
              }
            });
          });
        }

        if (item) {
          const { isOutOfStock } = checkOutOfStockAddons(item, appLabels["order"]);

          if (isOutOfStock) {
            outOfStockAddons.push(item);
          }
        }
      });

      if (outOfStockAddons.length > 0) {
        const cartObjectCopy = { ...cartData };
        let cartItemsCopy = cartObjectCopy.value;

        outOfStockAddons.forEach((itemWithOutOfStockAddon) => {
          cartItemsCopy.forEach((cartItem, idx) => {
            if (itemWithOutOfStockAddon.id === cartItem.id) {
              // remove every item found with out of stock addons that doesn't meet minimum quantity requirements
              cartItemsCopy.splice(idx, 1);
            }
          });
        });

        setItemsWithOutOfStockAddons(outOfStockAddons); // show notification popup

        cartData.updateCart(cartItemsCopy); // update the actual context cart object with the new list
      } else {
        setItemsWithOutOfStockAddons(false);
      }
    }
  }, [menuContext, cartData]);

  /** Coupon, discounts and rewards */
  useEffect(() => {
    if (storedPromoCode == null) {
      localforage.getItem(skin + "__storedPromoCode").then((retrievedPromoCode) => {
        if (retrievedPromoCode) setStoredPromoCode(retrievedPromoCode);
        else setStoredPromoCode("");
      });
    }
  }, [storedPromoCode]);

  useEffect(() => {
    if (coupon == null) {
      localforage.getItem(skin + "__coupon").then((storedCoupon) => {
        if (storedCoupon) {
          setCoupon(storedCoupon);
        } else {
          setCoupon("");
        }
      });
    }
  }, [coupon]);

  //Get stored rewards from localforage
  useEffect(() => {
    if (!error1307) {
      if (storedRewards == null) {
        localforage.getItem(skin + "__storedRewards").then((retrievedRewards) => {
          if (retrievedRewards) setStoredRewards(retrievedRewards);
          else setStoredRewards([]);
        });

        localforage.getItem(skin + "__storedRewardsDetails").then((retrievedRewardDetails) => {
          if (retrievedRewardDetails) setStoredRewardsDetails(retrievedRewardDetails);
          else setStoredRewardsDetails(null);
        });
      } else if (storedRewards != null && items) {
        setOrderSummaryData(null);
        localforage.setItem(skin + "__storedRewards", storedRewards);
      }
    }
  }, [storedRewards, error1307]);

  useEffect(() => {
    if (skin) {
      localforage.getItem(skin + "__storedRewardsDetails").then((storedRewardsDetails) => {
        if (storedRewardsDetails) {
          setStoredRewardsDetails(storedRewardsDetails);
        } else {
          setStoredRewardsDetails(null);
        }
      });
    }
  }, [skin]);

  useEffect(() => {
    if (storedRewardsDetails && storedRewardsDetails.length > 0) {
      localforage.setItem(skin + "__storedRewardsDetails", storedRewardsDetails);
    } else {
      localforage.removeItem(skin + "__storedRewardsDetails");
    }
  }, [storedRewardsDetails]);

  useEffect(() => {
    localforage.getItem(skin + "__cardSpecificRewards").then((cardRewards) => {
      if (cardRewards) {
        setCardRewards(JSON.parse(cardRewards));
      }
    });
  }, []);

  const getDiscountPrice = (rewardId) => {
    let discountPrice = 0;

    if (merchantRewards) {
      merchantRewards.forEach((reward) => {
        if (reward.id === rewardId) {
          discountPrice = reward.price;
        }
      });
    }

    if (cardRewards) {
      cardRewards.forEach((reward) => {
        if (reward.id === rewardId) {
          discountPrice = reward.price;
        }
      });
    }
    return discountPrice;
  };

  useEffect(() => {
    if (itemsArray && itemsArray.length > 0 && (cardRewards || merchantRewards)) {
      itemsArray.forEach((item) => {
        if (item[1].discounts) {
          item[1].discounts.forEach((discountEntry) => {
            discountEntry.discount_price = getDiscountPrice(discountEntry.reward_id);
          });
        }
      });
    }
  }, [itemsArray, cardRewards, merchantRewards]);

  /** End of Coupon, discounts and rewards */

  /** Order Time related functions */
  const updateOrderTime = () => {
    orderTimeContext.update({
      value: nextAvailableOrderTime,
      displayValue: getTimeAmPm(nextAvailableOrderTime),
      trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeStore),
      timeAtOrdering: new Date(),
    });
    setTimeout(() => {
      hideIsOrderTimePassedDialog();
    }, 250);
  };

  const hideIsOrderTimePassedDialog = () => {
    setIsPassedOrderTime(false);
    setIsCalling1307(false);
  };

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    const month = date.getMonth();
    const calDate = date.getDate();
    const day = date.getDay();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    const nameOfDay = getDayOfWeekFromIndex(day);
    const timestamp = `${
      nameOfDay.substr(0, 3).charAt(0).toUpperCase() + nameOfDay.substr(1, 2)
    } ${getMonthFromIndex(month)} ${calDate} at ${strTime}`;

    return (
      <>
        <br /> <br /> <span style={{ fontWeight: 700 }}>{timestamp} </span>
        <br /> <br />{" "}
      </>
    );
  };

  const declineTimeUpdate = () => {
    setIsPassedOrderTime(null);
    history.push("/dashboard");
  };
  /** End of Order Time related functions */

  /** Tip Related functions */
  const updateStoredTipAmount = (
    newOrderSummaryData,
    tipObject,
    isDeliveryIntegrationScreen = false
  ) => {
    const amount = tipObject.tipAmount || 0;
    const tempOrderSummaryData = { ...newOrderSummaryData };
    if (isDeliveryIntegrationScreen) {
      localforage.setItem(skin + "__storedCourierTip", tipObject);
      tempOrderSummaryData.courierTipAmount = amount > 0 ? parseFloat(amount).toFixed(2) : 0;
    } else {
      localforage.setItem(skin + "__storedTip", tipObject);
      tempOrderSummaryData.tipAmount = amount > 0 ? parseFloat(amount).toFixed(2) : 0;
    }

    setOrderSummaryData(tempOrderSummaryData);
  };

  const getTipAmount = (newOrderSummaryData, isDeliveryIntegrationScreen = false) => {
    let key = isDeliveryIntegrationScreen ? "__storedCourierTip" : "__storedTip";
    localforage.getItem(skin + key).then((storedTip) => {
      if (storedTip) {
        const tipIndex = storedTip.index;
        const tipCalcPercentage = storedTip.calcPercentage;

        if (tipIndex === "custom") {
          updateStoredTipAmount(newOrderSummaryData, storedTip, isDeliveryIntegrationScreen);
        } else {
          let tempSubtotal = getCartSubtotal(newOrderSummaryData);
          const tempTipObject = { ...storedTip };
          tempTipObject.tipAmount = tipCalcPercentage * tempSubtotal;
          updateStoredTipAmount(newOrderSummaryData, tempTipObject, isDeliveryIntegrationScreen);
        }
      }
    });
  };
  /** End of  Tip Related functions */

  /** Item Quantity and reorder functions */
  const cleanUpItemsListBasedOnQuantity = (itemsList) => {
    //when dealing with discounts, exclude the discount_amount since the amount could differ for each item even if the discount id is the same
    itemsList.forEach((tempItem) => {
      if (tempItem.total_addon_discount === 0) {
        delete tempItem.total_addon_discount;
      }

      if (!!tempItem.discounts) {
        tempItem.discounts.forEach((discount) => {
          delete discount.discount_amount;
        });

        if (!tempItem.combo_child_items || tempItem.combo_child_items.length === 0) {
          Object.keys(tempItem.addonGroups).forEach((addonGroupKey) => {
            const addonGroupItems = tempItem.addonGroups[addonGroupKey].items;
            Object.keys(addonGroupItems).forEach((addonItemKey) => {
              if (!!addonGroupItems[addonItemKey].discounts) {
                addonGroupItems[addonItemKey].discounts.forEach((discount) => {
                  delete discount.discount_amount;
                });
              }
            });
          });
        }
      }
    });

    //calculate quantity
    itemsList.forEach((tempItem) => {
      delete tempItem.quantity;
      delete tempItem.entryKey;

      tempItem.quantity = getOccurrence(
        itemsList,
        tempItem.id,
        JSON.stringify(tempItem.discounts),
        JSON.stringify(tempItem.addonGroups),
        JSON.stringify(tempItem.modifierGroups),
        tempItem.total_addon_discount,
        JSON.stringify(tempItem.combo_child_items),
        tempItem.specialInstructions
      );
    });

    //remove duplicates from itemsList
    itemsList = removeDuplicates(itemsList, (item) => JSON.stringify(item));

    //assign new entry keys
    itemsList.forEach((tempItem, index) => {
      tempItem.entryKey = Date.now() + index;
    });

    //update the cart Data without triggering 1307
    setLocallyUpdatingCartData(true);

    itemsList.forEach((element) => {
      if (!element.specialInstructions) {
        element.specialInstructions = "";
      }

      if (!element.combo_child_items || element.combo_child_items.length === 0) {
        delete element.combo_child_items;
      }

      if (element.addonGroups) {
        Object.keys(element.addonGroups).forEach((key) => {
          const addonGroup = element.addonGroups[key];
          addonGroup.type = "addonGroup";
          addonGroup.id = element.addonGroups[key].id ? key.split("-")[1] : "";

          const addons = addonGroup.items;
          if (Object.keys(addons).length === 1 && !addonGroup.id) {
            addonGroup.isStandalone = true;
          }
          Object.keys(addons).forEach((addonKey) => {
            const addon = addons[addonKey];
            if (addonGroup.isStandalone) {
              addon.isStandalone = true;
              addonGroup.name = addon.name;
            }
            if (addon.isSelected) {
              addon.quantity = Number(addon.quantity ? addon.quantity : 1);
              addon.addonQuantity = addon.quantity;
            } else {
              delete addon.quantity;
              delete addon.addonQuantity;
            }

            if (addon.modifierGroups && Object.keys(addon.modifierGroups).length > 0) {
              const nestedModifierGroups = addon.modifierGroups;
              Object.keys(nestedModifierGroups).forEach((modifierGroupKey) => {
                const nestedModifierGroup = nestedModifierGroups[modifierGroupKey];
                const modifiers = nestedModifierGroup.items;
                Object.keys(modifiers).forEach((modifierKey) => {
                  const mod = modifiers[modifierKey];
                  if (mod.isSelected) {
                    nestedModifierGroup.isSelected = true;
                  }
                });
              });
            }
          });
        });
      }

      if (element.modifierGroups) {
        Object.keys(element.modifierGroups).forEach((key) => {
          element.modifierGroups[key].type = "modifierGroup";
          const modifiers = element.modifierGroups[key].items;
          Object.keys(modifiers).forEach((modifierKey) => {
            const mod = modifiers[modifierKey];
            if (mod.isSelected) {
              mod.quantity = 1;
            }
          });
        });
      }
    });

    const listOfKeysToIgnore = [
      "quantity",
      "entryKey",
      "isFirstInstanceDefault",
      "positioningTemplateIndex",
      "type",
      "category",
      "subcategory",
      "parentId",
    ];
    itemsList = consolidateComboChildItems(itemsList);
    itemsList = consolidateAndSumQuantities(itemsList, listOfKeysToIgnore);

    if (window.location.href.includes("checkout")) {
      cartData.updateCart(itemsList);
    }
    setTimeout(() => {
      setLocallyUpdatingCartData(false);
    }, 1000);

    return itemsList;
  };

  /** Removes an item from the cart */
  const removeItem = (entryKey) => {
    const newCart = [...items];
    const item = getCartItemByKey(entryKey, newCart);
    const index = item.index;
    const itemDiscounts = item.value.discounts;
    const newRewards = [...storedRewards];
    const newRewardDetails = storedRewardsDetails ? [...storedRewardsDetails] : [];

    let discountShouldBeRemoved = false;

    if (get(itemDiscounts, "length")) {
      itemDiscounts.forEach((discount) => {
        if (discount.reward_id) {
          newRewards.map((newReward, index) => {
            if (newReward[1].toString() === discount.reward_id) {
              newRewards.splice(index, 1);
              newRewardDetails.splice(index, 1);
            }
            return newRewards;
          });

          setStoredRewards(newRewards);
          setStoredRewardsDetails(newRewardDetails); // the useEffect will also store it in localforage
          localforage.setItem(skin + "__storedRewards", newRewards);
        } else {
          //if discount is not a reward
          if (item.value.discounts.includes(discount)) {
            //the discount is relevant to the item being removed
            if (discount.discount_amount === orderSummaryData.discountTotal.toString()) {
              discountShouldBeRemoved = true;
            }
          }
        }
      });
    }

    // Animate item removal while the item is still in cart
    const removedItem = newCart[index];
    removedItem.className = "cartItem--animate-remove";

    newCart[index] = removedItem;
    setItems(newCart);

    // Remove item from cart after animation is finished
    newCart.splice(index, 1);
    cartData.updateCart(newCart);

    if (newCart.length === 0) {
      discountShouldBeRemoved = true;
    }
    if (discountShouldBeRemoved) {
      localforage.removeItem(skin + "__storedPromoCode");
      setStoredPromoCode(null);
    }

    setOrderSummaryData(null); //to trigger 1307
  };

  /** updated the quantity of an item and calls API 1307 */
  const updateItemQuantity = (isAdding, item, itemMaxQuantity) => {
    let currentQuantity = item.quantity;
    if (isAdding) {
      currentQuantity++;
      if (itemMaxQuantity !== "" && currentQuantity >= itemMaxQuantity) {
        currentQuantity = itemMaxQuantity;
      }
    } else {
      currentQuantity--;
      if (currentQuantity <= 1) {
        currentQuantity = 1;
      }
    }
    const index = getCartItemByKey(item.entryKey, items).index;
    const newCart = [...items];
    const newItem = jsonCopy(item);
    const newItemIndex = index;

    newItem.entryKey = Date.now();
    //newItem.className = "cartItem--animate-add";
    newItem.quantity = currentQuantity;
    newCart[index] = newItem;

    suggestedItemsGotGeneratedRef.current = true;
    updateOrderSummary(null, "", newCart).then((updatedItems) => {
      if (updatedItems) {
        //After calling 1307 - wait for the animations to finish, then remove the animation class from the added items
        setTimeout(() => {
          delete updatedItems[newItemIndex].className;

          setItems(updatedItems); //update DOM with removed animation class

          const itemsToStore = [];

          //Clean up cart from discounts before storing it
          updatedItems.forEach((item) => {
            const cleanItem = { ...item };
            delete cleanItem.discounts;
            itemsToStore.push(cleanItem);
          });
          setIsQuantityUpdated(true);
          cartData.updateCart(itemsToStore); //update storage with removed discounts
          setTimeout(() => {
            setIsQuantityUpdated(false);
          }, 250);
        }, 750);
      }
    });
  };

  //remove everything from the cart, including any rewards, coupons or discount codes
  const removeAllItemsFromCart = (is1307Error) => {
    onRemoveProductFromCart(cartData.value, "", "", true);
    onRemoveProductFromCartXtreme(cartData.value, "", "", true, skin);

    setStoredRewards(null);
    setStoredPromoCode(null);
    setCoupon(null);
    setItems(null);

    localforage.removeItem(skin + "__storedRewards");
    localforage.removeItem(skin + "__storedRewardsDetails");
    localforage.removeItem(skin + "__storedPromoCode");
    localforage.removeItem(skin + "__coupon");

    cartData.updateCart(null);

    setOrderSummaryData("reset");

    setIsRemovingAllItems(false);
    if (!is1307Error) history.push("/online-ordering/menu", { from: "cart" });
  };

  /** End of Item Quantity and reorder functions */

  const updateOrderSummary = async (promoCode, rewards, newCart, orderCoupon) => {
    suggestedItemsGotGeneratedRef.current = true;
    if (window.location.href.includes("order-confirmation")) {
      return;
    }
    setIsCalling1307(true);
    const updatedLocation = await updateLocationData(
      activeStore,
      activeOrderType,
      skin,
      appLanguage
    );
    if (updatedLocation) {
      let isStoreClosedForTheDay = isStoreClosedToday(updatedLocation, orderTimeContext);

      const orderTimeIsPassed = isOrderTimePassed(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        setNextAvailableOrderTime
      );
      const orderTimeIsBeforeOpening = isOrderTimeBeforeOpeningTime(
        updatedLocation,
        orderTimeContext
      );
      const orderTimeIsBlocked = isOrderTimeBlocked(
        updatedLocation,
        orderTimeContext,
        activeOrderType
      );
      const lateOrderAttempt = isLateOrderAttempted(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        skin,
        setNextAvailableOrderTime
      );
      if (lateOrderAttempt) {
        isStoreClosedForTheDay = true;
      }

      if (isStoreClosedForTheDay) {
        setIsStoreClosed(true);
        return;
      } else if (orderTimeIsPassed) {
        setIsPassedOrderTime(true);
        return;
      } else if (orderTimeIsBeforeOpening) {
        setIsOrderTimeBeforeOpening(true);
        setIsCalling1307(false);
        return;
      } else if (orderTimeIsBlocked) {
        setOrderTimeIsBlocked(true);
        setIsCalling1307(false);
        return;
      }

      let promoValue = "";

      if (promoCode || promoCode === "") promoValue = promoCode;
      else if (storedPromoCode) promoValue = storedPromoCode;
      else promoValue = "";

      let appliedCoupon = orderCoupon || coupon;

      const rewardsList = rewards || storedRewards || "";
      const cartItems = newCart || items;
      if (cartItems.length === 0) {
        return;
      }

      setOrderSummaryData("reset");

      const formattedCart = formatCartForApi(cartItems);
      const formattedOrderType = formatOrderType(activeOrderType);
      const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";
      const orderSummaryPromise = new Promise((resolve, reject) => {
        CallApi1307(
          skin,
          formattedCart,
          promoValue,
          formattedOrderType,
          activeStore,
          rewardsList,
          appLanguage,
          orderTimeContext && orderTimeContext.value.value !== "Select Time"
            ? getTimestamp(orderTimeContext.value.value)
            : "",
          getDeviceTypeId(activeOrderType),
          isStadiumSchema
        ).then((data1307) => {
          if (data1307.result.I0) {
            //Update cart items with discounts returned from 1307
            const result = data1307.result;
            const apiItems = get(result, "I2.items");

            if (get(apiItems, "length")) {
              let newItems = [...cartItems];

              let tempNewItems = [];
              newItems.forEach((newItem) => {
                for (let i = 0; i < newItem.quantity; i++) {
                  tempNewItems.push(jsonCopy(newItem));
                }
              });

              newItems = tempNewItems;
              apiItems.forEach((apiItem, index) => {
                if (newItems[index]) {
                  if (get(apiItem, "discounts")) newItems[index].discounts = apiItem.discounts;
                  else delete newItems[index].discounts;

                  //check for addon discounts
                  let addonDiscount = 0;
                  apiItem.addonGroups.map((addonGroup) => {
                    addonGroup.items.forEach((addonGroupItem) => {
                      if (get(addonGroupItem, "discount_amount")) {
                        if (!addonGroup.id) {
                          //stand alone addon
                          newItems[index].addonGroups[
                            `addonGroup-${addonGroup.items[0].id}`
                          ].discounts = addonGroupItem.discounts;
                        }
                        addonDiscount += parseFloat(addonGroupItem.discount_amount);
                      }
                    });
                    newItems[index].total_addon_discount = addonDiscount;
                    return 0;
                  });
                }
              });

              //calculate the quantity and remove the duplicates from itemsList
              let newItemsCopy = window.location.href.includes("order-confirmation")
                ? newItems
                : cleanUpItemsListBasedOnQuantity(jsonCopy(newItems));
              setItems(newItemsCopy);
              resolve(newItemsCopy);
            }
            //END OF Update cart items with discounts returned from 1307

            let calculatedTotal = result.I3;

            if (get(appliedCoupon, "balance")) calculatedTotal -= parseFloat(appliedCoupon.balance);

            if (calculatedTotal < 0) calculatedTotal = 0;

            const newOrderSummaryData = {
              orderTotal: parseFloat(calculatedTotal),
              taxTotal: parseFloat(result.I4),
              discountTotal: parseFloat(result.I2.discount_total),
              serviceChargeAmount: parseFloat(result.I2.serviceChargeAmount),
              serviceChargeDesc: result.I2.serviceChargeDesc,
              items: result.I2.items,
            };

            const summaryDataItems = newOrderSummaryData.items;
            let taxIsIncluded = false;
            for (let i = 0; i < summaryDataItems.length; i++) {
              const item = summaryDataItems[i];
              if (item["tax_included"] === "True") {
                taxIsIncluded = true;
                break;
              }
            }
            if (taxIsIncluded) {
              setIsTaxIncluded(true);
            } else {
              setIsTaxIncluded(false);
            }

            setOrderSummaryData(newOrderSummaryData);
            getTipAmount(newOrderSummaryData);
            setIsCalling1307(false);

            if (checkPriceChanges(newOrderSummaryData, jsonCopy(menuContext), activeOrderType)) {
              setPriceIsChanged(true);
            }

            suggestedItemsGotGeneratedRef.current = false;
            let merchantSuggestedItems = [];
            let aiSuggestedItems = [];
            if (
              data1307.result.I2 &&
              data1307.result.I2["standardSuggestedItems"] &&
              data1307.result.I2["standardSuggestedItems"].length >= 0 &&
              !orderSuggestedItems
            ) {
              merchantSuggestedItems = data1307.result.I2["standardSuggestedItems"];
              setOrderSuggestedItems(merchantSuggestedItems);
            }
            if (
              data1307.result.I2 &&
              data1307.result.I2["AISuggestedItems"] &&
              data1307.result.I2["AISuggestedItems"].length >= 0 &&
              !orderSuggestedItems
            ) {
              aiSuggestedItems = data1307.result.I2["AISuggestedItems"];
              setAIOrderSuggestedItems(aiSuggestedItems);
            }

            if (merchantSuggestedItems.length === 0 && aiSuggestedItems.length === 0) {
              setShowOrderSuggestedItemsDialog(false);
            } else {
              setShowOrderSuggestedItemsDialog(true);
            }

            if (state && (state.from === "item-page" || state.from === "login-register")) {
              setShowOrderSuggestedItemsDialog(false);
            }
          } else {
            // if rewards were invalid for any reason, remove all rewards and show the error message to the user
            if (data1307.error.code === 60) {
              setIsInvalidReward(true);
              localforage.removeItem(skin + "__storedRewards");
              localforage.removeItem(skin + "__storedRewardsDetails");
              setStoredRewards([]);
              setStoredRewardsDetails(null);
            } else if (data1307.error.code === 396) {
              //if error code is 396 then there is at least one item that is not in stock anymore
              //extract the out of stock item id from the error message
              const outOfStockId = data1307.result.message.replace(/^\D+|\D+$/g, "");
              let outOfStockItem = null;
              const cartItemsCopy = cartData.value;
              cartData.value.forEach((item, index) => {
                if (item.id === outOfStockId) {
                  cartItemsCopy.splice(index, 1);
                  outOfStockItem = item;
                } else if (item.combo_child_items && !!item.combo_child_items.length) {
                  item.combo_child_items.forEach((comboChildItem) => {
                    if (comboChildItem.id === outOfStockId) {
                      cartItemsCopy.splice(index, 1);
                      outOfStockItem = comboChildItem;
                    }
                  });
                }
              });
              cartData.updateCart(cartItemsCopy);
              setApi1307OutOfStockItem(outOfStockItem);
            } else if (data1307.error.code === 377) {
              setItemsAreInvalid(true);
              //setError1307(`${appLabels["general"]["error-code"]}: ${data1307.error.code}.`);
              removeAllItemsFromCart(true);
            } else {
              setError1307(
                `${appLabels["general"]["error-code"]}: ${data1307.error.code}, ${data1307.error.message}`
              );
              removeAllItemsFromCart(true);
            }

            reject(data1307.result.message);
          }
        });
      }).catch((error) => console.error(error));

      return orderSummaryPromise;
    } else {
      history.push("/dashboard");
    }
  };

  // Run 1307 to get initial order summary
  useEffect(() => {
    const cartLength = get(cartData, "value.length");
    if (
      skin &&
      cartLength &&
      activeStore &&
      (orderSummaryData == null || (cartData && cartData.updateCartData)) &&
      storedPromoCode != null &&
      coupon != null &&
      storedRewards != null &&
      !error1307 &&
      locallyUpdatingCartData === false &&
      isQuantityUpdated === false &&
      !isCalling1307 &&
      !isPassedOrderTime &&
      !isModal
    ) {
      delete cartData.updateCartData;
      updateOrderSummary(storedPromoCode, storedRewards, cartData.value, coupon);
    } else if (!cartData.value || cartLength === 0) {
      setItems([]);
    }
  }, [
    skin,
    cartData,
    storeContext,
    orderSummaryData,
    storedPromoCode,
    storedRewards,
    error1307,
    orderTimeContext,
    coupon,
    isCalling1307,
  ]);

  // END OF Run 1307 to get initial order summary
  const [showDeliveryIntegrationWhenReady, setShowDeliveryIntegrationWhenReady] = useState(false);
  useEffect(() => {
    if (state && state.from && state.from === "login-register") {
      if (activeOrderType !== "dinein") {
        setShowOrderSuggestedItemsDialog(false);
        if (activeOrderType === "delivery" && activeStoreConfig.isUberDirectEnabled) {
          setShowDeliveryIntegrationWhenReady(true);
        } else {
          showCustomerInfo();
        }
      }
      sessionStorage.removeItem(skin + "__redirectToCheckout");
    } else {
      onCheckoutXtreme(cartData.value, 1, "", skin);
    }
    // if user is coming from modifying and item and back to cart, do not show suggested items
    if (state && state.from === "item-page") {
      setShowOrderSuggestedItemsDialog(false);
    }
  }, [state]);

  useEffect(() => {
    if (
      showDeliveryIntegrationWhenReady &&
      orderSummaryData &&
      orderSummaryData !== null &&
      orderSummaryData !== "reset"
    ) {
      showUberDirectDeliveryModal();
    }
  }, [showDeliveryIntegrationWhenReady, orderSummaryData]);

  const handleOrderAndSubmitClick = () => {
    onCheckoutXtreme(cartData.value, 2, "", skin);
    if (activeOrderType === "dinein") {
      history.push("/online-ordering/payment");
    } else {
      if (userStatus === "guest") {
        sessionStorage.setItem(skin + "__redirectToCheckout", "true");
        history.push("/login-register");
      } else {
        if (activeOrderType === "delivery" && activeStoreConfig.isUberDirectEnabled) {
          showUberDirectDeliveryModal();
        } else {
          showCustomerInfo();
        }
      }
    }
  };

  const showUberDirectDeliveryModal = () => {
    setShowDeliveryIntegrationWhenReady(false);
    setModalSettings({
      content: (
        <UberDirectDeliverySettings
          closeModal={toggleModal}
          orderSummaryData={orderSummaryData}
          updateStoredTipAmount={updateStoredTipAmount}
          skin={skin}
          showCustomerInfo={showCustomerInfo}
        />
      ),
      type: "fullscreen",
      isCloseButton: true,
      description: "Uber direct delivery settings",
      closeAnimation: closeAnimation,
      isTransparent: true,
      wrapperClass: "customer-info__modal modal-wrapper--apply-discounts modal--vertical-animation",
    });
    getTipAmount(orderSummaryData, true);
    toggleModal();
  };
  const showCustomerInfo = () => {
    onCheckout(cartData.value, 1);

    setModalSettings({
      content: <CustomerInfo closeModal={toggleModal} />,
      type: "fullscreen",
      isCloseButton: true,
      description: "customer info for payment",
      closeAnimation: closeAnimation,
      isTransparent: true,
      wrapperClass: "customer-info__modal modal-wrapper--apply-discounts modal--vertical-animation",
    });
    toggleModal();
  };

  const getItemAddonDiscounts = (item) => {
    let addonDiscounts = [];

    if (storedRewards.length === 0) {
      return [];
    }

    item.addonGroups.forEach((addonGroup) => {
      const addonGroupItems = addonGroup.items;
      addonGroupItems.forEach((addon) => {
        if (addon.discounts && !!addon.discounts.length) {
          addon.discounts.forEach((discount) => {
            addonDiscounts.push(discount);
          });
        }
      });
    });
    return addonDiscounts;
  };

  const getComboChildImages = (item) => {
    const childImages = [];

    if (item.type !== "cartCombo") {
      return null;
    } else {
      const childItems = item.items;
      childItems.forEach((childItem) => {
        childImages.push(childItem.imageUrl === "None" ? null : childItem.imageUrl);
      });
    }

    return childImages;
  };

  return (
    <>
      <CartOrderDetailsSummary
        activeOrderStore={activeStore}
        activeOrderType={activeOrderType}
        numberOfItems={itemsArray.length}
      />

      {activeOrderType === "dinein" && <ConfirmationEmailCollapsable />}

      {!error1307 && (
        <>
          {deviceWidth < 660 ? (
            <>
              {/** MOBILE DESIGN */}
              <CartItemsCollapsable
                setIsCartFAB={setIsCartFAB}
                cartData={cartData}
                itemsArray={itemsArray}
                orderSummaryData={orderSummaryData}
                containerModifier={containerModifier}
                isCalling1307={isCalling1307}
                items={items}
                updateItemQuantity={updateItemQuantity}
                removeItem={removeItem}
                getItemAddonDiscounts={getItemAddonDiscounts}
                getComboChildImages={getComboChildImages}
                skin={skin}
                setStoredRewards={setStoredRewards}
                setOrderSummaryData={setOrderSummaryData}
                storedRewardsDetails={storedRewardsDetails}
                setStoredRewardsDetails={setStoredRewardsDetails}
                error1307={error1307}
                setIsRemovingAllItems={setIsRemovingAllItems}
                initiallyIsCollapsed={activeOrderType === "dinein" ? true : false}
                isOnCheckoutPage={isOnCheckoutPage}
                storedRewards={storedRewards}
              />

              {items && isOnCheckoutPage && (
                <div className="desktop-container cart-content-desktop">
                  {!!itemsArray.length && !!activeOrderType && (
                    <>
                      <div className="cart-summary-tip-desktop">
                        <CartSummaryCollapsable
                          items={items}
                          isAnimateIcon={cartData.isAnimated}
                          orderSummaryData={orderSummaryData}
                          updateOrderSummary={updateOrderSummary}
                          currentLocation={activeStore}
                          storedPromoCode={storedPromoCode}
                          setStoredPromoCode={setStoredPromoCode}
                          coupon={coupon}
                          setCoupon={setCoupon}
                          storedRewards={storedRewards}
                          setStoredRewards={setStoredRewards}
                          setShowCheckoutButton={setShowCheckoutButton}
                          isTaxIncluded={isTaxIncluded}
                          setStoredRewardsDetails={setStoredRewardsDetails}
                          storedRewardsDetails={storedRewardsDetails}
                          setOrderSummaryData={setOrderSummaryData}
                          skin={skin}
                          updateStoredTipAmount={updateStoredTipAmount}
                          isCalling1307={isCalling1307}
                          activeOrderType={activeOrderType}
                        />

                        {!isCalling1307 &&
                          orderSummaryData &&
                          orderSummaryData !== "reset" &&
                          activeOrderType === "dinein" && (
                            <PaymentCollapsable
                              orderTotal={
                                orderSummaryData.orderTotal +
                                parseFloat(orderSummaryData.tipAmount || 0)
                              }
                            />
                          )}

                        <AdditionalDisclaimer
                          disclaimer={appLabels["order"]["review-order-disclaimer"]}
                          styleObject={{ paddingLeft: "30px", paddingRight: "30px" }}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="cart-checkout__desktop-container">
              {/** DESKTOP DESIGN */}
              <div aria-live="off">
                <CartItemsCollapsable
                  setIsCartFAB={setIsCartFAB}
                  cartData={cartData}
                  itemsArray={itemsArray}
                  orderSummaryData={orderSummaryData}
                  containerModifier={containerModifier}
                  isCalling1307={isCalling1307}
                  items={items}
                  updateItemQuantity={updateItemQuantity}
                  removeItem={removeItem}
                  getItemAddonDiscounts={getItemAddonDiscounts}
                  getComboChildImages={getComboChildImages}
                  skin={skin}
                  setStoredRewards={setStoredRewards}
                  setOrderSummaryData={setOrderSummaryData}
                  storedRewardsDetails={storedRewardsDetails}
                  setStoredRewardsDetails={setStoredRewardsDetails}
                  error1307={error1307}
                  setIsRemovingAllItems={setIsRemovingAllItems}
                  initiallyIsCollapsed={false}
                  isOnCheckoutPage={isOnCheckoutPage}
                  storedRewards={storedRewards}
                  subHeaderContent={
                    <>
                      <div className="cart-checkout__cart-items-collapsable__cart_indicator">
                        <CartIndicator
                          quantity={cartData.quantity}
                          isAnimated={cartData.isAnimated}
                          isFloating={true}
                          activeOrderStore={activeStore}
                        />
                        <span>{appLabels["order"]["items-in-the-cart"]}</span>
                      </div>
                    </>
                  }
                />
              </div>

              {items && isOnCheckoutPage && (
                <div className="cart-content-desktop">
                  {!!itemsArray.length && !!activeOrderType && (
                    <>
                      <div className="cart-summary-tip-desktop">
                        <CartSummaryCollapsable
                          items={items}
                          isAnimateIcon={cartData.isAnimated}
                          orderSummaryData={orderSummaryData}
                          updateOrderSummary={updateOrderSummary}
                          currentLocation={activeStore}
                          storedPromoCode={storedPromoCode}
                          setStoredPromoCode={setStoredPromoCode}
                          coupon={coupon}
                          setCoupon={setCoupon}
                          storedRewards={storedRewards}
                          setStoredRewards={setStoredRewards}
                          setShowCheckoutButton={setShowCheckoutButton}
                          isTaxIncluded={isTaxIncluded}
                          setStoredRewardsDetails={setStoredRewardsDetails}
                          storedRewardsDetails={storedRewardsDetails}
                          setOrderSummaryData={setOrderSummaryData}
                          skin={skin}
                          updateStoredTipAmount={updateStoredTipAmount}
                          isCalling1307={isCalling1307}
                          activeOrderType={activeOrderType}
                        />

                        {deviceWidth >= 850 && (
                          <AdditionalDisclaimer
                            disclaimer={appLabels["order"]["review-order-disclaimer"]}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {error1307 && (items == null || items.length === 0) && (
        <>
          <div className="cart__1307-error">
            {"Looks like something went wrong."}
            <br />
            {error1307}
          </div>
          <div className="cart-content__buttons cart-content__buttons-single-button">
            {addMoreItems}
          </div>
        </>
      )}
      {!items && !error1307 && <LoadingSpinner />}
      {isCalling1307 && <LoadingSpinner />}

      {isCartFAB && !!itemsArray.length && showCheckoutButton && (
        <>
          {(deviceWidth >= 660 || activeOrderType !== "dinein") && (
            <div className="cart-content__desktop-checkout-buttons">
              <div className="desktop-cart__link-and-button-wrapper desktop-container single-button">
                <button
                  className="button button--primary"
                  type="button"
                  onClick={handleOrderAndSubmitClick}
                  disabled={isMinDeliverySpendingMet === false}
                >
                  {appLabels["order"]["checkout"]}
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {modalSettings && (
        <Modal
          isModal={isModal}
          toggleModal={() => {
            toggleModal();
            updateOrderSummary(storedPromoCode, storedRewards, cartData.value, coupon);
          }}
          content={modalSettings.content}
          type={modalSettings.type}
          isCloseButton={modalSettings.isCloseButton}
          description={modalSettings}
          closeAnimation={modalSettings.closeAnimation}
          isTransparent={modalSettings.isTransparent}
          wrapperClass={modalSettings.wrapperClass}
        />
      )}
      {isInvalidRewards && (
        <DialogModal
          message={appLabels["order"]["invalid-reward"]}
          resetRemoveDialog={() => {
            setIsInvalidReward(null);
            window.location.reload();
          }}
        />
      )}
      {isRemovingAllItems && (
        <DialogModal
          message={appLabels["general"]["remove-all-cart-items-confirmation-message"]}
          confirmAction={removeAllItemsFromCart}
          isCancelConfirm={true}
          resetRemoveDialog={() => setIsRemovingAllItems(false)}
        />
      )}
      {itemsWithOutOfStockAddons && (
        <DialogModal
          message={
            itemsWithOutOfStockAddons.length > 1 ? (
              itemsOutOfStockAddonsMessage
            ) : (
              <>
                {itemOutOfStockAddonsMessage.split("[item-name]")[0]}
                {<strong>{itemsWithOutOfStockAddons[0].name}</strong>}
                {itemOutOfStockAddonsMessage.split("[item-name]")[1]}
              </>
            )
          }
          resetRemoveDialog={() => setItemsWithOutOfStockAddons(false)}
        />
      )}
      {isLateOrderAttempt && (
        <DialogModal
          message={appLabels["order"]["order-too-close-to-closing-time-error"]}
          resetRemoveDialog={() => {
            setIsLateOrderAttempt(false);

            /** If order type is dine-in and the store is closed, remove all order related setting from the storage */
            if (activeOrderType === "dinein") {
              localforage.removeItem(skin + "__orderTime");
              localforage.removeItem(skin + "__orderDate");
              localforage.removeItem(skin + "__activeOrderType");
              localforage.removeItem(skin + "__activeOrderStore");
              localforage.removeItem(skin + "__cart");
              localforage.removeItem(skin + "__activeOrderStoreTable");
              localforage.removeItem(skin + "__trueBusinessDate");
            }
            orderTimeContext.update({
              value: nextAvailableOrderTime,
              displayValue: getTimeAmPm(nextAvailableOrderTime),
              trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeStore),
            });
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {isStoreClosed && (
        <DialogModal
          hideCloseButton={true}
          closeModalAction={() => {
            setIsStoreClosed(false);
          }}
          isCancelConfirm={false}
          hideConfirmButton={false}
          isCustomConfirmText={appLabels["order"]["pick-new-time"]}
          resetRemoveDialog={() => {
            history.push({
              pathname: "/dashboard",
              state: {
                showTimePanel: true,
                timeAtOrdering: orderTimeContext.value.timeAtOrdering,
                originalOrderTimeContext: orderTimeContext,
              },
            });
          }}
          message={
            <div>
              <h2>{appLabels["order"]["sorry-it-is-closing-time"]}</h2>
              <p>{appLabels["order"]["store-closed-message"]}</p>
            </div>
          }
        />
      )}
      {isPassedOrderTime && (
        <DialogModal
          message={
            <div>
              {appLabels["order"]["select-order-time-passed-message"]}
              {": "}
              {formatOrderTime(nextAvailableOrderTime)}
              {appLabels["order"]["confirm-updated-order-time-message"]}
            </div>
          }
          resetRemoveDialog={declineTimeUpdate}
          isCancelConfirm={true}
          confirmAction={updateOrderTime}
          isHTMLContent={true}
        />
      )}

      {isOrderTimeBeforeOpening && (
        <DialogModal
          message={appLabels["order"]["order-before-store-opening-time-error"]}
          resetRemoveDialog={() => {
            setIsOrderTimeBeforeOpening(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {orderTimeIsBlocked && (
        <DialogModal
          message={appLabels["order"]["order-time-is-blocked"]}
          resetRemoveDialog={() => {
            setOrderTimeIsBlocked(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {priceIsChanged && (
        <DialogModal
          message={appLabels["order"]["price-change-error-message"]}
          resetRemoveDialog={() => {
            setPriceIsChanged(null);
          }}
        />
      )}

      {itemsAreInvalid && (
        <DialogModal
          message={appLabels["order"]["items-removed-from-menu-error-message"]}
          resetRemoveDialog={() => {
            setItemsAreInvalid(null);
            removeAllItemsFromCart();
          }}
        />
      )}

      {api1307OutOfStockItem !== null && (
        <DialogModal
          message={appLabels["order"]["cart-out-of-stock-item-error-message"].replace(
            "[item-name]",
            api1307OutOfStockItem.name
          )}
          resetRemoveDialog={() => {
            setApi1307OutOfStockItem(null);
            window.location.reload();
          }}
        />
      )}
      {cartData &&
        showOrderSuggestedItemsDialog &&
        !isCalling1307 &&
        ((orderSuggestedItems && orderSuggestedItems.length > 0) ||
          (aiOrderSuggestedItems && aiOrderSuggestedItems.length > 0)) && (
          <DialogModal
            customClass={deviceWidth > 660 ? "wideDialogModal" : "fullScreenMobileModal"}
            isHTMLContent={true}
            message={
              <div>
                <SuggestedItemsOrderLevel
                  suggestedItemIds={orderSuggestedItems}
                  aiSuggestedItemIds={aiOrderSuggestedItems}
                  setIsAnySuggestedItemAddedToCart={setIsAnySuggestedItemAddedToCart}
                  setShowOrderSuggestedItemsDialog={setShowOrderSuggestedItemsDialog}
                />
              </div>
            }
            resetRemoveDialog={() => {
              setShowOrderSuggestedItemsDialog(false);
              if (isAnySuggestedItemAddedToCart) {
                delete cartData.updateCartData;
                updateOrderSummary(storedPromoCode, storedRewards, cartData.value, coupon);
              }
            }}
            isCustomConfirmText={
              !isAnySuggestedItemAddedToCart
                ? appLabels["order"]["no-thanks"]
                : appLabels["order"]["continue-to-checkout"]
            }
            isCenteredDialogButton={true}
          />
        )}
    </>
  );
};
