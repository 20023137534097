// Libraries
import React, { useState, useEffect, useRef, useContext } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import { useOrderType } from "./useOrderType";
import { useUserRoleSetup } from "../_common/hooks/useUserRoleSetup";
// import useOrderSetup from "../_common/hooks/useOrderSetup";
import { useLoyaltySetup } from "../_common/hooks/useLoyaltySetup";
import useWindowSize from "../_common/hooks/useWindowSize";
import useWindowPath from "../_common/hooks/useWindowPath";
import { useForterScript } from "./useForterScript";
import { useCustomBodyScript } from "./useCustomBodyScript";

// Helper function
import { get, arrayToObject, getTimestamp, getDeviceTypeId } from "../_common/helpers";
import { setStoreHoursProperties } from "../OnlineOrdering/GoogleMaps/dateHelpers";
import { checkIfExpiredOrNewCookieBanner } from "../_common/helpers/checkIfExpiredOrNewCookieBanner";
import { getCartQuantity, removeDuplicates } from "../_common/CartHelpers";
import {
  getTimeAmPm,
  getTrueBusinessDate,
  newTime,
  prettifyDate,
  roundTime,
  roundToNearestQuarter,
} from "../Dashboard/DashboardOrder/dateHelpers";
import {
  formatStoreTimeOverrides,
  getIntermittentIncrementInterval,
  getMinInterval,
  getOpeningTime,
  getClosingTime,
  isTimeSlotBlocked,
  getDayOfWeekFromIndex,
  getFormattedStoreHoursWithMidnight,
  isWithinBusinessHours,
} from "../OnlineOrdering/Locations/helpers/isStoreOpenOrClosed";
import { isStoreAvailable } from "../OnlineOrdering/Menu/isStoreAvailable";
import { isValidOrderTime, updateLocationData } from "../_common/PaymentHelpers";

// API helper functions
import { get1300Stores } from "../OnlineOrdering/GoogleMaps/get1300Stores";
import { getStore1306Config } from "./apiHelpers/getStore1306Config";
import callApi1333 from "../OnlineOrdering/Menu/callApi1333";

// Contexts
import { UserRoleProvider } from "./UserRoleContext";
import { LocationsProvider } from "../OnlineOrdering/Locations/LocationsContext";
import { OrderTypeProvider } from "../OnlineOrdering/OrderTypeContext";
import { StoreProvider } from "../OnlineOrdering/StoreContext";
import { OrderTimeProvider } from "../OnlineOrdering/OrderTimeContext";
import { MenuProvider } from "../OnlineOrdering/Menu/MenuContext";
import { LoyaltyProvider } from "./LoyaltyContext";
import { OrderProvider } from "./OrderContext";
import { CartProvider } from "../OnlineOrdering/Cart/CartContext";
import { GCCartProvider } from "../GiftCard/GCCartContext";
import { ActiveOrdersProvider } from "../OnlineOrdering/OrderStatus/ActiveOrdersContext";
import { BillProvider } from "../OnlineOrdering/Menu/Bill/BillContext";
import CWS5ModuleContext from "./CWS5ModuleContext";
import { CurrencyContextProvider } from "./CurrencyContext";

// UI Components
import { HelmetComponent } from "./HelmetComponent";
import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import { LoginRegister } from "../LoginRegister/LoginRegister";
import { Welcome } from "../Dashboard/Welcome/Welcome";
import { Dashboard } from "../Dashboard/Dashboard";

// Routing groups
import { LoginRegisterRoutes } from "../LoginRegister/LoginRegisterRoutes";
import { OnlineOrderingRoutes } from "../OnlineOrdering/OnlineOrderingRoutes";
import { LoyaltyRoutes } from "../Loyalty/LoyaltyRoutes";
import { AccountRoutes } from "../Account/AccountRoutes";
import { ScanInStoreRoutes } from "../PayInStore/ScanInStoreRoutes";
import { StaticPageRoutes } from "./StaticPageRoutes";

// General modals and dialogs
import { AppInstallPrompt } from "./AppInstallPrompt";
import { CookiesBanner } from "./CookiesBanner";
import { ExpiredSessionDialog } from "./ExpiredSessionDialog";
import { OrderTimeModal } from "../_common/components/OrderTimeModal";
import { GenericAppError } from "./GenericAppError";
import { isTouchDevice } from "../_common/helpers/isTouchDevice";
import { GiftCardRoutes } from "../GiftCard/GiftCardRoutes";
import { getTranslatedOrderType } from "../OrderSettings/helpers/orderSettingsHelpers";
import { GCAvailableProvider } from "../GiftCard/GiftCardsAvailableContext";

export const App = (props) => {
  const {
    appSettingsContext,
    appLanguage,
    appDefaultLabelsPath,
    merchantConfigContext,
    appLabels,
    customHeadScriptContents,
    customBodyScriptContents,
  } = props;

  const cws5ModulesContext = useContext(CWS5ModuleContext);
  const isOnlineOrderServicesEnabled = cws5ModulesContext.isOnlineOrderingEnabled;
  const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";

  const { skin } = merchantConfigContext;

  const { orderType, updateOrderType } = useOrderType();

  const [isSessionExpiredDialog, setIsSessionExpiredDialog] = useState(false);

  const userRoleContext = useUserRoleSetup(setIsSessionExpiredDialog);
  const path = useWindowPath()[0];
  /** this ref works as a flag for determining if 1301 should be called or not */
  const triggerMenuRef = useRef(true);
  // Retrieve all merchant stores
  const [allStores, setAllStores] = useState({
    data: null,
    error: "",
    statusCode: "",
  });

  useEffect(() => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__activeItemInCustomizationModel");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
    window.location.href = window.location.href.replace("?customization-modal", "");
  }, []);

  /** this state will prevent additional 1300 calls is a call is already in processs */
  const [isCalling1300, setIsCalling1300] = useState(false);
  useEffect(() => {
    if (
      (orderType ||
        window.location.href.includes("/register") ||
        window.location.href.includes("/add-money") ||
        window.location.href.includes("/account") ||
        window.location.href.includes("order-store")) &&
      allStores.data === null &&
      !window.location.href.includes("account/delivery-address") &&
      !window.location.href.includes("change-password") &&
      !window.location.href.includes("account/payment-methods") &&
      !window.location.href.includes("account/account-deletion") &&
      !window.location.href.includes("pay-in-store/add-money") &&
      !isCalling1300
    ) {
      setIsCalling1300(true);
      get1300Stores(
        skin,
        orderType
          ? orderType
          : merchantConfigContext.vexilor.I2.merch_st_active_order_types.includes("pickup")
          ? "pickup"
          : merchantConfigContext.vexilor.I2.merch_st_active_order_types.split(",")[0],
        appLanguage
      ).then((apiStores) => {
        setIsCalling1300(false);
        if (apiStores.error) setAllStores(apiStores);
        else setAllStores({ data: apiStores });
      });
    }
  }, [orderType, window.location.href, path]);

  const [orderTimeContext, setOrderTimeContext] = useState(null);
  const [activeOrderStore, setActiveOrderStore] = useState(null);
  const updateActiveOrderStore = async (store) => {
    if (!!store) {
      let storeCopy = { ...store };
      storeCopy = setStoreHoursProperties(storeCopy);

      if (storeCopy.vexilorConfig) {
        setActiveOrderStore(storeCopy);
        localforage.setItem(skin + "__activeOrderStore", storeCopy);
      } else {
        let storeCopyWithVexilorConfig = await getStore1306Config(skin, appLanguage, storeCopy);
        if (storeCopyWithVexilorConfig) {
          setActiveOrderStore(storeCopyWithVexilorConfig);
          localforage.setItem(skin + "__activeOrderStore", storeCopyWithVexilorConfig);
        }
      }
    } else {
      localforage.removeItem(skin + "__activeOrderStore");
      setActiveOrderStore(null);
    }
    triggerMenuRef.current = true;
  };

  const history = useHistory();

  // Retrieve order store from storage on any page except for Dashboard
  useEffect(() => {
    //const hashSplit = window.location.hash.split("/");
    //const isDashboard = hashSplit[hashSplit.length - 1] === "dashboard";
    const isDinein = path.hash.includes("dinein");
    const isOrderStatus = path.hash.includes("order-status");
    if (!activeOrderStore && !isDinein && !isOrderStatus) {
      localforage.getItem(skin + "__activeOrderStore").then((storedOrderStore) => {
        if (storedOrderStore) {
          setActiveOrderStore(storedOrderStore);
        } else {
          // order store is not in context or storage
          if (
            !window.location.href.includes("direct") &&
            !window.location.href.includes("order-type") &&
            !window.location.href.includes("order-store") &&
            !window.location.href.includes("order-status") &&
            !window.location.href.includes("delivery-address") &&
            !window.location.href.includes("recent-orders") &&
            !window.location.href.includes("rewards") &&
            !window.location.href.includes("login-register") &&
            !window.location.href.includes("register") &&
            !window.location.href.includes("account") &&
            !window.location.href.includes("pay-in-store") &&
            !window.location.href.includes("past-orders") &&
            !window.location.href.includes("active-orders") &&
            !window.location.href.includes("gift-card")
          ) {
            history.push("/dashboard");
          }
        }
      });
    }
  }, [activeOrderStore]);

  // Retrieve & Update Order Store Context
  const [storeContext, setStoreContext] = useState(null);
  const [activeOrderStoreTable, setActiveOrderStoreTable] = useState(null);

  useEffect(() => {
    setStoreContext({
      activeOrderStore,
      activeOrderStoreTable,
      updateActiveOrderStore,
      updateActiveOrderStoreTable,
    });
  }, [activeOrderStore, activeOrderStoreTable]);

  useEffect(() => {
    localforage.getItem(skin + "__activeOrderStoreTable").then((storedTableId) => {
      if (storedTableId) setActiveOrderStoreTable(storedTableId);
    });
  }, []);

  const updateActiveOrderStoreTable = (tableId) => {
    setActiveOrderStoreTable(tableId);
    localforage.setItem(skin + "__activeOrderStoreTable", tableId);
  };

  const [foodMenu, setFoodMenu] = useState({
    activeTopCategory: null,
    activeSubcategory: null,
    activeItem: null,
    activeFeaturedItems: null,
    isEmpty: false,
    updateMenu: (newMenu) => setFoodMenu(newMenu),
  });

  const [gcAvailable, setGCAvailable] = useState({
    giftCards: null,
    isEmpty: false,
    updateGCAvailable: (giftCards) => setGCAvailable(giftCards),
  });

  const [favoriteOrderItems, setFavoriteOrderItems] = useState("idle");
  useEffect(() => {
    localforage.getItem(skin + "__menuFavorites").then((storedFavorites) => {
      if (storedFavorites && storedFavorites.length > 0) setFavoriteOrderItems(storedFavorites);
      else setFavoriteOrderItems(null);
    });
  }, []);

  useEffect(() => {
    if (favoriteOrderItems !== "idle") {
      const updatedFoodMenu = {
        ...foodMenu,
        favorites: favoriteOrderItems,
        updateFavorites: (updatedFavorites) => {
          const newFavorites = updatedFavorites.length > 0 ? updatedFavorites : null;
          setFavoriteOrderItems(newFavorites);
          localforage.setItem(skin + "__menuFavorites", newFavorites);
        },
      };

      setFoodMenu(updatedFoodMenu);
    }
  }, [favoriteOrderItems]);

  const [orderTime, setOrderTime] = useState(null);
  const resetOrderTime = () => {
    updateOrderTime({
      value: null,
      displayValue: "",
    });

    if (
      window.location.href.includes("online-ordering") &&
      !window.location.href.includes("online-ordering/dinein") &&
      !window.location.href.includes("recent-orders")
    ) {
      history.push("/dashboard");
    }
  };

  const useStoredOrderTypeAndTimeValues = async (orderTime, orderType, orderStore) => {
    let orderStoreWithConfig = orderStore.vexilorConfig
      ? orderStore
      : await getStore1306Config(skin, appLanguage, orderStore);
    //let storedOrderDate = await localforage.getItem(skin + "__orderDate");
    orderStoreWithConfig = setStoreHoursProperties(orderStoreWithConfig);

    let orderingDate = new Date();
    let selectedDayApiOpeningTime = getOpeningTime(orderStore.hours, orderingDate.getDay());
    let selectedDayApiClosingTime = getClosingTime(orderStore.hours, orderingDate.getDay());

    let actualStoreHours = getFormattedStoreHoursWithMidnight(
      selectedDayApiOpeningTime,
      selectedDayApiClosingTime,
      orderingDate,
      orderStore
    );

    let openTime = actualStoreHours.formattedOpeningTime;
    if (orderTime.value < openTime) {
      orderTime.value = openTime;
    }
    const formattedStoreOverrides =
      orderType === "dinein"
        ? []
        : formatStoreTimeOverrides(
            orderType === "delivery"
              ? orderStoreWithConfig.vexilorConfig.delivery_min_interval_override
              : orderStoreWithConfig.vexilorConfig.pickup_min_interval_override,
            orderTime.value
          );
    const blockedTimeIntervals =
      Object.keys(formattedStoreOverrides).length === 0
        ? []
        : formattedStoreOverrides[getDayOfWeekFromIndex(orderTime.value.getDay())].filter(
            (interval) => interval.isBlocked
          );

    const tempMinInterval = getMinInterval(orderStoreWithConfig.vexilorConfig, orderType, skin);
    let tempOrderTime = new Date();
    tempOrderTime.setTime(tempOrderTime.getTime() + tempMinInterval * 60000);

    /** There is a stored order time and it is ASAP */
    if (
      orderTime.displayValue &&
      orderTime.displayValue.includes(appLabels["order"]["asap"]) &&
      isValidOrderTime(orderStoreWithConfig, { value: orderTime }) &&
      orderStoreWithConfig.isOpen &&
      !isTimeSlotBlocked(orderTime.value, blockedTimeIntervals) &&
      !isTimeSlotBlocked(tempOrderTime, blockedTimeIntervals)
    ) {
      if (orderStoreWithConfig.vexilorConfig) {
        const minInterval = getMinInterval(orderStoreWithConfig.vexilorConfig, orderType, skin);
        const orderTime = new Date();
        orderTime.setTime(orderTime.getTime() + minInterval * 60000);
        updateOrderTime({
          value: new Date(orderTime),
          displayValue: `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`,
          triggerMenu: true,
          trueBusinessDate: getTrueBusinessDate(orderTime, orderStoreWithConfig),
        });

        localforage.setItem(
          skin + "__trueBusinessDate",
          getTrueBusinessDate(orderTime, orderStoreWithConfig)
        );
      }
    } else {
      /** if the stored order time is not ASAP, update it to the first available order time compared to current time */

      /**
       * if store is open set the time as asap
       * else set the time as the actual value
       */

      const storeConfig = orderStoreWithConfig.vexilorConfig;
      const minInterval = getMinInterval(storeConfig, orderType, skin);

      let tempStoredOrderTime;
      if (orderStoreWithConfig.isOpen) {
        const formattedStoreOverrides =
          orderType === "dinein"
            ? []
            : formatStoreTimeOverrides(
                orderType === "delivery"
                  ? storeConfig.delivery_min_interval_override
                  : storeConfig.pickup_min_interval_override,
                new Date()
              );
        const blockedTimeIntervals =
          Object.keys(formattedStoreOverrides).length === 0
            ? []
            : formattedStoreOverrides[getDayOfWeekFromIndex(new Date().getDay())].filter(
                (interval) => interval.isBlocked
              );

        let tempOrderTime = new Date(orderTime.value);
        tempOrderTime.setTime(tempOrderTime.getTime() + tempMinInterval * 60000);
        const currentTime = new Date();

        // if order time is not blocked
        if (
          !isTimeSlotBlocked(new Date(orderTime.value), blockedTimeIntervals) &&
          isValidOrderTime(orderStoreWithConfig, {
            value: {
              value: tempOrderTime,
              trueBusinessDate: getTrueBusinessDate(tempOrderTime, orderStoreWithConfig),
            },
          }) &&
          !isTimeSlotBlocked(tempOrderTime, blockedTimeIntervals) &&
          isWithinBusinessHours(orderStoreWithConfig, orderTime.value) &&
          tempOrderTime.getTime() >= currentTime.getTime()
        ) {
          tempStoredOrderTime = {
            value: new Date(orderTime.value),
            displayValue:
              minInterval >= 1440 ||
              !orderTime.displayValue.includes(appLabels["order"]["asap"]) ||
              Math.abs(new Date().getTime() - orderTime.value.getTime()) / 60000 > minInterval // if minimum interval is more than 24 hrs, do not use ASAP
                ? `${getTimeAmPm(orderTime.value)}`
                : `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`,
            triggerMenu: true,
            trueBusinessDate: getTrueBusinessDate(orderTime.value, orderStoreWithConfig),
          };
          localforage.setItem(
            skin + "__trueBusinessDate",
            getTrueBusinessDate(orderTime.value, orderStoreWithConfig)
          );
        } else {
          //if order time is blocked
          //calculate the next available time slot after the block finishes

          let lookingForNextAvailableTime = true;

          let now = new Date();
          let iterationIndex = 0;
          const pickupIncrement = storeConfig.pickup_inc_interval;
          while (lookingForNextAvailableTime) {
            const roundedMinutes = roundToNearestQuarter(now.getMinutes());
            const roundedTime = roundTime(now, roundedMinutes, true);

            const increment = getIntermittentIncrementInterval(
              formattedStoreOverrides,
              roundedTime,
              pickupIncrement
            );
            const nextTime = newTime(roundedTime, iterationIndex === 0 ? minInterval : increment); // "In {pickupMinInterval OR pickupIncrement} min"
            const nextTimeWithResetSeconds = new Date(nextTime);
            nextTimeWithResetSeconds.setSeconds(0, 0); // reset seconds to 0 to allow pre-select based on storage value

            const nextTimeWithResetSecondsPlusInterval = new Date(nextTimeWithResetSeconds);
            nextTimeWithResetSecondsPlusInterval.setTime(
              nextTimeWithResetSecondsPlusInterval.getTime() + tempMinInterval * 60000
            );
            if (
              !isTimeSlotBlocked(nextTimeWithResetSeconds, blockedTimeIntervals) &&
              isValidOrderTime(orderStoreWithConfig, {
                value: {
                  value: nextTimeWithResetSeconds,
                  trueBusinessDate: getTrueBusinessDate(
                    nextTimeWithResetSeconds,
                    orderStoreWithConfig
                  ),
                },
              }) &&
              isWithinBusinessHours(orderStoreWithConfig, nextTimeWithResetSeconds)
            ) {
              tempStoredOrderTime = {
                value: new Date(nextTimeWithResetSeconds),
                displayValue:
                  minInterval >= 1440 ||
                  !orderTime.displayValue.includes(appLabels["order"]["asap"]) ||
                  Math.abs(new Date().getTime() - nextTimeWithResetSeconds.getTime()) / 60000 >
                    minInterval // if minimum interval is more than 24 hrs, do not use ASAP
                    ? `${getTimeAmPm(nextTimeWithResetSeconds)}`
                    : `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`,
                triggerMenu: true,
                trueBusinessDate: getTrueBusinessDate(
                  nextTimeWithResetSeconds,
                  orderStoreWithConfig
                ),
              };

              localforage.setItem(
                skin + "__trueBusinessDate",
                getTrueBusinessDate(nextTimeWithResetSeconds, orderStoreWithConfig)
              );
              lookingForNextAvailableTime = false;
              break;
            } else {
              now = new Date(nextTimeWithResetSeconds);
            }
            iterationIndex++;

            // fail safe to break out of the loop
            if (iterationIndex > 1000) {
              break;
            }
          }
        }
      } else {
        // if the store is closed
        // Store is closed and/or ordering for future date
        let dayInFutureIndex = 0;

        let today = new Date();
        let futureDate = new Date();
        let nextDay = new Date();
        nextDay.setDate(today.getDate() + dayInFutureIndex);
        futureDate = nextDay;
        let selectedDay = futureDate;

        let selectedDayApiOpeningTime = getOpeningTime(
          orderStoreWithConfig.hours,
          selectedDay.getDay()
        );
        let selectedDayApiClosingTime = getClosingTime(
          orderStoreWithConfig.hours,
          selectedDay.getDay()
        );

        let realHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          orderStoreWithConfig
        );

        let todayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          today,
          orderStoreWithConfig
        );

        let futureDatePlusMinInterval = new Date();
        futureDatePlusMinInterval.setTime(today.getTime() + minInterval * 60000);

        while (
          (isNaN(selectedDayApiOpeningTime.replace(":", "")) &&
            selectedDayApiOpeningTime !== "closed") ||
          (isNaN(selectedDayApiClosingTime.replace(":", "")) &&
            selectedDayApiClosingTime !== "closed") ||
          (realHours !== "closed" && isNaN(realHours.formattedClosingTime.getTime())) ||
          (realHours !== "closed" && isNaN(realHours.formattedOpeningTime.getTime())) ||
          realHours === "closed" ||
          orderStoreWithConfig.hours[getDayOfWeekFromIndex(today.getDay() + dayInFutureIndex)] ===
            "closed" ||
          (todayStoreHours !== "closed" &&
            futureDate.getTime() >= todayStoreHours.formattedClosingTime.getTime() &&
            futureDate.getDate() === todayStoreHours.formattedClosingTime.getDate()) ||
          today.getTime() > futureDate.getTime() ||
          !isValidOrderTime(orderStoreWithConfig, {
            value: {
              value: futureDatePlusMinInterval,
              trueBusinessDate: getTrueBusinessDate(
                futureDatePlusMinInterval,
                orderStoreWithConfig
              ),
            },
          })
        ) {
          dayInFutureIndex++;
          let nextDay = new Date();
          nextDay.setDate(today.getDate() + dayInFutureIndex);
          futureDate = nextDay;
          selectedDayApiOpeningTime = getOpeningTime(
            orderStoreWithConfig.hours,
            futureDate.getDay()
          );
          selectedDayApiClosingTime = getClosingTime(
            orderStoreWithConfig.hours,
            futureDate.getDay()
          );

          realHours = getFormattedStoreHoursWithMidnight(
            selectedDayApiOpeningTime,
            selectedDayApiClosingTime,
            futureDate,
            orderStoreWithConfig
          );

          if (realHours === "closed") {
            futureDate.setTime(
              new Date(new Date(futureDate).setDate(futureDate.getDate() + 1)).getTime()
            );
          } else {
            futureDate.setTime(realHours.formattedOpeningTime.getTime());
          }

          futureDatePlusMinInterval.setTime(futureDate.getTime() + minInterval * 60000);

          if (dayInFutureIndex >= 7) {
            break;
          }
        }

        selectedDayApiOpeningTime = getOpeningTime(orderStoreWithConfig.hours, futureDate.getDay());
        selectedDayApiClosingTime = getClosingTime(orderStoreWithConfig.hours, futureDate.getDay());

        const selectedDayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          orderStoreWithConfig
        );

        let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
        let selectedDayStoreOpeningTimeWithMinInterval = new Date(selectedDayStoreOpeningTime);
        selectedDayStoreOpeningTimeWithMinInterval.setTime(
          selectedDayStoreOpeningTimeWithMinInterval.getTime() + minInterval * 60000
        );

        /** Make sure when the blocked intervals are being checked, they have the same date as the calculated time
         * otherwise isTimeSlotBlocked could return incorrect results
         */
        let blockedTimeIntervalsForTheSelectedDay =
          Object.keys(formattedStoreOverrides).length === 0
            ? []
            : formattedStoreOverrides[
                getDayOfWeekFromIndex(new Date(selectedDayStoreOpeningTimeWithMinInterval).getDay())
              ].filter((interval) => interval.isBlocked);

        if (blockedTimeIntervalsForTheSelectedDay.length > 0) {
          blockedTimeIntervalsForTheSelectedDay.forEach((blockedInterval) => {
            blockedInterval.end.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
            blockedInterval.end.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
            blockedInterval.end.setFullYear(
              selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
            );

            blockedInterval.start.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
            blockedInterval.start.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
            blockedInterval.start.setFullYear(
              selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
            );
          });
        }

        while (
          isTimeSlotBlocked(
            new Date(selectedDayStoreOpeningTimeWithMinInterval),
            blockedTimeIntervalsForTheSelectedDay
          )
        ) {
          selectedDayStoreOpeningTimeWithMinInterval.setTime(
            selectedDayStoreOpeningTimeWithMinInterval.getTime() + minInterval * 60000
          );

          if (blockedTimeIntervalsForTheSelectedDay.length > 0) {
            blockedTimeIntervalsForTheSelectedDay.forEach((blockedInterval) => {
              blockedInterval.end.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
              blockedInterval.end.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
              blockedInterval.end.setFullYear(
                selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
              );

              blockedInterval.start.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
              blockedInterval.start.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
              blockedInterval.start.setFullYear(
                selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
              );
            });
          }
        }

        tempStoredOrderTime = {
          value: new Date(selectedDayStoreOpeningTimeWithMinInterval),
          displayValue: getTimeAmPm(selectedDayStoreOpeningTimeWithMinInterval),
          triggerMenu: true,
          trueBusinessDate: getTrueBusinessDate(
            selectedDayStoreOpeningTimeWithMinInterval,
            orderStoreWithConfig
          ),
        };

        localforage.setItem(
          skin + "__trueBusinessDate",
          getTrueBusinessDate(selectedDayStoreOpeningTimeWithMinInterval, orderStoreWithConfig)
        );
      }

      updateOrderTime(tempStoredOrderTime);
    }
  };

  const calculateNextAvailableOrderTime = async (foundStore, orderType) => {
    /* Set the order time as now + min interval */

    const storeConfig = foundStore.vexilorConfig;

    const formattedStoreOverrides =
      orderType === "dinein"
        ? []
        : formatStoreTimeOverrides(
            orderType === "delivery"
              ? storeConfig.delivery_min_interval_override
              : storeConfig.pickup_min_interval_override,
            new Date()
          );
    const blockedTimeIntervals =
      Object.keys(formattedStoreOverrides).length === 0
        ? []
        : formattedStoreOverrides[getDayOfWeekFromIndex(new Date().getDay())].filter(
            (interval) => interval.isBlocked
          );

    if (storeConfig) {
      const minInterval = getMinInterval(storeConfig, orderType, skin);

      const nowPlusMinInterval = new Date();
      nowPlusMinInterval.setTime(nowPlusMinInterval.getTime() + minInterval * 60000);

      if (
        foundStore.isOpen &&
        isValidOrderTime(foundStore, {
          value: {
            value: nowPlusMinInterval,
            trueBusinessDate: getTrueBusinessDate(nowPlusMinInterval, foundStore),
          },
        })
      ) {
        const orderTime = new Date();
        orderTime.setTime(orderTime.getTime() + minInterval * 60000);
        if (!isTimeSlotBlocked(new Date(orderTime), blockedTimeIntervals)) {
          updateOrderTime({
            value: new Date(orderTime),
            displayValue:
              minInterval >= 1440 // if minimum interval is more than 24 hrs, do not use ASAP
                ? `${getTimeAmPm(orderTime)}`
                : `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`,
            triggerMenu: true,
            trueBusinessDate: getTrueBusinessDate(orderTime, foundStore),
          });
          localforage.setItem(
            skin + "__trueBusinessDate",
            getTrueBusinessDate(orderTime, foundStore)
          );
        } else {
          //if the order time falls in a blocked time interval
          //calculate the next available time slot after the block finishes

          let lookingForNextAvailableTime = true;

          let now = new Date();
          let iterationIndex = 0;
          const pickupIncrement = storeConfig.pickup_inc_interval;
          while (lookingForNextAvailableTime) {
            const roundedMinutes = roundToNearestQuarter(now.getMinutes());
            const roundedTime = roundTime(now, roundedMinutes, true);

            const increment = getIntermittentIncrementInterval(
              formattedStoreOverrides,
              roundedTime,
              pickupIncrement
            );
            const nextTime = newTime(roundedTime, iterationIndex === 0 ? minInterval : increment); // "In {pickupMinInterval OR pickupIncrement} min"
            const nextTimeWithResetSeconds = new Date(nextTime);
            nextTimeWithResetSeconds.setSeconds(0, 0); // reset seconds to 0 to allow pre-select based on storage value
            if (!isTimeSlotBlocked(nextTimeWithResetSeconds, blockedTimeIntervals)) {
              lookingForNextAvailableTime = false;
              updateOrderTime({
                value: new Date(nextTimeWithResetSeconds),
                displayValue: `${getTimeAmPm(nextTimeWithResetSeconds)}`,
                triggerMenu: true,
                trueBusinessDate: getTrueBusinessDate(nextTimeWithResetSeconds, foundStore),
              });
              localforage.setItem(
                skin + "__trueBusinessDate",
                getTrueBusinessDate(nextTimeWithResetSeconds, foundStore)
              );
              break;
            } else {
              now = new Date(nextTimeWithResetSeconds);
            }
            iterationIndex++;

            // fail safe to break out of the loop
            if (iterationIndex > 1000) {
              break;
            }
          }
        }
      } else {
        // Store is closed and/or ordering for future date
        let dayInFutureIndex = 0;

        /** if ordering for a future date, use the default min pickup/delivery interval */
        let defaultMinInterval =
          orderType === "delivery"
            ? storeConfig.delivery_min_interval
            : storeConfig.pickup_min_interval;

        let today = new Date();
        let futureDate = new Date();
        let nextDay = new Date();
        nextDay.setDate(today.getDate() + dayInFutureIndex);
        futureDate = nextDay;
        let selectedDay = futureDate;

        let selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, selectedDay.getDay());
        let selectedDayApiClosingTime = getClosingTime(foundStore.hours, selectedDay.getDay());

        let realHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          foundStore
        );
        let todayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          today,
          foundStore
        );

        let futureDatePlusMinInterval = new Date();
        futureDatePlusMinInterval.setTime(today.getTime() + minInterval * 60000);

        while (
          (isNaN(selectedDayApiOpeningTime.replace(":", "")) &&
            selectedDayApiOpeningTime !== "closed") ||
          (isNaN(selectedDayApiClosingTime.replace(":", "")) &&
            selectedDayApiClosingTime !== "closed") ||
          (realHours !== "closed" && isNaN(realHours.formattedClosingTime.getTime())) ||
          (realHours !== "closed" && isNaN(realHours.formattedOpeningTime.getTime())) ||
          realHours === "closed" ||
          foundStore.hours[getDayOfWeekFromIndex(today.getDay() + dayInFutureIndex)] === "closed" ||
          (todayStoreHours !== "closed" &&
            futureDate.getTime() >= todayStoreHours.formattedClosingTime.getTime() &&
            futureDate.getDate() === todayStoreHours.formattedClosingTime.getDate()) ||
          today.getTime() > futureDate.getTime() ||
          !isValidOrderTime(foundStore, {
            value: {
              value: futureDatePlusMinInterval,
              trueBusinessDate: getTrueBusinessDate(futureDatePlusMinInterval, foundStore),
            },
          })
        ) {
          dayInFutureIndex++;
          let nextDay = new Date();
          nextDay.setDate(today.getDate() + dayInFutureIndex);
          futureDate = nextDay;
          selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, futureDate.getDay());
          selectedDayApiClosingTime = getClosingTime(foundStore.hours, futureDate.getDay());

          realHours = getFormattedStoreHoursWithMidnight(
            selectedDayApiOpeningTime,
            selectedDayApiClosingTime,
            futureDate,
            foundStore
          );

          if (realHours !== "closed") {
            futureDate.setTime(realHours.formattedOpeningTime.getTime());
          }

          futureDatePlusMinInterval.setTime(futureDate.getTime() + minInterval * 60000);

          if (dayInFutureIndex >= 7) {
            break;
          }
        }
        const selectedDayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          foundStore
        );

        let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
        let selectedDayStoreOpeningTimeWithMinInterval = new Date(selectedDayStoreOpeningTime);
        selectedDayStoreOpeningTimeWithMinInterval.setTime(
          selectedDayStoreOpeningTimeWithMinInterval.getTime() + defaultMinInterval * 60000
        );

        while (
          isTimeSlotBlocked(
            new Date(selectedDayStoreOpeningTimeWithMinInterval),
            blockedTimeIntervals
          )
        ) {
          selectedDayStoreOpeningTimeWithMinInterval.setTime(
            selectedDayStoreOpeningTimeWithMinInterval.getTime() + defaultMinInterval * 60000
          );
        }

        updateOrderTime({
          value: new Date(selectedDayStoreOpeningTimeWithMinInterval),
          displayValue: getTimeAmPm(selectedDayStoreOpeningTimeWithMinInterval),
          triggerMenu: true,
          trueBusinessDate: getTrueBusinessDate(
            selectedDayStoreOpeningTimeWithMinInterval,
            foundStore
          ),
        });
        localforage.setItem(
          skin + "__trueBusinessDate",
          getTrueBusinessDate(selectedDayStoreOpeningTimeWithMinInterval, foundStore)
        );
      }
    }
  };

  const resetAllPreviousSelectedStoreSettings = () => {
    localforage.removeItem(skin + "__orderTime");
    localforage.removeItem(skin + "__orderDate");
    localforage.removeItem(skin + "__activeOrderType");
    localforage.removeItem(skin + "__activeOrderStore");
    localforage.removeItem(skin + "__stadium-schema");
    updateActiveOrderStore(null);
    updateOrderType("");
    resetOrderTime();
  };

  useEffect(() => {
    if (appLabels && appLabels.order) {
      localforage.getItem(skin + "__orderTime").then((orderTime) => {
        localforage.getItem(skin + "__activeOrderType").then((orderType) => {
          localforage.getItem(skin + "__activeOrderStore").then((orderStore) => {
            if (orderTime && orderTime.value && orderType && orderStore) {
              if (!orderTime.trueBusinessDate /*|| orderType === "dinein"*/) {
                // TODO: uncomment the dinein conditional
                localforage.removeItem(skin + "__orderTime");
                localforage.removeItem(skin + "__orderDate");
                localforage.removeItem(skin + "__activeOrderType");
                localforage.removeItem(skin + "__activeOrderStore");
                updateActiveOrderStore(null);
                updateOrderType("");
                resetOrderTime();
                updateCart(null);
              } else {
                const currentTime = new Date();
                /** if the stored order time is not ASAP and the it is currently passed, reset the time */
                if (
                  currentTime.getTime() >= orderTime.value.getTime() &&
                  !orderTime.displayValue.includes(appLabels["order"]["asap"])
                ) {
                  //calculate the next available order time
                  updateLocationData(orderStore, orderType, skin, appLanguage).then(
                    (updatedStore) => {
                      if (updatedStore) {
                        updatedStore = setStoreHoursProperties(updatedStore);
                        calculateNextAvailableOrderTime(updatedStore, orderType);
                      } else {
                        resetAllPreviousSelectedStoreSettings();
                      }
                    }
                  );
                } else {
                  updateLocationData(orderStore, orderType, skin, appLanguage).then(
                    (updatedStore) => {
                      if (updatedStore) {
                        useStoredOrderTypeAndTimeValues(orderTime, orderType, updatedStore);
                      } else {
                        resetAllPreviousSelectedStoreSettings();
                      }
                    }
                  );
                }
              }
            } else {
              /** If resetting time, then clear everything order related to prevent any white screen issues */
              if (!window.location.href.includes("direct")) {
                resetAllPreviousSelectedStoreSettings();
              }
            }
          });
        });
      });
    }
  }, [appLabels]);

  const updateOrderTime = (newOrderTime) => {
    setOrderTime(newOrderTime);
    localforage.setItem(skin + "__orderTime", newOrderTime);

    /** updated the order date in storage based on the new order time */
    if (newOrderTime.value) {
      const orderDate = new Date(newOrderTime.value);
      const orderDateToStore = {
        value: orderDate.getMonth() + "-" + orderDate.getDate(), // the day of a date as a number (1-31)
        displayValue:
          orderDate.getDate() === new Date().getDate()
            ? appLabels["order"]["today"]
            : prettifyDate(orderDate, appLanguage), // stringified Date object
        dateObject: orderDate,
      };

      localforage.setItem(skin + "__orderDate", orderDateToStore);
    }
  };

  useEffect(() => {
    setOrderTimeContext({
      value: orderTime,
      update: updateOrderTime,
      openOrderTimePopup: false,
    });
  }, [orderTime]);

  // Format Food Menu items into objects
  const formatItems = (apiItems) => {
    const formattedItemsArray = [...apiItems];

    formattedItemsArray.map((apiItem) => {
      // Convert addons into objects
      apiItem.addonGroups.map((addonGroup) => {
        addonGroup.items.map((addon) => {
          //convert addon modifiers to objects
          if (addon.modifierGroups && addon.modifierGroups.length > 0) {
            addon.modifierGroups.map((addonMod) => {
              addonMod.items = arrayToObject(addonMod.items, "id", "modifier");
              return addonMod;
            });
            addon.modifierGroups = arrayToObject(addon.modifierGroups, "id", "addonModifierGroup");
          }
          return addon;
        });

        addonGroup.items = arrayToObject(addonGroup.items, "id", "addon");
        return addonGroup;
      });

      apiItem.addonGroups = arrayToObject(apiItem.addonGroups, "id", "addonGroup");

      // Convert modifiers into objects
      apiItem.modifierGroups.map((modifierGroup) => {
        modifierGroup.items = arrayToObject(modifierGroup.items, "id", "modifier");
        return modifierGroup;
      });

      apiItem.modifierGroups = arrayToObject(apiItem.modifierGroups, "id", "modifierGroup");
      return apiItem;
    });

    return formattedItemsArray;
  };

  const [isCalling1333, setIsCalling1333] = useState(false);

  const getFullMenu = () => {
    setIsCalling1333(true);
    const orderTimestamp =
      orderTime &&
      orderTime.value &&
      orderTime.value !== "Select Time" &&
      getTimestamp(orderTime.value)
        ? getTimestamp(orderTime.value)
        : "";
    callApi1333(
      skin,
      activeOrderStore.storeId,
      appLanguage,
      orderTimestamp,
      getDeviceTypeId(orderType)
    ).then((categoriesData) => {
      const apiCategories = get(categoriesData, "result.I2");
      if (apiCategories && apiCategories.length > 0) {
        let categoriesArray = [];
        for (let key in apiCategories) {
          categoriesArray.push(apiCategories[key].id);
        }
        let topLevelData = {};
        topLevelData.type = apiCategories[0].type;
        topLevelData.list = categoriesArray.join(",");
        topLevelData.value = apiCategories;
        if (get(topLevelData, "type") === "category") {
          // 3 Level Menu - Retrieve Menu Subcategories
          //const apiSubcategories =
          let allItems = [];
          let allSubcategories = [];
          topLevelData.value.forEach((category) => {
            category.subcategories = category.subcat_list;
            delete category.subcat_list;

            category.subcategories.forEach((subcategory) => {
              subcategory.items = subcategory.item_list;
              delete subcategory.item_list;

              allSubcategories.push(subcategory);

              subcategory.items.forEach((item) => {
                allItems.push(item);
              });
            });
          });

          const formattedItems = formatItems(allItems);
          const itemsObject = arrayToObject(formattedItems, "id", "item");

          allSubcategories.forEach((subcategory) => {
            const subcategoryId = subcategory.id;
            const subcategoryItems = {};
            Object.entries(itemsObject).forEach((item) => {
              if (item[1].parentId === subcategoryId) {
                subcategoryItems["item-" + item[1].id] = item[1];
              }
            });
            subcategory.items = subcategoryItems;
          });

          combineMenu(topLevelData.value, allSubcategories);
        } else if (get(topLevelData, "type") === "subCategory") {
          // 2 Level Menu - Retrieve Menu Items
          let allItems = [];
          let allSubcategories = [];
          topLevelData.value.forEach((subcategory) => {
            subcategory.items = subcategory.item_list;
            delete subcategory.item_list;

            allSubcategories.push(subcategory);
            subcategory.items.forEach((item) => {
              allItems.push(item);
            });
          });
          const formattedItems = formatItems(allItems);
          const itemsObject = arrayToObject(formattedItems, "id", "item");

          allSubcategories.forEach((subcategory) => {
            const subcategoryId = subcategory.id;
            const subcategoryItems = {};

            Object.entries(itemsObject).forEach((item) => {
              if (item[1].parentId === subcategoryId) {
                subcategoryItems["item-" + item[1].id] = item[1];
              }
            });

            subcategory.items = subcategoryItems;
          });

          combineMenu(allSubcategories, allSubcategories);
        } else if (
          get(topLevelData, "type") === "productItem" ||
          get(topLevelData, "type") === "comboItem"
        ) {
          const allItems = topLevelData.value;
          formatItems(allItems);
          combineMenu(topLevelData.value, topLevelData.value);
        }
      } else if (!apiCategories || categoriesData.error) {
        setIsCalling1333(false);
        console.error(
          `API 1333 | Error ${categoriesData.error.code} | ${categoriesData.error.message}`
        );
        setFoodMenu({ ...foodMenu, isEmpty: true });
      }
    });
  };

  const getMenuFromApi = () => {
    // Retrieve Menu Categories
    //leaving the parent type field empty will return the top most level of the menu (category or subcategory)

    getFullMenu();
  };

  useEffect(() => {
    if (
      !isCalling1333 &&
      activeOrderStore != null &&
      isOnlineOrderServicesEnabled &&
      ((orderType === "dinein" && orderTime && orderTime.value !== null) ||
        (orderType !== "dinein" &&
          orderTime &&
          orderTime.value !== "Select Time" &&
          orderTime.value !== null)) &&
      orderTime.triggerMenu !== false &&
      triggerMenuRef.current === true &&
      !window.location.href.includes("order-confirmation")
    ) {
      getMenuFromApi();
    }
  }, [orderType, activeOrderStore, isOnlineOrderServicesEnabled, orderTime, triggerMenuRef]);

  // Combine Categories and Subcategories into foodMenu
  const combineMenu = (topCategories, subcategories) => {
    let tempFoodMenu = {};
    let menuLevel = "";

    if (topCategories[0].type === "category") {
      menuLevel = "threeLevels";
      topCategories.forEach((category) => {
        category.subcategories = [];
        subcategories.forEach((subcategory) => {
          if (!category.subcategories) category.subcategories = {};
          if (subcategory.parentId === category.id) {
            category.subcategories["subcategory-" + subcategory.id] = subcategory;
          }
          tempFoodMenu["category-" + category.id] = category;
        });
      });
    } else if (topCategories[0].type === "subCategory") {
      menuLevel = "twoLevels";
      topCategories.forEach((subcategory) => {
        tempFoodMenu["subcategory-" + subcategory.id] = subcategory;
      });
    } else if (topCategories[0].type === "productItem" || topCategories[0].type === "comboItem") {
      /**if the most top level is a productItem or comboItem, this means that the positioning template only has one subcat with items in it
      setup the menu in a way that is similar to a subcategory based menu but with only one subcategory **/
      menuLevel = "twoLevels";
      tempFoodMenu["subcategory-" + topCategories[0].parentId] = {};
      tempFoodMenu["subcategory-" + topCategories[0].parentId].id = topCategories[0].parentId;
      tempFoodMenu["subcategory-" + topCategories[0].parentId].name = appLabels["order"]["menu"];
      tempFoodMenu["subcategory-" + topCategories[0].parentId].noOfItems = topCategories.length;
      tempFoodMenu["subcategory-" + topCategories[0].parentId].items = {};
      topCategories.forEach((item) => {
        tempFoodMenu["subcategory-" + topCategories[0].parentId].items["item-" + item.id] = item;
      });
    }

    // /** if menu got pulled and there are item in the cart, update the cart once more to trigger Cart.js to call 1307 */
    // if (cartData.quantity && cartData.quantity > 0) {
    //   updateCart(cartData.value);
    // }

    const newMenu = { ...foodMenu, apiData: tempFoodMenu, menuLevel, isEmpty: false };
    setFoodMenu(newMenu);

    localforage.setItem(skin + "__storedMenu", {
      data: tempFoodMenu,
      menuLevel,
    });
    updateOrderTime({ ...orderTime, triggerMenu: false });
    setIsCalling1333(false);
  };

  const loyaltyContext = useLoyaltySetup(); // TODO: implement in other components

  // const { orderContext, isOrderTimeModal } = useOrderSetup(setErrorNotificationText, setIsErrorNotification);

  // TODO: remove once userOrderSetup is fully implemented
  const [orderContext, setOrderContext] = useState(null);
  const [isOrderTimeModal, setIsOrderTimeModal] = useState(false);

  //const [isErrorNotification, setIsErrorNotification] = useState(false);
  //const [errorNotificationText, setErrorNotificationText] = useState("");

  useEffect(() => {
    if (orderType !== null && orderTime) {
      const orderSettings = {
        checkOrderSettings,
        checkStoreAvailability,
        openOrderTimePopupWidget,
        closeOrderTimePopupWidget,
      };

      setOrderContext(orderSettings);
    }
  }, [orderType, orderTime, activeOrderStore]);

  function openOrderTimePopupWidget(timeContext) {
    setOrderTimeContext({ ...timeContext, openOrderTimePopup: true });
  }

  function closeOrderTimePopupWidget(timeContext) {
    setOrderTimeContext({ ...timeContext, openOrderTimePopup: false });
  }

  // Called whenever required order settings need to be checked
  function checkOrderSettings(orderTypeParam, storeContextParam, orderTimeContextParam) {
    sessionStorage.removeItem(skin + "__featuredItemLink");
    //if order type is not selected redirect to order type screen
    if (
      (!orderTypeParam ||
        (orderTypeParam.hasOwnProperty("value") && orderTypeParam.value === "")) &&
      !orderType
    ) {
      history.push("/online-ordering/order-type");
    } else if (storeContextParam && !storeContextParam.activeOrderStore) {
      if (orderType === "dinein") {
        history.push("/online-ordering/dinein");
      } else if (orderType === "delivery") {
        if (isStadiumSchema) {
          history.push("/online-ordering/seat-section");
        } else {
          history.push("/online-ordering/delivery-address");
        }
      } else {
        history.push("/online-ordering/order-store");
      }
    }
    //if order store is not selected redirect to order stores screen
    else if (!storeContext.activeOrderStore && !storeContextParam) {
      if (orderType === "dinein") {
        history.push("/online-ordering/dinein");
      } else if (orderType === "delivery") {
        if (isStadiumSchema) {
          history.push("/online-ordering/seat-section");
        } else {
          history.push("/online-ordering/delivery-address");
        }
      } else {
        history.push("/online-ordering/order-store");
      }
    } else if (
      orderTimeContextParam &&
      (orderTimeContextParam.value === null ||
        (orderTimeContextParam.value && orderTimeContextParam.value.value === null))
    ) {
      //if order time is not selected
      openOrderTimePopupWidget(orderTimeContext);
    } else if (
      (!orderTimeContextParam && orderTimeContext.value === null) ||
      (!orderTimeContextParam && orderTimeContext.value && orderTimeContext.value.value === null)
    ) {
      //if order time is not selected
      openOrderTimePopupWidget(orderTimeContext);
    } else {
      //else redirect to menu
      history.push("/online-ordering/menu");
    }
  }

  /*function checkPickupAndDeliveryOrderSettings(isButtonClick, isCartButtonClick) {
    if (orderTime.value == null || orderTime.value === "Select Time" || activeOrderStore == null) {
      // No active order time

      if (isCartButtonClick) {
        // Set a flag in session storage so that OrderTimeModal.js can detect if cart button was clicked from dashboard
        sessionStorage.setItem(skin + "__isCartButtonClick", isCartButtonClick);
      }

      const hashSplit = window.location.hash.split("/");
      const isOrderConfirmation = hashSplit[hashSplit.length - 1] === "order-confirmation";
      const isRecentOrders = window.location.href.includes("/online-ordering/recent-orders");

      // Make sure URL doesn't contain outlet_id (user is not currently scanning QR code from outside the app)
      const isDineinFromThirdPartyScanner = window.location.href.includes("outlet_id");

      const isOnFindStorePage =
        window.location.href.includes("/online-ordering/pickup") ||
        window.location.href.includes("/online-ordering/delivery");

      if (isOrderConfirmation) {
        history.push("/dashboard");
      } else if (!isDineinFromThirdPartyScanner && !isRecentOrders && !isOnFindStorePage) {
        setIsOrderTimeModal(true);
      } else if (isRecentOrders && isButtonClick) {
        setIsOrderTimeModal(true);
      }
      //
    } else if (isButtonClick && !isCartButtonClick) {
      // Active order time exists
      if (orderType === "delivery") {
        history.push("/online-ordering/delivery");
      } else if (orderType === "pickup" && window.location.hash !== "#/online-ordering/pickup") {
        history.push({
          pathname: "/online-ordering/menu",
          state: { from: "order-settings-modal" },
        });
      }
      //
    } else if (isCartButtonClick) {
      if (orderType === "delivery") {
        localforage.getItem(skin + "__userDeliveryAddress").then((deliveryAddress) => {
          if (deliveryAddress) {
            history.push("/online-ordering/review-order");
          } else {
            history.push("/online-ordering/delivery");
          }
        });
      } else {
        // Active order time exists
        history.push("/online-ordering/review-order");
      }
    }
  }*/

  function checkStoreAvailability(store) {
    if (store.vexilorConfig) {
      if (store["__isUnavailable"]) {
        // Trigger order settings modal
        setIsOrderTimeModal(true);
      }
    } else {
      isStoreAvailable(skin, appLanguage, store).then((isStoreStillAvailable) => {
        if (!isStoreStillAvailable) {
          // Update active order store parameters based on its unavailability
          const updatedStore = { ...store };
          delete updatedStore.vexilorConfig;
          updatedStore["__isUnavailable"] = true;
          updateActiveOrderStore(updatedStore);
          // Reset active order time
          orderTimeContext.update({
            value: null,
            displayValue: "",
          });

          // Trigger order settings modal
          setIsOrderTimeModal(true);
        }
      });
    }
  }
  // TODO: END OF: remove once userOrderSetup is fully implemented

  const deviceWidth = useWindowSize().width;

  const [isCookieBanner, setIsCookieBanner] = useState(false);
  useEffect(() => {
    checkIfExpiredOrNewCookieBanner(skin).then((isExpiredOrNewCookieBanner) => {
      setIsCookieBanner(isExpiredOrNewCookieBanner);
    });
  }, []);

  /**
   * Active Orders Context Data
   */
  const [activeOrdersData, setActiveOrdersData] = useState("idle");
  useEffect(() => {
    localforage.getItem(skin + "__activeOrders").then((storedActiveOrders) => {
      let activeOrdersData = { updateActiveOrders, activeOrders: [], quantity: 0 };
      if (storedActiveOrders) {
        activeOrdersData.activeOrders = storedActiveOrders;
        activeOrdersData.quantity = storedActiveOrders.length;
      }
      setActiveOrdersData(activeOrdersData);
    });
  }, []);

  const updateActiveOrders = (newActiveOrderData) => {
    const tempActiveOrderData = {
      activeOrders: newActiveOrderData,
      updateActiveOrders,
      quantity: newActiveOrderData.length,
    };
    setActiveOrdersData(tempActiveOrderData);
    localforage.setItem(skin + "__activeOrders", newActiveOrderData);
  };

  // Retrieve & Update Shopping Cart Context
  const [cartData, setCartData] = useState("idle");
  const [gcCartData, setGCCartData] = useState("idle");

  useEffect(() => {
    localforage.getItem(skin + "__cart").then((storedCart) => {
      const quantity = getCartQuantity(storedCart);
      let combinedData = { updateCart, quantity, updateCartData: true };
      if (storedCart) combinedData.value = storedCart;
      setCartData(combinedData);
    });

    localforage.getItem(skin + "__gcCart").then((storedCart) => {
      const quantity = storedCart ? storedCart.length : 0;
      let combinedData = { updateGCCart, quantity, updateCartData: true };
      if (storedCart) combinedData.value = storedCart;
      setGCCartData(combinedData);
    });
  }, []);

  const updateCart = (newCartData, updateCartDataFlag) => {
    let isAnimated = true;
    const quantity = getCartQuantity(newCartData);
    const newData = {
      value: newCartData,
      updateCart,
      isAnimated,
      quantity,
      updateCartData: updateCartDataFlag ? updateCartDataFlag : false,
    };

    setCartData(newData);
    localforage.setItem(skin + "__cart", newCartData);

    setTimeout(() => {
      isAnimated = false;
      setCartData({ ...newData, isAnimated });
    }, 250);
  };

  const updateGCCart = (newCartData, updateCartDataFlag) => {
    let isAnimated = true;
    const quantity = newCartData ? newCartData.length : 0;
    const newData = {
      value: newCartData,
      updateGCCart,
      isAnimated,
      quantity,
      updateCartData: updateCartDataFlag ? updateCartDataFlag : false,
    };

    setGCCartData(newData);
    localforage.setItem(skin + "__gcCart", newCartData);

    setTimeout(() => {
      isAnimated = false;
      setGCCartData({ ...newData, isAnimated });
    }, 250);
  };

  // Inject the Forter script if it's enabled for this merchant in Marqui
  useForterScript(appSettingsContext);

  // Inject script that contains merchant-specific snippets at the end of body
  useCustomBodyScript(customBodyScriptContents);

  useEffect(() => {
    isTouchDevice()
      ? document.body.classList.add("body--touch-device")
      : document.body.classList.remove("body--touch-device");
  }, [deviceWidth]);

  const [isInvalidDirectUrl, setIsInvalidDirectUrl] = useState(false);
  const [directURLOrderType, setDirectURLOrderType] = useState("");
  const checkDirectUrlValidity = async (url) => {
    const querySplit = url.split("?");
    const urlParams = new URLSearchParams(querySplit[1]);

    //if login is required
    if (!userRoleContext.isGuestEnabled && userRoleContext.status !== "logged-in") {
      sessionStorage.setItem(skin + "__lastVisitedLink", url.split("#")[1]);
      history.push("/login-register");
      window.location.reload();
      return;
    }

    if (
      urlParams.has("outlet_id") &&
      urlParams.get("outlet_id") &&
      urlParams.has("order_type") &&
      urlParams.get("order_type")
    ) {
      const storeId = urlParams.get("outlet_id");
      const orderType = urlParams.get("order_type");

      updateOrderType(orderType);
      if (orderType === "delivery") {
        updateOrderTime({
          value: null,
          displayValue: "",
        });
        setTimeout(() => {
          history.push("/online-ordering/delivery-address");
        }, 1000);
      } else {
        get1300Stores(skin, orderType, appLanguage).then(async (apiStores) => {
          if (apiStores) {
            let directLocation = null;
            apiStores.forEach((store) => {
              if (store.storeId === storeId) {
                directLocation = store;
              }
            });

            if (directLocation) {
              directLocation = setStoreHoursProperties(directLocation);
              await updateActiveOrderStore(directLocation);

              if (directLocation && !directLocation.vexilorConfig) {
                getStore1306Config(skin, appLanguage, directLocation).then(
                  (foundStoreWithVexilorConfig) => {
                    if (foundStoreWithVexilorConfig) {
                      calculateNextAvailableOrderTime(foundStoreWithVexilorConfig, orderType);
                      history.push("/online-ordering/menu");
                    }
                  }
                );
              }
            } else {
              console.error("invalid url");
              setDirectURLOrderType(orderType);
              setIsInvalidDirectUrl(true);
            }
          }
        });
      }
    }
  };

  //https://dilbert.givex.com/cws5/pwa-test3/#/direct?outlet_id=196407&order_type=pickup
  //https://dilbert.givex.com/cws5/pwa-test3/#/direct?outlet_id=136842&order_type=pickup

  useEffect(() => {
    if (
      (window.location.href.includes("direct") || path.hash.includes("direct")) &&
      userRoleContext
    ) {
      const urlHash = window.location.hash;
      const url = window.location.href;
      const hasOutletId = urlHash.includes("outlet_id");
      const hasOrderType = urlHash.includes("order_type");

      if (hasOutletId && hasOrderType) {
        checkDirectUrlValidity(url);
      }
    }
  }, [userRoleContext, path]);

  const [billData, setBillData] = useState("idle");

  useEffect(() => {
    localforage.getItem(skin + "__bill").then((storedBill) => {
      let combinedData = { updateBill };
      if (storedBill) {
        combinedData.value = storedBill;
        localforage.getItem(skin + "__billDetails").then((storedBillDetails) => {
          if (storedBillDetails) {
            combinedData.details = storedBillDetails;
          }
        });
      }
      setBillData(combinedData);
    });
  }, []);

  const updateBill = (newBillItems, newBillDetails) => {
    newBillItems = removeDuplicates(newBillItems, (vxl_order_line_id) =>
      JSON.stringify(vxl_order_line_id)
    );
    const newData = { value: newBillItems, details: newBillDetails, updateBill };
    localforage.setItem(skin + "__bill", newBillItems);
    localforage.setItem(skin + "__billDetails", newBillDetails);

    setBillData(newData);
  };

  const [currencyData, setCurrencyData] = useState([merchantConfigContext.merchant.I34, 1]);

  useEffect(() => {
    localforage.getItem(skin + "__currencyData").then((storedCurrencyData) => {
      if (storedCurrencyData) {
        setCurrencyData(storedCurrencyData);
        sessionStorage.setItem(skin + "__currencyData", JSON.stringify(storedCurrencyData));
      } else {
        setCurrencyData([merchantConfigContext.merchant.I34, 1]);
        sessionStorage.setItem(
          skin + "__currencyData",
          JSON.stringify([merchantConfigContext.merchant.I34, 1])
        );
      }
    });

    if (merchantConfigContext.merchant.I59.length === 0) {
      localforage.removeItem(skin + "__currencyData");
      sessionStorage.removeItem(skin + "__currencyData");
    }
  }, []);
  const updateCurrencyData = (newCurrencyData) => {
    localforage.setItem(skin + "__currencyData", newCurrencyData);
    sessionStorage.setItem(skin + "__currencyData", JSON.stringify(newCurrencyData));
    setCurrencyData(newCurrencyData);
  };

  //change for ordering for later pop up message job 583831 - do not delete
  // const [orderingForLater, setOrderingForLater] = useState(false);
  // const [checkDoneForLaterOrder, setCheckDoneForLaterOrder] = useState(false);
  // const [prevActiveOrderStore, setPrevActiveOrderStore] = useState();
  // const { state } = useLocation()

  // useEffect(() => {
  //   if (!prevActiveOrderStore && activeOrderStore) {
  //     setPrevActiveOrderStore(activeOrderStore.storeId)
  //   }
  //   if (activeOrderStore && activeOrderStore.storeId !== prevActiveOrderStore) {
  //     setCheckDoneForLaterOrder(false);
  //     setPrevActiveOrderStore(activeOrderStore.storeId)
  //   } else {
  //     if (!window.location.href.includes("order-confirmation")) {
  //       setCheckDoneForLaterOrder(false);
  //     }
  //   }
  // }, [activeOrderStore]);

  // useEffect(() => {
  //   //check state for when store closing flow is enabled, if it is hide ordering for next business day popup
  //   let isPickNewTimeCase = false;
  //   if (state && state.timeAtOrdering) {
  //     isPickNewTimeCase = true;
  //   }

  //   if (
  //     orderTime &&
  //     orderTime.trueBusinessDate &&
  //     isOnlineOrderServicesEnabled &&
  //     activeOrderStore &&
  //     !checkDoneForLaterOrder &&
  //     !isPickNewTimeCase
  //   ) {
  //     setCheckDoneForLaterOrder(true);
  //     let nextTime = calcNextTime(activeOrderStore, skin);
  //     let today = new Date();
  //     let currentSelectedDate = orderTime.trueBusinessDate;
  //     let dayName = orderTime.trueBusinessDate.toLocaleString("en-us", {
  //       weekday: "long",
  //     });

  //     let orderCutOffTimeMinutes = Number(activeOrderStore.vexilorConfig.ordering_cut_off_time);
  //     const orderDayHours = activeOrderStore.hours[dayName.toLowerCase()];
  //     const orderDayOpeningTime = orderDayHours.split(" - ")[0];

  //     let openTimeStamp = new Date();
  //     openTimeStamp.setDate(orderTime.value.getDate());
  //     openTimeStamp.setHours(orderDayOpeningTime.split(":")[0]);
  //     openTimeStamp.setMinutes(orderDayOpeningTime.split(":")[1]);
  //     openTimeStamp.setSeconds(0);

  //     let yesterdayDate = new Date(orderTime.trueBusinessDate);
  //     yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  //     let yesterdayDayName = yesterdayDate.toLocaleString("en-us", {
  //       weekday: "long",
  //     });
  //     const yesterDayOrderDayHours = activeOrderStore.hours[yesterdayDayName.toLowerCase()];
  //     const yesterdayOrderDayClosingTime = yesterDayOrderDayHours.split(" - ")[1];
  //     let yesterdayClosingDate = new Date();
  //     yesterdayClosingDate.setDate(yesterdayDate.getDate());
  //     yesterdayClosingDate.setHours(yesterdayOrderDayClosingTime.split(":")[0]);
  //     yesterdayClosingDate.setMinutes(yesterdayOrderDayClosingTime.split(":")[1]);
  //     yesterdayClosingDate.setSeconds(0);

  //     let orderTimeVal = orderTime.value;
  //     let dName = orderTimeVal.toLocaleString("en-us", {
  //       weekday: "long",
  //     });

  //     let isTodayOpenPastMidnight = activeOrderStore.openPassedMidnight[dName.toLowerCase()];

  //     const dayHours = activeOrderStore.hours[dName.toLowerCase()];
  //     const dayOpeningTime = dayHours.split(" - ")[0];
  //     const dayClosingTime = dayHours.split(" - ")[1];

  //     let closingDateTime = new Date();
  //     let openingDateTime = new Date();
  //     if (isTodayOpenPastMidnight) {
  //       closingDateTime.setDate(orderTime.value.getDate() + Number(1));
  //       openingDateTime.setDate(orderTime.value.getDate());

  //     } else {
  //       closingDateTime.setDate(orderTime.value.getDate());
  //       openingDateTime.setDate(orderTime.value.getDate());
  //     }
  //     closingDateTime.setHours(dayClosingTime.split(":")[0]);
  //     closingDateTime.setMinutes(dayClosingTime.split(":")[1]);
  //     closingDateTime.setSeconds(0);
  //     openingDateTime.setHours(dayOpeningTime.split(":")[0]);
  //     openingDateTime.setMinutes(dayOpeningTime.split(":")[1]);
  //     openingDateTime.setSeconds(0);

  //     let dateCheck = orderTime.value < openingDateTime;

  //     let orderTimeDate = new Date(orderTime.value);
  //     orderTimeDate.setDate(orderTimeDate.getDate());

  //     let orderTimeDayClosingDate = new Date();
  //     orderTimeDayClosingDate.setDate(orderTimeDate.getDate());
  //     orderTimeDayClosingDate.setHours(yesterdayOrderDayClosingTime.split(":")[0]);
  //     orderTimeDayClosingDate.setMinutes(yesterdayOrderDayClosingTime.split(":")[1]);
  //     orderTimeDayClosingDate.setSeconds(0);

  //     const yesterdayCutOffTimestamp = new Date(
  //       orderTimeDayClosingDate - orderCutOffTimeMinutes * 60000
  //     );

  //     let isStoreOpenToday = isStoreOpenOrClosed(activeOrderStore);
  //     let skipRestOfChecks = false;
  //     if (!isStoreOpenToday) {
  //       setOrderingForLater(true);
  //       setCheckDoneForLaterOrder(true);
  //       skipRestOfChecks = true;
  //     }

  //     let todayDayName = today.toLocaleString("en-us", {
  //       weekday: "long",
  //     });
  //     const todayOrderDayHours = activeOrderStore.hours[todayDayName.toLowerCase()];
  //     const todayOrderDayCloseTime = todayOrderDayHours.split(" - ")[1];
  //     let todayCutOffTimestamp = new Date();
  //     todayCutOffTimestamp.setHours(todayOrderDayCloseTime.split(":")[0]);
  //     todayCutOffTimestamp.setMinutes(todayOrderDayCloseTime.split(":")[1]);
  //     todayCutOffTimestamp.setTime(todayCutOffTimestamp.getTime() - orderCutOffTimeMinutes * 60000);
  //     let isTodayCloseAfterMidnight = activeOrderStore.openPassedMidnight[todayDayName.toLowerCase()];

  //     if (!skipRestOfChecks) {
  //       if (dateCheck && yesterdayCutOffTimestamp < orderTimeVal) {
  //         setOrderingForLater(true);
  //         setCheckDoneForLaterOrder(true);
  //       } else if (
  //         (orderTime.trueBusinessDate.getTime() > today.getTime() &&
  //           today.getDate() !== currentSelectedDate.getDate() &&
  //           nextTime.getDate() !== today.getDate()) ||
  //         (!isTodayCloseAfterMidnight && nextTime.getTime() >= todayCutOffTimestamp.getTime())
  //       ) {
  //         setOrderingForLater(true);
  //         setCheckDoneForLaterOrder(true);
  //       } else {
  //         setOrderingForLater(false);
  //         setCheckDoneForLaterOrder(true);
  //       }
  //     }
  //   }
  // }, [isOnlineOrderServicesEnabled, orderTime, checkDoneForLaterOrder]);

  return (
    <>
      <HelmetComponent customHeadScriptContents={customHeadScriptContents} />
      {userRoleContext &&
        (orderContext || (!orderContext && !isOnlineOrderServicesEnabled)) &&
        loyaltyContext !== "idle" &&
        cartData !== "idle" &&
        activeOrdersData !== "idle" &&
        billData !== "idle" && (
          <>
            <UserRoleProvider value={userRoleContext}>
              <OrderProvider value={orderContext}>
                <LocationsProvider value={allStores.data}>
                  <OrderTypeProvider value={{ value: orderType, update: updateOrderType }}>
                    <StoreProvider value={storeContext}>
                      <OrderTimeProvider value={orderTimeContext}>
                        <LoyaltyProvider value={loyaltyContext}>
                          <MenuProvider value={foodMenu}>
                            <CartProvider value={cartData}>
                              <GCCartProvider value={gcCartData}>
                                <GCAvailableProvider value={gcAvailable}>
                                  <ActiveOrdersProvider value={activeOrdersData}>
                                    <BillProvider value={billData}>
                                      <CurrencyContextProvider
                                        value={{ currencyData, updateCurrencyData }}
                                      >
                                        <div className="App" role="application">
                                          <Route exact path="/">
                                            {!userRoleContext.isGuestEnabled &&
                                            userRoleContext.status !== "logged-in" &&
                                            deviceWidth <= 660 ? (
                                              <PageComponentTemplate
                                                component={<LoginRegister />}
                                                isFooter={false}
                                                hasHeaderLogo={false}
                                                isLanguageButton={true}
                                                pageHeading={appLabels["login-register"]["sign-in"]}
                                              />
                                            ) : (
                                              <>
                                                {!userRoleContext.isGuestEnabled &&
                                                userRoleContext.status !== "logged-in" ? (
                                                  <Redirect to="/login-register" />
                                                ) : (
                                                  <>
                                                    {!window.location.href.includes("direct") && (
                                                      <Redirect to="/dashboard" />
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </Route>
                                          <LoginRegisterRoutes />
                                          <Route path="/welcome" render={() => <Welcome />} />
                                          <Route path="/dashboard" render={() => <Dashboard />} />
                                          <Route
                                            path="/online-ordering"
                                            render={() => (
                                              <OnlineOrderingRoutes
                                                triggerMenuRef={triggerMenuRef}
                                              />
                                            )}
                                          />
                                          <LoyaltyRoutes />
                                          <AccountRoutes />
                                          <ScanInStoreRoutes />
                                          <StaticPageRoutes />
                                          <GiftCardRoutes />
                                          {!userRoleContext.isGuestEnabled &&
                                            userRoleContext.status !== "logged-in" &&
                                            !path.hash.includes("login") &&
                                            !path.hash.includes("forgot-password") &&
                                            !path.hash.includes("register") &&
                                            !path.hash.includes("change-password") &&
                                            !path.hash.includes("welcome") &&
                                            !path.hash.includes("terms-and-conditions") &&
                                            !path.hash.includes("direct") &&
                                            path.hash !== "#/" && <Redirect to="/login-register" />}
                                          <AppInstallPrompt />
                                          {isCookieBanner && (
                                            <CookiesBanner
                                              closeBanner={() => setIsCookieBanner(false)}
                                              appDefaultLabelsPath={appDefaultLabelsPath}
                                            />
                                          )}
                                          {isSessionExpiredDialog && (
                                            <ExpiredSessionDialog
                                              closeSessionExpiredDialog={() =>
                                                setIsSessionExpiredDialog(false)
                                              }
                                            />
                                          )}
                                          {isOrderTimeModal && (
                                            <OrderTimeModal
                                              setIsOrderTimeModal={setIsOrderTimeModal}
                                            />
                                          )}
                                        </div>
                                      </CurrencyContextProvider>
                                    </BillProvider>
                                  </ActiveOrdersProvider>
                                </GCAvailableProvider>
                              </GCCartProvider>
                            </CartProvider>
                          </MenuProvider>
                        </LoyaltyProvider>
                      </OrderTimeProvider>
                    </StoreProvider>
                  </OrderTypeProvider>
                </LocationsProvider>
              </OrderProvider>
            </UserRoleProvider>
          </>
        )}
      {allStores.error && !allStores.statusCode && (
        <GenericAppError
          text={
            <>
              {`${appLabels["general"]["error-code"]}: 374. ${appLabels["general"]["something-went-wrong-error"]}`}
              {appLabels["general"]["maintenance_message"]}
            </>
          }
        />
      )}
      {allStores.statusCode && (
        <GenericAppError
          text={`${appLabels["general"]["error-code"]}: ${allStores.statusCode}. ${appLabels["general"]["something-went-wrong-error"]}`}
        />
      )}

      {isInvalidDirectUrl && (
        <GenericAppError
          text={`${appLabels["order"]["invalid-direct-url-error"].replace(
            "[order-type]",
            getTranslatedOrderType(directURLOrderType, appLabels)
          )}`}
        />
      )}

      {/* {orderingForLater && (
        <DialogModal
          message={
            <div>
              <b>{appLabels["order"]["ordering-for-tomorrow-warning-message"]}</b>
              <br /> <br />
              {appLabels["order"]["ordering-for-tomorrow-info-message"]}
            </div>
          }
          resetRemoveDialog={() => {
            setOrderingForLater(false);
            history.push("/dashboard");
          }}
          isCancelConfirm={true}
          confirmAction={() => setOrderingForLater(false)}
          isHTMLContent={true}
        />
      )} */}
    </>
  );
};
