import { callAPI } from "../../_common/Api";

export const getMerchantConfigs = async (skin, settingsLevel, language) => {
  try {
    const api1306Data = await callAPI(skin, "info_vxl_1306", {
      params: [language, "1306", "mqid", "mqpass", settingsLevel, "", "t"],
      id: "1306",
    });

    if (api1306Data.result.I0) {
      // Successfully retrieved the Merchant-level Vexilor config

      try {
        const api1003Data = await callAPI(skin, "info_dc_1003", {
          params: [language, "1003", "mqid", "mqpass", "consumer", skin, "t"],
          id: "1003",
        });

        if (api1003Data.result.I0) {
          // Successfully retrieved the Merchant config

          /**
           * override the active_order_types field with the combined order types list returned in merch_st_active_order_types
           * other components will still reference active_order_types when they want to know what order types are available
           */

          const moveElementInArrayBasedOnValue = (array, element, newPosition) => {
            const currentIndex = array.indexOf(element);

            if (currentIndex === -1) {
              //Element not found in the array
              return array;
            }

            array.splice(currentIndex, 1); // remove the element from its current position
            array.splice(newPosition, 0, element); // insert it at the new position

            return array;
          };

          /** Create static positions for the order types, so that the order will always be "Pickup", "Delivery", "Dinein" */
          let allOrderTypes = api1306Data.result.I2.merch_st_active_order_types.split(",");
          allOrderTypes = moveElementInArrayBasedOnValue(allOrderTypes, "pickup", 0); // move 'pickup' to the start of the array
          allOrderTypes = moveElementInArrayBasedOnValue(allOrderTypes, "delivery", 1); // move 'delivery' to the middle of the array
          allOrderTypes = moveElementInArrayBasedOnValue(allOrderTypes, "dinein", 2); // move 'dinein' to the end of the array

          const combinedOrderTypes = allOrderTypes.join(","); //"delivery,pickup,dinein"; // api1306Data.result.I2.merch_st_active_order_types;

          const api1306DataCopy = { ...api1306Data };
          api1306DataCopy.result.I2.active_order_types = combinedOrderTypes;

          return {
            vexilor: {
              ...api1306Data.result,
              isUberDirectEnabled:
                !!api1306DataCopy.result.I2["delivery_integration"] &&
                api1306DataCopy.result.I2["delivery_integration"].includes("UBERDIRECT")
                  ? true
                  : false,
            },
            merchant: api1003Data.result,
            skin,
          };
        } else if (api1003Data.error) {
          // 1003 API (Merchant config) error
          console.error(
            `API 1003 | Error code ${api1003Data.error.code} | ${api1003Data.error.message}`
          );
          return { error: api1003Data.error.message, statusCode: api1003Data.error.code };
        }
        //
      } catch (error) {
        // Generic fallback for the failed 1003 API call
        console.error(`API 1003 | ${error.message}`);
        return { error: "Error" };
      }
      //
    } else if (api1306Data.error) {
      // 1306 API (Merchant-level Vexilor config) error
      console.error(
        `API 1306 | Error code ${api1306Data.error.code} | ${api1306Data.error.message}`
      );
      return { error: api1306Data.error.message, statusCode: api1306Data.error.code };
    }
    //
  } catch (error) {
    // Generic fallback for the failed 1306 API call
    console.error(`API 1306 | Merchant level | ${error.message}`);
    return { error: "Error" };
  }
};

/**
 * Create a list of ALL order types based on 1300 calls
 * to see if any stores get returned for a specific order type.
 *
 * This method is only used when there are no order types
 * set at merchant level.
const get1300StoreLevelOrderTypes = async (skin, language, merchantOrderTypes) => {
  const storeLevelOrderTypes = merchantOrderTypes !== "" ? merchantOrderTypes.split(",") : [];

  try {
    const api1300TakeOutData = await callAPI(skin, "info_vxl_1300", {
      params: [language, "1300", "mqid", "mqpass", "TAKE_OUT"],
      id: "1300",
    });

    const api1300DeliveryData = await callAPI(skin, "info_vxl_1300", {
      params: [language, "1300", "mqid", "mqpass", "DELIVERY"],
      id: "1300",
    });

    const api1300DineinData = await callAPI(skin, "info_vxl_1300", {
      params: [language, "1300", "mqid", "mqpass", "DINEIN"],
      id: "1300",
    });

    if (api1300TakeOutData && api1300DeliveryData && api1300DineinData) {
      // Successfully retrieved all 3 store-level order types

      if (
        api1300TakeOutData.status === 0 &&
        api1300TakeOutData.result.I2.length > 0 &&
        !merchantOrderTypes.includes("pickup")
      ) {
        storeLevelOrderTypes.push("pickup");
      }

      if (
        api1300DeliveryData.status === 0 &&
        api1300DeliveryData.result.I2.length > 0 &&
        !merchantOrderTypes.includes("delivery")
      ) {
        storeLevelOrderTypes.push("delivery");
      }

      if (
        api1300DineinData.status === 0 &&
        api1300DineinData.result.I2.length > 0 &&
        !merchantOrderTypes.includes("dinein")
      ) {
        storeLevelOrderTypes.push("dinein");
      }

      return storeLevelOrderTypes.length > 0 ? storeLevelOrderTypes.join(",") : null;
      //
    } else {
      console.error(
        "API 1300 | Fetch error when retrieving one of the store-level active order types lists"
      );
      return null;
    }
    //
  } catch (error) {
    console.error(error);
    return null;
  }
};
*/
