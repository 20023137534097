/* eslint-disable no-loop-func */
import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

import AppLabelsContext from "../../App/AppLabelsContext";
import OrderContext from "../../App/OrderContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import OrderTypeContext from "../OrderTypeContext";
import StoreContext from "../StoreContext";
import OrderTimeContext from "../OrderTimeContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import { MenuContext } from "../Menu/MenuContext";

import { arrayToObject, getDeviceTypeId, getTimestamp, jsonCopy } from "../../_common/helpers";
import { callAPI } from "../../_common/Api";
import {
  cleanUpItemsListBasedOnQuantity,
  getItemQuantityFromCart,
} from "../../_common/CartHelpers";
import { checkLimitedStockedItemToCart, isValidItemQuantity } from "../../_common/MenuHelpers";
import localforage from "localforage";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import {
  getOrderItemDetails,
  getOrderStoreAddressForDialogModal,
  getUnavailableItems,
  removeDuplicatesAndUpdateCount,
} from "./orderHistoryHelpers";
import { get1300Stores } from "../GoogleMaps/get1300Stores";
import {
  getTimeAmPm,
  getTrueBusinessDate,
  prettifyDate,
} from "../../Dashboard/DashboardOrder/dateHelpers";
import { getMinInterval } from "../Locations/helpers/isStoreOpenOrClosed";
import { getStore1306Config } from "../../App/apiHelpers/getStore1306Config";
import {
  setIsOpenPassedMidnightProperties,
  setStoreHoursProperties,
} from "../GoogleMaps/dateHelpers";
import { calculateNextAvailableOrderTime } from "../../_common/PaymentHelpers";
import { onAddProductToCartXtreme } from "../../_common/helpers/xtremePushHelper";
import { LoadingSpinner } from "../../_common/LoadingSpinner";

export const ReorderButton = (props) => {
  const {
    order,
    foodMenu,
    cart,
    updateIsNotification,
    setIsAlertNotification,
    menuLevel,
    orderStore,
  } = props;

  const orderContext = useContext(OrderContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";
  const appLanguage = useContext(AppLanguageContext);
  const appSettings = useContext(AppSettingsContext);
  const orderMenu = useContext(MenuContext);
  // const menuContext = useContext(MenuContext);
  const deviceWidth = useWindowSize().width;
  const history = useHistory();

  const appLabels = useContext(AppLabelsContext);

  const url = window.location.href;
  const orderIdFound = url.match("order_id");

  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value !== "" ? orderTypeContext.value : order.orderType;

  const storeContext = useContext(StoreContext);
  const activeOrderStore =
    storeContext.activeOrderStore !== null ? storeContext.activeOrderStore : orderStore;

  const orderTimeContext = useContext(OrderTimeContext);

  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";

  const [isInvalidOrder, setIsInvalidOrder] = useState(false);
  const [isReOrder, setIsReOrder] = useState(false);
  const [foundStoreWithVexilorConfig, setFoundStoreWithVexilorConfig] = useState(null);
  const [nextAvailableOrderTime, setNextAvailableOrderTime] = useState(null);

  const [showConfirmLoadingSpinner, setShowConfirmLoadingSpinner] = useState(false);

  const [itemMaxOrderLimitReached, setItemMaxOrderLimitReached] = useState(false);
  const [loading, setLoading] = useState(false)
  const orderWithDetails = useRef(order);

  useEffect(() => {
    localforage.getItem(skin + "__activeOrderType").then((storedOrderType) => {
      if (storedOrderType) {
        orderTypeContext.update(storedOrderType);
      }
    });

    if (isReOrder && foodMenu) {
      (async () => {
        const availableOrderItems = await checkAvailableOrderItems();

        let outOfStockItems = jsonCopy(availableOrderItems).filter((menuItem) =>
          checkOutOfStockItem(menuItem)
        );

        if (availableOrderItems.length > 0 && availableOrderItems.length > outOfStockItems.length) {
          updateIsNotification(false);

          const tempCart = cart.value ? [...cart.value] : []; // get cart from storage, if doesn't exist start a fresh array

          const filteredUnavailableItems = getUnavailableItems(
            orderWithDetails.current,
            availableOrderItems
          );

          const outOfStockItemsWithUnavailbleItems = [
            ...outOfStockItems,
            ...filteredUnavailableItems,
          ];

          if (outOfStockItemsWithUnavailbleItems.length > 0) {
            const filteredOutOfStockItems = outOfStockItemsWithUnavailbleItems.map(
              (outOfStockItem) => {
                return {
                  id: outOfStockItem.id,
                  name: outOfStockItem.name,
                  item_count: Number(outOfStockItem.item_count),
                  quantity: outOfStockItem.quantity,
                  itemOutOfStock: outOfStockItem.itemUnavailable ? false : true,
                  itemUnavailable: outOfStockItem.itemUnavailable
                    ? outOfStockItem.itemUnavailable
                    : false,
                };
              }
            );

            localforage.setItem(skin + "__outOfStockItems", filteredOutOfStockItems);
          }
          //assign the group id to each combo child item as well as formatting their addon/modifier groups
          availableOrderItems.forEach((reOrderItem, index) => {
            if (reOrderItem.combo_child_items) {
              reOrderItem.combo_child_items = formatItems(reOrderItem.combo_child_items);

              if (reOrderItem.comboType === "standard") {
                //filter out the combo child items that are not part of the original order
                const reorderItemId = reOrderItem.id;
                reOrderItem.combo_child_items.forEach((comboChildItem) => {
                  if (
                    orderWithDetails.current.items.filter((item) => item.id === reorderItemId)
                      .length > 0
                  ) {
                    const matchingItemsList = orderWithDetails.current.items.filter(
                      (item) => item.id === reorderItemId
                    );

                    comboChildItem.quantity = 0;
                    matchingItemsList.forEach((matchingItem) => {
                      if (
                        matchingItem.combo_child_items.filter(
                          (childItem, index) =>
                            childItem.id === comboChildItem.id &&
                            index + 1 === Number(comboChildItem.group_id)
                        ).length > 0
                      ) {
                        comboChildItem.quantity = matchingItem.combo_child_items.filter(
                          (childItem, index) => childItem.id === comboChildItem.id
                        )[0].quantity;
                      }
                    });
                  }
                });

                reOrderItem.combo_child_items = reOrderItem.combo_child_items.filter(
                  (item) => Number(item.quantity) !== 0
                );
              }
            }
            // validate the item quantity
            if (isValidItemQuantity(tempCart, reOrderItem)) {
              reOrderItem.entryKey = Date.now() + index;
              onAddProductToCartXtreme(
                reOrderItem,
                reOrderItem.category,
                reOrderItem.subcategory,
                skin
              );
              tempCart.push(reOrderItem);
            } else if (checkLimitedStockedItemToCart(reOrderItem)) {
              reOrderItem.entryKey = Date.now() + index;
              reOrderItem.quantity = reOrderItem.item_count;
              onAddProductToCartXtreme(
                reOrderItem,
                reOrderItem.category,
                reOrderItem.subcategory,
                skin
              );
              tempCart.push(reOrderItem);
            }
          });

          /** Check if any item will exceed their ordering maximum limit */
          let hasItemWithOrderLimitReached = false;
          for (let i = 0; i < tempCart.length; i++) {
            const tempCartItem = tempCart[i];
            if (!!tempCartItem["online_qty_limit"] && tempCartItem["online_qty_limit"] !== "None") {
              const onlineOrderLimit = tempCartItem["online_qty_limit"];
              if (!!availableOrderItems.filter((item) => item.id === tempCartItem.id).length) {
                if (
                  getItemQuantityFromCart(cart.value || [], tempCartItem.id) >= onlineOrderLimit
                ) {
                  //if the item in the cart has already reached the order limit
                  hasItemWithOrderLimitReached = true;
                  break;
                } else {
                  let tempQuantityCount = 0;
                  availableOrderItems
                    .filter((item) => item.id === tempCartItem.id)
                    .forEach((matchingItem) => {
                      tempQuantityCount += matchingItem.quantity;
                    });
                  if (
                    getItemQuantityFromCart(cart.value || [], tempCartItem.id) + tempQuantityCount >
                    onlineOrderLimit
                  ) {
                    // if the items in the past order with the item already in the cart will exceed the order limit
                    hasItemWithOrderLimitReached = true;
                    break;
                  }
                }
              }
            }
          }

          if (hasItemWithOrderLimitReached) {
            setIsReOrder(false);
            setItemMaxOrderLimitReached(true);
            return;
          }

          cart.updateCart(tempCart);
          orderContext.checkOrderSettings(activeOrderType, storeContext, orderTimeContext);
        } else if (!foodMenu) {
          history.push("/online-ordering/order-type");
        } else {
          updateIsNotification(true);
          setIsAlertNotification(true);
          setIsInvalidOrder(true);
          setIsReOrder(false);
          // storeContext.updateActiveOrderStore(null);
          setShowConfirmLoadingSpinner(false);
          // resetStoredMenu();
        }
      })();
    }
  }, [foodMenu, isReOrder]);

  // const resetStoredMenu = () => {
  //   localforage.removeItem(skin + "__storedMenu");
  //   menuContext.updateMenu({
  //     ...menuContext,
  //     apiData: null,
  //     activeTopCategory: null,
  //     activeSubcategory: null,
  //     activeItem: null,
  //     menuLevel: null,
  //   });
  // };

  const updateOrderTime = async (orderStore) => {
    let orderStoreWithVexilorConfig = null;
    if (orderStore && !orderStore.vexilorConfig) {
      orderStoreWithVexilorConfig = await getStore1306Config(skin, appLanguage, orderStore);
      setFoundStoreWithVexilorConfig(orderStoreWithVexilorConfig);
      setNextAvailableOrderTime(
        calculateNextAvailableOrderTime(orderStoreWithVexilorConfig, activeOrderType, skin)
      );
    }

    /** If the order details are missing call 1305 and update the orderWithDetails ref  */
    let filteredOrderWithDetails = {};
    if (!order.items && !orderWithDetails.current.items) {
      const itemDetails = await getOrderItemDetails(
        skin,
        order.id,
        order.storeLocation.id,
        appLanguage
      );
      if (itemDetails) {
        filteredOrderWithDetails = jsonCopy(order);
        filteredOrderWithDetails.discountTotal = itemDetails.discountTotal;
        filteredOrderWithDetails.items = itemDetails.items;
        filteredOrderWithDetails.paymentDetails = itemDetails.paymentDetails;
        filteredOrderWithDetails.rewardDetails = itemDetails.rewardDetails;
        filteredOrderWithDetails.orderAddress = itemDetails.orderAddress;
      }
    } else {
      if (orderWithDetails.current.items) {
        filteredOrderWithDetails = orderWithDetails.current;
      } else {
        filteredOrderWithDetails = order;
      }
    }

    orderWithDetails.current = filteredOrderWithDetails;
    setIsReOrder(true);
    setLoading(false)
  };

  const formatDeliveryAddress = (addressComponent) => {
    return {
      "first-address": `${addressComponent.street1}${
        addressComponent.street2 ? " " + addressComponent.street2 : ""
      }`,
      city: addressComponent.city,
      country: addressComponent.countryCode,
      postal: addressComponent.zipCode,
      province: addressComponent.stateCode.split("-")[1],
    };
  };

  const handleReorder = async () => {
    setLoading(true)
    localforage.setItem(
      skin + "__activeOrderType",
      activeOrderType.toLowerCase().replaceAll("-", "")
    );
    let foundStore;

    if (activeOrderType) {
      get1300Stores(skin, order.orderType.toLowerCase().replaceAll("-", ""), appLanguage).then(
        (apiStores) => {
          if (apiStores) {
            // setAllStores(apiStores);
            foundStore = apiStores.filter((store) => store.storeId === orderStore.id)[0];

            // set openPassedMidnight properties
            foundStore.openPassedMidnight = setIsOpenPassedMidnightProperties(foundStore.hours);
            foundStore = setStoreHoursProperties(foundStore);

            updateOrderTime(foundStore);
          }
        }
      );
    } else {
      setLoading(false)
    }
  };

  const storeOrderDateInStorage = (orderTime) => {
    const orderDate = new Date(orderTime);
    const orderDateToStore = {
      value: orderDate.getMonth() + "-" + orderDate.getDate(), // the day of a date as a number (1-31)
      displayValue:
        orderDate.getDate() === new Date().getDate()
          ? appLabels["order"]["today"]
          : prettifyDate(orderDate, appLanguage), // stringified Date object
      dateObject: orderDate,
    };

    localforage.setItem(skin + "__orderDate", orderDateToStore);
  };

  // Format Food Menu items into objects
  const formatItems = (apiItems) => {
    const formattedItemsArray = [...apiItems];

    formattedItemsArray.map((apiItem) => {
      if (apiItem.addonGroups.length > 0) {
        // Convert addons into objects
        apiItem.addonGroups.map((addonGroup) => {
          addonGroup.items.map((addon) => {
            //convert addon modifiers to objects
            if (addon.modifierGroups && addon.modifierGroups.length > 0) {
              addon.modifierGroups.map((addonMod) => {
                addonMod.items = arrayToObject(addonMod.items, "id", "modifier");
                return addonMod;
              });
              addon.modifierGroups = arrayToObject(
                addon.modifierGroups,
                "id",
                "addonModifierGroup"
              );
            }
            addon.parentId = addonGroup.id;
            return addon;
          });

          addonGroup.items = arrayToObject(addonGroup.items, "id", "addon");
          return addonGroup;
        });

        apiItem.addonGroups = arrayToObject(apiItem.addonGroups, "id", "addonGroup");
      }

      if (apiItem.modifierGroups.length > 0) {
        // Convert modifiers into objects
        apiItem.modifierGroups.map((modifierGroup) => {
          modifierGroup.items = arrayToObject(modifierGroup.items, "id", "modifier");
          return modifierGroup;
        });

        apiItem.modifierGroups = arrayToObject(apiItem.modifierGroups, "id", "modifierGroup");
      }
      return apiItem;
    });

    return formattedItemsArray;
  };

  const reOrder = async (event) => {
    setShowConfirmLoadingSpinner(true);
    if (order.orderType === "Delivery") {
      const deliveryAddress = formatDeliveryAddress(orderWithDetails.current.orderAddress);
      if (isStadiumSchema) {
        localforage.setItem(
          skin + "__stadium-schema",
          JSON.stringify({
            section: deliveryAddress.postal.split("/")[0],
            row: deliveryAddress.postal.split("/")[1],
            seat: deliveryAddress.postal.split("/")[2],
          })
        );
        localforage.setItem(skin + "__userDeliveryAddress", deliveryAddress.postal);
      } else {
        localforage.setItem(skin + "__userDeliveryAddress", deliveryAddress);
      }
    }

    if (order.orderType === "Dine-in") {
      sessionStorage.setItem(skin + "__tempReorderData", JSON.stringify(order));
      history.push("/online-ordering/dinein");
    } else {
      const minInterval = getMinInterval(
        foundStoreWithVexilorConfig.vexilorConfig,
        activeOrderType,
        skin
      );

      await orderTimeContext.update({
        value: new Date(nextAvailableOrderTime),
        displayValue: foundStoreWithVexilorConfig.isOpen
          ? minInterval >= 1440 // if minimum interval is more than 24 hrs, do not use ASAP
            ? `${getTimeAmPm(nextAvailableOrderTime)}`
            : `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`
          : `${getTimeAmPm(nextAvailableOrderTime)}`,
        triggerMenu: true,
        trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, foundStoreWithVexilorConfig),
      });

      storeOrderDateInStorage(nextAvailableOrderTime);

      localforage.setItem(
        skin + "__trueBusinessDate",
        getTrueBusinessDate(nextAvailableOrderTime, foundStoreWithVexilorConfig)
      );

      await storeContext.updateActiveOrderStore(foundStoreWithVexilorConfig);
    }
    setLoading(false)
  };

  const getComboChildItems = (comboId) => {
    const isThreeLevel = orderMenu.menuLevel === "threeLevels";
    const menuData = orderMenu.apiData;

    if (menuData) {
      let comboChildItems = [];
      for (let key in menuData) {
        if (isThreeLevel) {
          const category = menuData[key];
          const subcategories = category.subcategories;
          for (let subCatKey in subcategories) {
            const subcategory = subcategories[subCatKey];
            const items = subcategory.items;
            for (let itemKey in items) {
              if (comboId === items[itemKey].id) {
                comboChildItems = items[itemKey].combo_child_items;
                break;
              }
            }
          }
        } else {
          const subcategory = menuData[key];
          const items = subcategory.items;
          for (let itemKey in items) {
            if (comboId === items[itemKey].id) {
              comboChildItems = items[itemKey].combo_child_items;
              break;
            }
          }
        }
      }

      return { result: { I2: comboChildItems } };
    } else {
      return callAPI(skin, "info_vxl_1301", {
        params: [
          appLanguage,
          "1301",
          "mqid",
          "mqpass",
          activeOrderStore.storeId, // Outlet ID
          "combo", // Parent Type
          comboId, // Item ID/Subcategory ID
          getDeviceTypeId(activeOrderType) || "", // Device Type,
          "", // Department ID,
          orderTimeContext.value && orderTimeContext.value.value !== "Select Time"
            ? getTimestamp(orderTimeContext.value.value)
            : "", // Order Timestamp (optional)
        ],
        id: "1301",
      });
    }
  };

  const getFullComboData = async (item) => {
    const comboChildItems = await getComboChildItems(item.id);
    if (comboChildItems) {
      return comboChildItems.result.I2;
    }
  };

  /** This function will check out of stock items
   */
  const checkOutOfStockItem = (menuItem) => {
    return Number(menuItem.item_count) <= 0 || Number(menuItem.item_count) < menuItem.quantity;
  };

  const checkAvailableOrderItems = async () => {
    let reOrderItems = [];
    let filteredOrderWithDetails = {};
    //if order items is missing, we need to call 1305 to get the details
    if (!order.items && !orderWithDetails.current.items) {
      const itemDetails = await getOrderItemDetails(
        skin,
        order.id,
        order.storeLocation.id,
        appLanguage
      );
      if (itemDetails) {
        filteredOrderWithDetails = jsonCopy(order);
        filteredOrderWithDetails.discountTotal = itemDetails.discountTotal;
        filteredOrderWithDetails.items = itemDetails.items;
        filteredOrderWithDetails.paymentDetails = itemDetails.paymentDetails;
        filteredOrderWithDetails.rewardDetails = itemDetails.rewardDetails;
        filteredOrderWithDetails.orderAddress = itemDetails.orderAddress;
      }
    } else {
      if (orderWithDetails.current.items) {
        filteredOrderWithDetails = orderWithDetails.current;
      } else {
        filteredOrderWithDetails = order;
      }
    }

    orderWithDetails.current = filteredOrderWithDetails;

    let invalidOrderItems = filteredOrderWithDetails.items.length;
    let selectedOptions = [];

    for (let key in foodMenu) {
      const subcategories = menuLevel === "twoLevels" ? foodMenu[key] : foodMenu[key].subcategories;

      if (menuLevel === "twoLevels") {
        const items = subcategories.items;

        for (let key in items) {
          const itemCopy = jsonCopy(items[key]);
          let relatedChildItems = [];

          for (let i = 0; i < filteredOrderWithDetails.items.length; i++) {
            if (filteredOrderWithDetails.items[i].id === itemCopy.id) {
              //check the modifiers
              if (filteredOrderWithDetails.items[i].modifiers && itemCopy.modifierGroups) {
                // eslint-disable-next-line no-loop-func
                filteredOrderWithDetails.items[i].modifiers.forEach((modifier) => {
                  for (let keyReorder in modifier.items) {
                    for (let keyStoredGroup in itemCopy.modifierGroups) {
                      for (let keyStoredOption in itemCopy.modifierGroups[keyStoredGroup].items) {
                        if (
                          itemCopy.modifierGroups[keyStoredGroup].items[keyStoredOption].id ===
                            modifier.items[keyReorder].id &&
                          itemCopy.modifierGroups[keyStoredGroup].items[keyStoredOption]
                            .parentId === modifier.items[keyReorder]["modifier_id"]
                        ) {
                          itemCopy.modifierGroups[keyStoredGroup].items[
                            keyStoredOption
                          ].isSelected = true;

                          selectedOptions.push(
                            itemCopy.modifierGroups[keyStoredGroup].items[keyStoredOption]
                          );
                        }
                      }
                    }
                  }
                });
              }

              //check the addons
              if (filteredOrderWithDetails.items[i].addons && itemCopy.addonGroups) {
                // eslint-disable-next-line no-loop-func
                filteredOrderWithDetails.items[i].addons.forEach((addon) => {
                  for (let keyReorder in addon.items) {
                    for (let keyStoredGroup in itemCopy.addonGroups) {
                      for (let keyStoredOption in itemCopy.addonGroups[keyStoredGroup].items) {
                        /** Note: if an addon from the order history does not have "addon_group_id", it is a single addon item */
                        if (
                          (itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption].id ===
                            addon.items[keyReorder].id &&
                            itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption].parentId ===
                              addon.items[keyReorder]["addon_group_id"]) ||
                          (!addon.items[keyReorder]["addon_group_id"] &&
                            addon.items[keyReorder].id ===
                              itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption].id &&
                            addon.id === itemCopy.addonGroups[keyStoredGroup].id)
                        ) {
                          itemCopy.addonGroups[keyStoredGroup].items[
                            keyStoredOption
                          ].isSelected = true;

                          selectedOptions.push(
                            itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption]
                          );

                          //must handle nested modifiers here
                          if (
                            addon.items[keyReorder].modifierGroups &&
                            addon.items[keyReorder].modifierGroups.length > 0
                          ) {
                            const orderNestedModifierGroups =
                              addon.items[keyReorder].modifierGroups;
                            orderNestedModifierGroups.forEach((nestedModifierGroup) => {
                              const nestedModifiers = nestedModifierGroup.items;
                              nestedModifiers.forEach((nestedModifier) => {
                                const itemAddonNestedModifierGroups =
                                  items[key].addonGroups[keyStoredGroup].items[keyStoredOption]
                                    .modifierGroups;
                                for (let nesterModifierGroupKey in itemAddonNestedModifierGroups) {
                                  const nestedModifiers =
                                    itemAddonNestedModifierGroups[nesterModifierGroupKey].items;
                                  for (let nestedModifierKey in nestedModifiers) {
                                    const itemNestedModifier = nestedModifiers[nestedModifierKey];
                                    if (itemNestedModifier.id === nestedModifier.id) {
                                      itemCopy.addonGroups[keyStoredGroup].items[
                                        keyStoredOption
                                      ].modifierGroups[nesterModifierGroupKey].items[
                                        nestedModifierKey
                                      ].isSelected = true;

                                      selectedOptions.push(
                                        itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption]
                                          .modifierGroups[nesterModifierGroupKey].items[
                                          nestedModifierKey
                                        ]
                                      );
                                    }
                                  }
                                }
                              });
                            });
                          }
                        }
                      }
                    }
                  }
                });
              }

              //check if item is combo, if so add its child items
              if (
                filteredOrderWithDetails.items[i].combo_child_items &&
                itemCopy.type === "comboItem"
              ) {
                /** to handle combo items correctly
                 * we need to pull all the combo child items from api 1301
                 * then compare all the combo child items to the order object and pick only the relative ones (ordered previously)
                 */
                const allComboChildren = await getFullComboData(items[key]);

                allComboChildren.forEach((childItem) => {
                  filteredOrderWithDetails.items[i].combo_child_items.forEach(
                    (orderedComboChildItem, index) => {
                      if (itemCopy.comboType === "standard") {
                        //Standard Combo
                        if (
                          childItem.id === orderedComboChildItem.id &&
                          index + 1 === Number(childItem.group_id)
                        ) {
                          relatedChildItems.push(childItem);
                        }
                      } else {
                        //Quantity Combo
                        //Only add the child item if it does not exist from the same group id
                        if (childItem.id === orderedComboChildItem.id) {
                          if (
                            relatedChildItems.filter((item) => item.id === childItem.id).length <
                              filteredOrderWithDetails.items[i].combo_child_items.filter(
                                (item) => item.id === childItem.id
                              ).length &&
                            relatedChildItems.filter((item) => item.group_id === childItem.group_id)
                              .length < Number(childItem.groupMinQty)
                          ) {
                            // Add a position Index to the child item
                            const childItemCopy = jsonCopy(childItem);
                            childItemCopy.positionIndex = index;
                            relatedChildItems.push(childItemCopy);
                          }
                        }
                      }
                    }
                  );
                });

                //items[key].combo_child_items = relatedChildItems;
                const isQuantityCombo =
                  relatedChildItems.filter((tempItem) => !!tempItem.groupMinQty).length > 0;
                relatedChildItems.forEach((childItem, childItemIndex) => {
                  //check child items addons
                  if (
                    !!childItem.addonGroups.length ||
                    !!Object.keys(childItem.addonGroups).length
                  ) {
                    if (!Array.isArray(childItem.addonGroups)) {
                      childItem.addonGroups = Object.values(childItem.addonGroups);
                    }

                    childItem.addonGroups.forEach((a) => {
                      if (!Array.isArray(a.items)) {
                        a.items = Object.values(a.items);
                      }

                      a.items.forEach((addonItem) => {
                        //validate the addon before marking as selected
                        filteredOrderWithDetails.items[i].combo_child_items.forEach(
                          (comboChildItem, index) => {
                            if (
                              comboChildItem.id === childItem.id &&
                              ((index + 1 === Number(childItem.group_id) && !isQuantityCombo) ||
                                (isQuantityCombo &&
                                  Number(childItem.group_id) === Number(comboChildItem.group_id) &&
                                  childItem.positionIndex === index))
                            ) {
                              comboChildItem.addonGroups.forEach((addonGroup) => {
                                addonGroup.items.forEach((addon) => {
                                  if (
                                    (addon.id === addonItem.id &&
                                      addon["addon_group_id"] === addonItem.parentId) ||
                                    (!addon["addon_group_id"] &&
                                      addon.id === addonItem.id &&
                                      addonItem.parentId === a.parentId)
                                  ) {
                                    addonItem.isSelected = true;
                                    selectedOptions.push(addonItem);

                                    //check for nested addon modifiers
                                    if (!!addonItem.modifierGroups.length) {
                                      addonItem.modifierGroups.forEach((nestedMod) => {
                                        nestedMod.items.forEach((nestedModItem) => {
                                          addon.modifierGroups.forEach((modifierGroup) => {
                                            modifierGroup.items.forEach((modifier) => {
                                              if (modifier.id === nestedModItem.id) {
                                                nestedModItem.isSelected = true;
                                                selectedOptions.push(nestedModItem);
                                              }
                                            });
                                          });
                                        });
                                      });
                                    }
                                  }
                                });
                              });
                            }
                          }
                        );
                      });
                    });
                  }

                  //check child itemds mods
                  if (
                    !!childItem.modifierGroups.length ||
                    !!Object.keys(childItem.modifierGroups).length
                  ) {
                    if (!Array.isArray(childItem.modifierGroups)) {
                      childItem.modifierGroups = Object.values(childItem.modifierGroups);
                    }

                    childItem.modifierGroups.forEach((mod) => {
                      if (!Array.isArray(mod.items)) {
                        mod.items = Object.values(mod.items);
                      }
                      mod.items.forEach((modItem) => {
                        filteredOrderWithDetails.items[i].combo_child_items.forEach(
                          (comboChildItem, index) => {
                            if (
                              comboChildItem.id === childItem.id &&
                              ((index + 1 === Number(childItem.group_id) && !isQuantityCombo) ||
                                (isQuantityCombo &&
                                  Number(childItem.group_id) === Number(comboChildItem.group_id) &&
                                  childItem.positionIndex === index))
                            ) {
                              comboChildItem.modifierGroups.forEach((modifierGroup) => {
                                modifierGroup.items.forEach((modifier) => {
                                  if (
                                    modifier.id === modItem.id &&
                                    modifier["modifier_id"] === modItem.parentId
                                  ) {
                                    modItem.isSelected = true;
                                    selectedOptions.push(modItem);
                                  }
                                });
                              });
                            }
                          }
                        );
                      });
                    });
                  }
                });
              }

              /** Get rid of the position index in for relatedChildItems */
              relatedChildItems.forEach((childItem) => {
                delete childItem.positionIndex;
              });
              invalidOrderItems--;

              itemCopy.entryKey = Date.now() + "-" + i;
              itemCopy.quantity = 1;

              /** This conditional makes sure that a certain item gets added to the list only if the number of its instance does not exceed the
               * number of instances of that item in the original order
               */
              if (
                reOrderItems.filter((tempItem) => tempItem.id === itemCopy.id).length <
                filteredOrderWithDetails.items.filter((tempItem) => tempItem.id === itemCopy.id)
                  .length
              ) {
                reOrderItems.push(jsonCopy({ ...itemCopy, combo_child_items: relatedChildItems }));
                relatedChildItems = [];
              }

              selectedOptions.forEach((option) => {
                delete option.isSelected;
              });
              selectedOptions = [];
            }
          }
        }
      } else {
        for (let subcatKey in subcategories) {
          const items = subcategories[subcatKey].items;
          for (let key in items) {
            const itemCopy = jsonCopy(items[key]);
            let relatedChildItems = [];
            for (let i = 0; i < filteredOrderWithDetails.items.length; i++) {
              if (filteredOrderWithDetails.items[i].id === itemCopy.id) {
                //check the modifiers
                if (filteredOrderWithDetails.items[i].modifiers && itemCopy.modifierGroups) {
                  filteredOrderWithDetails.items[i].modifiers.forEach((modifier) => {
                    for (let keyReorder in modifier.items) {
                      for (let keyStoredGroup in itemCopy.modifierGroups) {
                        for (let keyStoredOption in itemCopy.modifierGroups[keyStoredGroup].items) {
                          if (
                            itemCopy.modifierGroups[keyStoredGroup].items[keyStoredOption].id ===
                              modifier.items[keyReorder].id &&
                            itemCopy.modifierGroups[keyStoredGroup].items[keyStoredOption]
                              .parentId === modifier.items[keyReorder]["modifier_id"]
                          ) {
                            itemCopy.modifierGroups[keyStoredGroup].items[
                              keyStoredOption
                            ].isSelected = true;

                            selectedOptions.push(
                              itemCopy.modifierGroups[keyStoredGroup].items[keyStoredOption]
                            );
                          }
                        }
                      }
                    }
                  });
                }

                //check the addons
                if (filteredOrderWithDetails.items[i].addons && itemCopy.addonGroups) {
                  filteredOrderWithDetails.items[i].addons.forEach((addon) => {
                    for (let keyReorder in addon.items) {
                      for (let keyStoredGroup in itemCopy.addonGroups) {
                        for (let keyStoredOption in itemCopy.addonGroups[keyStoredGroup].items) {
                          /** Note: if an addon from the order history does not have "addon_group_id", it is a single addon item */
                          if (
                            (itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption].id ===
                              addon.items[keyReorder].id &&
                              itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption]
                                .parentId === addon.items[keyReorder]["addon_group_id"]) ||
                            (!addon.items[keyReorder]["addon_group_id"] &&
                              addon.items[keyReorder].id ===
                                itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption].id &&
                              addon.id === itemCopy.addonGroups[keyStoredGroup].id)
                          ) {
                            itemCopy.addonGroups[keyStoredGroup].items[
                              keyStoredOption
                            ].isSelected = true;

                            selectedOptions.push(
                              itemCopy.addonGroups[keyStoredGroup].items[keyStoredOption]
                            );

                            //must handle nested modifiers here
                            if (
                              addon.items[keyReorder].modifierGroups &&
                              addon.items[keyReorder].modifierGroups.length > 0
                            ) {
                              const orderNestedModifierGroups =
                                addon.items[keyReorder].modifierGroups;
                              orderNestedModifierGroups.forEach((nestedModifierGroup) => {
                                const nestedModifiers = nestedModifierGroup.items;
                                nestedModifiers.forEach((nestedModifier) => {
                                  const itemAddonNestedModifierGroups =
                                    items[key].addonGroups[keyStoredGroup].items[keyStoredOption]
                                      .modifierGroups;
                                  for (let nesterModifierGroupKey in itemAddonNestedModifierGroups) {
                                    const nestedModifiers =
                                      itemAddonNestedModifierGroups[nesterModifierGroupKey].items;
                                    for (let nestedModifierKey in nestedModifiers) {
                                      const itemNestedModifier = nestedModifiers[nestedModifierKey];
                                      if (itemNestedModifier.id === nestedModifier.id) {
                                        itemCopy.addonGroups[keyStoredGroup].items[
                                          keyStoredOption
                                        ].modifierGroups[nesterModifierGroupKey].items[
                                          nestedModifierKey
                                        ].isSelected = true;

                                        selectedOptions.push(
                                          itemCopy.addonGroups[keyStoredGroup].items[
                                            keyStoredOption
                                          ].modifierGroups[nesterModifierGroupKey].items[
                                            nestedModifierKey
                                          ]
                                        );
                                      }
                                    }
                                  }
                                });
                              });
                            }
                          }
                        }
                      }
                    }
                  });
                }

                //check if item is combo, if so add its child items
                if (
                  filteredOrderWithDetails.items[i].combo_child_items &&
                  itemCopy.type === "comboItem"
                ) {
                  /** to handle combo items correctly
                   * we need to pull all the combo child items from api 1301
                   * then compare all the combo child items to the order object and pick only the relative ones (ordered previously)
                   */
                  const allComboChildren = await getFullComboData(itemCopy);

                  allComboChildren.forEach((childItem) => {
                    filteredOrderWithDetails.items[i].combo_child_items.forEach(
                      (orderedComboChildItem, index) => {
                        if (itemCopy.comboType === "standard") {
                          //Standard Combo
                          if (
                            childItem.id === orderedComboChildItem.id &&
                            index + 1 === Number(childItem.group_id)
                          ) {
                            relatedChildItems.push(childItem);
                          }
                        } else {
                          //Quantity Combo
                          //Only add the child item if it does not exist from the same group id
                          if (childItem.id === orderedComboChildItem.id) {
                            if (
                              relatedChildItems.filter((item) => item.id === childItem.id).length <
                                filteredOrderWithDetails.items[i].combo_child_items.filter(
                                  (item) => item.id === childItem.id
                                ).length &&
                              relatedChildItems.filter(
                                (item) => item.group_id === childItem.group_id
                              ).length < Number(childItem.groupMinQty)
                            ) {
                              // Add a position Index to the child item
                              const childItemCopy = jsonCopy(childItem);
                              childItemCopy.positionIndex = index;
                              relatedChildItems.push(childItemCopy);
                            }
                          }
                        }
                      }
                    );
                  });

                  //itemCopy.combo_child_items = relatedChildItems;
                  const isQuantityCombo =
                    relatedChildItems.filter((tempItem) => !!tempItem.groupMinQty).length > 0;
                  //Loop through the related child items and mark the selected addons and modifiers
                  relatedChildItems.forEach((childItem, childItemIndex) => {
                    //check child items addons
                    if (
                      !!childItem.addonGroups.length ||
                      !!Object.keys(childItem.addonGroups).length
                    ) {
                      if (!Array.isArray(childItem.addonGroups)) {
                        childItem.addonGroups = Object.values(childItem.addonGroups);
                      }

                      childItem.addonGroups.forEach((a) => {
                        if (!Array.isArray(a.items)) {
                          a.items = Object.values(a.items);
                        }
                        a.items.forEach((addonItem) => {
                          //validate the addon before marking as selected
                          filteredOrderWithDetails.items[i].combo_child_items.forEach(
                            (comboChildItem, index) => {
                              if (
                                comboChildItem.id === childItem.id &&
                                ((index + 1 === Number(childItem.group_id) && !isQuantityCombo) ||
                                  (isQuantityCombo &&
                                    Number(childItem.group_id) ===
                                      Number(comboChildItem.group_id) &&
                                    childItem.positionIndex === index))
                              ) {
                                comboChildItem.addonGroups.forEach((addonGroup) => {
                                  addonGroup.items.forEach((addon) => {
                                    if (
                                      (addon.id === addonItem.id &&
                                        addon["addon_group_id"] === addonItem.parentId) ||
                                      (!addon["addon_group_id"] &&
                                        addon.id === addonItem.id &&
                                        addonItem.parentId === a.parentId)
                                    ) {
                                      addonItem.isSelected = true;
                                      selectedOptions.push(addonItem);

                                      //check for nested addon modifiers
                                      if (!!addonItem.modifierGroups.length) {
                                        addonItem.modifierGroups.forEach((nestedMod) => {
                                          nestedMod.items.forEach((nestedModItem) => {
                                            addon.modifierGroups.forEach((modifierGroup) => {
                                              modifierGroup.items.forEach((modifier) => {
                                                if (modifier.id === nestedModItem.id) {
                                                  nestedModItem.isSelected = true;
                                                  selectedOptions.push(nestedModItem);
                                                }
                                              });
                                            });
                                          });
                                        });
                                      }
                                    }
                                  });
                                });
                              }
                            }
                          );
                        });
                      });
                    }

                    //check child itemds mods
                    if (
                      !!childItem.modifierGroups.length ||
                      !!Object.keys(childItem.modifierGroups).length
                    ) {
                      if (!Array.isArray(childItem.modifierGroups)) {
                        childItem.modifierGroups = Object.values(childItem.modifierGroups);
                      }
                      childItem.modifierGroups.forEach((mod) => {
                        if (!Array.isArray(mod.items)) {
                          mod.items = Object.values(mod.items);
                        }
                        mod.items.forEach((modItem) => {
                          filteredOrderWithDetails.items[i].combo_child_items.forEach(
                            (comboChildItem, index) => {
                              if (
                                comboChildItem.id === childItem.id &&
                                ((index + 1 === Number(childItem.group_id) && !isQuantityCombo) ||
                                  (isQuantityCombo &&
                                    Number(childItem.group_id) ===
                                      Number(comboChildItem.group_id) &&
                                    childItem.positionIndex === index))
                              ) {
                                comboChildItem.modifierGroups.forEach((modifierGroup) => {
                                  modifierGroup.items.forEach((modifier) => {
                                    if (
                                      modifier.id === modItem.id &&
                                      modifier["modifier_id"] === modItem.parentId
                                    ) {
                                      modItem.isSelected = true;
                                      selectedOptions.push(modItem);
                                    }
                                  });
                                });
                              }
                            }
                          );
                        });
                      });
                    }
                  });
                }

                /** Get rid of the position index in for relatedChildItems */
                relatedChildItems.forEach((childItem) => {
                  delete childItem.positionIndex;
                });

                invalidOrderItems--;

                itemCopy.entryKey = Date.now() + "-" + i;
                itemCopy.quantity = 1;

                /** This conditional makes sure that a certain item gets added to the list only if the number of its instance does not exceed the
                 * number of instances of that item in the original order
                 */
                if (
                  reOrderItems.filter((tempItem) => tempItem.id === itemCopy.id).length <
                  filteredOrderWithDetails.items.filter((tempItem) => tempItem.id === itemCopy.id)
                    .length
                ) {
                  reOrderItems.push(
                    jsonCopy({ ...itemCopy, combo_child_items: relatedChildItems })
                  );
                  relatedChildItems = [];
                }

                selectedOptions.forEach((option) => {
                  delete option.isSelected;
                });
                selectedOptions = [];
              }
            }
          }
        }
      }
    }

    /**clean up combo child items*/
    reOrderItems.forEach((item) => {
      if (item.combo_child_items && item.combo_child_items.length > 0) {
        item.combo_child_items = removeDuplicatesAndUpdateCount(
          item.combo_child_items,
          isAddonQuantityEnabled
        );
      }
    });

    if (
      invalidOrderItems <= 0 ||
      (invalidOrderItems > 0 && invalidOrderItems < filteredOrderWithDetails.items.length)
    ) {
      return cleanUpItemsListBasedOnQuantity(
        reOrderItems,
        isAddonQuantityEnabled,
        filteredOrderWithDetails
      );
    } else {
      return [];
    }
  };

  return (
    <>
      <button
        onClick={handleReorder}
        className={`button recent-order__reorder-button button--pill-shaped ${
          orderIdFound !== null && deviceWidth < 660
            ? "recent-order__reorder-button-view-details"
            : "recent-order__reorder-button--filled"
        } ${isInvalidOrder ? " recent-order__reorder-button--alert" : ""}`}
        type="button"
        disabled={isInvalidOrder || loading}
        aria-label={appLabels["order"]["reorder"] + " " + order.id}
      >
        {loading ? (
          <span className="reorder_button_with_loading_spinner">
            <LoadingSpinner customClass={"reorder_button_loading_spinner"} />
            {appLabels["order"]["reorder"]}
          </span>
        ) : (
          <span>{appLabels["order"]["reorder"]}</span>
        )}
      </button>

      {isReOrder && (storeContext.activeOrderStore === null || showConfirmLoadingSpinner) && (
        <DialogModal
          message={getOrderStoreAddressForDialogModal(
            orderWithDetails.current,
            appLabels,
            true,
            nextAvailableOrderTime,
            foundStoreWithVexilorConfig,
            skin,
            activeOrderType,
            orderTimeContext,
            skin
          )}
          isReorderFlow={true}
          isConfirmText={true}
          isModifyText={appLabels["order"]["modify"]}
          isCancelConfirm={true}
          confirmAction={reOrder}
          resetRemoveDialog={() => {
            sessionStorage.setItem(skin + "__tempReorderData", JSON.stringify(order));
            history.push("/online-ordering/order-type");
          }}
          closeModalAction={() => {
            setIsReOrder(!setIsReOrder);
          }}
          showConfirmLoadingSpinner={showConfirmLoadingSpinner}
        />
      )}

      {!!itemMaxOrderLimitReached && (
        <DialogModal
          message={appLabels["order"]["limit-reached-past-order"]}
          isReorderFlow={false}
          isConfirmText={false}
          isCancelConfirm={false}
          hideConfirmButton={false}
          confirmAction={() => {
            setItemMaxOrderLimitReached(null);
          }}
          resetRemoveDialog={() => {
            setItemMaxOrderLimitReached(null);
          }}
          closeModalAction={() => {
            setItemMaxOrderLimitReached(null);
          }}
        />
      )}
    </>
  );
};
