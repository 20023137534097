export const generalLabels = {
  welcome: "Welcome",
  "loading-your-setup": "Loading your setup",
  "good-morning": "Good Morning",
  "good-afternoon": "Good Afternoon",
  "good-evening": "Good Evening",
  account: "Account",
  "scan-in-store": "Scan",
  "my-account": "My Account",
  rewards: "Loyalty",
  "gift-cards": "Gift Cards",
  order: "Order",
  "order-now": "Order Now",
  "find-store": "Find a Location",
  "terms-and-conditions": "Terms & Conditions",
  maintenance_message: "This site is currently under maintenance. We'll be back shortly...",
  "cookie-message":
    "We use cookies to enable your shopping experience and analyze website traffic, and to help speed up your use of our site. [link] to learn more about these. You consent to our cookies if you continue to use our website.",
  "cookie-message-link-text": "View our policy",
  "remove-all-cart-items-confirmation-message":
    "Are you sure you want to remove all items from your cart?",
  "dialog-modal-confirm": "Confirm",
  "dialog-modal-cancel": "Cancel",
  "dialog-modal-ok": "Ok",
  "expires-on": "Expires on",
  remove: "Remove",
  "credit-card": "Credit Card",
  "gift-card": "Gift Card",
  optional: "Optional",
  subtotal: "Subtotal",
  total: "Total",
  barcode: "Barcode",
  "something-went-wrong-error": "Something went wrong. Please try again later.",
  "login-expired-modal-message": "Your login has expired. Redirecting you back to login page...",
  "add-to-home-screen-prompt": "Add to Home Screen",
  "logging-you-out": "Logging you out...",
  "disabled-geolocation-alert":
    "In order to search for nearby locations, enable location services under your device's settings and allow the app permission to use your location.",
  "renew-expired-session-confirmation": "Your session has expired, Would you like to renew it?",
  "expired-session-logout-countdown": "You will be logged out in [number] seconds",
  "your-last-visit": "Your last visit",
  "new-version-message": "A new update is available. Please confirm to reload.",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  "default-loyalty-card": "Default Card",
  "app-version": "Version [number]",
  "error-code": "Error code",
  "main-website": "Main Website",
  applied: "Applied",
  "go-back": "Go back",
  "close-dialog-modal": "Close dialog modal",
  "close-modal": "Close modal",
  close: "Close",
  "close-disclaimer": "Close disclaimer",
  "close-install-prompt": "Close install application prompt",
  hide: "Hide",
  show: "Show",
  open: "Open",
  "language-dropdown": "language dropdown",
  "show-info-active-store": "Show info for currently active location",
  "show-info-all-stores": "Show info for all locations in range",
  "find-location-google-maps": "Find my current location on Google Maps",
  "search-address": "Search for the address",
  "close-store-info": "Close location information",
  options: "options",
  "change-barcode-type-to": "Change barcode type to [barcode-type]",
  "show-app-header-order-summary": "Show order time and location details",
  "hide-app-header-order-summary": "Hide order time and location details",
  "language-dropdown-display-name": "Language",
  done: "Done",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  "client-gift-card-website-label": "Gift Card",
  skip: "Skip",
  "go-to-cart": "Go to Cart",
  "subscribe-to-notifications": "Subscribe For Push Notifications",
  "xp-subscribe-to-notifications-header": "Never miss out!",
  "xp-subscribe-to-notifications-message":
    "Allow [merchant-name] to send you exclusive offers and discounts.",
  select: "Select",
  "for-text": "for",
  "go-to-dashboard": "Go to dashboard",
  modify: "Modify",
  checking: "Checking",
  home: "Home",
  "hide-menu": "Hide menu",
  resources: "Resources",
  "go-to": "Go to",
  selected: "selected",
  "scroll-to-top": "Scroll to top of the page.",
  breadcrumb: "breadcrumb",
  "select-language": "Select language [language-name]",
  "back-to-item": "Back to [item-name]",
  "currency-dropdown": "Currency drop down",
};
