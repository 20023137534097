import React, { useState, useContext, useEffect } from "react";

/** Contexts */
import MerchantConfigContext from "../../App/MerchantConfigContext";
import CurrencyContext from "../../App/CurrencyContext";

/** CSS */
import "./DashboardHeaderCurrencyDropdown.css";

export const DashboardHeaderCurrencyDropdown = (props) => {
  const { setIsCurrencyDropDown } = props;
  const merchantConfig = useContext(MerchantConfigContext);
  const currencyContext = useContext(CurrencyContext);
  const [dropdownData, setDropdownData] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(currencyContext.currencyData);

  useEffect(() => {
    if (merchantConfig.merchant.I59) {
      setDropdownData([[merchantConfig.merchant.I34, 1], ...merchantConfig.merchant.I59]);
    }
  }, []);

  const onCurrencyChange = (currency) => {
    currencyContext.updateCurrencyData(currency);
    setSelectedCurrency(currency);
    setIsCurrencyDropDown(false);
  };

  return (
    <>
      {dropdownData && (
        <div
          className="dashboard-header__language-dropdown"
          style={{ left: "unset", right: "40px" }}
        >
          <ul className="language-dropdown__list">
            {dropdownData.map((currency, index) => (
              <li
                key={index}
                className={`language-dropdown-list__item currency-dropdown-list__item ${
                  selectedCurrency[0] === currency[0] ? " isSelected" : ""
                }`}
                onClick={() => {
                  onCurrencyChange(currency);
                }}
              >
                <div
                  className="language-dropdown-list-item__link"
                  style={{
                    color: "#2a2a2a",
                    textDecoration: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {currency[0]}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
