const ORIGIN = process.env.NODE_ENV === "development" ? "https://dilbert.givex.com" : "";

// "cws40b_svc" redirects beta-wwws.givex.com/cws40_cvs
const SVC_DIR = "cws40_svc"; // cws40b_svc

const FETCH_SETTINGS = {
  method: "POST",
  cache: "no-cache",
  credentials: "same-origin",
};

const isLocalhost = process.env.NODE_ENV === "development";

export const callAPI = async (skin, apiName, data, storeIdFrom1304) => {
  const fetchParameters = { ...FETCH_SETTINGS };
  fetchParameters.body = JSON.stringify(data);

  let outletID = "";

  if (
    (apiName === "info_vxl_1301" ||
      apiName === "vxl_1307" ||
      apiName === "info_vxl_1307" ||
      apiName === "vxl_1302" ||
      apiName === "vxl_1308" ||
      apiName === "info_vxl_1333" ||
      apiName === "vxl_1335" ||
      apiName === "vxl_1341" ||
      apiName === "vxl_1342") &&
    data.params[4]
  ) {
    outletID = data.params[4] + "/";
  } else if (
    (apiName === "vxl_1305" ||
      apiName === "vxl_1322" ||
      apiName === "vxl_1323" ||
      apiName === "vxl_1325") &&
    storeIdFrom1304
  ) {
    outletID = storeIdFrom1304 + "/";
  }

  try {
    const apiResponse = await fetch(
      `${ORIGIN}/${SVC_DIR}/${skin}/consumer/${outletID}${apiName}.rpc`,
      fetchParameters
    );
    const apiData = await apiResponse.json();

    return apiData;
    //
  } catch (error) {
    // Fetch error
    console.error(`Fetch error | ${error}`);
    return false;
  }
};

export const callPaymentAPI = (skin, apiName, data) => {
  const fetchParameters = { ...FETCH_SETTINGS };
  fetchParameters.body = JSON.stringify(data);

  if (!isLocalhost) {
    fetchParameters.headers = {
      "X-Java-Enabled": "false",
      "X-Display-Spec": `${window.screen.width} ${window.screen.height} ${window.screen.colorDepth}`,
      "X-Time-Zone": `${new Date().getTimezoneOffset()}`,
    };
  }

  const outletID =
    (apiName === "dc_cws_vxl_online_order" || apiName === "dc_vxl_pay_bill") && data.params[3]
      ? data.params[3] + "/"
      : "";

  return fetch(
    `${ORIGIN}/${SVC_DIR}/${skin}/consumer/${outletID}${apiName}.cc`,
    fetchParameters
  ).then((response) => response.json());
};

export const callMarquiPaymentAPI = async (skin, apiName, data) => {
  const fetchParameters = { ...FETCH_SETTINGS };
  fetchParameters.body = JSON.stringify(data);

  if (!isLocalhost) {
    fetchParameters.headers = {
      "X-Java-Enabled": "false",
      "X-Display-Spec": `${window.screen.width} ${window.screen.height} ${window.screen.colorDepth}`,
      "X-Time-Zone": `${new Date().getTimezoneOffset()}`,
    };
  }

  const marquiPaymentAPIData = await fetch(
    `${ORIGIN}/${SVC_DIR}/${skin}/consumer/dc_${apiName}.cc`,
    fetchParameters
  );

  if (marquiPaymentAPIData) {
    return marquiPaymentAPIData.json();
  }
};

export const callGetApplePaySession = (skin, apiName, data, storeId) => {
  const fetchParameters = { ...FETCH_SETTINGS };
  fetchParameters.body = JSON.stringify(data);

  let outletID = "";

  if (storeId) {
    outletID = storeId + "/";
  }

  return fetch(
    `${ORIGIN}/${SVC_DIR}/${skin}/consumer/${outletID}${apiName}.cc`,
    fetchParameters
  ).then((response) => response.json());
};

export const callGoogleAddressValidationAPI = (data, apiKey) => {
  const fetchParameters = {
    ...FETCH_SETTINGS,
    headers: {
      "Content-Type": "application/json",
    },
  };
  fetchParameters.body = JSON.stringify(data);

  return fetch(
    `https://addressvalidation.googleapis.com/v1:validateAddress?key=${apiKey}`,
    fetchParameters
  ).then((response) => response.json());
};
