// Libraries
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import { useModal } from "../../../_common/Modal/useModal";

// Helper functions
import {
  formatOrderType,
  getDeviceTypeId,
  getTimestamp,
  toDollars,
} from "../../../_common/helpers";
import { sortByPriceAscending } from "../../../_common/CartHelpers";
import { onRewardClickXtreme } from "../../../_common/helpers/xtremePushHelper";

// API helper functions
import { CallApi1335 } from "../CallApi1335";
import { getAPICardList } from "../../Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";

// Contexts
import AppLanguageContext from "../../../App/AppLanguageContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import OrderTimeContext from "../../OrderTimeContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import UserRoleContext from "../../../App/UserRoleContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import BrandingContext from "../../../App/BrandingContext";
import OrderTypeContext from "../../OrderTypeContext";

// UI components
import RewardButton from "./RewardButton";
import { Modal } from "../../../_common/Modal/Modal";
import { getAPIRewards } from "../../../Dashboard/DashboardRewards/apiHelpers/getAPIRewards";
import { getAPIFullCardNumber } from "../../../Dashboard/DashboardRewards/apiHelpers/getAPIFullCardNumber";
import { LoadingSpinner } from "../../../_common/LoadingSpinner";
import { DialogModal } from "../../../_common/DialogModal/DialogModal";

// Assets
import { ReactComponent as IconStar } from "../../../_common/icons/IconStar.svg";
import { ReactComponent as IconArrowDown } from "../../../_common/icons/IconArrowDown.svg";

// CSS
import "./RewardsForm.css";
import "../../../MyRewards/MyRewards.css";

export default withRouter(function RewardsForm(props) {
  const {
    storedRewards,
    setStoredRewards,
    formattedCart,
    storedPromoCode,
    currentLocation,
    updateOrderSummary,
    closeModal,
    storedRewardsDetails,
    setStoredRewardsDetails,
  } = props;

  const merchantConfigContext = useContext(MerchantConfigContext);
  const apiMerchantRewards = merchantConfigContext.merchant.I14;
  const skin = merchantConfigContext.skin;

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const { isModal, toggleModal, closeAnimation } = useModal();

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const branding = useContext(BrandingContext);
  const orderTypeContext = useContext(OrderTypeContext);
  const orderType = orderTypeContext.value;

  const [selectedCard, setSelectedCard] = useState(null);

  const [userGiftCards, setUserGiftCards] = useState(null);
  useEffect(() => {
    if (loginToken) {
      getAPICardList(
        skin,
        loginToken,
        appSettings["currency-symbol"],
        appSettings["currency-symbol-side"],
        appLanguage,
        branding
      ).then((fetchedData) => {
        if (fetchedData) {
          if (fetchedData.length > 0) {
            setUserGiftCards(fetchedData);
          } else if (fetchedData.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          }
        }
      });
    }
  }, [loginToken]);

  useEffect(() => {
    const storedActiveLoyaltyCard = JSON.parse(
      sessionStorage.getItem(skin + "__activeLoyaltyCard")
    );

    if (storedActiveLoyaltyCard) setSelectedCard(storedActiveLoyaltyCard);
    else if (userGiftCards) setSelectedCard(userGiftCards[0]);
  }, [userGiftCards]);

  const [isErrorNotification, setIsErrorNotification] = useState(false);
  const [errorNotificationText, setErrorNotificationText] = useState("");
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);

  const [toBeSelectedCard, setToBeSelectedCard] = useState(selectedCard);

  const removeAllStoredRewards = () => {
    localforage.removeItem(skin + "__storedRewards");
    setStoredRewards(null);
    setCheckedRewards(null);

    setTimeout(() => {
      updateOrderSummary(storedPromoCode, []);
    }, 500);

    setIsErrorNotification(false);
    setSelectedCard(toBeSelectedCard);
    sessionStorage.setItem(skin + "__activeLoyaltyCard", JSON.stringify(toBeSelectedCard));
  };

  const appLabels = useContext(AppLabelsContext);

  const updateSelectedCard = (e) => {
    const select = e.target;

    if (userGiftCards) {
      userGiftCards.forEach((card) => {
        if (card.iso + "-" + card.serial === select.value) {
          setToBeSelectedCard(card);
          if (
            storedRewards &&
            checkedRewards &&
            storedRewards.length > 0 &&
            checkedRewards.length > 0
          ) {
            setErrorNotificationText(
              appLabels["loyalty"]["moving-applied-reward-on-card-switch-confirm-text"]
            );
            setIsCancelConfirm(true);
            setIsErrorNotification(true);
          } else {
            setSelectedCard(card);
            sessionStorage.setItem(skin + "__activeLoyaltyCard", JSON.stringify(card));
          }
        }
      });
    }
  };

  const [fullCardNumber, setFullCardNumber] = useState(null);
  useEffect(() => {
    if (loginToken && selectedCard) {
      getAPIFullCardNumber(
        skin,
        loginToken,
        selectedCard.iso + "-" + selectedCard.serial,
        appLanguage
      ).then((apiFullCardNumber) => {
        if (apiFullCardNumber) {
          if (apiFullCardNumber.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else {
            setFullCardNumber(apiFullCardNumber);
          }
        }
      });
    }
  }, [selectedCard]);

  const [cardSpecificRewards, setCardSpecificRewards] = useState(null);
  useEffect(() => {
    if (fullCardNumber) {
      getAPIRewards(skin, fullCardNumber, appLanguage).then((apiRewards) => {
        if (apiRewards) {
          apiRewards.forEach((r) => {
            r.rewardType = "cardSpecific";
          });
          localforage.setItem(skin + "__cardSpecificRewards", JSON.stringify(apiRewards));
          setCardSpecificRewards(apiRewards);
        }
      });
    }
  }, [fullCardNumber]);

  const [merchantRewards, setMerchantRewards] = useState(null);
  useEffect(() => {
    let reformattedRewards = [];

    apiMerchantRewards.forEach((apiReward) => {
      //Filter out Free rewards(TypeCode 21) && Points to Money Conversion (TypeCode 6),
      if (apiReward[5] !== "21" && apiReward[5] !== "6") {
        const reformattedReward = {
          id: apiReward[0],
          name: apiReward[1],
          description: apiReward[2],
          price: parseInt(apiReward[3]),
          image: apiReward[4] || false,
          typeCode: apiReward[5],
          typeName: apiReward[6],
          rewardType: "merchant",
        };

        reformattedRewards.push(reformattedReward);
      }
    });

    reformattedRewards = sortByPriceAscending(reformattedRewards);

    // TEMP (until backend adds support): Filter out rewards with images - implied merchandise rewards
    /* const filteredMerchantRewards = reformattedRewards.filter(reward => !reward.image);
    setMerchantRewards(filteredMerchantRewards); */

    localforage.setItem(skin + "__merchantRewards", JSON.stringify(reformattedRewards));
    setMerchantRewards(reformattedRewards);

    /* // TEMP (until backend adds support): Filter out rewards with images - implied card-specific rewards
    const filteredMerchandiseRewards = reformattedRewards.filter(reward => reward.image);
    setMerchandiseRewards(filteredMerchandiseRewards); */
  }, []);

  const [validCardRewards, setValidCardRewards] = useState(null);
  const [validMerchantRewards, setValidMerchantRewards] = useState(null);
  const [isAPILoading, setIsAPILoading] = useState(true);

  // new function to combine all rewards and do one single request
  useEffect(() => {
    let allRewards = [];

    if (merchantRewards && cardSpecificRewards) {
      allRewards = cardSpecificRewards.concat(merchantRewards);

      setIsAPILoading(true);
      /** Valid the merchant rewards based on the current shopping cart */
      let tempApplicableMerchantRewards = [];
      let tempApplicableCardSpecificRewards = [];

      (async () => {
        try {
          const checkRewardData = await checkReward(allRewards);
          if (checkRewardData) {
            if (checkRewardData.result.I0) {
              //Check for merchant specific rewards
              checkRewardData.result.I2.forEach((r) => {
                let merchantRewardFound = allRewards.find(
                  (i) => i.id === r.reward_id && i.rewardType === "merchant"
                );

                let cardSpecificRewardFound = allRewards.find(
                  (i) => i.id === r.reward_id && i.rewardType === "cardSpecific"
                );

                if (merchantRewardFound) {
                  tempApplicableMerchantRewards.push(merchantRewardFound);
                }

                if (cardSpecificRewardFound) {
                  tempApplicableCardSpecificRewards.push(cardSpecificRewardFound);
                }
              });
              //create a new set to remove duplicates
              tempApplicableMerchantRewards = [...new Set(tempApplicableMerchantRewards)];
              tempApplicableCardSpecificRewards = [...new Set(tempApplicableCardSpecificRewards)];
            }
            setValidCardRewards(tempApplicableCardSpecificRewards);
            setValidMerchantRewards(tempApplicableMerchantRewards);
          }
        } catch (error) {
          console.error(error);
        }

        if (cardSpecificRewards.length === 0) {
          setValidCardRewards(tempApplicableCardSpecificRewards);
        }
        if (merchantRewards.length === 0) {
          setValidMerchantRewards(tempApplicableMerchantRewards);
        }
      })();
    }
  }, [merchantRewards, cardSpecificRewards, selectedCard, fullCardNumber]);

  useEffect(() => {
    if (!!validMerchantRewards && !!validCardRewards) {
      setIsAPILoading(false);
    }
  }, [validMerchantRewards, validCardRewards]);

  const orderTimeContext = useContext(OrderTimeContext);

  const [checkedRewards, setCheckedRewards] = useState(storedRewards);

  const checkReward = (rewards) => {
    const checkRewardPromise = new Promise(function (resolve, reject) {
      let redeemRewards = [];

      //iterate over set of reward to make them correct array format
      rewards.forEach((r) => {
        redeemRewards.push([fullCardNumber, r.id]);
      });

      const formattedOrderType = formatOrderType(orderType);
      const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";

      CallApi1335(
        skin,
        formattedCart,
        storedPromoCode,
        formattedOrderType,
        currentLocation,
        redeemRewards,
        appLanguage,
        orderTimeContext.value && orderTimeContext.value.value !== "Select Time"
          ? getTimestamp(orderTimeContext.value.value)
          : "",
        getDeviceTypeId(orderType),
        isStadiumSchema
      )
        .then((data1335) => {
          if (data1335) {
            const result = data1335.result;
            if (result.I0) {
              const discountResult = result.I2.discount_total;
              if (discountResult && parseFloat(discountResult)) {
                setCheckedRewards(redeemRewards);
              }
            }
            resolve(data1335);
          } else {
            reject("API 1335 | ERROR");
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
    return checkRewardPromise;
  };

  //Calculate if the selected card has enough points for redeeming the selected rewards
  const selectedCardHasEnoughPoints = (rewardDetails) => {
    let totalRequiredPoints = 0;
    rewardDetails.forEach((reward) => {
      totalRequiredPoints += reward.price;
    });
    return selectedCard.points >= totalRequiredPoints;
  };

  //Apply the selected rewards to the Order
  const [notEnoughPointsErrorMessage, setNotEnoughPointsErrorMessage] = useState(null);
  const redeemReward = (reward, rewardQuantity) => {
    onRewardClickXtreme(reward, skin);
    let tempNewStoredRewards = !!storedRewards ? [...storedRewards] : [];
    let tempRewardDetails = !!storedRewardsDetails ? [...storedRewardsDetails] : [];

    for (let i = 0; i < rewardQuantity; i++) {
      tempNewStoredRewards.push([fullCardNumber, Number(reward.id)]);
      tempRewardDetails.push(reward);
    }

    if (selectedCardHasEnoughPoints(tempRewardDetails)) {
      setStoredRewardsDetails(null);
      setStoredRewards(null);

      setStoredRewardsDetails(tempRewardDetails);
      setStoredRewards(tempNewStoredRewards);
      closeModal();
    } else {
      setNotEnoughPointsErrorMessage(
        `${appLabels["loyalty"]["not-enough-points-for-redeeming"]
          .replace("[reward-quantity]", rewardQuantity)
          .replace("[reward-name]", reward.name)}`
      );

      setTimeout(() => {
        setNotEnoughPointsErrorMessage(null);
      }, 6000);
    }
  };
  //END OF Apply the selected rewards to the Order

  //const [renderRewards, setRenderRewards] = useState(null);
  // Tag checked rewards in the main list for rendering and update used points
  useEffect(() => {
    if ((checkedRewards && checkedRewards.length === 0) || !checkedRewards) {
      localforage.removeItem(skin + "__storedRewards");
      localforage.removeItem(skin + "__storedRewardsDetails");
      updateOrderSummary(storedPromoCode, checkedRewards);
      setStoredRewards([]);
      setStoredRewardsDetails(null);
    }
  }, [checkedRewards]);
  // END OF Tag checked rewards in the main list for rendering and update used points

  return (
    <>
      {selectedCard && userGiftCards && (
        <>
          <section className="my-rewards__section my-rewards__points-and-card-select reward-form__points-and-card-select">
            <div className="desktop-container">
              <div className="rewards-module__balance-and-star">
                <div className="rewards-form__point-balance-container">
                  <span className="rewards-module__balance">{selectedCard.points}</span>
                  <IconStar />
                </div>
                <div>
                  <span className="rewards-module__balance rewards-module__balance-money">
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      selectedCard.balance,
                      appLanguage,
                      true
                    )}
                  </span>
                </div>
              </div>
              <div className="rewards-module__select-wrapper">
                {userGiftCards && (
                  <select
                    onChange={updateSelectedCard}
                    className="rewards-module__select"
                    value={selectedCard.iso + "-" + selectedCard.serial}
                    id="reward-card-numbers-dropdown"
                  >
                    {userGiftCards.map((card) => (
                      <option
                        key={card.iso + "-" + card.serial}
                        value={card.iso + "-" + card.serial}
                      >
                        {card.maskedNumber}
                      </option>
                    ))}
                  </select>
                )}
                <IconArrowDown />
              </div>
            </div>
          </section>

          <div className=" rewards__main-container">
            <section className="my-rewards__section my-rewards__options-container use-rewards__section desktop-container">
              <div className="my-rewards-section__header">
                <h2 className="my-rewards-section__heading">
                  {appLabels["loyalty"]["available-rewards-subheading"]}
                </h2>
              </div>
              <p className="my-rewards-section__text">
                {appLabels["loyalty"]["add-associated-item-before-redeeming-reward-blurb"]}
              </p>
              {(validCardRewards || validMerchantRewards) && !isAPILoading ? (
                <>
                  <div className="my-rewards__options-table">
                    <div className="my-rewards__options-table-section rewards-form__options-table-section">
                      {validCardRewards.filter((reward) => reward.price === 0).length > 0 && (
                        <>
                          {validCardRewards.map(
                            (reward, index) =>
                              reward.price === 0 && (
                                <RewardButton
                                  itemIndex={index}
                                  key={reward.id}
                                  reward={reward}
                                  selectedCard={selectedCard}
                                  checkedRewards={checkedRewards}
                                  storedRewards={storedRewards}
                                  storedRewardsDetails={storedRewardsDetails}
                                  setStoredRewards={setStoredRewards}
                                  setStoredRewardsDetails={setStoredRewardsDetails}
                                  redeemReward={redeemReward}
                                  maxRedeemableRewardQuantity={reward.rewardBalance}
                                />
                              )
                          )}
                        </>
                      )}
                      {validMerchantRewards && validMerchantRewards.length > 0 && (
                        <>
                          {validMerchantRewards.map((reward, index) => (
                            <RewardButton
                              itemIndex={index}
                              key={reward.id}
                              reward={reward}
                              selectedCard={selectedCard}
                              checkedRewards={checkedRewards}
                              storedRewards={storedRewards}
                              storedRewardsDetails={storedRewardsDetails}
                              setStoredRewards={setStoredRewards}
                              setStoredRewardsDetails={setStoredRewardsDetails}
                              redeemReward={redeemReward}
                              maxRedeemableRewardQuantity={reward.rewardBalance}
                            />
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                  {(!validCardRewards || validCardRewards.length === 0) && (
                    <span className="rewards__none-available-note">
                      {appLabels["loyalty"]["no-rewards-for-card"]}
                    </span>
                  )}
                </>
              ) : (
                <>
                  <div className="rewards-form__skeleton"></div>
                  <div className="rewards-form__skeleton"></div>
                  <div className="rewards-form__skeleton"></div>
                  <div className="rewards-form__skeleton"></div>
                  <div className="rewards-form__skeleton"></div>
                  <LoadingSpinner />
                </>
              )}
            </section>

            {isErrorNotification && (
              <DialogModal
                message={errorNotificationText}
                resetRemoveDialog={() => {
                  setIsErrorNotification(false);
                  setIsCancelConfirm(false);
                }}
                isCancelConfirm={isCancelConfirm}
                confirmAction={removeAllStoredRewards}
              />
            )}

            {notEnoughPointsErrorMessage !== null && (
              <div className="desktop-container reward-form__error-message-container">
                <p>{notEnoughPointsErrorMessage}</p>
              </div>
            )}

            <Modal
              isModal={isModal}
              toggleModal={toggleModal}
              content={<p>{appLabels["general"]["login-expired-modal-message"]}</p>}
              type="default"
              description="Expired login message"
              closeAnimation={closeAnimation}
            />
          </div>
        </>
      )}
    </>
  );
});
