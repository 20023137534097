//Helpers
import { callAPI } from "../../_common/Api";

export const getDeliveryQuote = async (
  skin,
  language,
  outletId,
  integrationType,
  deliveryAddress,
  deliveryTimeStamp,
  deliveryInstructions
) => {
  const formattedDeliveryAddress = {
    order_address: deliveryAddress["first-address"],
    order_postal: deliveryAddress["postal"],
    order_city: deliveryAddress["city"],
    order_prov: deliveryAddress["province"],
  };

  const deliveryQuoteData = await callAPI(skin, "vxl_1341", {
    params: [
      language,
      "1341",
      "mqid",
      "mqpass",
      outletId, //outlet_id
      integrationType, //delivery_integration_type
      formattedDeliveryAddress, //Delivery address
      deliveryTimeStamp, // delivery date and time
      deliveryInstructions, // delivery instructions
    ],
    id: "1341",
  });
  if (deliveryQuoteData) {
    if (deliveryQuoteData.status === 0) {
      const deliveryQuoteInfo = deliveryQuoteData.result.I2;
      return deliveryQuoteInfo;
    } else {
      return {
        successful: false,
        errorCode: deliveryQuoteData.error.code,
        errorMessage: deliveryQuoteData.result.message,
      };
    }
  }
};

export const getDeliveryInfo = async (skin, language, outletId, onlineOrderId) => {
  const deliveryInfo = await callAPI(skin, "vxl_1342", {
    params: [
      language,
      "1342",
      "mqid",
      "mqpass",
      outletId, //outlet_id
      onlineOrderId, //online order id
    ],
    id: "1342",
  });

  if (deliveryInfo) {
    if (deliveryInfo.status === 0) {
      const deliveryInfoData = deliveryInfo.result.I2;
      return deliveryInfoData;
    } else {
      return null;
    }
  }
};
