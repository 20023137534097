window.dataLayer = window.dataLayer || [];
window.gtag = window.gtag || function (){};
/**
 * Measures product impressions and also tracks a standard
 * pageview for the tag configuration.
 * Product impressions are sent by pushing an impressions object
 * containing one or more impressionFieldObjects.
 * @param {Array} listOfItems list of item being viewed
 * @param {String} category A string representing the category id of the items.
 * @param {String} subcategory A string representing a the subcategory id of the items.
 */
export const onImpression = (listOfItems, category, subcategory) => {

  window.dataLayer.push({ ecommerce: null });
  let impressions = [];
  listOfItems.forEach((item, index) => {
    impressions.push({
      name: item.name,
      id: item.id,
      price: item.price,
      position: index + 1,
      category: category,
      subcategory: subcategory,
    });
  });

  if (impressions.length > 0) {
    window.gtag("event", "impression", {
      event: "impression",
      ecommerce: {
        impressions: impressions,
      },
    });
  }
};

/**
 * Call this function when a user clicks on a product link. This function uses the event
 * callback datalayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 * @param {Object} item An object representing a product.
 * @param {String} category A string representing the category id of the item.
 * @param {String} subcategory A string representing a the subcategory id of the item.
 */
export const onProductClick = (item, category, subcategory) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

  /** On Product click */
  window.gtag("event", "productClick", {
    ecommerce: {
      click: {
        products: [
          {
            name: item.name,
            id: item.id,
            price: item.price,
            category: category,
            subcategory: subcategory,
          },
        ],
      },
    },
  });

  /** On Product detail view */
  window.gtag("event", "productClick", {
    ecommerce: {
      detail: {
        products: [
          {
            name: item.name,
            id: item.id,
            price: item.price,
            category: category,
            subcategory: subcategory,
          },
        ],
      },
    },
  });
};

/**
 * Measure adding a product to a shopping cart by using an 'add' actionFieldObject
 */
export const onAddProductToCart = (item, category, subcategory) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.gtag("event", "addToCart", {
    ecommerce: {
      add: {
        products: [
          {
            //  adding a product to a shopping cart.
            name: item.name,
            id: item.id,
            price: item.price,
            category: category,
            subcategory: subcategory,
            quantity: item.quantity,
          },
        ],
      },
    },
  });
};

/**
 * Measure the removal of a product from a shopping cart.
 */
export const onRemoveProductFromCart = (item, category, subcategory, isMultipleItems) => {
  let productList = [];

  if (item) {
    if (isMultipleItems) {
      item.forEach((tempItem) => {
        productList.push({
          name: tempItem.name,
          id: tempItem.id,
          price: tempItem.price,
          category: tempItem.category,
          subcategory: tempItem.subcategory,
          quantity: tempItem.quantity,
        });
      });
    } else {
      productList.push({
        name: item.name,
        id: item.id,
        price: item.price,
        category: category,
        subcategory: subcategory,
        quantity: item.quantity,
      });
    }

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.gtag("event", "removeFromCart", {
      ecommerce: {
        remove: {
          // 'remove' actionFieldObject measures.
          products: productList,
        },
      },
    });
  }
};

export const onAddProductToGCCart = (item, category) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.gtag("event" , "addToGCCart", {
    ecommerce: {
      add: {
        products: [
          {
            //  adding a product to a shopping cart.
            giftCardName: item.name,
            id: item.id,
            amount: item.amount,
            category: "category",
            message: item.message,
            quantity: item.quantity,
          },
        ],
      },
    },
  });
};
export const onRemoveProductFromGCCart = (item, category, subcategory, isMultipleItems) => {
  let productList = [];
  if (item) {
    if (isMultipleItems) {
      item.forEach((tempItem) => {
        productList.push({
          giftCardName: tempItem.giftCardTitle,
          id: tempItem.entryKey,
          amount: tempItem.amount,
          category: "category",
          message: tempItem.message,
          quantity: tempItem.quantity,
        });
      });
    } else {
      productList.push({
        giftCard: item.giftCard,
        id: item.entryKey,
        amount: item.amount,
        message: item.message,
        quantity: item.quantity,
      });
    }

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.gtag("event", "removeFromGCCart", {
      ecommerce: {
        remove: {
          // 'remove' actionFieldObject measures.
          products: productList,
        },
      },
    });
  }
};


/**
 * A function to handle a click on a checkout button. This function uses the eventCallback
 * data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
 */
export const onCheckout = (items, stepNumber, paymentType = "") => {
  if (items === null || !items) {
    return;
  }
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  let products = [];

  items.forEach((item) => {
    products.push({
      name: item.name,
      id: item.id,
      price: item.price,
      category: item.category,
      subcategory: item.subcategory,
      quantity: item.quantity,
    });
  });

  window.gtag("event", "checkout", {
    ecommerce: {
      checkout: {
        actionField: { step: stepNumber, option: paymentType },
        products: products,
      },
    },
  });
};

/**
 * Send transaction data with a pageview if available
 * when the page loads. Otherwise, use an event when the transaction
 * data becomes available.
 * @param {Array} cartItems list of purchased items
 * @param {Object} orderSummary details of the order (total, tax etc)
 * @param {Object} orderStore details of the selected store
 */
export const onPurchaseComplete = (
  cartItems,
  orderSummary,
  orderStore,
  onlineOrderId,
  purchaseAdditionalInfo
) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

  let products = [];

  cartItems.forEach((item) => {
    products.push({
      name: item.name,
      id: item.id,
      price: item.price,
      category: item.category,
      subcategory: item.subcategory,
      quantity: item.quantity,
    });
  });

  window.gtag("event", "purchase",{
    ecommerce: {
      purchase: {
        actionField: {
          id: onlineOrderId, // Transaction ID. Required for purchases and refunds.
          affiliation: orderStore.name,
          revenue: orderSummary.I3, // Total transaction value (incl. tax and shipping)
          tax: orderSummary.I4,
        },
        products: products,
        totalDiscount: orderSummary.I2.discount_total,
        orderType: purchaseAdditionalInfo.orderType,
        promoCode: purchaseAdditionalInfo.promoCode,
        rewards: purchaseAdditionalInfo.rewards,
        tip: purchaseAdditionalInfo.tip,
        pointsIssued: purchaseAdditionalInfo.pointsIssued,
        coupon: purchaseAdditionalInfo.coupon,
      },
    },
  });
};
