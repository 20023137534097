import React, { useContext, useEffect, useState } from "react";

/** Helpers */
import { jsonCopy, toDollars } from "../../_common/helpers";
import { getItemQuantityFromCart } from "../../_common/CartHelpers";

/** CSS */
import "../Menu/MenuCategoryCard/MenuCategoryCard.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import BrandingContext from "../../App/BrandingContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import CartContext from "./CartContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import { isOutOfStock } from "../Menu/helpers/orderingHelpers";
import "./suggetedItemsOrderLevel.css";
import SuggestedMenuItemQuickAddButton from "../Menu/MenuCategoryCard/QuickOrderButton/SuggestedMenuItemQuickAddButton";
import useWindowSize from "../../_common/hooks/useWindowSize";
import "../Cart/SuggestedItems/SuggestedItems.css";
import AppLanguageContext from "../../App/AppLanguageContext";

export const SuggestedItemOrderLevelCard = ({
  handleClick,
  itemData,
  index,
  isFeaturedItemCard,
  isSuggestedItemCard = false,
  setIsAnySuggestedItemAddedToCart = null,
  modifyPanelActive = null,
}) => {
  const {
    id,
    name,
    proxyImageUrl,
    imageUrl,
    price,
    calorie_count,
    type,
    item_count,
    onlineQuantityLimit,
  } = itemData;

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);

  const brandingContext = useContext(BrandingContext);
  const imagePlaceholder = brandingContext["no-image-available-placeholder"];
  const outOfStockImage = brandingContext["item-out-of-stock-image"];
  const cartData = useContext(CartContext);
  const cartItems = cartData.value || [];

  const isFeaturedItem = name.toLowerCase() === "featured";
  const displayName = isFeaturedItem ? appLabels["order"]["featured"] : name;

  const [itemAriaLabel, setItemAriaLabel] = useState();

  const cardButtonLabel = appLabels["order"]["show-category-subcategories"].replace(
    "[category-name]",
    name.toLowerCase()
  );

  const displayPrice = toDollars(
    appSettings["currency-symbol"],
    appSettings["currency-symbol-side"],
    price,
    appLanguage
  );

  const textColor = "#2a2a2a";
  const hasNoAddonModifier =
    type === "productItem" &&
    Object.keys(itemData.modifierGroups).length === 0 &&
    Object.keys(itemData.addonGroups).length === 0 &&
    (Number(item_count > 0) || item_count === "None");

  const [isQuickAddButtonEnabled, setIsQuickAddButtonEnabled] = useState(false);
  const [preSelectedItem, setPreSelectedItem] = useState(null);
  const deviceWidth = useWindowSize().width;

  useEffect(() => {
    if (itemData && type === "productItem" && (Number(item_count > 0) || item_count === "None")) {
      let tempPreSelectedItem = jsonCopy(itemData);
      delete tempPreSelectedItem.entryKey;
      delete tempPreSelectedItem.onlineQuantityLimit;
      /** there is no addon or modifier groups, show the quick add button */
      if (
        Object.keys(tempPreSelectedItem.modifierGroups).length === 0 &&
        Object.keys(tempPreSelectedItem.addonGroups).length === 0
      ) {
        setIsQuickAddButtonEnabled(true);
      } else if (
        Object.keys(tempPreSelectedItem.modifierGroups).length > 0 ||
        Object.keys(tempPreSelectedItem.addonGroups).length > 0
      ) {
        /** if there is addon or modifier group check and see if there is default selection */
        const addonGroups = tempPreSelectedItem.addonGroups;
        const modifierGroups = tempPreSelectedItem.modifierGroups;

        let hasDefaultSelectionForAddons = false;
        let hasDefaultSelectionForNestedModifiers = false;
        let hasDefaultSelectionForModifiers = false;

        let numberOfDefaultRequired = 0;
        // Loop the addon groups
        Object.keys(addonGroups).forEach((addonGroupKey) => {
          const addonGroup = addonGroups[addonGroupKey];
          const isSingleAddon = !addonGroup.id;
          addonGroup.type = "addonGroup";
          const addonGroupMinQuantity = Number(addonGroup.minQuantity);
          const addons = addonGroup.items;
          if (!isSingleAddon) {
            if (addonGroupMinQuantity > 0) {
              numberOfDefaultRequired += addonGroupMinQuantity;
            }

            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              if (addon.isDefault === "True" && !isOutOfStock(addon)) {
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected = true;
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity = 1;
                if (addonGroupMinQuantity > 0) {
                  numberOfDefaultRequired--;
                }
                //check if addon has a nested modifier group
                if (Object.keys(addon.modifierGroups).length > 0) {
                  const nestedModifierGroups = addon.modifierGroups;
                  Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                    const nestedModifierGroup = nestedModifierGroups[nestedModifierGroupKey];
                    const nestedModifierGroupMinQuantity = Number(nestedModifierGroup.minQuantity);

                    if (nestedModifierGroupMinQuantity > 0) {
                      numberOfDefaultRequired += nestedModifierGroupMinQuantity;
                    }
                    const nestedModifiers = nestedModifierGroup.items;
                    Object.keys(nestedModifiers).forEach((nestedModifierKey) => {
                      const nestedModifier = nestedModifiers[nestedModifierKey];
                      if (nestedModifier.isDefault === "True") {
                        hasDefaultSelectionForNestedModifiers = true;
                        if (nestedModifierGroupMinQuantity > 0) {
                          numberOfDefaultRequired--;
                        }
                        tempPreSelectedItem.addonGroups[addonGroupKey].items[
                          addonKey
                        ].modifierGroups[nestedModifierGroupKey].items[
                          nestedModifierKey
                        ].isSelected = true;
                      } else {
                        delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey]
                          .modifierGroups[nestedModifierGroupKey].items[nestedModifierKey]
                          .isSelected;
                      }
                    });
                  });
                }
              } else {
                delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected;
                delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity;
              }
            });
          } else if (isSingleAddon) {
            addonGroup.isStandalone = true;
            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              addonGroup.name = addon.name;
              addonGroup.id = addon.id;
              addon.isStandalone = true;
              if (addon.isDefault === "True" && !isOutOfStock(addon)) {
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected = true;
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity = 1;
              }
            });
          }
          /** numberOfDefaultRequired will reach a negative value if there are more than one default item in a group and the minQuantity is 1 */
          if (numberOfDefaultRequired < 0) {
            numberOfDefaultRequired = 0;
          }
        });

        // numberOfDefaultRequired will reach a negative value if there are multiple default selectons in a group which has a min quantity of 1
        if (numberOfDefaultRequired < 0) {
          numberOfDefaultRequired = 0;
        }

        // Loop the modifier groups
        Object.keys(modifierGroups).forEach((modifierGroupKey) => {
          const modifierGroup = modifierGroups[modifierGroupKey];
          modifierGroup.type = "modifierGroup";
          const modifiers = modifierGroup.items;
          const modifierGroupMinQuantity = Number(modifierGroup.minQuantity);

          if (modifierGroupMinQuantity > 0) {
            numberOfDefaultRequired += modifierGroupMinQuantity;
          }
          Object.keys(modifiers).forEach((modifierKey) => {
            const modifier = modifiers[modifierKey];
            if (modifier.isDefault === "True") {
              if (modifierGroupMinQuantity > 0) {
                numberOfDefaultRequired--;
              }
              tempPreSelectedItem.modifierGroups[modifierGroupKey].items[
                modifierKey
              ].isSelected = true;
            } else {
              delete tempPreSelectedItem.modifierGroups[modifierGroupKey].items[modifierKey]
                .isSelected;
            }
          });

          /** numberOfDefaultRequired will reach a negative value if there are more than one default item in a group and the minQuantity is 1 */
          if (numberOfDefaultRequired < 0) {
            numberOfDefaultRequired = 0;
          }
        });

        if (numberOfDefaultRequired === 0) {
          hasDefaultSelectionForAddons = true;
          hasDefaultSelectionForNestedModifiers = true;
          hasDefaultSelectionForModifiers = true;
        } else {
          hasDefaultSelectionForAddons = false;
          hasDefaultSelectionForNestedModifiers = false;
          hasDefaultSelectionForModifiers = false;
        }

        if (
          hasDefaultSelectionForModifiers &&
          hasDefaultSelectionForAddons &&
          hasDefaultSelectionForNestedModifiers
        ) {
          setIsQuickAddButtonEnabled(true);
          setPreSelectedItem(tempPreSelectedItem);
        } else if (cartItems.filter((item) => item.id === id).length > 0) {
          setIsQuickAddButtonEnabled(true);
        } else {
          setIsQuickAddButtonEnabled(false);
          setPreSelectedItem(null);
        }
      }
    } else if (
      itemData.type === "comboItem" &&
      cartItems.filter((item) => item.id === id).length > 0
    ) {
      setIsQuickAddButtonEnabled(true);
    } else {
      setIsQuickAddButtonEnabled(false);
      setPreSelectedItem(null);
    }
  }, [cartData]);

  useEffect(() => {
    let itemQuantity = getItemQuantityFromCart(cartItems, itemData.id);

    let label = displayName;
    if (itemQuantity > 0) {
      label = label + "." + appLabels["order"]["quantity-in-cart-is"] + " " + itemQuantity + ".";
    }
    if (onlineQuantityLimit) {
      if (itemQuantity < onlineQuantityLimit) {
        label =
          label +
          ". " +
          appLabels["order"]["limit-per-order"].replace("[qty-limit]", onlineQuantityLimit);
      } else if (itemQuantity >= onlineQuantityLimit) {
        label = label + " " + appLabels["order"]["limit-reached"];
      }
    }
    setItemAriaLabel(label);
  }, [cartData]);

  return (
    <>
      {isFeaturedItemCard ? (
        <div
          className={`${
            isFeaturedItemCard ? " menu__category-card-featured" : " menu__category-card"
          } ${isQuickAddButtonEnabled ? " withQuickAddButton" : ""}`}
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="menu-category-card__content-wrapper">
            <div
              className="menuItemDetailsRow suggestedMenuItemDetailsRow"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="menu-category-card__image-wrapper">
                <img
                  src={imageUrl || proxyImageUrl || imagePlaceholder}
                  alt={displayName}
                  className="menu-category-card__image"
                  loading={index > 4 ? "lazy" : "auto"}
                />
                {Number(item_count) <= 0 && (
                  <img
                    src={outOfStockImage}
                    alt={displayName}
                    className="menu-category-card__image-overlay"
                  />
                )}
              </div>
              <div
                className="menu-category-card___text-wrapper"
                style={{
                  backgroundColor: "#fff",
                  borderBottomLeftRadius: isQuickAddButtonEnabled ? "0px" : "",
                  borderBottomRightRadius: isQuickAddButtonEnabled ? "0px" : "",
                }}
              >
                <h2
                  className="menu-category-card__name"
                  style={{
                    color: textColor,
                  }}
                >
                  {displayName}
                </h2>
                {price && (
                  <>
                    {price > 0 ? (
                      <div
                        className="menu-category-card__price"
                        style={{
                          color: textColor,
                        }}
                      >
                        {displayPrice}
                      </div>
                    ) : (
                      <div
                        className="menu-category-card__price"
                        style={{
                          color: textColor,
                        }}
                      >
                        {appLabels["order"]["price-based-on-selection"]}
                      </div>
                    )}
                  </>
                )}
                {calorie_count && (
                  <div
                    className="menu-category-card__calories"
                    style={{
                      color: textColor,
                    }}
                  >{`${calorie_count} ${appLabels["order"]["calories"]}`}</div>
                )}

                {onlineQuantityLimit && (
                  <div
                    className="menu-category-card__price menu-category-card__limit"
                    style={{
                      color: textColor,
                    }}
                  >
                    {getItemQuantityFromCart(cartItems, itemData.id) >= onlineQuantityLimit ? (
                      <>{appLabels["order"]["limit-reached"]}</>
                    ) : (
                      <>
                        {appLabels["order"]["limit-per-order"].replace(
                          "[qty-limit]",
                          onlineQuantityLimit
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              <Link
                onClick={() => handleClick(id, type, itemData)}
                className="menu-category-card__button suggestedOrderLevelItemCardButton"
                aria-label={cardButtonLabel}
                type="button"
                style={type === "productItem" || type === "comboItem" ? { height: "87%" } : {}}
              />
            </div>
            {isQuickAddButtonEnabled && (
              <SuggestedMenuItemQuickAddButton
                itemData={itemData}
                isOrderLeveLSuggestedItemButton={true}
                setIsAnySuggestedItemAddedToCart={setIsAnySuggestedItemAddedToCart}
                requiresCustomization={
                  itemData.type === "comboItem" ||
                  Object.keys(itemData.modifierGroups).length > 0 ||
                  Object.keys(itemData.addonGroups).length > 0
                }
                onlineQuantityLimit={Number(onlineQuantityLimit)}
                preSelectedItem={preSelectedItem}
                hasNoAddonModifier={hasNoAddonModifier}
              />
            )}
          </div>
        </div>
      ) : (
        <li
          className={`${
            isFeaturedItemCard ? " menu__category-card-featured" : " menu__category-card"
          } ${isQuickAddButtonEnabled ? " withQuickAddButton" : ""} ${
            isSuggestedItemCard ? " suggested-item-order-level-card" : ""
          } ${!isQuickAddButtonEnabled ? "remove-suggested-item-card-border" : ""} `}
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="menu-category-card__content-wrapper">
            <div className="menuItemDetailsRow suggestedMenuItemDetailsRow">
              <div className="menu-category-card__image-wrapper suggestedItemOrderLevelImageWrapper">
                <img
                  src={imageUrl || proxyImageUrl || imagePlaceholder}
                  alt={displayName}
                  className="menu-category-card__image suggested-item-list-image"
                  loading={index > 4 ? "lazy" : "auto"}
                />
                {Number(item_count) <= 0 && (
                  <img
                    src={outOfStockImage}
                    alt={displayName}
                    className="menu-category-card__image-overlay"
                  />
                )}
              </div>

              <div className="suggestedItems__name_price_wrapper orderLevelSuggestedItemsPriceWrapper">
                {deviceWidth > 660 && deviceWidth < 1860 ? (
                  <span className="cartItem__heading orderLevelSuggestedItemHeading">
                    {displayName}
                  </span>
                ) : deviceWidth > 660 ? (
                  <span className="cartItem__heading orderLevelSuggestedItemHeading">
                    {displayName.length > 28 ? displayName.substr(0, 28) + "..." : displayName}
                  </span>
                ) : (
                  <span className="cartItem__heading">
                    {displayName.length > 25 ? displayName.substr(0, 25) + "..." : displayName}
                  </span>
                )}

                <span className="cartItem__heading cartItem_keyDetails">
                  {!!itemData.calorie_count &&
                    itemData.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                  {toDollars(
                    appSettings["currency-symbol"],
                    appSettings["currency-symbol-side"],
                    itemData["price"],
                    appLanguage
                  )}
                </span>

                <div className="suggestedItems__button_wrapper">
                  <button
                    type="button"
                    className="suggested_items__view-details"
                    onClick={() => null}
                    style={
                      itemData.description === "" && false
                        ? { visibility: "hidden" }
                        : { visibility: "visible" }
                    }
                  >
                    {appLabels["order"]["view-details"]}
                  </button>
                </div>
              </div>
              <Link
                onClick={() => handleClick(id, type, itemData)}
                className="menu-category-card__button suggestedOrderLevelItemCardButton"
                aria-label={itemAriaLabel}
                type="button"
                style={type === "productItem" || type === "comboItem" ? { height: "87%" } : {}}
              />
            </div>
            {isQuickAddButtonEnabled && (
              <div className="suggestedMenuItemQuickAddButtonContainer">
                <SuggestedMenuItemQuickAddButton
                  itemData={itemData}
                  requiresCustomization={
                    itemData.type === "comboItem" ||
                    Object.keys(itemData.modifierGroups).length > 0 ||
                    Object.keys(itemData.addonGroups).length > 0
                  }
                  onlineQuantityLimit={Number(onlineQuantityLimit)}
                  preSelectedItem={preSelectedItem}
                  hasNoAddonModifier={hasNoAddonModifier}
                  isOrderLeveLSuggestedItemButton={true}
                  setIsAnySuggestedItemAddedToCart={setIsAnySuggestedItemAddedToCart}
                  modifyPanelActive={modifyPanelActive || null}
                />
              </div>
            )}
          </div>
        </li>
      )}
    </>
  );
};
