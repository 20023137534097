import React, { useContext } from "react";
import { useHistory } from "react-router";
import useWindowSize from "../_common/hooks/useWindowSize";

//Context
import StoreContext from "../OnlineOrdering/StoreContext";
import BrandingContext from "../App/BrandingContext";
import AppLabelsContext from "../App/AppLabelsContext";

//Helper functions
import { getContrastColor } from "../_common/colorHelpers";

//UI Components
import { ReactComponent as IconArrowRight } from "../_common/icons/IconArrowRight.svg";
import { ReactComponent as IconMapPin } from "../_common/icons/IconMapPin.svg";

export const OrderSettingsStore = () => {
  const storeContext = useContext(StoreContext);
  const activeOrderStore = storeContext.activeOrderStore;
  const branding = useContext(BrandingContext);

  const deviceWidth = useWindowSize().width;

  const appLabels = useContext(AppLabelsContext);
  const history = useHistory();
  const goToOrderTypes = () => {
    history.push("/online-ordering/order-type");
  };

  const textIconColor = getContrastColor(branding["online-order-settings-background-colour"]);

  return (
    <>
      {deviceWidth >= 660 && (
        <IconMapPin className="orderSettingsRowLocationIcon"
          style={{ color: textIconColor, width: "20px", height: "20px", marginBottom: "3px" }}
        />
      )}
      <div
        className="order-settings-component__store-wrapper"
        style={{ justifyContent: "flex-start" }}
      >
        <div
          className="order-settings-component__store order-settings-component__store-name-wrapper"
          style={{ borderColor: textIconColor }}
        >
          <span
            style={{
              color: textIconColor,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginBottom: "4px",
            }}
          >
            {activeOrderStore ? activeOrderStore.name : appLabels["order"]["select-store"]}
          </span>
        </div>

        <button
          type="button"
          className="order-settings-component__store-button"
          onClick={goToOrderTypes}
          aria-label={appLabels["order"]["select-pickup-location"]}
        >
          <IconArrowRight
            aria-hidden="true"
            style={{ fill: textIconColor }}
            className={"order-settings__right-arrow"}
          />
        </button>
      </div>
    </>
  );
};
