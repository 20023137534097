// Libraries
import React, { useEffect, useContext, useState } from "react";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import localforage from "localforage";
import useWindowSize from "../_common/hooks/useWindowSize";

//Contexts
import AppLabelsContext from "../App/AppLabelsContext";
import BrandingContext from "../App/BrandingContext";
import OrderTypeContext from "./OrderTypeContext";
import AppSettingsContext from "../App/AppSettingsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import { MenuContext } from "./Menu/MenuContext";

//Helpers
import { getOrderTypeImages } from "../OrderSettings/helpers/getOrderTypeImages";
import defaultOrderTypeBackgroundImage from "../OrderSettings/assets/orderTypesBackground.png";
import { getOrderTypeInOptionFormat } from "../_common/helpers";

// UI components
import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import { Pickup } from "./Pickup/Pickup";
import { DineIn } from "./DineIn/DineIn";
import { Delivery } from "./Delivery/Delivery";
import { Menu } from "./Menu/Menu";
import { Payment } from "./Payment/Payment";
import { Cart } from "./Cart/Cart";
import { CartCheckout } from "./Cart/CartCheckout";
import { OrderConfirmation } from "./Payment/OrderConfirmation/OrderConfirmation";
import { RecentOrders } from "./RecentOrders/RecentOrders";
import { OrderTypes } from "../OrderSettings/OrderTypes";
import { OrderStores } from "../OrderSettings/OrderStores";
import { CompletedOrderDetails } from "../OnlineOrdering/RecentOrders/CompletedOrderDetails";
import Bill from "./Menu/Bill/Bill";
import BillPayment from "./Menu/Bill/BillPayment";
import PaymentConfirmation from "./Payment/PaymentConfirmation/PaymentConfirmation";
import { MenuItemDetail } from "./Menu/MenuItemDetail/MenuItemDetail";
import { ActiveOrders } from "./RecentOrders/ActiveOrders";
import { PastOrders } from "./RecentOrders/PastOrders";
import { MenuComboDetail } from "./Menu/MenuComboDetail/MenuComboDetail";
import PaymentMethodPaypalHandlePayment from "./Payment/PaymentMethod/PaymentMethodPaypal/PaymentMethodPaypalHandlePayment";
import OrderDeliveryAddress from "../OrderSettings/OrderDeliveryAddress";
import OrderConfirmAddress from "../OrderSettings/OrderConfirmAddress";
import OrderSeatRowSection from "../OrderSettings/OrderSeatRowSection";
import { SuggestedMenuItemDetail } from "./Menu/MenuItemDetail/SuggestedMenuItemDetail";

export const OnlineOrderingRoutes = (props) => {
  const { triggerMenuRef } = props;
  const branding = useContext(BrandingContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const vexilor = merchantConfigContext.vexilor;
  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  const history = useHistory();
  const { pathname, state } = useLocation();

  const skin = merchantConfigContext.skin;
  const orderMenu = useContext(MenuContext);
  const menuLevel = orderMenu.menuLevel;
  const isThreeLevelMenu = menuLevel === "threeLevels";
  const isTwoLevelMenu = menuLevel === "twoLevels";
  const appLabels = useContext(AppLabelsContext);

  const deviceWidth = useWindowSize().width;

  const [backgroundImage, setBackgroundImage] = useState("");

  const appSettings = useContext(AppSettingsContext);
  const relativePath = appSettings["relative-path"];

  const isPayOnly = appSettings["is-pay-only"] === "yes";
  const isDineinHidden = !!appSettings["hide-dinein"] && appSettings["hide-dinein"] === "yes";

  const isLocalhost = process.env.NODE_ENV === "development";
  const IS_GIVEX_HOST = !isLocalhost;
  const PATHNAME = window.location.pathname;
  const IS_DEFAULT_LANGUAGE = IS_GIVEX_HOST
    ? PATHNAME.split("/")[3] === ""
    : PATHNAME.split("/")[1] === "";

  useEffect(() => {
    getOrderTypeImages(relativePath).then((fetchedImagesData) => {
      if (fetchedImagesData) {
        if (isLocalhost) {
          setBackgroundImage(defaultOrderTypeBackgroundImage);
        } else {
          if (
            fetchedImagesData !== "no-images-set" &&
            fetchedImagesData["background-image"] &&
            fetchedImagesData["background-image"]["image-src"] !== "" &&
            !fetchedImagesData["background-image"]["image-src"].includes("_blank")
          ) {
            if (IS_DEFAULT_LANGUAGE) {
              setBackgroundImage(fetchedImagesData["background-image"]["image-src"]);
            } else {
              setBackgroundImage("../" + fetchedImagesData["background-image"]["image-src"]);
            }
          }
        }
      }
    });
  }, []);

  /** Check to see if there are more than one order type */
  const [hasMoreThanOneOrderType, setHasMoreThanOneOrderType] = useState(true);
  useEffect(() => {
    const options = [];
    const merchantOrderTypes = vexilor.I2.active_order_types.split(",");

    merchantOrderTypes.forEach((merchantOrderType) => {
      const option = getOrderTypeInOptionFormat(merchantOrderType, appLabels["order"]);
      if (!(option.value === "dinein" && isDineinHidden)) {
        options.push(option);
      }
    });
    setHasMoreThanOneOrderType(options.length > 1);
  }, []);

  const [menuBackButtonPath, setMenuBackButtonPath] = useState("/dashboard");
  const setupMenuBackButtonPath = (path) => {
    let tempBackButtonDestination = "";
    const isTwoLevels = orderMenu.menuLevel === "twoLevels";
    const isThreeLevels = orderMenu.menuLevel === "threeLevels";

    const currentPath = path;
    const isOnItemLevel = currentPath.includes("item") || currentPath.includes("combo") || currentPath.includes("suggestedItem");
    const isOnSubcategoryItemsLevel = currentPath.includes("subcategory") && !isOnItemLevel;
    const isOnCategoryItemsLevel =
      currentPath.includes("category") && !isOnSubcategoryItemsLevel && !isOnItemLevel;

    if (isTwoLevels) {
      if (isOnSubcategoryItemsLevel) {
        tempBackButtonDestination = "/online-ordering/menu";
      } else if (isOnItemLevel) {
        tempBackButtonDestination = `/online-ordering/menu/subcategory/${orderMenu.activeSubcategory}`;
      } else {
        tempBackButtonDestination = "/dashboard";
      }
    } else if (isThreeLevels) {
      const categoryId = currentPath.match(/category\/[0-9]*/)
        ? currentPath.match(/category\/[0-9]*/)[0].split("/")[1]
        : "";
      const subcategoryId = currentPath.match(/subcategory\/[0-9]*/)
        ? currentPath.match(/subcategory\/[0-9]*/)[0].split("/")[1]
        : "";

      if (isOnCategoryItemsLevel) {
        tempBackButtonDestination = "/online-ordering/menu";
      } else if (isOnSubcategoryItemsLevel) {
        if (orderMenu.apiData && Object.keys(orderMenu.apiData).length > 0) {
          const hasOneSubcategory =
            orderMenu.apiData[`category-${categoryId}`] &&
            orderMenu.apiData[`category-${categoryId}`].noOfSubcats === "1";
          if (hasOneSubcategory) {
            tempBackButtonDestination = "/online-ordering/menu";
          } else {
            tempBackButtonDestination = `/online-ordering/menu/category/${categoryId}`;
          }
        } else {
          tempBackButtonDestination = `/online-ordering/menu/category/${categoryId}`;
        }
      } else if (isOnItemLevel) {
        tempBackButtonDestination = `/online-ordering/menu/category/${categoryId}/subcategory/${subcategoryId}`;
      } else {
        tempBackButtonDestination = "/dashboard";
      }
    } else {
      if (currentPath === "/online-ordering/menu") {
        tempBackButtonDestination = "/dashboard";
      } else if (currentPath.includes("category") && !currentPath.includes("subcategory")) {
        tempBackButtonDestination = "/online-ordering/menu";
      } else if (currentPath.includes("category") && currentPath.includes("subcategory")) {
        const categoryId = currentPath.match(/category\/[0-9]*/)[0].split("/")[1];
        tempBackButtonDestination = `/online-ordering/menu/category/${categoryId}`;
      } else {
        tempBackButtonDestination = null;
      }
    }

    setMenuBackButtonPath(tempBackButtonDestination);
  };

  useEffect(() => {
    if (state && state.isModifyingItem) {
      setMenuBackButtonPath(null);
    }
  }, [state]);

  useEffect(() => {
    setupMenuBackButtonPath(history.location.pathname);
    history.listen((location, action) => {
      if (location && action === "PUSH") {
        setupMenuBackButtonPath(location.pathname);
      }
    });
  }, [orderMenu]);

  const [isIncomingLinkValid, setIsIncomingLinkValid] = useState(false);

  const [suggestedItemTrackingInfo, setSuggestedItemTrackingInfo] = useState(false) // if a suggested item route/flow, extract info needed for xtreme push event
  useEffect(() => {
    if (state && state.from === "order-level-suggested-items" && state.originalItemData) {
      let trackingInfo = {
        suggestedItemId: state.originalItemData.id,
        isAIrecommendation: state.originalItemData.isAIrecommendation,
        isSuggestedItem: state.originalItemData.isSuggestedItem,
        isSuggestedTwice: state.originalItemData.isSuggestedTwice,
        suggestedItemType: state.originalItemData.suggestedItemType,
      };
      setSuggestedItemTrackingInfo(trackingInfo)
    }
  },[state])

  useEffect(() => { 
    if (orderMenu.apiData) {
      if (pathname.includes("undefined")) {
        orderMenu.updateMenu({
          ...orderMenu,
          activeTopCategory: null,
          activeSubcategory: null,
          activeItem: null,
        });
        history.push("/online-ordering/menu");
        //
      } else {
        const isTopCategoryLevel =
          pathname.includes("/category/") &&
          !pathname.includes("/subcategory/") &&
          !pathname.includes("/item/") &&
          !pathname.includes("/combo/") && 
          !pathname.includes("/suggestedItem/");

        const isTwoLevelSubcategory =
          pathname.includes("/subcategory/") &&
          !pathname.includes("/category/") &&
          !pathname.includes("/item/") &&
          !pathname.includes("/combo/") &&
          !pathname.includes("/suggestedItem/");

        const isSubcategoryLevel =
          pathname.includes("/category/") &&
          pathname.includes("/subcategory/") &&
          !pathname.includes("/item/") &&
          !pathname.includes("/combo/") && 
          !pathname.includes("/suggestedItem/");

        const isItemLevel =
          ((pathname.includes("/item/") || pathname.includes("/combo/")) || pathname.includes("/suggestedItem/") )&&
          ((pathname.includes("/category/") || pathname.includes("/subcategory/")));

        let isValidLink = false;

        if (isTopCategoryLevel) {
          const topCategoryIdFromURL = pathname.split("/category/")[1];
          if (orderMenu.apiData[`category-${topCategoryIdFromURL}`]) isValidLink = true;
          //
        } else if (isTwoLevelSubcategory) {
          // 2-level menu on subcategory reload
          const subcategoryIdFromURL = pathname.split("/subcategory/")[1];
          if (orderMenu.apiData[`subcategory-${subcategoryIdFromURL}`]) isValidLink = true;
          //
        } else if (isSubcategoryLevel) {
          // 3-level menu on subcategory reload
          const topCategoryIdFromURL = pathname.split("/category/")[1].split("/")[0];
          const subcategoryIdFromURL = pathname.split("/subcategory/")[1];

          if (
            orderMenu.apiData[`category-${topCategoryIdFromURL}`] &&
            orderMenu.apiData[`category-${topCategoryIdFromURL}`].subcategories[
              `subcategory-${subcategoryIdFromURL}`
            ]
          ) {
            isValidLink = true;
          }
          //
        } else if (isItemLevel) {
          if (isThreeLevelMenu) {
            // 3-level menu on subcategory reload
            const topCategoryIdFromURL =
              pathname.split("/category/")[1] && pathname.split("/category/")[1].split("/")[0];
            const subcategoryIdFromURL =
              pathname.split("/subcategory/")[1] &&
              pathname.split("/subcategory/")[1].split("/")[0];

            let itemIdFromURL = "";

            if (pathname.includes("/item/") && !pathname.includes("/combo/")) {
              itemIdFromURL = pathname.split("/item/")[1];
            } else if (pathname.includes("/combo/") && !pathname.split("/item/")[1]) {
              itemIdFromURL = pathname.split("/combo/")[1];
            } else if (pathname.includes("/combo/") && pathname.split("/item/")[1]) {
              itemIdFromURL = pathname.split("/combo/")[1].split("/item/")[0];
            }else if (pathname.includes("/suggestedItem/") && !pathname.includes("/combo/")) {
              itemIdFromURL = pathname.split("/suggestedItem/")[1];
            }

            if (
              orderMenu.apiData[`category-${topCategoryIdFromURL}`] &&
              orderMenu.apiData[`category-${topCategoryIdFromURL}`].subcategories[
                `subcategory-${subcategoryIdFromURL}`
              ] &&
              orderMenu.apiData[`category-${topCategoryIdFromURL}`].subcategories[
                `subcategory-${subcategoryIdFromURL}`
              ].items[`item-${itemIdFromURL}`]
            ) {
              isValidLink = true;
            }
            if (
              orderMenu.apiData[`category-${topCategoryIdFromURL}`] &&
              orderMenu.apiData[`category-${topCategoryIdFromURL}`].subcategories[
                `subcategory-${subcategoryIdFromURL}`
              ] &&
              orderMenu.apiData[`category-${topCategoryIdFromURL}`].subcategories[
                `subcategory-${subcategoryIdFromURL}`
              ].items[`suggestedItem-${itemIdFromURL}`]
            ) {
              isValidLink = true;
            }
            //
          } else if (isTwoLevelMenu) {
            const subcategoryIdFromURL = pathname.split("/subcategory/")[1].split("/")[0];

            let itemIdFromURL = "";

            if (pathname.includes("/item/") && !pathname.includes("/combo/")) {
              itemIdFromURL = pathname.split("/item/")[1];
            } else if (pathname.includes("/combo/") && !pathname.split("/item/")[1]) {
              itemIdFromURL = pathname.split("/combo/")[1];
            } else if (pathname.includes("/combo/") && pathname.split("/item/")[1]) {
              itemIdFromURL = pathname.split("/combo/")[1].split("/item/")[0];
            } else if (pathname.includes("/suggestedItem/") && !pathname.includes("/combo/")) {
              itemIdFromURL = pathname.split("/suggestedItem/")[1];
            }

            if (
              orderMenu.apiData[`subcategory-${subcategoryIdFromURL}`] &&
              orderMenu.apiData[`subcategory-${subcategoryIdFromURL}`].items[
                `item-${itemIdFromURL}`
              ]
            ) {
              isValidLink = true;
            }
            if (
              orderMenu.apiData[`subcategory-${subcategoryIdFromURL}`] &&
              orderMenu.apiData[`subcategory-${subcategoryIdFromURL}`].items[
                `suggestedItem-${itemIdFromURL}`
              ]
            ) {
              isValidLink = true;
            }
            //
          }
        }
        else {
          // URL was for Menu's landing page
          isValidLink = true;
        }

        if (isValidLink) {
          setIsIncomingLinkValid(true);
        }
        else {
          orderMenu.updateMenu({
            ...orderMenu,
            activeTopCategory: null,
            activeSubcategory: null,
            activeItem: null,
          });
          if (pathname !== "/online-ordering/menu") history.push("/online-ordering/menu");
        }
      }
    }
  }, [orderMenu.apiData, pathname]);

  const [activeModifyItem, setActiveModifyItem] = useState(null);
  useEffect(() => {
    if (pathname.includes("combo") || pathname.includes("item") || pathname.includes("suggestedItem")) {
      localforage.getItem(skin + "__activeItemForModifying").then((modifyItem) => {
        if (modifyItem) {
          setActiveModifyItem(JSON.parse(modifyItem));
          return true;
        } else {
          setActiveModifyItem(null);
          return false;
        }
      });
    } else {
      localforage.removeItem(skin + "__activeItemForModifying");
      setActiveModifyItem(null);
    }
  }, [pathname]);

  const [hasItemInProgress, setHasItemInProgress] = useState(false);
  useEffect(() => {
    localforage.getItem(skin + "__itemSelectionInProgress").then((itemInProgress) => {
      if (itemInProgress) {
        setHasItemInProgress(true);
      } else {
        setHasItemInProgress(false);
      }
    });
  }, [pathname]);

  const [currentComboChildItem, setCurrentComboChildItem] = useState(null);
  const [showingComboChildItem, setShowingComboChildItem] = useState(null);
  const [isEditingComboItem, setIsEditingComboItem] = useState(false);

  const updateActiveItem = (
    id,
    parentId,
    type,
    isForComboChildItem = false,
    comboChildItem = null,
    isModifyDisplay = false,
    isEditingComboItem = false,
    isFromCustomizationModal = false
  ) => {
    orderMenu.updateMenu({ ...orderMenu, activeComboSubItem: id, activeItem: parentId });

    let itemDestination;
    if (isForComboChildItem) {
      itemDestination = (
        "/" +
        window.location.hash
          .toString()
          .replace(/\/item\/[0-9]*/, "")
          .replace(/item\/[0-9]*/, "") +
        `/item/${id}`
      ).replace("/#", "");
      setCurrentComboChildItem(comboChildItem);
      setShowingComboChildItem(true);
    } else {
      setShowingComboChildItem(false);
      if (type === "comboItem") {
        if (isThreeLevelMenu) {
          itemDestination = `/online-ordering/menu/category/${orderMenu.activeTopCategory}/subcategory/${parentId}/combo/${id}`;
        } else {
          itemDestination = `/online-ordering/menu/subcategory/${parentId}/combo/${id}`;
        }
      } else {
        if (isTwoLevelMenu) {
          itemDestination = `/online-ordering/menu/category/${orderMenu.activeTopCategory}/subcategory/${parentId}/item/${id}`;
        } else {
          itemDestination = `/online-ordering/menu/subcategory/${parentId}/item/${id}`;
        }
      }
    }

    setIsEditingComboItem(isEditingComboItem);
    history.push({
      pathname: `${itemDestination.replace("?customization-modal", "")}${
        isFromCustomizationModal ? "?customization-modal" : ""
      }`,
      state: isEditingComboItem
        ? { from: "editing-combo-item" }
        : isModifyDisplay
        ? { isModifyingItem: true, from: "menu-slider" }
        : state && state.from === "suggestedItems"
        ? { from: "suggestedItems" }
        : { from: "menu-slider" },
    });
  };

  const location = useLocation()
  return (
    <Switch>
      <Route
        exact
        path="/online-ordering"
        render={() =>
          activeOrderType === "delivery" ? (
            <Redirect to="/online-ordering/delivery" />
          ) : activeOrderType === "dinein" ? (
            <Redirect to="/online-ordering/dinein" />
          ) : (
            <Redirect
              to={{ pathname: "/online-ordering/menu", state: { from: "/online-ordering" } }}
            />
          )
        }
      />
      <Route
        path="/online-ordering/pickup"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["select-store"]}
            isHomeLink={true}
            isBackButton={true}
            mainClassOverride="main-content--pickup"
            component={<Pickup />}
          />
        )}
      />
      <Route
        path="/online-ordering/dinein"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["dine-in"]}
            isHomeLink={true}
            backButtonDestination={hasMoreThanOneOrderType ? null : "/dashboard"}
            isBackButton={true}
            bannerImage={branding["online-ordering-landing-page"]}
            mainClassOverride="main-content--dine-in-qr-scanner"
            component={<DineIn hasMoreThanOneOrderType={hasMoreThanOneOrderType} />}
            isFooter={false}
          />
        )}
      />

      {/** This route is required for when dinein qr code is scanned and guest access is disabled  */}
      <Route
        path="/online-ordering/dinein?outlet_id=:outletID&table_id=:tableID"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["dine-in"]}
            isHomeLink={true}
            backButtonDestination={hasMoreThanOneOrderType ? null : "/dashboard"}
            isBackButton={true}
            bannerImage={branding["online-ordering-landing-page"]}
            mainClassOverride="main-content--dine-in-qr-scanner"
            component={<DineIn hasMoreThanOneOrderType={hasMoreThanOneOrderType} />}
            isFooter={false}
          />
        )}
      />

      <Route
        path="/online-ordering/delivery"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["select-store"]}
            isBackButton={true}
            isHomeLink={true}
            mainClassOverride="main-content--delivery"
            component={<Delivery />}
          />
        )}
      />

      <Route
        path="/online-ordering/payment"
        exact
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["payment"]}
            isBackButton={true}
            component={<Payment />}
            isFooter={false}
            backButtonDestination="/online-ordering/checkout"
          />
        )}
      />

      <Route
        path="/online-ordering/payment/paypal"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["payment"]}
            isBackButton={true}
            component={<PaymentMethodPaypalHandlePayment />}
            isFooter={false}
            backButtonDestination="/online-ordering/checkout"
          />
        )}
      />

      <Route
        path="/online-ordering/review-order"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["review-order"]}
            isBackButton={true}
            backButtonDestination="/online-ordering/menu"
            isHomeLink={true}
            mainClassOverride="main-content--with-FAB cart-content"
            component={<Cart isOnCheckoutPage={false} />}
            isFooter={false}
          />
        )}
      />

      <Route
        path="/online-ordering/checkout"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["checkout"]}
            isBackButton={true}
            backButtonDestination={
              activeOrderType === "dinein"
                ? "/online-ordering/review-order"
                : "/online-ordering/menu"
            }
            isHomeLink={true}
            mainClassOverride="checkout-content cart-content"
            component={<CartCheckout isOnCheckoutPage={true} />}
            isFooter={false}
          />
        )}
      />

      <Route
        path="/online-ordering/order-confirmation"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["order-confirmation"]}
            isHomeLink={true}
            component={<OrderConfirmation />}
          />
        )}
      />

      <Route
        path="/online-ordering/recent-orders"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["account"]["recent-orders"]}
            isBackButton={true}
            isHomeLink={true}
            mainClassOverride="recent-order-content"
            component={<RecentOrders />}
          />
        )}
      />

      <Route
        path="/online-ordering/recent-orders/active-orders"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["active-orders"]}
            isBackButton={true}
            isHomeLink={true}
            mainClassOverride="recent-order-content"
            component={<ActiveOrders />}
          />
        )}
      />

      <Route
        path="/online-ordering/recent-orders/past-orders"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["past-orders"]}
            isBackButton={true}
            isHomeLink={true}
            mainClassOverride="recent-order-content"
            component={<PastOrders />}
          />
        )}
      />

      <Route
        path="/online-ordering/order-status?order_id=:id"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["account"]["order-details"]}
            isBackButton={true}
            isHomeLink={true}
            mainClassOverride="order-status-content"
            component={<CompletedOrderDetails />}
          />
        )}
      />

      <Route
        path="/online-ordering/order-type"
        render={() => (
          <PageComponentTemplate
            hasHeaderLogo={false}
            isLanguageButton={false}
            isBackButton={true}
            mainClassOverride="order-types__main-container"
            component={<OrderTypes />}
            backgroundURL={backgroundImage}
            backButtonDestination={"/dashboard"}
            pageHeading={appLabels["order"]["order-types"]}
          />
        )}
      />

      <Route
        path="/online-ordering/delivery-address"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["delivery-address"]}
            isBackButton={true}
            isHomeLink={true}
            //backButtonDestination={"/dashboard"}
            component={<OrderDeliveryAddress />}
          />
        )}
      />

      <Route
        path="/online-ordering/confirm-address"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["confirm-address"]}
            isBackButton={true}
            isHomeLink={true}
            backButtonDestination={"/online-ordering/delivery-address"}
            component={<OrderConfirmAddress />}
            mainClassOverride={"confirm-order__form-container"}
          />
        )}
      />

      <Route
        path="/online-ordering/seat-section"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["confirm-seating"]}
            isBackButton={true}
            isHomeLink={true}
            backButtonDestination={"/online-ordering/order-type"}
            component={<OrderSeatRowSection />}
            mainClassOverride={"seat-section__form-container"}
            isFooter={true}
          />
        )}
      />

      <Route
        path="/online-ordering/order-store"
        render={() => (
          <PageComponentTemplate
            isBackButton={true}
            isHomeLink={true}
            backButtonDestination={
              hasMoreThanOneOrderType ? "/online-ordering/order-type" : "/dashboard"
            }
            pageHeading={appLabels["order"]["select-store"]}
            mainClassOverride="order-stores__main-container"
            component={<OrderStores triggerMenuRef={triggerMenuRef} />}
          />
        )}
      />

      <Route
        path="/online-ordering/bill"
        render={() => (
          <PageComponentTemplate
            isBackButton={!isPayOnly}
            isHomeLink={true}
            hasHeaderLogo={false}
            mainClassOverride="pay-bill__main-container"
            component={<Bill />}
            isFooter={false}
            pageHeading={appLabels["order"]["bill"]}
          />
        )}
      />

      <Route
        path="/online-ordering/bill-payment"
        render={() => (
          <PageComponentTemplate
            isBackButton={true}
            isHomeLink={true}
            hasHeaderLogo={false}
            pageHeading={appLabels["order"]["checkout"]}
            backButtonDestination={"/online-ordering/bill"}
            mainClassOverride="bill-payment__main-container"
            component={<BillPayment />}
            isFooter={false}
          />
        )}
      />

      <Route
        path="/online-ordering/payment-confirmation"
        render={() => (
          <PageComponentTemplate
            isBackButton={false}
            isHomeLink={true}
            hasHeaderLogo={false}
            pageHeading={appLabels["order"]["payment-confirmation"]}
            mainClassOverride="payment-confirmation__main-container"
            component={<PaymentConfirmation />}
            isFooter={true}
          />
        )}
      />

      {orderMenu.apiData && isIncomingLinkValid && (
        <Route
          exact
          path="/online-ordering/menu/subcategory/:subcategory/item/:item"
          render={() => (
            <PageComponentTemplate
              isBackButton={false}
              isHomeLink={false}
              hasHeaderLogo={false}
              pageHeading={appLabels["order"]["customize"]}
              mainClassOverride="menu-item_main-container"
              component={
                <MenuItemDetail
                  displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                  menuLevel={menuLevel}
                  isInCart={state && state.isModifyingItem ? true : false}
                  cartItem={!!activeModifyItem ? activeModifyItem : null}
                  backButtonDestination={menuBackButtonPath}
                  hasItemInProgress={hasItemInProgress}
                  suggestedItemTrackingInfo={suggestedItemTrackingInfo}
                />
              }
              isFooter={false}
              isXButton={true}
              isFavButton={true}
              backButtonDestination={menuBackButtonPath}
            />
          )}
        />
      )}

      {orderMenu.apiData && isIncomingLinkValid && (
        <Route
          key={location.pathname}
          exact
          path="/online-ordering/menu/category/:category/subcategory/:subcategory/parentItem/:parentItem/suggestedItem/:suggestedItem"
          render={() => (
            <PageComponentTemplate
              isBackButton={false}
              isHomeLink={false}
              hasHeaderLogo={false}
              pageHeading={appLabels["order"]["customize"]}
              mainClassOverride="menu-item_main-container"
              component={
                <SuggestedMenuItemDetail 
                displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                menuLevel={menuLevel}
                isInCart={state && state.isModifyingItem ? true : false}
                cartItem={!!activeModifyItem ? activeModifyItem : null}
                backButtonDestination={menuBackButtonPath}
                hasItemInProgress={hasItemInProgress}
                suggestedItemTrackingInfo={suggestedItemTrackingInfo}
                />
              }
              isFooter={false}
              isXButton={true}
              isFavButton={true}
              backButtonDestination={menuBackButtonPath}
            />
          )}
        />
      )}
      {orderMenu.apiData && isIncomingLinkValid && (
        <Route
          key={location.pathname}
          exact
          path="/online-ordering/menu/subcategory/:subcategory/parentItem/:parentItem/suggestedItem/:suggestedItem"
          render={() => (
            <PageComponentTemplate
              isBackButton={false}
              isHomeLink={false}
              hasHeaderLogo={false}
              pageHeading={appLabels["order"]["customize"]}
              mainClassOverride="menu-item_main-container"
              component={
                <SuggestedMenuItemDetail
                  displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                  menuLevel={menuLevel}
                  isInCart={state && state.isModifyingItem ? true : false}
                  cartItem={!!activeModifyItem ? activeModifyItem : null}
                  backButtonDestination={menuBackButtonPath}
                  hasItemInProgress={hasItemInProgress}
                  suggestedItemTrackingInfo={suggestedItemTrackingInfo}
                />
              }
              isFooter={false}
              isXButton={true}
              isFavButton={true}
              backButtonDestination={menuBackButtonPath}
            />
          )}
        />
      )}
      {orderMenu.apiData && isIncomingLinkValid && (
        <Route
          exact
          path="/online-ordering/menu/subcategory/:subcategory/combo/:combo"
          render={() => (
            <>
              {deviceWidth < 660 ? (
                <MenuComboDetail
                  displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                  isInCart={state && state.isModifyingItem ? true : false}
                  updateActiveItem={updateActiveItem}
                  currentComboChildItem={currentComboChildItem} //the child item of the main combo
                  showingComboChildItem={showingComboChildItem}
                  setShowingComboChildItem={setShowingComboChildItem}
                  cartItem={!!activeModifyItem ? activeModifyItem : null}
                  backButtonDestination={menuBackButtonPath}
                  isEditingComboItem={isEditingComboItem}
                  setIsEditingComboItem={setIsEditingComboItem}
                />
              ) : (
                <PageComponentTemplate
                  isBackButton={false}
                  isHomeLink={false}
                  hasHeaderLogo={false}
                  mainClassOverride="menu-item_main-container menu-combo-item_main-container"
                  component={
                    <MenuComboDetail
                      displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                      isInCart={state && state.isModifyingItem ? true : false}
                      updateActiveItem={updateActiveItem}
                      currentComboChildItem={currentComboChildItem} //the child item of the main combo
                      showingComboChildItem={showingComboChildItem}
                      setShowingComboChildItem={setShowingComboChildItem}
                      cartItem={!!activeModifyItem ? activeModifyItem : null}
                      backButtonDestination={menuBackButtonPath}
                      isEditingComboItem={isEditingComboItem}
                      setIsEditingComboItem={setIsEditingComboItem}
                    />
                  }
                  isFooter={false}
                  isXButton={true}
                  isFavButton={true}
                  backButtonDestination={menuBackButtonPath}
                />
              )}
            </>
          )}
        />
      )}

      {orderMenu.apiData && isIncomingLinkValid && (
        <Route
          exact
          path="/online-ordering/menu/subcategory/:subcategory/combo/:combo/item/:item"
          render={() => (
            <>
              {deviceWidth < 660 ? (
                <MenuComboDetail
                  displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                  isInCart={state && state.isModifyingItem ? true : false}
                  updateActiveItem={updateActiveItem}
                  currentComboChildItem={currentComboChildItem} //the child item of the main combo
                  showingComboChildItem={showingComboChildItem}
                  setShowingComboChildItem={setShowingComboChildItem}
                  cartItem={!!activeModifyItem ? activeModifyItem : null}
                  backButtonDestination={menuBackButtonPath}
                  isEditingComboItem={isEditingComboItem}
                  setIsEditingComboItem={setIsEditingComboItem}
                />
              ) : (
                <PageComponentTemplate
                  isBackButton={false}
                  isHomeLink={false}
                  hasHeaderLogo={false}
                  mainClassOverride={`menu-item_main-container menu-combo-item_main-container ${
                    showingComboChildItem ? "menu-combo-item-details_main-container" : ""
                  }`}
                  component={
                    <MenuComboDetail
                      displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                      isInCart={state && state.isModifyingItem ? true : false}
                      updateActiveItem={updateActiveItem}
                      currentComboChildItem={currentComboChildItem} //the child item of the main combo
                      showingComboChildItem={showingComboChildItem}
                      setShowingComboChildItem={setShowingComboChildItem}
                      cartItem={!!activeModifyItem ? activeModifyItem : null}
                      backButtonDestination={menuBackButtonPath}
                      isEditingComboItem={isEditingComboItem}
                      setIsEditingComboItem={setIsEditingComboItem}
                    />
                  }
                  isFooter={false}
                  isXButton={true}
                  isFavButton={true}
                  backButtonDestination={menuBackButtonPath}
                />
              )}
            </>
          )}
        />
      )}
      {orderMenu.apiData && isIncomingLinkValid && (
        <Route
          exact
          path="/online-ordering/menu/category/:category/subcategory/:subcategory/item/:item"
          render={() => (
            <PageComponentTemplate
              isBackButton={false}
              isHomeLink={false}
              hasHeaderLogo={false}
              pageHeading={appLabels["order"]["customize"]}
              mainClassOverride="menu-item_main-container"
              component={
                <MenuItemDetail
                  displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                  menuLevel={menuLevel}
                  isInCart={state && state.isModifyingItem ? true : false}
                  cartItem={!!activeModifyItem ? activeModifyItem : null}
                  backButtonDestination={menuBackButtonPath}
                  hasItemInProgress={hasItemInProgress}
                  suggestedItemTrackingInfo={suggestedItemTrackingInfo}
                />
              }
              isFooter={false}
              isXButton={true}
              isFavButton={true}
              backButtonDestination={menuBackButtonPath}
            />
          )}
        />
      )}

      {orderMenu.apiData && isIncomingLinkValid && (
        <Route
          exact
          path="/online-ordering/menu/category/:category/subcategory/:subcategory/combo/:combo"
          render={() => (
            <>
              {deviceWidth < 660 ? (
                <MenuComboDetail
                  displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                  isInCart={state && state.isModifyingItem ? true : false}
                  updateActiveItem={updateActiveItem}
                  currentComboChildItem={currentComboChildItem} //the child item of the main combo
                  showingComboChildItem={showingComboChildItem}
                  setShowingComboChildItem={setShowingComboChildItem}
                  cartItem={!!activeModifyItem ? activeModifyItem : null}
                  backButtonDestination={menuBackButtonPath}
                  isEditingComboItem={isEditingComboItem}
                  setIsEditingComboItem={setIsEditingComboItem}
                />
              ) : (
                <PageComponentTemplate
                  isBackButton={false}
                  isHomeLink={false}
                  hasHeaderLogo={false}
                  mainClassOverride={`menu-item_main-container menu-combo-item_main-container ${
                    showingComboChildItem ? "menu-combo-item-details_main-container" : ""
                  }`}
                  component={
                    <MenuComboDetail
                      displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                      isInCart={state && state.isModifyingItem ? true : false}
                      updateActiveItem={updateActiveItem}
                      currentComboChildItem={currentComboChildItem} //the child item of the main combo
                      showingComboChildItem={showingComboChildItem}
                      setShowingComboChildItem={setShowingComboChildItem}
                      cartItem={!!activeModifyItem ? activeModifyItem : null}
                      backButtonDestination={menuBackButtonPath}
                      isEditingComboItem={isEditingComboItem}
                      setIsEditingComboItem={setIsEditingComboItem}
                    />
                  }
                  isFooter={false}
                  isXButton={true}
                  isFavButton={true}
                  backButtonDestination={menuBackButtonPath}
                />
              )}
            </>
          )}
        />
      )}

      {orderMenu.apiData && isIncomingLinkValid && (
        <Route
          exact
          path="/online-ordering/menu/category/:category/subcategory/:subcategory/combo/:combo/item/:item"
          render={() => (
            <>
              {deviceWidth < 660 ? (
                <MenuComboDetail
                  displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                  isInCart={state && state.isModifyingItem ? true : false}
                  updateActiveItem={updateActiveItem}
                  currentComboChildItem={currentComboChildItem} //the child item of the main combo
                  showingComboChildItem={showingComboChildItem}
                  setShowingComboChildItem={setShowingComboChildItem}
                  cartItem={!!activeModifyItem ? activeModifyItem : null}
                  backButtonDestination={menuBackButtonPath}
                  isEditingComboItem={isEditingComboItem}
                  setIsEditingComboItem={setIsEditingComboItem}
                />
              ) : (
                <PageComponentTemplate
                  isBackButton={false}
                  isHomeLink={false}
                  hasHeaderLogo={false}
                  mainClassOverride={`menu-item_main-container menu-combo-item_main-container ${
                    showingComboChildItem ? "menu-combo-item-details_main-container" : ""
                  }`}
                  component={
                    <MenuComboDetail
                      displayType={state && state.isModifyingItem ? "cart-modify" : "menu"}
                      isInCart={state && state.isModifyingItem ? true : false}
                      updateActiveItem={updateActiveItem}
                      currentComboChildItem={currentComboChildItem} //the child item of the main combo
                      showingComboChildItem={showingComboChildItem}
                      setShowingComboChildItem={setShowingComboChildItem}
                      cartItem={!!activeModifyItem ? activeModifyItem : null}
                      backButtonDestination={menuBackButtonPath}
                      isEditingComboItem={isEditingComboItem}
                      setIsEditingComboItem={setIsEditingComboItem}
                    />
                  }
                  isFooter={false}
                  isXButton={true}
                  isFavButton={true}
                  backButtonDestination={menuBackButtonPath}
                />
              )}
            </>
          )}
        />
      )}

      <Route
        path="/online-ordering/menu"
        render={() => (
          <PageComponentTemplate
            hasHeaderLogo={false}
            backButtonDestination={menuBackButtonPath}
            isBackButton={true}
            isHomeLink={false}
            pageHeading={appLabels["order"]["menu"]}
            isMapPin={false}
            component={<Menu />}
            isFooter={true}
            isOrderSettingsHidden={window.location.href.includes("combo") ? true : false}
            mainClassOverride="main-content menu-main-content"
          />
        )}
      />
    </Switch>
  );
};
