// Libraries
import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import useWindowSize from "../../../_common/hooks/useWindowSize";

// Helper functions
import { findPos, getArray, isArrItems, jsonCopy, toDollars } from "../../../_common/helpers";
import {
  checkOutOfStockAddons,
  checkRequiredSelection,
  getTotalItemPriceBasedOnSelection,
} from "../../../_common/MenuHelpers";
import {
  determineNextOptionGroupButtonText,
  determineOrderingStateActiveButton,
  reachedMinRequirements,
} from "../MenuItemDetail/MenuItemDetailHelpers";

// Contexts
import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import { MenuContext } from "../MenuContext";
import CartContext from "../../Cart/CartContext";
import BrandingContext from "../../../App/BrandingContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

// UI components
import { AddonsOrModifiers } from "../MenuItemDetail/temp/AddonsOrModifiers";
import { MenuAddComboChoice } from "./MenuAddComboChoice";
import { QuantityButtons } from "../MenuItemDetail/QuantityButtons";
import OptionGroupNextButton from "../MenuItemDetail/OptionGroupNextButton";

// Assets
import { ReactComponent as IconArrowLeft } from "../../../_common/icons/IconArrowLeft.svg";
import { useLocation } from "react-router-dom";

export const MenuComboItemDetail = (props) => {
  const {
    displayType,
    cartItem,
    item,
    comboCartItem,
    currentSubgroupNumber,
    setShowingComboChildItem,
    isQuantityCombo,
    isEditingComboItem,
    setShowSummary,
    state,
    subGroupQuantityReached,
    comboSubgroupNumber,
    groupIsComplete,
    setCurrentSubgroupNumber,
  } = props;

  const performAutoScroll = useRef(true);

  const deviceWidth = useWindowSize().width;

  const foodMenu = useContext(MenuContext);
  const cart = useContext(CartContext);
  const brandingContext = useContext(BrandingContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const cartContext = useContext(CartContext);
  const { skin } = useContext(MerchantConfigContext);

  /** menuItem represents the selected combo subitem */
  const [menuItem, setMenuItem] = useState(null);
  // When modifying item details item is derived from localforage
  const [specialInstructions, setSpecialInstructions] = useState();
  const [addonsOutOfStock, setAddonsOutOfStock] = useState(false);
  const [outOfStockError, setOutOfStockError] = useState("");

  const [addToOrderButtonText, setAddToOrderButtonText] = useState("");

  const [formattedOptionGroups, setFormattedOptionGroups] = useState({});
  const [orderingState, setOrderingState] = useState({
    currentActiveOptionGroupIndex: 0,
    numberOfOptionGroups: null,
    hideAllButtons: false,
    activeButton: "add" /** possible values: add (for add to order), next (For next button) */,
  });

  const isMenuDisplay = displayType === "menu";
  const isModifyDisplay = displayType === "cart-modify";
  const noImagePlaceholder = brandingContext["no-image-available-placeholder"];
  const outOfStockImage = brandingContext["item-out-of-stock-image"];
  const addonGroups = getArray(menuItem, "addonGroups");
  const modifierGroups = getArray(menuItem, "modifierGroups");
  const itemIsOutofStock = menuItem ? Number(menuItem.item_count) <= 0 : false;
  const isStepByStepOrderingEnabled =
    appSettings["enable-step-by-step-ordering"] === "yes" && isMenuDisplay && !isEditingComboItem;
  const isItemOutOfStock = menuItem ? Number(menuItem.item_count) <= 0 : false;

  const [isShowingFullItemDescription, setIsShowingFullItemDescription] = useState(
    appSettings["enable-step-by-step-ordering"] === "yes" ? false : true
  );
  const [isItemDescriptionMinimized, setIsItemDescriptionMinimized] = useState(
    appSettings["enable-step-by-step-ordering"] === "yes" ? true : false
  );

  const showPriceOnOrderButton =
    appSettings["show-price-for-order-button"] === "yes" ||
    !appSettings["show-price-for-order-button"];

  const [storedItemQuantity, setStoredItemQuantity] = useState();
  const { pathname } = useLocation();

  if (isModifyDisplay && !menuItem) {
    setMenuItem(jsonCopy(cartItem));
    setSpecialInstructions(cartItem.specialInstructions);
  }

  useEffect(() => {
    if (item !== null && isEditingComboItem) {
      let editItem;
      if (
        comboCartItem.combo_child_items.find(
          (tempItem) => tempItem.group_id === item.group_id && tempItem.id === item.id
        )
      ) {
        if (item.entryKey) {
          editItem = jsonCopy(
            comboCartItem.combo_child_items.find(
              (tempItem) =>
                tempItem.group_id === item.group_id &&
                tempItem.id === item.id &&
                tempItem.entryKey === item.entryKey
            )
          );
        } else {
          if (
            comboCartItem.combo_child_items.find(
              (tempItem) =>
                tempItem.group_id === item.group_id &&
                tempItem.id === item.id &&
                JSON.stringify(tempItem) === JSON.stringify(item)
            )
          ) {
            editItem = jsonCopy(
              comboCartItem.combo_child_items.find(
                (tempItem) =>
                  tempItem.group_id === item.group_id &&
                  tempItem.id === item.id &&
                  JSON.stringify(tempItem) === JSON.stringify(item)
              )
            );
          }

          if (!editItem) {
            editItem = jsonCopy(
              comboCartItem.combo_child_items.find(
                (tempItem) => tempItem.group_id === item.group_id && tempItem.id === item.id
              )
            );
          }
        }
      } else {
        editItem = jsonCopy(item);
      }

      if (editItem.addonGroups && Object.keys(editItem.addonGroups).length > 0) {
        Object.keys(editItem.addonGroups).forEach((groupKey) => {
          editItem.addonGroups[groupKey].type = "addonGroup";
        });
      }

      if (editItem.modifierGroups && Object.keys(editItem.modifierGroups).length > 0) {
        Object.keys(editItem.modifierGroups).forEach((groupKey) => {
          editItem.modifierGroups[groupKey].type = "modifierGroup";
        });
      }

      setMenuItem(jsonCopy(editItem));
      setSpecialInstructions(jsonCopy(editItem).specialInstructions);
    }
  }, [isEditingComboItem, item]);

  useEffect(() => {
    if (item != null && !isEditingComboItem) {
      let tempComboSubItem = item;

      /** reset the quantity of the item, in case it was selected previously */
      if (
        comboCartItem.combo_child_items.filter(
          (item) => item.id === tempComboSubItem.id && item.group_id === tempComboSubItem.group_id
        ).length === 0
      ) {
        delete tempComboSubItem.quantity;
      }

      Object.keys(tempComboSubItem).map((key) => {
        if (key === "addonGroups") {
          let addonGroups = tempComboSubItem[key];

          Object.keys(addonGroups).map((addonKey) => {
            addonGroups[addonKey].type = "addonGroup";
            return 0;
          });
        }

        if (key === "modifierGroups") {
          let modifierGroups = tempComboSubItem[key];

          Object.keys(modifierGroups).map((modifierKey) => {
            modifierGroups[modifierKey].type = "modifierGroup";
            return 0;
          });
        }

        return 0;
      });

      /** Clean the menuItem Data before assigning it to the menuItem state */
      if (tempComboSubItem) {
        Object.keys(tempComboSubItem.addonGroups).forEach((addonGroupKey) => {
          const addonGroup = tempComboSubItem.addonGroups[addonGroupKey];
          Object.keys(addonGroup.items).forEach((addonKey) => {
            const addon = addonGroup.items[addonKey];
            delete addon.isSelected;
            delete addon.quantity;
            Object.keys(addon.modifierGroups).forEach((modifierGroupKey) => {
              const modifierGroup = addon.modifierGroups[modifierGroupKey];
              Object.keys(modifierGroup.items).forEach((modifierKey) => {
                const modifier = modifierGroup.items[modifierKey];
                delete modifier.isSelected;
                delete modifier.quantity;
              });
            });
          });
        });

        Object.keys(tempComboSubItem.modifierGroups).forEach((modifierGroupKey) => {
          const modifierGroup = tempComboSubItem.modifierGroups[modifierGroupKey];
          Object.keys(modifierGroup.items).forEach((modifierKey) => {
            const modifier = modifierGroup.items[modifierKey];
            delete modifier.isSelected;
            delete modifier.quantity;
          });
        });
      }
      setMenuItem(item);
    }
  }, []);

  useEffect(() => {
    if (menuItem) {
      const { isOutOfStock, errorMessage } = checkOutOfStockAddons(menuItem, appLabels["order"]);
      setAddonsOutOfStock(isOutOfStock);
      if (errorMessage) setOutOfStockError(errorMessage);
    }
  }, [menuItem]);

  useEffect(() => {
    if (menuItem && formattedOptionGroups) {
      const updateState = async () => {
        const activeButton = await determineOrderingStateActiveButton(
          menuItem,
          orderingState.currentActiveOptionGroupIndex,
          formattedOptionGroups,
          isStepByStepOrderingEnabled,
          orderingState.numberOfOptionGroups,
          isQuantityCombo ? false : true,
          skin
        );

        if (activeButton) {
          updateOrderingState({
            numberOfOptionGroups: Object.keys(formattedOptionGroups).length,
            activeButton: activeButton,
          });
        }
      };
      updateState();
    }
  }, [menuItem, formattedOptionGroups]);

  const updateMenuItem = (updatedItem) => {
    setMenuItem(jsonCopy(updatedItem));
  };

  const updateOrderingState = (value) => {
    setOrderingState({ ...orderingState, ...value });
  };

  const updateActiveOptionGroupIndex = async () => {
    let currentOptionGroupIndex = orderingState.currentActiveOptionGroupIndex;
    let moveForward = true;
    Object.keys(formattedOptionGroups).forEach((groupKey) => {
      let optionGroup = formattedOptionGroups[groupKey];
      if (optionGroup.positioningTemplateIndex === currentOptionGroupIndex) {
        if (Number(optionGroup.minQuantity) > 0 && !reachedMinRequirements(optionGroup)) {
          moveForward = false;
          currentOptionGroupIndex = optionGroup.positioningTemplateIndex;
        }
      }
    });

    if (moveForward) {
      currentOptionGroupIndex++;
    }
    if (currentOptionGroupIndex >= orderingState.numberOfOptionGroups) {
      // scroll to quantity and special instructions

      setTimeout(() => {
        if (
          document.getElementsByClassName("item-details-main__Wrapper").length > 0 &&
          document.getElementById("quantity-special-instruction__container")
        ) {
          document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
            top:
              findPos(document.getElementById("quantity-special-instruction__container")) -
              (deviceWidth >= 660 ? 190 : 207),
            behavior: "smooth",
          });
        }
      }, 500);
    }

    const activeButton = await determineOrderingStateActiveButton(
      menuItem,
      currentOptionGroupIndex,
      formattedOptionGroups,
      isStepByStepOrderingEnabled,
      orderingState.numberOfOptionGroups,
      isQuantityCombo ? false : true,
      skin
    );
    if (activeButton) {
      updateOrderingState({
        currentActiveOptionGroupIndex: currentOptionGroupIndex,
        activeButton: activeButton,
      });
      performAutoScroll.current = true;
    }
  };

  const onOptionGroupNextButtonClick = async () => {
    performAutoScroll.current = true;
    await updateActiveOptionGroupIndex();
  };

  const history = useHistory();
  const closeComboItemDetailsPage = () => {
    const currentGroup = currentSubgroupNumber;
    history.push({
      pathname: window.location.hash.split("/item/")[0].replace("#", ""),
      state: state ? state : null,
    });
    setShowingComboChildItem(false);

    /** if all groups are completed take user to the summary */
    let allGroupsAreCompleted = true;
    for (let i = 0; i < comboSubgroupNumber; i++) {
      if (!groupIsComplete(i + 1)) {
        allGroupsAreCompleted = false;
        break;
      }
    }

    if (allGroupsAreCompleted) {
      setShowSummary(true);
    } else {
      setCurrentSubgroupNumber(currentGroup);
    }
  };

  useEffect(() => {
    if (deviceWidth < 660) {
      if (orderingState !== null && !orderingState.hideAllButtons) {
        document.getElementsByClassName("menu-combo-detail")[0].style.paddingBottom = "5em";
      } else {
        document.getElementsByClassName("menu-combo-detail")[0].style.paddingBottom = 0;
      }
    } else {
      document.getElementsByClassName("menu-combo-detail")[0].style.paddingBottom = 0;
    }
  }, [orderingState]);

  const determineExplicitMaxQuantity = () => {
    let maxQuantity = Number(menuItem["groupMinQty"]);
    let explicitValue = maxQuantity;

    let combinedQuanaityOfAllGroupItems = 0;
    let combinedQuantityOfAllOtherItemsInGroup = 0;
    const menuItemCopy = jsonCopy(menuItem);
    delete menuItemCopy.quantity;
    delete menuItemCopy.entryKey;

    comboCartItem.combo_child_items.forEach((childItem) => {
      const chilItemCopy = jsonCopy(childItem);
      delete chilItemCopy.quantity;
      delete chilItemCopy.entryKey;
      if (
        childItem.group_id === menuItem.group_id &&
        JSON.stringify(chilItemCopy) !== JSON.stringify(menuItemCopy)
      ) {
        combinedQuantityOfAllOtherItemsInGroup += childItem.quantity || 1;
      }

      if (childItem.group_id === menuItem.group_id) {
        combinedQuanaityOfAllGroupItems += childItem.quantity || 1;
      }
    });

    if (isEditingComboItem) {
      explicitValue = maxQuantity - combinedQuantityOfAllOtherItemsInGroup;
    } else {
      explicitValue = maxQuantity - combinedQuanaityOfAllGroupItems;
    }

    /*comboCartItem.combo_child_items.forEach((childItem) => {
      if (Number(childItem.group_id) === Number(menuItem.group_id)) {
        maxQuantity = maxQuantity - childItem.quantity;
        if (Number(childItem.id) === Number(menuItem.id) && isEditingComboItem) {
          maxQuantity += childItem.quantity;
        }
      }
    });*/
    return explicitValue;
  };

  const scrollToTop = () => {
    document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [numberOfRequiredChoices, setNumberOfRequiredChoices] = useState("idle");

  useEffect(() => {
    if (menuItem) {
      const getNumberOfRequiredChoices = async () => {
        setNumberOfRequiredChoices(
          await checkRequiredSelection(
            menuItem,
            isStepByStepOrderingEnabled,
            orderingState.currentActiveOptionGroupIndex,
            orderingState.numberOfOptionGroups,
            skin
          )
        );
      };
      getNumberOfRequiredChoices();
    }
  }, [orderingState, isStepByStepOrderingEnabled, menuItem]);

  const [savedMenuItemModifiers, setSavedMenuItemModifiers] = useState();
  useEffect(() => {
    if (state && state.from) {
      if (state.from === "suggested-item-page") {
        let savedItem = JSON.parse(
          sessionStorage.getItem(skin + "__savedMenuItem-" + pathname.split("/item/")[1])
        ).saved;
        if (savedItem) {
          let savedModifiers = JSON.parse(
            sessionStorage.getItem(skin + "__savedMenuItem-" + pathname.split("/item/")[1])
          ).savedModifiers;

          setMenuItem(savedItem);
          setStoredItemQuantity(savedItem.quantity);
          if (savedItem && savedItem.modifierGroups && savedModifiers) {
            savedItem.modifierGroups = savedModifiers;
          }
          setSavedMenuItemModifiers(savedModifiers);
          let suggestedItemSection = document.getElementById(savedItem.id + "-footerSuggestedItem");
          if (suggestedItemSection) {
            suggestedItemSection.getBoundingClientRect();
          }
        }
      }
    }
    //sessionStorage.removeItem(skin + "__savedMenuItem-"+item);
  }, [state]);

  const [autoScrollToSuggestedItemsDone, setAutoScrollToSuggestedItemsDone] = useState(false);
  useEffect(() => {
    if (menuItem && state && state.from && state.from === "suggested-item-page") {
      let suggestedItemSection = document.getElementById(menuItem.id + "-suggesteItemSection");
      if (suggestedItemSection && !autoScrollToSuggestedItemsDone) {
        setTimeout(function () {
          let coords = suggestedItemSection.getBoundingClientRect();
          document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
            top: coords.y - 50,
            behavior: "smooth",
          });
          setAutoScrollToSuggestedItemsDone(true);
        }, 200);
      }
    }
  }, [menuItem]);

  useEffect(() => {
    var obj = Object.keys(sessionStorage).reduce(function (obj, key) {
      obj[key] = localStorage.getItem(key);
      return obj;
    }, {});

    for (let key in obj) {
      if (key.includes("__savedMenuItem")) {
        sessionStorage.removeItem(key);
      }
    }
  }, []);

  return (
    <>
      {!!menuItem && (
        <div className={`item-details-main__Wrapper`}>
          <header className="header curved-shadow-container menu-combo-item-detail-header">
            <div className="curved-shadow-overlay-hack" />
            <div className={`header-primary`}>
              <button
                onClick={closeComboItemDetailsPage}
                aria-label={appLabels["order"]["close-item-details"].replace(
                  "[item-name]",
                  menuItem.name
                )}
                className="button"
                type="button"
                style={{ width: "40px" }}
              >
                <IconArrowLeft
                  aria-hidden="true"
                  style={
                    deviceWidth < 660 ? { fill: "var(--color-header-text-and-icon-colour)" } : {}
                  }
                />
              </button>
              <h1 className="page-title">
                {" "}
                {appLabels["order"]["combo-choice"]} {currentSubgroupNumber}
              </h1>

              <div style={{ width: "40px", height: "40px" }}></div>
            </div>
          </header>

          <div
            className={`item-detail__content combo ${
              isItemDescriptionMinimized ? " isMinimized" : " isMaximized"
            } ${isShowingFullItemDescription ? " isExpanded" : "isCollapsed"} `}
            id="top-hidden-item-name"
            tabIndex={-1}
          >
            <div className={` item_detail__top-content flex__row space-between wizard`}>
              <>
                {/**item description */}
                <div className="flex__column item_detail__secondary-info_wrapper space-between expanded">
                  <div className="flex__row item-detail__image-container">
                    <div className={`item-detail__image-wrapper flex__column wizard expanded `}>
                      {isItemOutOfStock && menuItem.imageUrl && (
                        <div className="item-detail__image-overlap-cotnainer">
                          <img
                            src={menuItem.imageUrl}
                            alt={menuItem.name}
                            className="item-detail__image item-detail__image-underlay"
                          />
                          <img
                            src={outOfStockImage}
                            alt={menuItem.name}
                            className=" item-detail__image item-detail__image-overlay"
                          />
                        </div>
                      )}
                      {isItemOutOfStock && !menuItem.imageUrl && (
                        <img
                          src={outOfStockImage}
                          alt={menuItem.name}
                          className="item-detail__image"
                        />
                      )}
                      {!isItemOutOfStock && (
                        <img
                          src={menuItem.imageUrl || noImagePlaceholder}
                          alt={menuItem.name}
                          className="item-detail__image"
                        />
                      )}
                    </div>

                    <div
                      className={`${
                        deviceWidth < 660
                          ? "flex__row item_details__name-price-container"
                          : "full__width"
                      }`}
                    >
                      <div className="item_details__name-price-wrapper flex__column space-between full__width">
                        <h2 className="item-detail__heading wizard">{menuItem.name}</h2>
                        <div className="item-detail__price-calories">
                          <p className="flex__row space-between full-width-column">
                            {menuItem.calorie_count && (
                              <span className="item-detail__calories wizard">
                                {menuItem.calorie_count} {appLabels["order"]["calories"]}
                              </span>
                            )}

                            <span
                              className={`item-detail__price wizard ${
                                !menuItem.calorie_count ? " item-detail__price-width" : ""
                              }`}
                            >
                              {menuItem.price > 0
                                ? toDollars(
                                    appSettings["currency-symbol"],
                                    appSettings["currency-symbol-side"],
                                    showPriceOnOrderButton
                                      ? getTotalItemPriceBasedOnSelection(menuItem)
                                      : menuItem.price,
                                    appLanguage
                                  )
                                : appLabels["order"]["price-based-on-selection"]}
                            </span>
                          </p>
                        </div>
                      </div>

                      {/** Desktop description */}
                      {deviceWidth >= 660 && (
                        <div
                          className={` item-detail__description__wrapper ${
                            isItemDescriptionMinimized && !isShowingFullItemDescription
                              ? "flex__row"
                              : ""
                          }`}
                        >
                          <p
                            className={`item-detail__description wizard item-detail__description--expanded ${
                              isShowingFullItemDescription
                                ? " item-detail__description--expanded"
                                : ""
                            }`}
                          >
                            {menuItem.description}
                          </p>
                          {!!menuItem.description && (
                            <>
                              {!isShowingFullItemDescription ? (
                                <button
                                  type="button"
                                  className="item__description--read-more"
                                  onClick={() => {
                                    setIsShowingFullItemDescription(true);
                                    setIsItemDescriptionMinimized(false);
                                    scrollToTop();
                                  }}
                                  aria-label={appLabels["order"]["read-more"]}
                                >
                                  {appLabels["order"]["read-more"]}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="item__description--read-more item__description--read-less"
                                  onClick={() => {
                                    setIsShowingFullItemDescription(false);
                                    setIsItemDescriptionMinimized(true);
                                  }}
                                  aria-label={appLabels["order"]["read-less"]}
                                >
                                  {appLabels["order"]["read-less"]}
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {/** Mobile description */}
                  {deviceWidth < 660 && (
                    <div
                      className={` item-detail__description__wrapper ${
                        isItemDescriptionMinimized && !isShowingFullItemDescription
                          ? "flex__row"
                          : ""
                      }`}
                    >
                      <p
                        className={`item-detail__description wizard item-detail__description--expanded ${
                          isShowingFullItemDescription ? " item-detail__description--expanded" : ""
                        }`}
                      >
                        {menuItem.description}
                      </p>
                      {!!menuItem.description && (
                        <>
                          {!isShowingFullItemDescription && isItemDescriptionMinimized ? (
                            <button
                              type="button"
                              className="item__description--read-more"
                              onClick={() => {
                                setIsShowingFullItemDescription(true);
                                setIsItemDescriptionMinimized(false);
                                scrollToTop();
                              }}
                              aria-label={appLabels["order"]["read-more"]}
                            >
                              {appLabels["order"]["read-more"]}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="item__description--read-more item__description--read-less"
                              onClick={() => {
                                setIsShowingFullItemDescription(false);
                                setIsItemDescriptionMinimized(true);
                              }}
                              aria-label={appLabels["order"]["read-less"]}
                            >
                              {appLabels["order"]["read-less"]}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {/** End of Large item description when expanded */}
              </>
            </div>
          </div>

          <>
            <div
              className="menu-combo-item-detail"
              style={isModifyDisplay ? { height: "100%" } : {}}
            >
              <div
                className="item-detail__options-container"
                style={isStepByStepOrderingEnabled ? {} : { paddingBottom: "50vh" }}
              >
                {(isArrItems(addonGroups) || isArrItems(modifierGroups)) && (
                  <AddonsOrModifiers
                    menuItem={menuItem}
                    updateMenuItem={updateMenuItem}
                    cartItem={cartItem}
                    isAddon={isArrItems(addonGroups) ? true : false}
                    isModifier={isArrItems(modifierGroups) ? true : false}
                    addonsOutOfStock={addonsOutOfStock}
                    isInCart={isModifyDisplay}
                    comboChildItem={menuItem}
                    comboCartItem={comboCartItem}
                    currentActiveOptionGroupIndex={orderingState.currentActiveOptionGroupIndex}
                    updateActiveOptionGroupIndex={updateActiveOptionGroupIndex}
                    isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                    displayType={displayType}
                    setAddToOrderButtonText={setAddToOrderButtonText}
                    updateOrderingState={updateOrderingState}
                    orderingState={orderingState}
                    formattedOptionGroups={formattedOptionGroups}
                    setFormattedOptionGroups={setFormattedOptionGroups}
                    performAutoScroll={performAutoScroll}
                    isEditingComboItem={isEditingComboItem}
                    skin={skin}
                    savedMenuItemModifiers={savedMenuItemModifiers}
                  />
                )}

                {isQuantityCombo && (
                  <div
                    className="quantity-special-instruction__container"
                    id="quantity-special-instruction__container"
                  >
                    {!isStepByStepOrderingEnabled ? (
                      <>
                        {/** Quantity buttons */}
                        <QuantityButtons
                          menuItem={menuItem}
                          cartItem={cartItem}
                          isMenuDisplay={isMenuDisplay}
                          isModifyDisplay={isModifyDisplay}
                          updateMenuItem={updateMenuItem}
                          cartData={cartContext}
                          explicitMaxValue={determineExplicitMaxQuantity()}
                          comboCartItem={comboCartItem}
                          currentSubgroupNumber={currentSubgroupNumber}
                          isEditingComboItem={isEditingComboItem}
                          isComboSubItem={true}
                          storedItemQuantity={storedItemQuantity}
                          setStoredItemQuantity={setStoredItemQuantity}
                        />
                      </>
                    ) : (
                      <>
                        {orderingState.currentActiveOptionGroupIndex >=
                          orderingState.numberOfOptionGroups && (
                          <>
                            {/** Quantity buttons */}
                            <QuantityButtons
                              menuItem={menuItem}
                              cartItem={cartItem}
                              isMenuDisplay={isMenuDisplay}
                              isModifyDisplay={isModifyDisplay}
                              updateMenuItem={updateMenuItem}
                              cartData={cartContext}
                              explicitMaxValue={determineExplicitMaxQuantity()}
                              comboCartItem={comboCartItem}
                              currentSubgroupNumber={currentSubgroupNumber}
                              isEditingComboItem={isEditingComboItem}
                              isComboSubItem={true}
                              storedItemQuantity={storedItemQuantity}
                              setStoredItemQuantity={setStoredItemQuantity}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>

              <div
                className="desktop-next-group-button__container"
                id="desktop-next-group-button__container"
              >
                {isStepByStepOrderingEnabled ? (
                  <>
                    {!orderingState.hideAllButtons && numberOfRequiredChoices !== "idle" && (
                      <>
                        {orderingState.activeButton === "add" && (
                          <MenuAddComboChoice
                            cart={cart}
                            item={menuItem}
                            category={foodMenu.activeTopCategory}
                            subcategory={foodMenu.activeSubcategory}
                            isFAB={true}
                            specialInstructions={specialInstructions}
                            isOutOfStock={itemIsOutofStock ? true : false}
                            addonsOutOfStock={addonsOutOfStock}
                            outOfStockError={outOfStockError}
                            comboCartItem={comboCartItem}
                            isMenuDisplay={isMenuDisplay}
                            isModifyDisplay={isModifyDisplay}
                            numberOfRequiredChoices={numberOfRequiredChoices}
                            comboParentItem={menuItem}
                            addToOrderButtonText={addToOrderButtonText}
                            isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                            animationClass={
                              orderingState.hideAllButtons
                                ? "hide-add-to-order"
                                : "show-add-to-order"
                            }
                            setShowingComboChildItem={setShowingComboChildItem}
                            isQuantityCombo={isQuantityCombo}
                            isRelativePositioning={deviceWidth >= 660 ? true : false}
                            relativeContainerId={"desktop-next-group-button__container"}
                            isEditingComboItem={isEditingComboItem}
                            setShowSummary={setShowSummary}
                            useAddToOrderButtonText={
                              orderingState.currentActiveOptionGroupIndex <
                              Object.keys(formattedOptionGroups).length
                            }
                            subGroupQuantityReached={subGroupQuantityReached}
                            comboSubgroupNumber={comboSubgroupNumber}
                            groupIsComplete={groupIsComplete}
                          />
                        )}

                        {orderingState.activeButton === "next" && (
                          <OptionGroupNextButton
                            onClick={async () => {
                              await onOptionGroupNextButtonClick();
                            }}
                            //customClass={" desktop-next-group-button next-group-button-combo"}
                            buttonText={determineNextOptionGroupButtonText(
                              formattedOptionGroups,
                              orderingState.currentActiveOptionGroupIndex,
                              appLabels
                            )}
                            container={
                              deviceWidth < 660
                                ? null
                                : document.getElementById("desktop-next-group-button__container")
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {numberOfRequiredChoices !== "idle" && (
                      <MenuAddComboChoice
                        cart={cart}
                        item={menuItem}
                        category={foodMenu.activeTopCategory}
                        subcategory={foodMenu.activeSubcategory}
                        isFAB={true}
                        specialInstructions={specialInstructions}
                        isOutOfStock={itemIsOutofStock ? true : false}
                        addonsOutOfStock={addonsOutOfStock}
                        outOfStockError={outOfStockError}
                        comboCartItem={comboCartItem}
                        isMenuDisplay={isMenuDisplay}
                        isModifyDisplay={isModifyDisplay}
                        numberOfRequiredChoices={numberOfRequiredChoices}
                        comboParentItem={menuItem}
                        addToOrderButtonText={addToOrderButtonText}
                        isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                        animationClass={
                          orderingState.hideAllButtons ? "hide-add-to-order" : "show-add-to-order"
                        }
                        setShowingComboChildItem={setShowingComboChildItem}
                        isQuantityCombo={isQuantityCombo}
                        isRelativePositioning={deviceWidth >= 660 ? true : false}
                        relativeContainerId={"desktop-next-group-button__container"}
                        isEditingComboItem={isEditingComboItem}
                        setShowSummary={setShowSummary}
                        useAddToOrderButtonText={
                          orderingState.currentActiveOptionGroupIndex <
                          Object.keys(formattedOptionGroups).length
                        }
                        subGroupQuantityReached={subGroupQuantityReached}
                        comboSubgroupNumber={comboSubgroupNumber}
                        groupIsComplete={groupIsComplete}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};
