export const orderLabels = {
  "dashboard-section-heading": "Pedir Online",
  "select-store-for-menu-modal-message": "Favor selecionar uma loja para visualizar o menu",
  "select-order-time-for-menu-modal-message":
    "Favor selecionar um horário válido de pedido para visualizar o menu",
  "closest-store": "Loja mais próxima de você",
  "select-store": "Selecione uma loja",
  "find-store": "Encontre uma loja",
  pickup: "Retirada",
  delivery: "Entrega",
  "dine-in": "Consumir em loja",
  "select-time": "Selecione o horário",
  "no-future-dates": "Loja indisponível",
  "pickup-and-delivery-times-disclaimer": "Os horários de retirada e entrega são estimados.",
  featured: "Destaque",
  "select-order-time-for-featured-modal-message":
    "Favor selecionar um horário válido de pedido para visualizar os itens destacados",
  "whats-new": "Novidades",
  "selected-store": "Loja selecionada",
  today: "Hoje",
  "in-minutes": "Em [number] minutos",
  "map-overlay-pickup-heading": "Vamos encontrar uma loja para retirada",
  "map-overlay-delivery-heading": "Qual é a localização para Entrega?",
  "use-your-location": "Utilize sua localização",
  "or-enter-an-address": "Ou insira um endereço",
  "maps-searchbox-placeholder": "Insira a cidade, CEP...",
  "previously-found-stores": "Anterior:",
  "order-here": "Peça Aqui",
  "order-ahead": "Peça Antecipadamente",
  "list-view": "Visualizar Lista",
  "map-view": "Visualizar Mapa",
  "more-store-info-button": "Info",
  "close-more-store-info-button": "Voltar",
  menu: "Menu",
  favorites: "Favoritos",
  "no-favorites-message": "Você não marcou nada como favorito",
  "remove-favorite-confirmation": "Tem certeza que deseja remover este item dos favoritos?",
  "view-favorite-item": "Visualizar Item",
  calories: "Cal",
  "select-options": "Selecionar Opções",
  "modify-this-item": "Modificar este item",
  "add-extras": "Adicionar extras",
  "special-instructions": "Instruções Especiais",
  "add-to-order": "Adicionar [item-quantity] ao Pedido",
  "add-another": "Adicionar Outros",
  "menu-disclaimer": "",
  "menu-item-disclaimer": "",
  added: "Adicionado",
  "save-changes": "Salvar Alterações",
  "button-save-changes-success": "Salvo",
  "recent-orders": "Pedidos Recentes",
  reorder: "Pedir novamente",
  "view-one-item": "Visualizar 1 Item",
  "view-multiple-items": "Visualizar [number] Itens",
  "view-cart": "Visualizar Carrinho",
  "back-to-menu": "Voltar ao Menu",
  "review-order": "Rever Pedido",
  "your-order-details": "Detalhes do seu Pedido",
  "items-in-the-cart": "iten(s) no carrinho",
  "discount-and-coupon-button": "Desconto/Cupom",
  "discount-code": "Código de Desconto",
  "check-discount": "Verificar Desconto",
  "coupon-code": "Código de Cupom",
  "check-coupon": "Verificar Cupom",
  "discount-remove-confirmation": "Tem certeza que deseja remover este código de desconto?",
  "use-rewards": "Usar Recompensas",
  discount: "Desconto",
  coupon: "Cupom",
  "apply-discount-coupon": "Aplicar",
  "invalid-discount-coupon-error": "Código de Desconto não é válido.",
  "only-one-discount-reward-error":
    "Apenas um desconto / recompensa pode ser aplicado ao seu pedido. Remova o desconto atualmente aplicado se deseja adicionar outro.",
  tax: "Taxa",
  "add-tip": "Adicionar Gorjeta",
  "no-tip": "Sem Gorjeta",
  tip: "Gorjeta",
  "custom-amount": "Quantidade Personalizada",
  modify: "Modificar",
  "remove-all-items": "Remover todos os itens",
  "add-more-items": "Adicionar mais itens",
  "empty-cart-message": "Seu carrinho de compras está vazio.",
  "begin-checkout": "Iniciar Checkout",
  "customer-info-form-heading": "Vamos confirmar seu contato do pedido",
  "order-contact-confirm": "Próximo",
  "order-contact-confirming": "Próximo...",
  "pickup-information": "Informações de Retirada",
  "review-order-disclaimer": "",
  "pickup-information-subheading": "",
  payment: "Pagamento",
  "use-another-credit-card": "Usar outro cartão de crédito",
  "saved-credit-card-heading": "Cartão de crédito salvo",
  "return-to-saved-credit-card": "Voltar ao cartão de crédito salvo",
  "replacing-saved-credit-card-confirmation":
    "Você já salvou um cartão de crédito. Tem certeza que deseja remover [card-number]?",
  "card-balance": "Saldo do Cartão",
  "apply-gift-card-to-order-button": "Aplicar",
  "registered-gift-card": "Cartão presente registrado",
  "back-to-registered-gift-card": "Voltar para o Cartão Presente Registrado",
  "apply-rewards-to-order-button": "Aplicar",
  "use-another-gift-card": "Usar outro Cartão Presente.",
  "gift-card-number": "Número do Cartão Presente",
  "pin-number": "Número do PIN",
  "no-active-gift-cards-message": "Você não possui nenhum cartão ativo.",
  "order-total": "Total do Pedido",
  "remaining-balance-on-gift-card": "Restante no Cartão",
  "pay-remainder-with-credit-card": "Pagar o Restante com cartão de crédito",
  "amount-due": "Quantia devida",
  "card-has-already-been-applied-message": "Este cartão já foi aplicado",
  "pay-on-pickup": "Pagar na Retirada",
  "pay-on-delivery": "Pagar na Entrega",
  "submit-order-button": "Finalizar Pedido",
  "submit-order-button-processing": "Completando o Pedido...",
  "payment-disclaimer": "",
  "order-too-close-to-closing-time-error":
    "Lamentamos informar que não podemos aceitar seu pedido no momento. Pedidos online não podem ser aceitos muito próximos ou após o horário de fechamento.",
  "select-order-time-passed-message":
    "O horário do pedido selecionado já passou. O próximo horário do pedido disponível seria",
  "confirm-updated-order-time-message": "Gostaria de continuar com o horário atualizado do pedido?",
  "order-confirmation": "Confirmação do Pedido",
  "order-thank-you-message": "Obrigado pelo pedido",
  "your-order-number-is": "O número do seu pedido é",
  "we-sent-you-confirmation-email": "Nós enviamos a você um e-mail de confirmação.",
  "order-confirmation-disclaimer": "",
  "item-for-pickup-at": "[quantity] item para [order-type] em",
  "items-for-pickup-at": "[quantity] itens para [order-type] em",
  "item-for-delivery-or-dinein-from": "[quantity] item para [order-type] de",
  "items-for-delivery-or-dinein-from": "[quantity] itens para [order-type] de",
  "item-for-dine-in-at": "[quantity] item para [order-type] em",
  "items-for-dine-in-at": "[quantity] itens para [order-type] em",
  "order-type-change-confirmation": "Tem certeza que deseja alterar seu pedido para [order-type]?",
  "order-store-change-confirmation": "Tem certeza que deseja alterar a loja para [order-store]?",
  "your-current-order-will-be-cleared": "Seu pedido atual será cancelado.",
  "cart-item-removal-confirmation":
    "Tem certeza que deseja remover [item-name] o item do carrinho?",
  "cart-item-removal-discount-also-removed-message":
    "Remover este item também removerá o código de desconto associado a ele.",
  "order-type-order-date-at-order-time": "[order-type] [order-date] às [order-time]",
  "dine-in-qr-scan-heading": "Escaneie o QR Code da sua mesa",
  "dine-in-qr-scan-processing": "Escaneando...",
  "delivery-shipping-form-submit-button": "Confirmar",
  "delivery-shipping-form-submit-button-processing": "Confirmando...",
  "no-pickup-stores-within-range-message": "Nenhuma loja de retirada em [stores-range] km de você.",
  "distance-to-store": "Distância [distance-from-user] km",
  "store-open-status": "Aberto",
  "store-closed-status": "Fechado",
  "store-open-until-closing-time": "Fecha às [closing-time]",
  "no-store-menu-message": "Nenhum menu encontrado para esta loja",
  "select-up-to-n-items": "Selecione até [max-quantity] itens (opcional)",
  "select-n-items": "Selecione [min-quantity] itens",
  "select-n-item": "Selecione [min-quantity] item",
  "select-between-n-and-n-items": "Selecione entre [min-quantity] e [max-quantity] itens",
  "calories-disclaimer":
    "Adultos e adolescenetes (13 anos ou mais) precisam de uma média de 2.000 calorias diárias, e crianças (de 4 a 12 anos) precisam de uma média de 1.500 calorias diárias. Entretante, necessidades individuais variam.",
  "button-add-to-combo-group-choice": "Adicionar à escolha [item-group-id]",
  "button-add-to-combo-group-choice-success": "Adicionado",
  "button-save-combo-choices": "Salvar Alterações",
  "button-save-combo-choices-success": "Salvo",
  "combo-choice": "Escolha",
  "combo-summary": "Resumo",
  "combo-choose-item": "Escolha um item das opções abaixo",
  "add-gift-card": "Adicionar Cartão de Presente",
  "add-another-gift-card": "Adicionar outro Cartão de Presente",
  "complete-order-pay-on-pickup-blurb": "Conclua seu pedido abaixo e pague quando retirar na loja.",
  "complete-order-pay-on-delivery-blurb":
    "Conclua seu pedido abaixo e pague quando o pedido for entregue.",
  "bring-coupon-notice": "Não se esqueça de trazer seu cupom de [balance]!",
  "complete-order-see-you-soon": "Nos vemos em breve!",
  "recent-order-item-modifier-heading": "Modificado",
  "recent-order-item-addon-heading": "Adicionado",
  "recent-order-unavailable-item-notification": "Um ou mais itens não estão mais disponíveis",
  "recent-order-price-change-notification":
    "* O subtotal de um pedido anterior pode variar uma vez que os preços estão sujeitos a alteração",
  "card-autoreload-success-message":
    "Você configurou com sucesso a recarga automática do seu cartão [card-number] para o valor de [amount]",
  "card-autoreload-when-balance-reaches": "Quando o saldo chegar a",
  "card-autoreload-weekly-frequency": "semanalmente",
  "card-autoreload-monthly-frequency": "mensalmente",
  "add-money-add-credit-card": "Adicionar Cartão de Crédito",
  "pay-in-store-card-expiry": "Expira em",
  "qr-code": "QR Code",
  "pay-in-store-how-to-heading": "Pronto para configurar o checkout expresso na loja?",
  "pay-in-store-how-to-blurb-1":
    "Adicione um cartão à sua conta para aproveitar as vantagens do checkout expresso na loja.",
  "pay-in-store-how-to-blurb-2":
    "Você pode adicionar cartões múltiplos à sua conta, verificar o saldo e adicionar dinheiro ou configurar recarga automática.",
  "pay-in-store-add-new-card": "Adicionar um Novo Cartão",
  "complete-order-pay-with-paypal":
    "Depois de clicar em “Concluir Pedido”, você será redirecionado para o PayPal para concluir seu pedido com segurança",
  "cart-removing-items-with-out-of-stock-addons-message":
    "Itens removidos com complementos fora de estoque.",
  "cart-removing-item-with-out-of-stock-addons-message":
    "[item-name] removido com complementos fora de estoque",
  "item-detail-addons-out-of-stock-error":
    "[number] complementos necessários estão fora de estoque",
  "item-detail-addon-out-of-stock-error": "Complemento necessário está agora fora de estoque",
  "dashboard-primary-order-button": "Pedir Agora",
  "dashboard-disclaimer": "",
  "dine-in-qr-scanner-instructions":
    "Leia o Código QR code se certificando que caiba dentro do quadrado abaixo OU simplesmente digite o número da mesa.",
  "complete-order-pay-later-dine-in-blurb":
    "Complete seu pedido abaixo e pague no final da sua refeição.",
  "pay-later": "Pagar Depois",
  "show-menu-button": "Exibir Menu",
  "show-menu-button-processing": "Obtendo Menu...",
  "table-number-field-label": "Número da Mesa",
  "invalid-qr-code": "Código QR Inválido",
  "camera-access-not-supported":
    "Este browser não suporta acesso a câmera. é melhor utilizar o Safari para este recurso.",
  "invalid-coupon-number": "Número de cupom inválido",
  "back-to-combo-item-name": "Voltar para [combo-item-name]",
  "confirm-modified-combo-changes-button": "Confirmar Alterações",
  "no-delivery-stores-within-range-message":
    "Nenhuma loja de entrega em [stores-range] km de você.",
  "order-before-store-opening-time-error":
    "Nós lamentamos informar que não podemos aceitar o seu pedido neste momento, nós estamos fechados atualmente.",
  "confirm-summary-details": "Confirme os detalhes abaixo",
  "coupon-remove-confirmation": "Você tem certeza que deseja remover este cupom?",
  "applied-discount-code": "Desconto Aplicado / Código Promocional",
  "applied-coupon-code": "Código de cupom aplicado",
  asap: "o mais rápido possível",
  "minimum-delivery-amount-message":
    "Um valor mínimo de [delivery-minimum-amount] é necessário para este pedido para entrega",
  quantity: "Quantidade",
  "check-balance": "Checar Saldo",
  "out-of-stock": "Sem estoque",
  "remove-item-from-favorites": "Remover [item-name] dos favoritos",
  "add-item-to-favorites": "Adicionar [item-name] aos favoritos",
  "go-to-item-name": "Ir para os detalhes do item [item-name]",
  "go-to-whats-new-image-link": "Ir para [image-link]",
  "remove-item-from-cart": "Remover [item-name] do carrinho",
  "modify-item-in-cart": "Modificar [item-name]",
  "decrease-quantity": "Diminuir quantidade",
  "increase-quantity": "Aumentar quantidade",
  "remove-tip": "Remover gorjeta",
  "show-category-subcategories": "Mostrar subcategorias de [category-name]",
  "show-subcategory-items": "Mostrar itens de [subcategory-name]",
  "close-item-details": "Fechar detalhes de [item-name]",
  "back-to-item": "Voltar para detalhes de [item-name]",
  "open-options-group-options": "Abrir opções de [option-group-name]",
  "select-payment-type": "Selecionar [payment-type] como pagamento",
  "remove-applied-giftcard": "Remover cartão presente aplicado",
  edit: "Editar",
  "delivering-to-address": "Entregando para",
  "edit-delivery-address": "Editar endereço de entrega",
  "order-time-is-blocked":
    "Seu horário solicitado não está mais disponível. Por favor, selecione um novo horário para o seu pedido",
  "ordering-from": "Loja selecionada",
  "select-another-store-prompt": "Selecione outra loja para visualizar o menu",
  "select-a-store-prompt": "Selecione uma loja para visualizar o menu",
  "discount-disclaimer": "",
  "coupon-disclaimer": "",
  "complete-your-order-below": "Conclua seu pedido abaixo e pague quando retirar na loja.",
  "invalid-reward": "Recompensa Inválida",
  "price-based-on-selection": "Preço com base na seleção",
  "total-combo-price-disclaimer": "O preço total do combo é calculado no carrinho de compras",
  "order-type": "Tipo de pedido",
  "number-of-required-choices": "[number-of-choices] Seleção(ões) requerida(s)",
  "minutes-short-hand": "min",
  "guest-attempt-favorite-item": "Faça o login para adicionar itens aos seus favoritos",
  "favorite-add-to-order": "Adicionar ao Pedido",
  "read-more": "Consulte Mais informação",
  "read-less": "Leia menos",
  "ios-camera-disclaimer":
    'Para verificar o menu:[line-break]Selecione "Usar minha localização" abaixo ou[line-break]Leia um QR code usando a câmera do seu dispositivo.',
  "lets-find-store-for-order-type": "Vamos encontrar uma loja para [order-type]",
  "lets-find-store-blurb":
    "Digite o seu endereço, e permita que o aplicativo use sua localização ou escolha a loja mais próxima na lista abaixo",
  "unit-can-be-added": "O número do apartamento pode ser adicionado no momento do pagamento",
  "use-my-location": "Use minha localização",
  "select-store-all": "Todos",
  "select-store-nearby": "Próximo",
  "select-store-favorites": "Favoritos",
  "select-store-hours-info": "Horário e informações",
  "select-store-get-direction": "Obter direção",
  "select-store-select-store": "Selecione uma loja",
  "guest-attempt-favorite-location": "Faça o login para adicionar as lojas aos seus favoritos",
  "no-favorite-locations-blurb":
    "Toque/clique no ícone de coração para salvar a localização de uma loja em seus favoritos para fácil acesso",
  "no-nearby-locations-blurb":
    "Para pesquisar as lojas próximas, insira seu endereço ou toque em 'Use minha localização'",
  "time-selection-panel-header": "Selecione [order-type], data e hora",
  "no-delivery-stores-within-range": "Nenhuma loja de entrega dentro da faixa de entrega",
  "order-type-selection-blurb": "Como você gostaria de fazer o pedido?",
  "type-in-address": "Endereço e cidade",
  "location-not-in-delivery-range": "Esta loja não está dentro da faixa de entrega",
  "no-nearby-locations-blurb-delivery":
    "Digite o seu endereço de entrega ou toque em 'Usar minha localização' para pesquisar as lojas próximas",
  "price-change-error-message":
    "Observe que os preços dos itens em seu carrinho foram atualizados para refletir o menu atual",
  "items-removed-from-menu-error-message":
    "Um ou mais itens não estão mais disponíveis para pedido na data/hora selecionada.",
  "dine-in-use-location-instructions":
    "Leia o QR code ou permita que o aplicativo encontre sua localização para prosseguir",
  "dine-in-not-in-location-error": "Você deve estar na loja para poder fazer o pedido",
  "dine-in-use-location-greetings":
    "Bem-vindo a [merchant-name]. Insira o número da sua mesa abaixo para visualizar o menu",
  "dine-in-scan-qr-code": "Scanear o QR code",
  "dinein-information-special-instructions": "Informações para Consumir em Loja",
  "invalid-item-quantity-error": "Não há estoque restante deste item",
  "item-not-available": "Este item está indisponível no momento",
  "app-rating-header": "Como foi sua experiência com o app hoje?",
  "app-rating-thumbs-up": "Favorável",
  "app-rating-thumbs-down": "Desfavorável",
  "merchant-email-subject": "Comentário do consumidor para CWS5 da [merchant-name]",
  "merchant-email-body-template-line-1":
    "Agradecemos o seu contato. Por favor, Nos informe como podemos melhorar para a próxima vez. Se você encontrou um problema técnico, adicione quaisquer detalhes relativos ao problema e quais passos você executou até encontrar o problema abaixo.",
  "merchant-email-do-not-remove": "***Por favor, não remova***",
  "merchant-email-username": "Nome do usuário",
  "merchant-email-guest-user": "Usuário convidado",
  "merchant-email-device-type": "Tipo de dispositivo",
  "merchant-email-os-version": "Versão de SO:",
  "merchant-email-description-of-issue": "Descrição do problema",
  "merchant-email-steps-taken": "Passos efetuados até encontrar o problema",
  "merchant-email-information-used-to-help":
    "Esta informação é utilizada para ajudar a identificar e resolver problemas técnicos.",
  "merchant-email-enter-details": "<insira os detalhes aqui>",
  "cart-out-of-stock-item-error-message":
    "Infelizmente [item-name] não está mais disponível. Foi removido do seu carrinho.",
  "pickup-information-special-instruction-placeholder": "",
  "submit-order": "Enviar pedido",
  "order-and-pay": "Pedir e pagar",
  checkout: "Pagar",
  "select-payment": "Selecionar pagamento",
  "ready-to-pay": "Pronto para pagar?",
  "view-bill": "Ver conta",
  "ready-to-pay-blurb": "Revise, divida e pague sua conta aqui",
  bill: "Conta",
  "confirmation-email": "E-mail de confirmação",
  "confirmation-email-blurb": "Deseja receber uma cópia do recibo por e-mail?",
  table: "Mesa",
  "ordered-together": "Pedido junto",
  seat: "Assento [seat-number]",
  "submitting-order": "Enviando pedido...",
  "payment-confirmation": "Confirmação de pagamento",
  "thank-you-for-payment": "Obrigado por seu pagamento",
  "email-receipt-blurb": "Enviamos um e-mail de confirmação para [email]",
  "payment-confirmation-blurb": "Você está pronto! Nós esperamos te ver novamente em breve.",
  "submit-payment-button": "Pagamento completo",
  "submit-payment-button-processing": "Concluindo o pagamento",
  "review-items": "Revisar Itens",
  "items-not-ready-for-billing":
    "Aguente firme! Estamos preparando seu pedido e você poderá pagar em breve.",
  "bill-page-no-order-placed-yet": "Nenhum pedido foi enviado ainda para esta mesa.",
  refresh: "Atualizar",
  "bill-api-error": "Ocorreu um erro. Por favor, entre em contato com seu atendente",
  "back-to-home": "Retornar ao Início",
  "view-details": "Ver Detalhes",
  "hide-details": "Ocultar Detalhes",
  "our-picks-for-you": "Nossas sugestões para você",
  customize: "Personalizar",
  "ordered-from": "Pedido de",
  "delivered-to": "Entregue para",
  "points-earned": "Pontos Ganhos",
  "points-redeemed": "Pontos Resgatados",
  "charged-to": "Cobrado de",
  "active-orders": "Pedidos Ativos",
  "past-orders": "Pedidos Anteriores",
  "no-orders-yet": "Nenhum pedido ainda",
  "view-menu": "Ver Menu",
  "browse-menu": "Navegar no menu e iniciar pedido",
  "confirm-reorder-details": "Para repetir o pedido, vamos confirmar os detalhes do seu pedido!",
  "active-orders-loading": "Aguente firme! Nos estamos procurando seus pedidos atuais",
  "past-orders-loading": "Aguente firmet! Nos estamos procurando seus pedidos anteriores",
  "addon-no-thank-you-option": "Não, obrigado. Ignore essas opções",
  "select-n-items-or-more": "Selecionar [n-items] ou mais ",
  "date-and-time": "Data e Hora ",
  "combo-summary-header": "Resumo do Combo",
  "edit-combo-item-arial": "Editar item de combo [item-name] ",
  "setting-favorite-error":
    "Por favor, termine as escolhas abaixo antes de adicionar aos seus favoritos",
  oops: "Oops!",
  "reorder-limited-stock-items":
    "Parece que os seguintes itens não puderam ser adicionados ao seu carrinho devido a estoque limitado",
  "reorder-items-no-longer-available":
    "Parece que os seguintes itens não puderam ser adicionados ao seu carrinho devido a não estarem mais disponíveis no menu",
  "reorder-items-out-of-stock-no-longer-available":
    "Parece que os seguintes itens não puderam ser adicionados ao seu carrinho devido a estoque limitado ou não estar mais disponíveis",
  "add-new-item": "Adicionar Novo Item",
  customization: "Personalização",
  "remove-all-items-confirmation-modal-message":
    "Você tem certeza que deseja remover todos os [item-name]?",
  "choice-n-of-total": "Escolha [current-choice] de [total-choices]",
  "hang-tight-processing-payment": "Aguente firme! Nós estamos processando seu pagamento",
  "hang-tight-reviewing-order": "Aguente firme! Nós estamos processando seu pedido agora",
  "contacting-bank-to-confirm-payment":
    "Nós estamos contactando o seu banco para confirmar o pagamento",
  "almost-done-passing-order-to-store": "Quase pronto! Nós estamos enviando seu pedido para a loja",
  "almost-done": "Quase pronto!",
  "do-not-click-back-or-refresh":
    "Por favor, não clique em Voltar, Atualizar ou saia da página, porque pode interromper o processo e/ou duplicar sua cobrança",
  connecting: "Conectando...",
  "complete-order-pay-with-google-pay":
    "Depois de clicar no botão Checkout abaixo, você será redirecionado ao Google Pay para concluir seu pedido com segurança.",
  "complete-order-pay-with-apple-pay":
    "Depois de clicar em “Confira com [apple-pay-icon]”, você será redirecionado para o Apple Pay para concluir seu pedido com segurança",
  "order-processed-using-digital-payment": "e foi processado com sucesso usando",
  "google-pay": "Google Pay",
  "apple-pay": "Apple Pay",
  "preparing-bill-for-verification": "Preparando sua conta para verificação...",
  "retrieving-bill-details": "Obtendo sua conta...",
  "processing-your-bill": "Processando sua conta...",
  "verifying-bill-details": "Verificando os detalhes da sua conta...",
  "double-checking-your-bill": "Conferindo sua conta...",
  "confirming-bill-details":
    "Confirmando os detalhes da conta, processamento do pagamento em andamento!",
  "processing-your-payment": "Processando seu pagamento...",
  "all-done": "Tudo pronto!",
  "delivery-address": "Endereço de entrega",
  "delivery-addresses": "Endereços de entrega",
  "confirm-address": "Confirme o endereço",
  "use-my-location-for-delivery": "Use minha localização como endereço de entrega",
  "use-my-location-for-pickup": "Use minha localização para encontrar lojas próximas",
  "confirm-your-pickup-location": "Confirme seu local de retirada",
  "modify-location": "Modificar local",
  "find-address": "Encontrar endereço",
  "primary-delivery-address-message":
    "Gostaria de salvar o endereço de entrega abaixo em sua conta?",
  "set-as-primary-delivery-address": "Definir como endereço de entrega principal",
  "recommended-address-changes": "Revise as alterações recomendadas e escolha a opção correta.",
  "save-address": "Salvar endereço",
  "not-now": "Agora não",
  "your-addresses": "Seus endereços",
  "no-saved-delivery-address": "Você ainda não tem um endereço de entrega salvo.",
  "you-entered": "Você forneceu:",
  recommended: "Recomendado:",
  "unable-to-verify-address": "Não foi possível verificar seu endereço!",
  "try-different-address": "Tente um endereço diferente",
  "store-filter-list": "Lista",
  "store-filter-map": "Mapa",
  "verification-issues": "Problemas de verificação",
  "street-number-not-found": "Número da rua não encontrado",
  "use-entered-address": "Usar inserido",
  "make-changes-to-address": "Faça mudanças",
  "street-number-missing": "Falta o número da rua",
  "confirm-seating": "Confirmar Assento",
  "edit-seating": "Editar Assento",
  "order-pickup": "Retirada do Pedido",
  "stadium-service-not-available": "Este serviço não está disponível para a sua seção.",
  "in-seat-service": "Atendimento no Assento",
  "in-seat-service-time-title": "Selecione o tempo de serviço no assento",
  "stadium-pickup-time-title": "Selecione o horário de retirada",
  "limit-per-order": "Limite de [qty-limit] por pedido",
  "limit-reached": "Limite máximo por pedido atingido",
  "limit-reached-past-order":
    "Esses itens não podem ser adicionados ao seu carrinho. Um ou mais itens excederão o limite máximo por pedido",
  "meal-voucher": "Vale Refeição",
  "address-already-exists": "O endereço do cliente já existe",
  "select-dates": "Selecionar Datas",
  "select-date-range": "Selecionar Intervalo de Datas",
  "load-more": "Carregar Mais",
  "select-one-bill": "Selecionar Tudo (Pagar como uma só conta)",
  "open-calendar": "Abrir calendário",
  "select-pickup-location": "Selecione o local de retirada",
  "select-pickup-time": "Selecione o horário de retirada",
  "set-store-location-favorite": "Definir esta loja como favorita",
  "remove-store-location-favorite": "Remover esta loja como favorita",
  "edit-order-time": "Editar detalhes do horário do pedido",
  "edit-order-type": "Editar tipo de pedido",
  "back-to-payment-method-select": "Voltar para a seleção da forma de pagamento",
  "pay-with-google-pay": "Pague com o Google Pay",
  "pay-with-apple-pay": "Pague com Apple Pay",
  "expand-section": "Expandir seção para",
  "collapse-section": "Recolher seção para",
  "remove-discount": "Remover desconto/cupom",
  "item-quantity": "Quantidade do item",
  "add-to-apple-wallet": "Adicionar à Carteira Apple",
  "add-to-google-pay": "Adicionar ao Google Pay",
  "expand-order-details": "Expandir detalhes do pedidoo",
  "collapse-order-details": "Recolher detalhes do pedido",
  "remove-promo": "Remover promoção",
  "store-hours": "Horário de funcionamento",
  "edit-ordering-locaiton": "Editar pedido do local",
  "missing-customer-id-error":
    "Para garantir o uso deste cartão de crédito salvo, valide suas informações de login",
  "missing-customer-id-error-gc":
    "Para garantir o uso deste vale-presente salvo, valide suas informações de login",
  "sorry-it-is-closing-time": "Desculpe-nos, Está na hora de fechar!",
  "store-closed-message":
    "Nos não conseguimos processar seu pedido agora porque já está muito proximo ou já passou do nosso horário de fechamento.",
  "pick-new-time": "Selecione um novo horário",
  "view-full-menu": "Ver Menu Completo",
  "recent-order-guest-attempt": "Por favor, faça login para ver seus pedidos recentes",
  "invalid-direct-url-error": "[order-type] não está disponível atualmente neste local.",
  "guest-attempt-favorite-item-dashboard": "Por favor, faça o login para ver seus favoritos",
  "order-types": "Tipos de pedidos",
  "customer-info-dialog-message":
    "Por favor, forneça os detalhes do consumidor antes de continuar para a página de pagamento.",
  "input-custom-tip-amount": "Forneça o valor de sua gorjeta personalizada em",
  "press-tab-to-add-tip": "Pressione a tecla Tab para aplicar o valor da gorjeta.",
  "currently-selected-tip-option": "A opção de gorjeta atualmente selecionada é",
  "currently-selected-category": "A categoria atualmente selecionada é",
  "menu-categories-list": "Lista de categorias de menu.",
  "on-combo-summary-page": "Você está na página de resumo do combo.",
  "on-step": "Você está na etapa",
  "select-item-to-continue": "Selecione um item para continuar.",
  "go-to-combo-summary-page": "Vá para a página de resumo do combo.",
  "go-to-choice": "Vá para a escolha",
  "please-select": "Selecione",
  "please-select-payment-method": "Selecione um método de pagamento.",
  "select-date-time-current-selected-date":
    "Selecione a data e a hora. A data atualmente selecionada é",
  "currently-selected-date": "Selecionado atualmente.",
  "select-time-option-for": "Selecione uma opção de horário para",
  "time-option-for": "opção de horário para",
  "your-total-is": "Seu total é",
  "on-menu-item-details-page-for": "Na página de detalhes do item de menu para",
  "additional-addons-area-expanded-message":
    "Complementos adicionais expandidos. Pressione tab para navegar e selecionar complementos adicionais.",
  "you-have-n-items-in-cart": "Você tem [item-quantity] itens no carrinho",
  "quantity-in-cart-is": "A quantidade no carrinho é",
  "remove-from-order": "Remover do [item-name] do pedido",
  "shopping-cart": "Carrinho de compras",
  "decrease-quantity-by-one": "Diminuir a quantidade em 1",
  "increase-quantity-by-one": "Aumentar a quantidade em 1",
  "checkout-cart-summary-message":
    "Está na página de finalização da compra. Tem os itens [cart.quantity] no carrinho.",
  "your-order-subtotal-is": "O seu subtotal é",
  "ordering-for-tomorrow-warning-message":
    "AVISO: seu pedido esta definido para o próximo dia útil!",
  "ordering-for-tomorrow-info-message": "Esta loja não está mais aceitando pedidos hoje.",
  "current-item-quantity-in-cart": "Quantidade atual: [item-quantity] no carrinho.",
  "tip-amount-is": "O valor da gorjeta é [tip-amount]",
  "order-total-is": "O Total do pedido é [order-total]",
  "item-addons-modifications": "Modificações ou complementos de items",
  "confirm-order-date-time": "Confirmar a data e hora do pedido.",
  "current-quantity": "Quantidade atual [item-quantity]",
  "frequently-bought-together": "Frequentemente comprados juntos",
  "we-think-you-may-like": "Nós achamos que você também pode gostar...",
  "continue-to-checkout": "Continuar para o pagamento",
  "no-thanks": "Não, obrigado",
  section: "Seção",
  row: "Fileira",
  "seat-stadium": "Assento",
  "in-store-tip-header": "Dê uma gorjeta à nossa equipe de loja",
  "tip-your-courier": "Adicione uma gorjeta para seu entregador",
  "tip-your-courier-blurb":
    "Esta gorjeta vai diretamente para o seu entregador com base no seu total de [order-total] após impostos, promoções e descontos.",
  "courier-tip": "Gorjeta do entregador:",
  "leave-at-door": "Deixar na porta (sem contato)",
  "meet-at-door": "Encontrar na porta",
  "delivery-preference": "Preferências de entrega",
  "delivery-preference-blurb-leave-at-door":
    "Ao selecionar 'Deixar na porta (sem contato)', você concorda em retirar seu pedido em 30 minutos e assumir total responsabilidade por ele assim que for entregue na sua porta.",
  "delivery-preference-blurb-meet-at-door":
    "Ao selecionar 'Encontrar na porta', você concorda em assumir total responsabilidade pelo seu pedido assim que for entregue. Se você não estiver disponível, o entregador deixará o pedido na porta, exceto bebidas alcoólicas ou itens restritos, que serão devolvidos à loja.",
  "track-order": "Rastrear pedido",
  "delivery-sms-blurb":
    "Usaremos seu número de celular para enviar atualizações por SMS sobre seu pedido e entrega.",
  "proof-of-delivery": "Comprovante de entrega",
  "click-to-track-delivery": "Clique aqui para rastrear seu pedido",
  "delivery-driver": "Motorista de entrega",
  "estimated-arrival": "Chegada estimada",
  "continue-to-payment": "Continuar para pagamento",
  "delivery-integration-instruction-placeholder": "Exemplo: Por favor, deixar na porta",
  "currency-title": "Moeda",
  "currency-conversion-disclaimer":
    "Observação: todas as transações são processadas na moeda do comerciante.",
  "uber-direct-disclaimer":
    "Para quaisquer problemas ou preocupações, entre em contato com a loja em [store-phone]",
};
