import { callAPI } from "../../../../../_common/Api";
import { toDollars } from "../../../../../_common/helpers";

/** the login token parameter can also be user's email, however if email is provided password the user has to be provided as well
 * if login token is provided, password is not required
 */
export const getAPICardList = async (
  skin,
  loginToken,
  currencySymbol,
  currencySymbolSide,
  language,
  brandingContext,
  password = ""
) => {
  const api948Data = await callAPI(skin, "dc_948", {
    params: [language, "948", "mqid", "mqpass", loginToken, password],
    id: "948",
  });

  const apiCustomerId = api948Data.result.I2;
  const apiCardList = api948Data.result.I5;

  // To test login token expiry uncomment the block below
  /* const tokenExpiryTested = localStorage.getItem("token-expiry-tested"); // makes sure the test runs only once unless the "token-expiry-tested" is removed from localStorage
  if (!tokenExpiryTested) {
    apiCardList = null;
    api948Data.status = 150; // forces the expired login token status
    localStorage.setItem("token-expiry-tested", true);
  } */

  if (apiCustomerId && apiCardList && apiCardList.length > 0) {
    const formattedCards = [];

    apiCardList.forEach((apiCard) => {
      /*
        By default, cardList includes only cards which are active or on hold.
        The system can be configured to also include cards which have been cancelled.
      */

      const isCardActive = apiCard[2] === "1";

      if (isCardActive) {
        const card = {
          iso: apiCard[0],
          serial: apiCard[1],
          maskedNumber: apiCard[0] + "*****" + apiCard[1] + "*",
          name: apiCard[3],
          currency: apiCard[4],
          balance: parseFloat(apiCard[5]),
          displayBalance:
            toDollars(currencySymbol, currencySymbolSide, apiCard[5], language, true) +
            " " +
            apiCard[4],
          expiry: apiCard[8],
          image: apiCard[9] || brandingContext["no-image-available-giftcard-placeholder"],
          points: parseInt(apiCard[6]),
        };

        formattedCards.push(card);
      }
    });

    return formattedCards;
  } else if (api948Data.status === 249 || api948Data.status === 150) {
    return { status: "expiredLoginToken" };
  } else {
    return { error: api948Data.result.message || "Error", status: api948Data.status };
  }
};
