export const accountLabels = {
  profile: "Profil",
  "log-out": "Fermer la session",
  "log-out-confirm-message": "Êtes-vous sûr de vouloir fermer la session?",
  "personal-info": "Renseignements personnels",
  "update-your-personal-info": "Mettre à jour vos données personnelles",
  update: "Mettre à jour",
  updating: "Mise à jour en cours…",
  "change-password": "Modifier le mot de passe",
  "update-your-password": "Mettre à jour votre mot de passe",
  "current-username": "Nom d'utilisateur actuel",
  "current-password": "Mot de passe actuel",
  "new-password": "Nouveau mot de passe",
  "confirm-new-password": "Confirmer le nouveau mot de passe",
  "changing-password": "Modification du mot de passe en cours…",
  "payment-methods": "Modes de paiement",
  "add-credit-card": "Ajouter une carte de crédit",
  "save-card": "Sauvegarder la carte",
  "saving-card": "Sauvegarde de la carte en cours…",
  "add-new-card": "Ajouter une nouvelle carte",
  "add-new-card-paragraph":
    "Pour ajouter une nouvelle carte, taper le numéro de la carte ou scanner le code-barres",
  "scan-barcode": "Scanner le code-barres",
  "scan-barcode-modal-heading": "Scanner le code-barres sur votre carte",
  "add-card": "Ajouter une carte",
  adding: "Ajout en cours…",
  added: "Ajoutée",
  "transaction-history": "Historique de transactions",
  "recent-orders": "Commandes récentes",
  "recent-online-orders": "Commandes en ligne récentes",
  "recent-orders-disclaimer":
    "Le total de la commande précédente pourrait différer étant donné que les prix peuvent fluctuer",
  "recent-orders-item-doesnt-exist-message":
    "Un ou plusieurs articles de cette commande n'existent plus",
  "no-previous-orders": "Aucune commande précédente",
  "recent-loyalty-transactions": "Transactions de fidélisation récentes",
  "recent-card-transactions": "Transactions récentes par carte",
  "pay-in-store": "Scanner sur place",
  "add-money": "Ajouter de l'argent",
  "add-money-submit-button": "Ajouter",
  "refresh-code": "Rafraîchir le code",
  "my-wallet": "Mon portefeuille",
  card: "Carte",
  "method-of-payment": "Mode de paiement",
  "reload-amount": "Recharger le montant",
  "auto-reload": "Rechargement automatique",
  "auto-reload-amount": "Montant du rechargement automatique",
  "when-balance-reaches": "Lorsque le solde atteindra",
  "choose-how-often-to-reload": "ou choisir la fréquence du rechargement",
  "set-auto-reload": "Régler le rechargement automatique",
  "button-remove-autoreload": "Supprimer le rechargement automatique",
  "update-auto-reload": "Mettre à jour le rechargement automatique",
  "update-account-details": "Mettre à jour les détails du compte",
  "enter-password-to-confirm-changes":
    "Veuillez saisir votre mot de passe pour confirmer les modifications",
  "balance-transfer-to-card-number": "Vers votre carte de fidélité",
  "balance-transfer-success-message": "Solde transféré avec succès",
  "balance-transfer-new-points-balance": "Votre nouveau solde de points est",
  "transfer-funds": "Transférer des fonds",
  "funds-transferred": "Fonds transférés",
  "balance-transfer-disclaimer":
    "Une fois les fonds transférés à votre compte, la carte-cadeau sera inactive.",
  "transfer-funds-blurb": "Vous avez une carte-cadeau? Transférer les fonds à votre compte ici.",
  "balance-transfer-to-gift-card": "Vers votre carte-cadeau",
  "open-barcode-scanner": "Ouvrir le scanneur de code-barre",
  "auto-reload-schedule-with-threshold":
    "[auto-reload] ajoutera [reload-amount] à cette carte quand son solde est en dessous de [threshold-amount].",
  "auto-reload-schedule-weekly":
    "[auto-reload] ajoutera [reload-amount] à cette carte [frequency].",
  "auto-reload-schedule-monthly":
    "[auto-reload] ajoutera [reload-amount] à cette carte [frequency].",
  "order-status": "Statut de la commande",
  "order-status-active-orders-blurb": "Vous avez [number-of-orders] commande(s) en cours",
  "order-details": "Détails de la commande",
  "order-status-make-changes-blurb":
    "Si vous désirez effectuer des changements veuillez contacter la succursale au [phone-number]",
  "order-placed": "Effectueé",
  "order-accepted": "Acceptée",
  "order-cancelled": "Annulée",
  "order-preparing": "En préparation",
  "order-ready-for-pickup": "Prête pour le retrait",
  "order-on-its-way": "Sur la route",
  "order-complete": "Terminée",
  "order-in-progress": "Commande(s) en cours",
  "past-orders": "Historiques des commandes",
  "ordered-items": "Articles commandés",
  "order-received": "Reçue",
  "order-void": "Annuler",
  "order-rejected": "Diminué",
  "order-prepared": "Préparé",
  "order-status-last-update-time": "Dernière mise à jour à [time-stamp]",
  "order-reviewing": "Vérification",
  "order-timestamp-at": "à",
  "my-orders": "Mes commandes",
  "request-account-deletion": "Demander la suppression du compte",
  "account-deletion-email-subject": "Demande de suppression de compte - [customer_id]",
  "account-deletion-email-body":
    "INSTRUCTIONS:\nVeuillez eenvoyer ce courriel pour soumettre une demande de suppression du compte suivant à [merchant_name]:\n\nID CLIENT:\n[customer_id]\n\nNom d'utilisateur du CLIENT\n[customer_email]\n\nPar cet envoi vouss comprenez que cette demande est irréversible et que votre compte [merchant_name] et les données qui y sont associées seront effacés pour toujours. Vous recevrez un courriel de confirmation finale une fois votre compte supprimé.",
  "delete-account": "Supprimer le compte",
  "delete-my-account": "Supprimer mon compte",
  deleting: "Suppression...",
  "enter-password": "Veuillez saisir le mot de passe",
  "delete-account-confirmation-header": "Confirmation",
  "delete-account-sorry-to-see-you-go": "Nous sommes dédolés de votre départ!",
  "delete-account-permanently": "Etes-vous sûrs de vouloir complètement supprimer votre compte?",
  "delete-account-confirm-deletion":
    "Veuillez saisir votre mot de passe pour confirmer la suppression de votre compte.",
  "delete-account-success-message": "Votre compte a été désactivé avec succès!",
  "delete-account-blurb1":
    "Nous supprimerons toutes les informations et données personnelles associées à votre compte dans [number-of-days] jours.",
  "delete-account-blurb2":
    "Nous avons envoyer un courriel de confirmation à l'addresse associée à votre compte",
  "remove-address-confirmation": "Etes-vous sûrs de vouloir supprimer cette adresse?",
  "add-delivery-address": "Ajouter une adrese de livraison",
  "confirm-delivery-address": "Confirmer l'adresse de livraison",
  "select-delivery-address": "Sélectionner l'adresse de livraison",
  "delivery-or-service-fee": "Frais de livraison/service",
  "service-fee": "Frais de service",
};
