// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";

// Contexts
import LocationsContext from "../Locations/LocationsContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper functions
import {
  getCourierDetails,
  getOrderItemDetails,
  getOrderStatusFromProgressBar,
  getOrderStoreAddress,
  getTotalPointsRedeemed,
  removeDuplicatesAndUpdateCount,
} from "./orderHistoryHelpers";
import { capitalizeWords } from "../../_common/stringHelpers";
import { jsonCopy, toDollars } from "../../_common/helpers";

// UI components
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { OrderStatusProgressBar } from "../OrderStatus/OrderStatusProgressBar";
import { RecentOrderOptionsList } from "./RecentOrderOptionsList";
import { LoadingSpinner } from "../../_common/LoadingSpinner";

// Assets
import StarIcon from "@material-ui/icons/Star";

export const ActiveOrdersCard = (props) => {
  const { order, activeOrdersLength, appLabels, appLanguage, formatOrderDateAndTime } = props;

  const { skin } = useContext(MerchantConfigContext);
  const allStores = useContext(LocationsContext);
  const appSettings = useContext(AppSettingsContext);

  const deviceWidth = useWindowSize().width;

  const [showDetails, setShowDetails] = useState(() => {
    if (activeOrdersLength === 1) {
      return true;
    } else if (order.deliveryIntegrationInfo) {
      return true;
    } else {
      return false;
    }
  });

  const showDetailsButtonRef = useRef(null);

  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";

  const handleShowDetails = () => {
    const currentState = showDetails;
    setShowDetails(!currentState);
  };

  useEffect(() => {
    if (showDetails) {
      showDetailsButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showDetails]);

  // const orderStatus = order.orderStatus.status;

  const [uniqueOrderItems, setUniqueOrderItems] = useState(null);
  const [orderWithDetails, setOrderWithDetails] = useState(null);
  useEffect(() => {
    if (showDetails && !uniqueOrderItems && !order.deliveryIntegrationInfo) {
      setOrderWithDetails(null);
      getOrderItemDetails(skin, order.id, order.storeLocation.id, appLanguage).then(
        (itemDetails) => {
          let orderWithDetails = jsonCopy(order);
          orderWithDetails.discountTotal = itemDetails.discountTotal;
          orderWithDetails.items = itemDetails.items;
          orderWithDetails.paymentDetails = itemDetails.paymentDetails;
          orderWithDetails.rewardDetails = itemDetails.rewardDetails;
          orderWithDetails.orderAddress = itemDetails.orderAddress;
          if (itemDetails.taxTotals > 0) {
            orderWithDetails.taxTotals = itemDetails.taxTotals;
          }
          if (itemDetails.serviceChargeAmount > 0) {
            orderWithDetails.serviceChargeAmount = itemDetails.serviceChargeAmount;
          }
          setOrderWithDetails(orderWithDetails);
          const uniqueItems = removeDuplicatesAndUpdateCount(
            orderWithDetails.items,
            isAddonQuantityEnabled
          );
          setUniqueOrderItems(uniqueItems);
        }
      );
    }
  }, [showDetails]);

  let paymentRewardDetailsAvailable = false;

  if (order) {
    paymentRewardDetailsAvailable =
      (order.paymentDetails && order.paymentDetails.length > 0) ||
      (order.rewardDetails && order.rewardDetails.length > 0)
        ? true
        : false;
  }

  const [grandTotal, setGrandTotal] = useState(null);
  const [accumulatedTips, setAccumulatedTips] = useState(null);
  useEffect(() => {
    if (orderWithDetails) {
      let totalAmount = Number(orderWithDetails.rawTotal || 0);

      // adding in applicable tip
      let tips = 0;
      if (orderWithDetails.paymentDetails && orderWithDetails.paymentDetails.length > 0) {
        orderWithDetails.paymentDetails.forEach((paymentDetail) => {
          if (paymentDetail.paymentTip && parseFloat(paymentDetail.paymentTip) > 0) {
            tips = tips + parseFloat(paymentDetail.paymentTip);
          }
        });
      }
      if (
        orderWithDetails.serviceChargeAmount &&
        parseFloat(orderWithDetails.serviceChargeAmount) > 0
      ) {
        totalAmount = totalAmount + parseFloat(orderWithDetails.serviceChargeAmount);
      }
      totalAmount = totalAmount + tips;
      setAccumulatedTips(tips)
     
      setGrandTotal(totalAmount);
    }
  }, [orderWithDetails]);

  return (
    <div className="recent-order">
      <section className="recent-order__header">
        <span>{formatOrderDateAndTime(order.date)}</span>
        <span>#{order.id}</span>
      </section>
      <section className="recent-order__main">
        {((order.orderStatus.status !== "delivery_completed" && order.orderType !== "Pickup") ||
          (order.orderStatus.status !== "closed" && order.orderType === "Pickup")) &&
          order.orderStatus.isEnabled &&
          order.orderStatus.isFinished !== true &&
          !showDetails &&
          !order.deliveryIntegrationInfo && (
            <h3 className="order-status__order-details-status-header">
              {deviceWidth >= 660 && <div className="recent-order__active-order-checkmark"></div>}
              {`${getOrderStatusFromProgressBar(order, appLabels)}`}
            </h3>
          )}
        <div
          ref={showDetailsButtonRef}
          className={
            order.deliveryIntegrationInfo && order.deliveryIntegrationInfo["driver_info"]
              ? "with-driver-info"
              : ""
          }
        >
          {getOrderStoreAddress(
            order,
            appLabels,
            true,
            order.deliveryIntegrationInfo && order.deliveryIntegrationInfo["dropoff_time"]
              ? order.deliveryIntegrationInfo["dropoff_time"]
              : null
          )}{" "}
          {order.deliveryIntegrationInfo && (
            <>
              <div style={deviceWidth >= 660 ? { marginTop: "10px" } : { marginTop: "10px" }}>
                {appLabels["order"]["uber-direct-disclaimer"].split("[store-phone")[0]}
                <strong>{order.storeLocation.phone}</strong>
              </div>
            </>
          )}
          {order.deliveryIntegrationInfo &&
            order.deliveryIntegrationInfo["driver_info"] &&
            showDetails &&
            deviceWidth >= 660 && (
              <div className="recent-order__driver-info">
                {getCourierDetails(order.deliveryIntegrationInfo["driver_info"], appLabels)}
              </div>
            )}
        </div>

        {order.deliveryIntegrationInfo &&
          order.deliveryIntegrationInfo["driver_info"] &&
          !showDetails &&
          deviceWidth < 660 && (
            <div className="recent-order__driver-info">
              {getCourierDetails(order.deliveryIntegrationInfo["driver_info"], appLabels)}
            </div>
          )}

        {order.deliveryIntegrationInfo && !!order.deliveryIntegrationInfo["tracking_url"] && (
          <div className="tracking-url-container">
            <iframe
              className={showDetails ? "isExpanded" : " isCollapsed"}
              title="Delivery Order"
              src={order.deliveryIntegrationInfo["tracking_url"]}
            ></iframe>
          </div>
        )}
      </section>
      <section className="recent-order__footer-active-orders">
        {showDetails && uniqueOrderItems && orderWithDetails && !order.deliveryIntegrationInfo && (
          <div className="order-status__transition">
            <div className="order-status__steps-wrapper">
              <OrderStatusProgressBar order={order} />
            </div>

            {allStores &&
              !!allStores.find((store) => store.storeId === orderWithDetails.storeLocation.id) && (
                <p className="order-status__order-details-disclaimer">
                  {appLabels["account"]["order-status-make-changes-blurb"]
                    .replace(
                      "[phone-number]",
                      allStores.find((store) => store.storeId === orderWithDetails.storeLocation.id)
                        .phone
                    )
                    .split("+1")
                    .slice(0, -1)
                    .join(" ")}
                  <strong>
                    {appLabels["account"]["order-status-make-changes-blurb"]
                      .replace(
                        "[phone-number]",
                        allStores.find(
                          (store) => store.storeId === orderWithDetails.storeLocation.id
                        ).phone
                      )
                      .split("+1")
                      .pop()}
                  </strong>
                </p>
              )}

            {orderWithDetails.items && !!orderWithDetails.items.length && (
              <>
                <h3 className="order-status__order-details-header">
                  {`${capitalizeWords(
                    appLabels["account"]["order-details"].replaceAll("_", " ").trim()
                  )}`}
                </h3>
                <ul className="order-status__order-details-items-list">
                  {uniqueOrderItems.map((item, index) => (
                    <React.Fragment key={item.name + "-" + index}>
                      <li className="recent-order__item">
                        <h2 className="recent-order__item-name-price">
                          <span className="recent-order__order-units">{item.quantity}</span>
                          <span className="recent-order__item-name">{item.name}</span>
                          <span className="recent-order__item-price">
                            {`
                          ${toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            item.price,
                            appLanguage,
                            true
                          )}
                          `}
                          </span>
                        </h2>
                        {item.modifiers && (
                          <RecentOrderOptionsList
                            type="modifier"
                            options={item.modifiers}
                            isAddonQuantityEnabled={isAddonQuantityEnabled}
                          />
                        )}
                        {item.addons && (
                          <RecentOrderOptionsList
                            type="addon"
                            options={item.addons}
                            isAddonQuantityEnabled={isAddonQuantityEnabled}
                          />
                        )}
                        {item.combo_child_items && (
                          <ul
                            className="recent-order__combo-child-items"
                            key={"combo-child-item-" + item.id + "-" + index}
                          >
                            {item.combo_child_items.map((childItem, childItemIndex) => (
                              <React.Fragment key={childItem.id + "-" + childItemIndex}>
                                <li>
                                  <h2 className="recent-order__combo-child-item-name">
                                    {childItem.name}
                                  </h2>
                                  {!!childItem.modifierGroups.length && (
                                    <RecentOrderOptionsList
                                      type="modifier"
                                      options={childItem.modifierGroups}
                                      isAddonQuantityEnabled={isAddonQuantityEnabled}
                                    />
                                  )}
                                  {!!childItem.addonGroups.length && (
                                    <RecentOrderOptionsList
                                      type="addon"
                                      options={childItem.addonGroups}
                                      isAddonQuantityEnabled={isAddonQuantityEnabled}
                                    />
                                  )}
                                </li>
                              </React.Fragment>
                            ))}
                          </ul>
                        )}
                      </li>
                    </React.Fragment>
                  ))}
                  <li className="recent-order__tip-total-active-orders">
                    {orderWithDetails.discountTotal &&
                      parseFloat(orderWithDetails.discountTotal) > 0 && (
                        <p className="recent-order__tip-discount-active-orders">
                          <span>{appLabels["order"]["discount"]}</span>
                          <span>
                            -
                            {toDollars(
                              appSettings["currency-symbol"],
                              appSettings["currency-symbol-side"],
                              orderWithDetails.discountTotal,
                              appLanguage,
                              true
                            )}
                          </span>
                        </p>
                      )}
                    {orderWithDetails.taxTotals &&
                      orderWithDetails.taxTotals > 0 &&
                      parseFloat(orderWithDetails.taxTotals) > 0 && (
                        <p className="recent-order__tip-discount-active-orders">
                          <span>{appLabels["order"]["tax"]}</span>
                          <span>
                            {toDollars(
                              appSettings["currency-symbol"],
                              appSettings["currency-symbol-side"],
                              orderWithDetails.taxTotals,
                              appLanguage,
                              true
                            )}
                          </span>
                        </p>
                      )}
                    {orderWithDetails.serviceChargeAmount &&
                      parseFloat(orderWithDetails.serviceChargeAmount) > 0 && (
                        <p className="recent-order__tip-discount-active-orders">
                          {orderWithDetails.orderType === "Delivery" ? (
                            <span>{appLabels["account"]["delivery-or-service-fee"]}</span>
                          ) : (
                            <span>{appLabels["account"]["service-fee"]}</span>
                          )}

                          <span>
                            {toDollars(
                              appSettings["currency-symbol"],
                              appSettings["currency-symbol-side"],
                              orderWithDetails.serviceChargeAmount,
                              appLanguage,
                              true
                            )}
                          </span>
                        </p>
                      )}
                    {orderWithDetails.paymentDetails &&
                      accumulatedTips !== 0 &&
                      accumulatedTips > 0 && (
                        <p className="recent-order__tip-discount-active-orders">
                          <span>{appLabels["order"]["tip"]}</span>
                          <span>
                            {toDollars(
                              appSettings["currency-symbol"],
                              appSettings["currency-symbol-side"],
                              accumulatedTips,
                              appLanguage,
                              true
                            )}
                          </span>
                        </p>
                      )}
                    {grandTotal && (
                      <p
                        className={`recent-order__total-active-orders ${
                          paymentRewardDetailsAvailable ? " recent-order__border-bottom" : ""
                        }`}
                      >
                        <span>
                          {appLabels["general"]["total"]}
                          {": "}
                        </span>
                        <span>
                          {toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            grandTotal,
                            appLanguage,
                            true
                          )}
                        </span>
                      </p>
                    )}
                    <section>
                      {orderWithDetails.paymentDetails &&
                        orderWithDetails.paymentDetails.length > 0 &&
                        orderWithDetails.paymentDetails[0].pointsEarned &&
                        orderWithDetails.paymentDetails[0].pointsEarned !== null &&
                        orderWithDetails.paymentDetails[0].pointsEarned !== "" &&
                        !isNaN(orderWithDetails.paymentDetails[0].pointsEarned) && (
                          <p className="recent-order__footer-current-order">
                            <span>{appLabels["order"]["points-earned"]}</span>
                            <span className="recent-order__footer-points">
                              +{parseInt(orderWithDetails.paymentDetails[0].pointsEarned)}
                              <StarIcon className="recent-order__footer-star-icon" />
                            </span>
                          </p>
                        )}
                      {orderWithDetails.rewardDetails &&
                        orderWithDetails.rewardDetails.length > 0 && (
                          <p className="recent-order__footer-current-order">
                            <span>{appLabels["order"]["points-redeemed"]}</span>
                            <span className="recent-order__footer-points">
                              -{getTotalPointsRedeemed(orderWithDetails.rewardDetails)}
                              <StarIcon className="recent-order__footer-star-icon" />
                            </span>
                          </p>
                        )}
                      {orderWithDetails.paymentDetails &&
                        orderWithDetails.paymentDetails.length > 0 && (
                          <p className="recent-order__footer-current-order">
                            <span>
                              {appLabels["order"]["charged-to"]}{" "}
                              {orderWithDetails.paymentDetails[0].paymentType}
                            </span>
                            {orderWithDetails.paymentDetails[0].paymentCard !== "None" && (
                              <span>{orderWithDetails.paymentDetails[0].paymentCard}</span>
                            )}
                          </p>
                        )}
                    </section>
                  </li>
                </ul>
              </>
            )}
          </div>
        )}
        <button
          className="recent-order__expand-more"
          aria-label={
            showDetails
              ? appLabels["order"]["collapse-order-details"]
              : appLabels["order"]["expand-order-details"]
          }
          onClick={handleShowDetails}
        >
          {!showDetails ? <ExpandMore /> : <ExpandLess />}
        </button>
      </section>
      {showDetails && !uniqueOrderItems && !order.deliveryIntegrationInfo && <LoadingSpinner />}
    </div>
  );
};
