import React, { useContext } from "react";
import { Link } from "react-router-dom";
import localforage from "localforage";
import MerchantConfigContext from "../MerchantConfigContext";
import AppLabelsContext from "../AppLabelsContext";
import CurrencyContext from "../CurrencyContext";
import BrandingContext from "../BrandingContext";

export const AppDesktopHeaderSubmenu = ({ subnavItems, customClass }) => {
  const currencyContext = useContext(CurrencyContext);
  const branding = useContext(BrandingContext);

  const selectedBackgroundColor =
    branding["header-text-and-icon-colour"] === "#fff"
      ? "rgba(255,255,255,0.1)"
      : "rgba(0,0,0,0.1)";

  return (
    <div className="desktop-header__subnav">
      <ul className={`desktop-header-subnav__content desktop-header-subnav__list ${customClass}`}>
        {subnavItems.map((item, index) => (
          <li
            key={index}
            className={`desktop-header-subnav__list-item ${
              currencyContext.currencyData[0] === item.displayName
                ? "desktop-header-subnav__currency-item isSelected"
                : ""
            }`}
            style={
              currencyContext.currencyData[0] === item.displayName
                ? { backgroundColor: selectedBackgroundColor }
                : {}
            }
          >
            {item.action && (
              <button
                onClick={item.action}
                className="desktop-header-nav__submenu-button"
                type="button"
              >
                {item.displayName}
              </button>
            )}
            {item.linkPathname && <SubmenuItemLink item={item} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

const SubmenuItemLink = ({ item }) => {
  const { skin } = useContext(MerchantConfigContext);
  const appLabels = useContext(AppLabelsContext);

  const handleClick = () => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
  };

  return (
    <>
      {!item.isExternalLink && !item.isLanguageLink && (
        <Link
          to={item.linkPathname}
          className="desktop-header-nav__submenu-link"
          onClick={handleClick}
        >
          {item.displayName}
        </Link>
      )}
      {item.isExternalLink && (
        <a
          href={item.linkPathname}
          className="desktop-header-nav__submenu-link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          {item.displayName}
        </a>
      )}
      {item.isLanguageLink && (
        <a
          href={item.linkPathname}
          className="desktop-header-nav__submenu-link"
          onClick={handleClick}
          aria-label={appLabels["general"]["select-language"].replace(
            "[language-name]",
            item.displayName
          )}
        >
          {item.displayName}
        </a>
      )}
    </>
  );
};
