import React from "react";

export const LoadingSpinner = ({ isDefault, customClass, customStyle }) => {
  return (
    <div
      className={`loading-spinner${isDefault ? " loading-spinner--default" : ""} ${
        customClass ? customClass : ""
      }`}
      style={customStyle ? customStyle : {}}
    />
  );
};
