/* eslint-disable no-loop-func */
import localforage from "localforage";
import { callAPI } from "../../../_common/Api";
import {
  cleanUpItemsListBasedOnQuantity,
  dynamicSort,
  getCartItemByKey,
  getOccurrence,
  removeDuplicates,
} from "../../../_common/CartHelpers";
import { arrayToObject, getDeviceTypeId, getTimestamp, jsonCopy } from "../../../_common/helpers";
import { onAddProductToCart } from "../../../_common/helpers/dataLayerHelpers";
import { isValidItemQuantity, validateOptions } from "../../../_common/MenuHelpers";
import {
  onAddProductToCartXtreme,
  onRemoveProductFromCartXtreme,
} from "../../../_common/helpers/xtremePushHelper";

export const quickAddToComboChoice = async (item, comboCartItem) => {
  const cartItem = { ...item };

  cartItem.category = item.category;
  cartItem.subcategory = item.subcategory;
  cartItem.quantity = item.quantity ? item.quantity + 1 : 1;
  cartItem.entryKey = Date.now();

  comboCartItem.combo_child_items.push(cartItem);
  comboCartItem.combo_child_items.sort(dynamicSort("group_id"));
  comboCartItem.updateComboCartItem({ ...comboCartItem });

  return { itemAdded: true };
};

export const quickRemoveFromComboChoice = async (item, comboCartItem) => {
  let removableElement = null;

  const cartItem = { ...item };

  //if the current subgroup already has an item, remove it or adjust the quantity and add the new one.
  comboCartItem.combo_child_items.forEach((element, index) => {
    if (element.group_id === cartItem.group_id && element.quantity === 1) {
      removableElement = index;
    } else {
      element.quantity = element.quantity - 1;
    }
  });

  if (removableElement !== null) {
    comboCartItem.combo_child_items.splice(removableElement, 1);
  }
  if (!!comboCartItem.combo_child_items.length) {
    comboCartItem.combo_child_items.sort(dynamicSort("group_id"));
  }
  comboCartItem.updateComboCartItem({ ...comboCartItem });

  return { itemRemoved: true };
};

export const addMenuItemToCart = async (
  menuItem,
  quantity,
  isQuickAdd = false,
  setAreOptionsValid,
  setIsQuantityValid,
  cart,
  cartContext,
  skin,
  appLabels
) => {
  const { errors, idOfGroupWithErrors } = validateOptions(menuItem, appLabels["form"]);

  if (errors === 0) {
    if (!isValidItemQuantity(cart, menuItem)) {
      setIsQuantityValid(false);
      return;
    }

    const cartItem = JSON.parse(JSON.stringify(menuItem));
    cartItem.specialInstructions = menuItem.specialInstructions || "";
    cartItem.category = menuItem.category || "";
    cartItem.subcategory = menuItem.subcategory || "";
    cartItem.quantity = quantity;
    delete cartItem.onlineQuantityLimit;

    const newEntryKeyValue = Date.now();
    cartItem.entryKey = newEntryKeyValue;

    const cartCopy = jsonCopy(cart);
    delete cartItem.customFields;
    delete cartItem.standardSuggestedItems;
    delete cartItem.requiresCustomization;
    delete cartItem.isAIrecommendation;
    delete cartItem.isSuggestedTwice;
    delete cartItem.isSuggestedItem;
    delete cartItem.suggestedItemType;
    const cartItemCopy = jsonCopy(cartItem);

    delete cartItemCopy.entryKey;
    delete cartItemCopy.quantity;
    let matchFound = false;
    let matchIndex = 0;
    cartCopy.forEach((cartCopyItem, index) => {
      let storedQuantity = cartCopyItem.quantity;
      delete cartCopyItem.entryKey;
      delete cartCopyItem.quantity;
      delete cartCopyItem.total_addon_discount;

      if (
        JSON.stringify(sortObjectKeysAlphabetically(cartCopyItem)) ===
        JSON.stringify(sortObjectKeysAlphabetically(cartItemCopy))
      ) {
        matchFound = true;
        matchIndex = index;
        cartItem.quantity = quantity + storedQuantity;
        cartItem.entryKey = newEntryKeyValue;
      }
    });


    const newCart = cart ? [...cart] : [];
    if (matchFound) {
      newCart[matchIndex] = cartItem;
    } else {
      newCart.push(cartItem);
    }

    newCart.forEach((element) => {
     // delete element.entryKey;
      if (element.addonGroups) {
        Object.keys(element.addonGroups).forEach((key) => {
          const addonGroup = element.addonGroups[key];
          delete addonGroup.positioningTemplateIndex;
          const addons = addonGroup.items;
          Object.keys(addons).forEach((addonKey) => {
            const addon = addons[addonKey];
            if (addon.modifierGroups) {
              const nestedModifierGroups = addon.modifierGroups;
              Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                delete nestedModifierGroups[nestedModifierGroupKey].isSelected;
              });
            }
          });
        });
      }

      if (element.modifierGroups) {
        Object.keys(element.modifierGroups).forEach((key) => {
          delete element.modifierGroups[key].positioningTemplateIndex;
        });
      }
    });

    onAddProductToCart(cartItem, menuItem.category, menuItem.subcategory);
    onAddProductToCartXtreme(
      { ...cartItem, quantity: 1 },
      menuItem.category,
      menuItem.subcategory,
      skin
    );
    cartContext.updateCart(newCart);

    if (isQuickAdd) {
      return { itemAdded: true };
    }

    //setAddedToOrder(true);
    setAreOptionsValid(true);
  } else {
    setAreOptionsValid(false);
    setIsQuantityValid(false);

    if (!isQuickAdd) {
      //check the addon group with error and scroll to that group
      console.log("idOfGroupWithErrors: ", idOfGroupWithErrors); //temp
    }
  }
};

export const removeMenuItemFromCart = async (menuItem, cartContext, removeAllInstances) => {
  let cart = cartContext.value || [];
  for (let i = 0; i < cart.length; i++) {
    if (
      cart[i].id === menuItem.id &&
      (cart[i].entryKey === menuItem.entryKey ||
        !menuItem.entryKey ||
        (!!menuItem.entryKey && menuItem.entryKey.toString().includes("-")))
    ) {
      if (cart[i].quantity === 1 || removeAllInstances) {
        cart.splice(i, 1);
      } else {
        cart[i].quantity = cart[i].quantity - 1;
      }

      break;
    }
  }

  await cartContext.updateCart(cart);
  return { itemRemoved: true };
};

export const removeAllInstanceOfItemFromCart = async (menuItem, cartContext, skin) => {
  let cart = cartContext.value || [];
  let numberOfInstances = cart.filter((item) => item.id === menuItem.id).length;
  for (let i = 0; i < numberOfInstances; i++) {
    for (let j = 0; j < cart.length; j++) {
      if (cart[j].id === menuItem.id) {
        onRemoveProductFromCartXtreme(
          cart[j],
          menuItem.category,
          menuItem.subcategory,
          false,
          skin
        );
        cart.splice(j, 1);

        break;
      }
    }
  }
  cartContext.updateCart(cart);
  return { itemRemoved: true };
};

export const getCurrentItemQuantityInCart = (menuItem, cart) => {
  if (cart.length === 0) {
    return 0;
  } else {
    const itemInCart = cart.filter((item) => item.id === menuItem.id);
    if (!!itemInCart.length) {
      let itemsQuantity = 0;
      itemInCart.forEach((item) => {
        itemsQuantity = itemsQuantity + item.quantity;
      });
      return itemsQuantity;
    } else {
      return 0;
    }
  }
};

export const formatOptionGroups = async (
  optionGroups,
  isInCart,
  resetOptionsGroup,
  isEditingComboItem = false,
  skin,
  savedMenuItemModifiers = null
) => {
  const tempOptionGroups = { ...optionGroups };
  const hasStoredItemInLocalForage = await localforage.getItem(skin + "__itemSelectionInProgress");
  Object.keys(tempOptionGroups).forEach((key) => {
    const isStandaloneGroup = !tempOptionGroups[key].id;

    if (isStandaloneGroup) {
      // Standalone addon groups don't have IDs so we grab its only child's id instead
      const onlyChildKeyname = Object.keys(tempOptionGroups[key].items)[0];
      const onlyChild = tempOptionGroups[key].items[onlyChildKeyname];

      onlyChild.isStandalone = true;

      tempOptionGroups[key].id = onlyChild.id;
      tempOptionGroups[key].name = onlyChild.name;
      tempOptionGroups[key].isStandalone = true;
    } else {
      /**
       * if item is not in the cart and has isSelected keys in the option groups,
       * clear them so that the min selection requirements are checked properly
       * otherwise item can be added to the cart without selecting mandatory selection
       */

      if (!isInCart && resetOptionsGroup && !isEditingComboItem && !hasStoredItemInLocalForage &&
        !savedMenuItemModifiers) {
        const tempOptionGroupItems = tempOptionGroups[key].items;

        Object.keys(tempOptionGroupItems).forEach((itemKey) => {
          if (tempOptionGroupItems[itemKey].isSelected && !tempOptionGroupItems[itemKey].quantity) {
            delete tempOptionGroupItems[itemKey].isSelected;
          }
        });
      }
    }
  });

  return tempOptionGroups;
};

export const groupHasDefaultSelection = (confirmedOptions) => {
  let hasDefaultSelection = false;
  if (confirmedOptions) {
    Object.keys(confirmedOptions).forEach((key) => {
      const option = confirmedOptions[key];
      if (option.isDefault === "True") {
        hasDefaultSelection = true;
      }
    });
  }

  return hasDefaultSelection;
};

export const groupHasNestedOptionsToBeSelected = (confirmedOptions) => {
  let requiresSelection = true;
  if (
    confirmedOptions === null ||
    Object.keys(confirmedOptions).length === 0 ||
    !confirmedOptions.modifierGroups
  ) {
    return false;
  }
  Object.keys(confirmedOptions.modifierGroups).forEach((modGroupKey) => {
    const nestedGroup = confirmedOptions.modifierGroups[modGroupKey];

    const nestedGroupItems = nestedGroup.items;

    Object.keys(nestedGroupItems).forEach((itemKey) => {
      if (nestedGroupItems[itemKey].isSelected) {
        requiresSelection = false;
      }
    });
  });

  return requiresSelection;
};

export const optionGroupHasAtLeastOneImage = (optionsGroup) => {
  let hasAtLeastOneImage = false;

  Object.keys(optionsGroup.items).forEach((key) => {
    if (!!optionsGroup.items[key].imageUrl) {
      hasAtLeastOneImage = true;
    }
  });

  return hasAtLeastOneImage;
};

// Format Food Menu items into objects
export const formatItems = (apiItems) => {
  const formattedItemsArray = [...apiItems];

  formattedItemsArray.map((apiItem) => {
    if (apiItem.addonGroups.length > 0) {
      // Convert addons into objects
      apiItem.addonGroups.map((addonGroup) => {
        addonGroup.items.map((addon) => {
          //convert addon modifiers to objects
          if (addon.modifierGroups && addon.modifierGroups.length > 0) {
            addon.modifierGroups.map((addonMod) => {
              addonMod.items = arrayToObject(addonMod.items, "id", "modifier");
              return addonMod;
            });
            addon.modifierGroups = arrayToObject(addon.modifierGroups, "id", "addonModifierGroup");
          }
          addon.parentId = addonGroup.id;
          return addon;
        });

        addonGroup.items = arrayToObject(addonGroup.items, "id", "addon");
        return addonGroup;
      });

      apiItem.addonGroups = arrayToObject(apiItem.addonGroups, "id", "addonGroup");
    }

    if (apiItem.modifierGroups.length > 0) {
      // Convert modifiers into objects
      apiItem.modifierGroups.map((modifierGroup) => {
        modifierGroup.items = arrayToObject(modifierGroup.items, "id", "modifier");
        return modifierGroup;
      });

      apiItem.modifierGroups = arrayToObject(apiItem.modifierGroups, "id", "modifierGroup");
    }
    return apiItem;
  });

  return formattedItemsArray;
};

const getComboChildItems = (
  comboId,
  appLanguage,
  storeId,
  orderType,
  orderTimeContext,
  skin,
  menuLevel,
  foodMenu
) => {
  if (foodMenu) {
    const isThreeLevel = menuLevel === "threeLevels";
    let comboChildItems = [];
    for (let key in foodMenu) {
      if (isThreeLevel) {
        const category = foodMenu[key];
        const subcategories = category.subcategories;
        for (let subCatKey in subcategories) {
          const subcategory = subcategories[subCatKey];
          const items = subcategory.items;
          for (let itemKey in items) {
            if (comboId === items[itemKey].id) {
              comboChildItems = items[itemKey].combo_child_items;
              break;
            }
          }
        }
      } else {
        const subcategory = foodMenu[key];
        const items = subcategory.items;
        for (let itemKey in items) {
          if (comboId === items[itemKey].id) {
            comboChildItems = items[itemKey].combo_child_items;
            break;
          }
        }
      }
    }

    return { result: { I2: comboChildItems } };
  } else {
    return callAPI(skin, "info_vxl_1301", {
      params: [
        appLanguage,
        "1301",
        "mqid",
        "mqpass",
        storeId, // Outlet ID
        "combo", // Parent Type
        comboId, // Item ID/Subcategory ID
        getDeviceTypeId(orderType) || "", // Device Type,
        "", // Department ID,
        orderTimeContext.value && orderTimeContext.value.value !== "Select Time"
          ? getTimestamp(orderTimeContext.value.value)
          : "", // Order Timestamp (optional)
      ],
      id: "1301",
    });
  }
};

const getFullComboData = async (
  item,
  appLanguage,
  storeId,
  orderType,
  orderTimeContext,
  skin,
  menuLevel,
  foodMenu
) => {
  const comboChildItems = await getComboChildItems(
    item.id,
    appLanguage,
    storeId,
    orderType,
    orderTimeContext,
    skin,
    menuLevel,
    foodMenu
  );
  if (comboChildItems) {
    return comboChildItems.result.I2;
  }
};

export const checkAvailableOrderItems = async (
  menuLevel,
  foodMenu,
  order,
  appLanguage,
  storeId,
  orderType,
  orderTimeContext,
  skin,
  isAddonQuantityEnabled
) => {
  let reOrderItems = [];
  let invalidOrderItems = order.items.length;
  let selectedOptions = [];

  for (let key in foodMenu) {
    const subcategories = menuLevel === "twoLevels" ? foodMenu[key] : foodMenu[key].subcategories;

    if (menuLevel === "twoLevels") {
      const items = subcategories.items;

      for (let key in items) {
        for (let i = 0; i < order.items.length; i++) {
          if (order.items[i].id === items[key].id) {
            //check the modifiers
            if (order.items[i].modifiers && items[key].modifierGroups) {
              order.items[i].modifiers.forEach((modifier) => {
                for (let keyReorder in modifier.items) {
                  for (let keyStoredGroup in items[key].modifierGroups) {
                    for (let keyStoredOption in items[key].modifierGroups[keyStoredGroup].items) {
                      if (
                        items[key].modifierGroups[keyStoredGroup].items[keyStoredOption].id ===
                          modifier.items[keyReorder].id &&
                        items[key].modifierGroups[keyStoredGroup].items[keyStoredOption]
                          .parentId === modifier.items[keyReorder]["modifier_id"]
                      ) {
                        items[key].modifierGroups[keyStoredGroup].items[
                          keyStoredOption
                        ].isSelected = true;

                        selectedOptions.push(
                          items[key].modifierGroups[keyStoredGroup].items[keyStoredOption]
                        );
                      }
                    }
                  }
                }
              });
            }

            //check the addons
            if (order.items[i].addons && items[key].addonGroups) {
              order.items[i].addons.forEach((addon) => {
                for (let keyReorder in addon.items) {
                  for (let keyStoredGroup in items[key].addonGroups) {
                    for (let keyStoredOption in items[key].addonGroups[keyStoredGroup].items) {
                      /** Note: if an addon from the order history does not have "addon_group_id", it is a single addon item */
                      if (
                        (items[key].addonGroups[keyStoredGroup].items[keyStoredOption].id ===
                          addon.items[keyReorder].id &&
                          items[key].addonGroups[keyStoredGroup].items[keyStoredOption].parentId ===
                            addon.items[keyReorder]["addon_group_id"]) ||
                        (!addon.items[keyReorder]["addon_group_id"] &&
                          addon.items[keyReorder].id ===
                            items[key].addonGroups[keyStoredGroup].items[keyStoredOption].id)
                      ) {
                        items[key].addonGroups[keyStoredGroup].items[
                          keyStoredOption
                        ].isSelected = true;

                        selectedOptions.push(
                          items[key].addonGroups[keyStoredGroup].items[keyStoredOption]
                        );

                        //must handle nested modifiers here
                        if (
                          addon.items[keyReorder].modifierGroups &&
                          addon.items[keyReorder].modifierGroups.length > 0
                        ) {
                          const orderNestedModifierGroups = addon.items[keyReorder].modifierGroups;
                          orderNestedModifierGroups.forEach((nestedModifierGroup) => {
                            const nestedModifiers = nestedModifierGroup.items;
                            nestedModifiers.forEach((nestedModifier) => {
                              const itemAddonNestedModifierGroups =
                                items[key].addonGroups[keyStoredGroup].items[keyStoredOption]
                                  .modifierGroups;
                              for (let nesterModifierGroupKey in itemAddonNestedModifierGroups) {
                                const nestedModifiers =
                                  itemAddonNestedModifierGroups[nesterModifierGroupKey].items;
                                for (let nestedModifierKey in nestedModifiers) {
                                  const itemNestedModifier = nestedModifiers[nestedModifierKey];
                                  if (itemNestedModifier.id === nestedModifier.id) {
                                    itemNestedModifier.isSelected = true;
                                    selectedOptions.push(itemNestedModifier);
                                  }
                                }
                              }
                            });
                          });
                        }
                      }
                    }
                  }
                }
              });
            }

            //check if item is combo, if so add its child items
            if (order.items[i].combo_child_items && items[key].type === "comboItem") {
              /** to handle combo items correctly
               * we need to pull all the combo child items from api 1301
               * then compare all the combo child items to the order object and pick only the relative ones (ordered previously)
               */
              const allComboChildren = await getFullComboData(
                items[key],
                appLanguage,
                storeId,
                orderType,
                orderTimeContext,
                skin,
                menuLevel,
                foodMenu
              );

              let relatedChildItems = [];
              allComboChildren.forEach((childItem) => {
                order.items[i].combo_child_items.forEach((orderedComboChildItem, index) => {
                  if (items[key].comboType === "standard") {
                    //Only add the child item if it does not exist from the same group id
                    if (
                      childItem.id === orderedComboChildItem.id &&
                      parseInt(childItem.group_id) === parseInt(index + 1) &&
                      relatedChildItems.filter(
                        (tempItem) => tempItem.group_id === childItem.group_id
                      ).length === 0
                    ) {
                      relatedChildItems.push(childItem);
                    }
                  } else {
                    if (childItem.id === orderedComboChildItem.id) {
                      if (
                        relatedChildItems.filter((item) => item.id === childItem.id).length <
                          order.items[i].combo_child_items.filter(
                            (item) => item.id === childItem.id
                          ).length &&
                        relatedChildItems.filter((item) => item.group_id === childItem.group_id)
                          .length < Number(childItem.groupMinQty)
                      ) {
                        relatedChildItems.push(childItem);
                      }
                    }
                  }
                });
              });

              items[key].combo_child_items = relatedChildItems;

              items[key].combo_child_items.forEach((childItem) => {
                //check child items addons
                if (!!childItem.addonGroups.length || !!Object.keys(childItem.addonGroups).length) {
                  if (!Array.isArray(childItem.addonGroups)) {
                    childItem.addonGroups = Object.values(childItem.addonGroups);
                  }

                  childItem.addonGroups.forEach((addon) => {
                    if (!Array.isArray(addon.items)) {
                      addon.items = Object.values(addon.items);
                    }
                    addon.items.forEach((addonItem) => {
                      //validate the addon before marking as selected
                      order.items[i].combo_child_items.forEach((comboChildItem, index) => {
                        if (
                          comboChildItem.id === childItem.id &&
                          index + 1 === Number(childItem.group_id)
                        ) {
                          comboChildItem.addonGroups.forEach((addonGroup) => {
                            addonGroup.items.forEach((addon) => {
                              if (
                                (addon.id === addonItem.id &&
                                  addon["addon_group_id"] === addonItem.parentId) ||
                                (!addon["addon_group_id"] && addon.id === addonItem.id)
                              ) {
                                addonItem.isSelected = true;
                                selectedOptions.push(addonItem);

                                //check for nested addon modifiers
                                if (!!addonItem.modifierGroups.length) {
                                  addonItem.modifierGroups.forEach((nestedMod) => {
                                    nestedMod.items.forEach((nestedModItem) => {
                                      addon.modifierGroups.forEach((modifierGroup) => {
                                        modifierGroup.items.forEach((modifier) => {
                                          if (modifier.id === nestedModItem.id) {
                                            nestedModItem.isSelected = true;
                                            selectedOptions.push(nestedModItem);
                                          }
                                        });
                                      });
                                    });
                                  });
                                }
                              }
                            });
                          });
                        }
                      });
                    });
                  });
                }

                //check child itemds mods
                if (
                  !!childItem.modifierGroups.length ||
                  !!Object.keys(childItem.modifierGroups).length
                ) {
                  if (!Array.isArray(childItem.modifierGroups)) {
                    childItem.modifierGroups = Object.values(childItem.modifierGroups);
                  }

                  childItem.modifierGroups.forEach((mod) => {
                    if (!Array.isArray(mod.items)) {
                      mod.items = Object.values(mod.items);
                    }
                    mod.items.forEach((modItem) => {
                      order.items[i].combo_child_items.forEach((comboChildItem, index) => {
                        if (
                          comboChildItem.id === childItem.id &&
                          index + 1 === Number(childItem.group_id)
                        ) {
                          comboChildItem.modifierGroups.forEach((modifierGroup) => {
                            modifierGroup.items.forEach((modifier) => {
                              if (
                                modifier.id === modItem.id &&
                                modifier["modifier_id"] === modItem.parentId
                              ) {
                                modItem.isSelected = true;
                                selectedOptions.push(modItem);
                              }
                            });
                          });
                        }
                      });
                    });
                  });
                }
              });
            }

            invalidOrderItems--;

            items[key].entryKey = Date.now() + "-" + i;
            items[key].quantity = 1;

            if (
              reOrderItems.filter((tempItem) => tempItem.id === items[key].id).length <
              order.items.filter((tempItem) => tempItem.id === items[key].id).length
            ) {
              reOrderItems.push(jsonCopy(items[key]));
            }

            selectedOptions.forEach((option) => {
              delete option.isSelected;
            });
            selectedOptions = [];
          }
        }
      }
    } else {
      for (let subcatKey in subcategories) {
        const items = subcategories[subcatKey].items;

        for (let key in items) {
          for (let i = 0; i < order.items.length; i++) {
            if (order.items[i].id === items[key].id) {
              //check the modifiers
              if (order.items[i].modifiers && items[key].modifierGroups) {
                order.items[i].modifiers.forEach((modifier) => {
                  for (let keyReorder in modifier.items) {
                    for (let keyStoredGroup in items[key].modifierGroups) {
                      for (let keyStoredOption in items[key].modifierGroups[keyStoredGroup].items) {
                        if (
                          items[key].modifierGroups[keyStoredGroup].items[keyStoredOption].id ===
                            modifier.items[keyReorder].id &&
                          items[key].modifierGroups[keyStoredGroup].items[keyStoredOption]
                            .parentId === modifier.items[keyReorder]["modifier_id"]
                        ) {
                          items[key].modifierGroups[keyStoredGroup].items[
                            keyStoredOption
                          ].isSelected = true;

                          selectedOptions.push(
                            items[key].modifierGroups[keyStoredGroup].items[keyStoredOption]
                          );
                        }
                      }
                    }
                  }
                });
              }

              //check the addons
              if (order.items[i].addons && items[key].addonGroups) {
                order.items[i].addons.forEach((addon) => {
                  for (let keyReorder in addon.items) {
                    for (let keyStoredGroup in items[key].addonGroups) {
                      for (let keyStoredOption in items[key].addonGroups[keyStoredGroup].items) {
                        /** Note: if an addon from the order history does not have "addon_group_id", it is a single addon item */

                        if (
                          (items[key].addonGroups[keyStoredGroup].items[keyStoredOption].id ===
                            addon.items[keyReorder].id &&
                            items[key].addonGroups[keyStoredGroup].items[keyStoredOption]
                              .parentId === addon.items[keyReorder]["addon_group_id"]) ||
                          (!addon.items[keyReorder]["addon_group_id"] &&
                            addon.items[keyReorder].id ===
                              items[key].addonGroups[keyStoredGroup].items[keyStoredOption].id)
                        ) {
                          items[key].addonGroups[keyStoredGroup].items[
                            keyStoredOption
                          ].isSelected = true;

                          selectedOptions.push(
                            items[key].addonGroups[keyStoredGroup].items[keyStoredOption]
                          );

                          //must handle nested modifiers here
                          if (
                            addon.items[keyReorder].modifierGroups &&
                            addon.items[keyReorder].modifierGroups.length > 0
                          ) {
                            const orderNestedModifierGroups =
                              addon.items[keyReorder].modifierGroups;
                            orderNestedModifierGroups.forEach((nestedModifierGroup) => {
                              const nestedModifiers = nestedModifierGroup.items;
                              nestedModifiers.forEach((nestedModifier) => {
                                const itemAddonNestedModifierGroups =
                                  items[key].addonGroups[keyStoredGroup].items[keyStoredOption]
                                    .modifierGroups;
                                for (let nesterModifierGroupKey in itemAddonNestedModifierGroups) {
                                  const nestedModifiers =
                                    itemAddonNestedModifierGroups[nesterModifierGroupKey].items;
                                  for (let nestedModifierKey in nestedModifiers) {
                                    const itemNestedModifier = nestedModifiers[nestedModifierKey];
                                    if (itemNestedModifier.id === nestedModifier.id) {
                                      itemNestedModifier.isSelected = true;
                                      selectedOptions.push(itemNestedModifier);
                                    }
                                  }
                                }
                              });
                            });
                          }
                        }
                      }
                    }
                  }
                });
              }

              //check if item is combo, if so add its child items
              if (order.items[i].combo_child_items && items[key].type === "comboItem") {
                /** to handle combo items correctly
                 * we need to pull all the combo child items from api 1301
                 * then compare all the combo child items to the order object and pick only the relative ones (ordered previously)
                 */
                const allComboChildren = await getFullComboData(
                  items[key],
                  appLanguage,
                  storeId,
                  orderType,
                  orderTimeContext,
                  skin,
                  menuLevel,
                  foodMenu
                );

                let relatedChildItems = [];
                allComboChildren.forEach((childItem) => {
                  order.items[i].combo_child_items.forEach((orderedComboChildItem, index) => {
                    if (items[key].comboType === "standard") {
                      //Only add the child item if it does not exist from the same group id
                      if (
                        childItem.id === orderedComboChildItem.id &&
                        parseInt(childItem.group_id) === parseInt(index + 1) &&
                        relatedChildItems.filter(
                          (tempItem) => tempItem.group_id === childItem.group_id
                        ).length === 0
                      ) {
                        relatedChildItems.push(childItem);
                      }
                    } else {
                      if (childItem.id === orderedComboChildItem.id) {
                        if (
                          relatedChildItems.filter((item) => item.id === childItem.id).length <
                            order.items[i].combo_child_items.filter(
                              (item) => item.id === childItem.id
                            ).length &&
                          relatedChildItems.filter((item) => item.group_id === childItem.group_id)
                            .length < Number(childItem.groupMinQty)
                        ) {
                          relatedChildItems.push(childItem);
                        }
                      }
                    }
                  });
                });

                items[key].combo_child_items = relatedChildItems;

                items[key].combo_child_items.forEach((childItem) => {
                  //check child items addons
                  if (
                    !!childItem.addonGroups.length ||
                    !!Object.keys(childItem.addonGroups).length
                  ) {
                    if (!Array.isArray(childItem.addonGroups)) {
                      childItem.addonGroups = Object.values(childItem.addonGroups);
                    }

                    childItem.addonGroups.forEach((addon) => {
                      if (!Array.isArray(addon.items)) {
                        addon.items = Object.values(addon.items);
                      }
                      addon.items.forEach((addonItem) => {
                        //validate the addon before marking as selected
                        order.items[i].combo_child_items.forEach((comboChildItem, index) => {
                          if (
                            comboChildItem.id === childItem.id &&
                            index + 1 === Number(childItem.group_id)
                          ) {
                            comboChildItem.addonGroups.forEach((addonGroup) => {
                              addonGroup.items.forEach((addon) => {
                                if (
                                  (addon.id === addonItem.id &&
                                    addon["addon_group_id"] === addonItem.parentId) ||
                                  (!addon["addon_group_id"] && addon.id === addonItem.id)
                                ) {
                                  addonItem.isSelected = true;
                                  selectedOptions.push(addonItem);

                                  //check for nested addon modifiers
                                  if (!!addonItem.modifierGroups.length) {
                                    addonItem.modifierGroups.forEach((nestedMod) => {
                                      nestedMod.items.forEach((nestedModItem) => {
                                        addon.modifierGroups.forEach((modifierGroup) => {
                                          modifierGroup.items.forEach((modifier) => {
                                            if (modifier.id === nestedModItem.id) {
                                              nestedModItem.isSelected = true;
                                              selectedOptions.push(nestedModItem);
                                            }
                                          });
                                        });
                                      });
                                    });
                                  }
                                }
                              });
                            });
                          }
                        });
                      });
                    });
                  }

                  //check child itemds mods
                  if (
                    !!childItem.modifierGroups.length ||
                    !!Object.keys(childItem.modifierGroups).length
                  ) {
                    if (!Array.isArray(childItem.modifierGroups)) {
                      childItem.modifierGroups = Object.values(childItem.modifierGroups);
                    }
                    childItem.modifierGroups.forEach((mod) => {
                      if (!Array.isArray(mod.items)) {
                        mod.items = Object.values(mod.items);
                      }
                      mod.items.forEach((modItem) => {
                        order.items[i].combo_child_items.forEach((comboChildItem, index) => {
                          if (
                            comboChildItem.id === childItem.id &&
                            index + 1 === Number(childItem.group_id)
                          ) {
                            comboChildItem.modifierGroups.forEach((modifierGroup) => {
                              modifierGroup.items.forEach((modifier) => {
                                if (
                                  modifier.id === modItem.id &&
                                  modifier["modifier_id"] === modItem.parentId
                                ) {
                                  modItem.isSelected = true;
                                  selectedOptions.push(modItem);
                                }
                              });
                            });
                          }
                        });
                      });
                    });
                  }
                });
              }

              invalidOrderItems--;

              items[key].entryKey = Date.now() + "-" + i;
              items[key].quantity = 1;

              if (
                reOrderItems.filter((tempItem) => tempItem.id === items[key].id).length <
                order.items.filter((tempItem) => tempItem.id === items[key].id).length
              ) {
                reOrderItems.push(jsonCopy(items[key]));
              }

              selectedOptions.forEach((option) => {
                delete option.isSelected;
              });
              selectedOptions = [];
            }
          }
        }
      }
    }
  }

  if (invalidOrderItems <= 0 || (invalidOrderItems > 0 && invalidOrderItems < order.items.length)) {
    return cleanUpItemsListBasedOnQuantity(reOrderItems, isAddonQuantityEnabled, order);
  } else {
    return [];
  }
};

export const sortObjectKeysAlphabetically = (unsortedObject) => {
  let sorted = Object.keys(unsortedObject)
    .sort()
    .reduce(function (acc, key) {
      acc[key] = unsortedObject[key];
      return acc;
    }, {});

  return sorted;
};

export const handleModifyClick = async (skin, item, orderMenu) => {
  await localforage.setItem(skin + "__activeItemForModifying", JSON.stringify(item));

  const itemCopy = { ...item };

  if (!item.category && !item.subcategory) {
    Object.keys(orderMenu.apiData).forEach((categoryKey) => {
      const category = orderMenu.apiData[categoryKey];

      if (category.type.toLowerCase() === "category") {
        Object.keys(category.subcategories).forEach((subcategoryKey) => {
          const subcategory = category.subcategories[subcategoryKey];

          Object.keys(subcategory.items).forEach((itemKey) => {
            const orderMenuItem = subcategory.items[itemKey];

            if (item.id === orderMenuItem.id) {
              itemCopy.category = category.id;
              itemCopy.subcategory = subcategory.id;
            }
          });
        });
      } else if (category.type.toLowerCase() === "subcategory") {
        Object.keys(category.items).forEach((itemKey) => {
          const orderMenuItem = category.items[itemKey];

          if (item.id === orderMenuItem.id) {
            itemCopy.subcategory = category.id;
          }
        });
      }
    });
  }

  let pathname =
    itemCopy.hasOwnProperty("combo_child_items") && itemCopy["combo_child_items"].length > 0
      ? itemCopy.category && itemCopy.category !== "None"
        ? `/online-ordering/menu/category/${itemCopy.category}/subcategory/${itemCopy.subcategory}/combo/${itemCopy.id}`
        : `/online-ordering/menu/subcategory/${itemCopy.subcategory}/combo/${itemCopy.id}`
      : itemCopy.category && itemCopy.category !== "None"
      ? `/online-ordering/menu/category/${itemCopy.category}/subcategory/${itemCopy.subcategory}/item/${itemCopy.id}`
      : `/online-ordering/menu/subcategory/${itemCopy.subcategory}/item/${itemCopy.id}`;

  return pathname;
};

export const updateItemQuantity = (isAdding, item, itemMaxQuantity, cartData) => {
  let currentQuantity = item.quantity;
  if (isAdding) {
    currentQuantity++;
    if (itemMaxQuantity !== "" && currentQuantity >= itemMaxQuantity) {
      currentQuantity = itemMaxQuantity;
    }
  } else {
    currentQuantity--;
    if (currentQuantity <= 1) {
      currentQuantity = 1;
    }
  }

  const items = cartData.value || [];
  const index = getCartItemByKey(item.entryKey, items).index;
  const newCart = [...items];
  const newItem = jsonCopy(item);

  newItem.entryKey = Date.now();
  newItem.quantity = currentQuantity;
  newCart[index] = newItem;

  cartData.updateCart(newCart);
};

/**
 *
 * @param {*} isAdding flag to determine if quantity is being increased or decreased
 * @param {*} comboChildItem combo child item to be modified
 * @param {*} itemMaxQuantity max quantity allowed for the combo child item
 * @param {*} comboCartItem  object which holds the combo selection data
 */
export const updateComboChildItemQuantity = (
  isAdding,
  comboChildItem,
  itemMaxQuantity,
  comboCartItem,
  removalAllowed = false,
  isQuantityCombo
) => {
  let currentQuantity = comboChildItem.quantity || 1;
  if (isAdding) {
    currentQuantity++;
    if (itemMaxQuantity !== "" && currentQuantity >= itemMaxQuantity) {
      currentQuantity = itemMaxQuantity;
    }
  } else {
    currentQuantity--;
    if (currentQuantity <= 1) {
      if (removalAllowed) {
        if (currentQuantity < 1) {
          currentQuantity = 0;
          return removeComboChildFromCombo(comboChildItem, comboCartItem, isQuantityCombo);
        }
      } else {
        currentQuantity = 1;
      }
    }
  }
  const comboChildItemCopy = jsonCopy(comboChildItem);
  const comboCartItemCopy = jsonCopy(comboCartItem);

  comboChildItemCopy.quantity = currentQuantity;
  let index;
  if (isQuantityCombo) {
    index = comboCartItem.combo_child_items.findIndex(
      (item) =>
        item.id === comboChildItem.id &&
        item.group_id === comboChildItem.group_id &&
        item.entryKey === comboChildItem.entryKey
    );
  } else {
    index = comboCartItem.combo_child_items.findIndex(
      (item) => item.id === comboChildItem.id && item.group_id === comboChildItem.group_id
    );
  }
  comboCartItemCopy.combo_child_items[index] = comboChildItemCopy;
  comboCartItemCopy["updateComboCartItem"] = comboCartItem["updateComboCartItem"];
  comboCartItem.updateComboCartItem(comboCartItemCopy);

  return { itemUpdated: true };
};

export const removeComboChildFromCombo = async (comboChildItem, comboCartItem, isQuantityCombo) => {
  const comboCartItemCopy = jsonCopy(comboCartItem);

  let index;
  if (isQuantityCombo) {
    index = comboCartItem.combo_child_items.findIndex(
      (item) =>
        item.id === comboChildItem.id &&
        item.group_id === comboChildItem.group_id &&
        JSON.stringify(item) === JSON.stringify(comboChildItem)
    );
  } else {
    index = comboCartItem.combo_child_items.findIndex(
      (item) => item.id === comboChildItem.id && item.group_id === comboChildItem.group_id
    );
  }

  comboCartItemCopy.combo_child_items.splice(index, 1);
  comboCartItemCopy["updateComboCartItem"] = comboCartItem["updateComboCartItem"];
  comboCartItem.updateComboCartItem(comboCartItemCopy);
  return { itemRemoved: true };
};

/**
 *
 * @param {*} comboChildItem the combo child item to be added
 * @param {*} comboCartItem the object holding the combo choice data
 * @param {*} foodMenu food menu context
 * @param {*} isQuantityCombo flag to determine if it is a quantity combo or not
 * @returns
 */
export const addComboChildToCombo = async (
  comboChildItem,
  comboCartItem,
  foodMenu,
  isQuantityCombo = true
) => {
  const cartItem = { ...comboChildItem };

  cartItem.category = foodMenu.activeTopCategory;
  cartItem.subcategory = foodMenu.activeSubcategory;
  cartItem.quantity = comboChildItem.quantity || 1;
  cartItem.entryKey = Date.now();

  if (comboCartItem.combo_child_items.length === 0) {
    comboCartItem.combo_child_items.push(cartItem);
    comboCartItem.combo_child_items.sort(dynamicSort("group_id"));
    comboCartItem.updateComboCartItem({ ...comboCartItem });
  } else {
    let removableElement = null;

    if (!isQuantityCombo) {
      //if the current subgroup already has an item, remove it and add the new one.
      comboCartItem.combo_child_items.forEach((element, index) => {
        if (element.group_id === cartItem.group_id) {
          removableElement = index;
        }
      });
    }

    if (removableElement !== null) {
      comboCartItem.combo_child_items.splice(removableElement, 1);
    }

    /** if another instance of this item already exist in comboCartItem, combine and update the quantity */
    let combinedQuantity = 0;
    let identicalItemsInSameSubGroup = comboCartItem.combo_child_items.filter((item) => {
      const itemCopy = jsonCopy(item);
      delete itemCopy.entryKey;
      delete itemCopy.quantity;
      delete itemCopy.total_addon_discount;

      const cartItemCopy = jsonCopy(cartItem);
      delete cartItemCopy.entryKey;
      delete cartItemCopy.quantity;
      delete cartItemCopy.total_addon_discount;
      return (
        itemCopy.id === cartItem.id &&
        itemCopy.group_id === cartItem.group_id &&
        JSON.stringify(sortObjectKeysAlphabetically(itemCopy)) ===
          JSON.stringify(sortObjectKeysAlphabetically(cartItemCopy))
      );
    });
    if (identicalItemsInSameSubGroup.length > 0) {
      combinedQuantity = identicalItemsInSameSubGroup[0].quantity || 1;
      combinedQuantity++;

      comboCartItem.combo_child_items.splice(
        comboCartItem.combo_child_items.indexOf(identicalItemsInSameSubGroup[0]),
        1
      );
    } else {
      combinedQuantity = 1;
    }

    cartItem.quantity = combinedQuantity;
    comboCartItem.combo_child_items.push(cartItem);
    comboCartItem.combo_child_items.sort(dynamicSort("group_id"));
    comboCartItem.updateComboCartItem({ ...comboCartItem });
  }

  return { itemAdded: true };
};

/**
 *
 * @param {*} itemsList : list of combo child items within a combo selection
 * @returns the sum of item quantities within itemsList
 */
export const getTotalComboChildQuantity = (itemsList) => {
  let totalQuantity = 0;
  itemsList.forEach((element) => {
    totalQuantity += element.quantity || 1;
  });
  return totalQuantity;
};

/**
 *
 * @param {*} itemsList - list of items to be cleaned up based on quantity
 * @returns a new items list groups and with updated quantity
 */
export const cleanUpItemsListBasedOnQuantityForCart = async (
  itemsList,
  cartContext,
  isAddonQuantityEnabled
) => {
  let itemsListCopy = jsonCopy(itemsList);
  //when dealing with discounts, exclude the discount_amount since the amount could differ for each item even if the discount id is the same
  itemsListCopy.forEach((tempItem) => {
    delete tempItem.total_addon_discount;
    if (!!tempItem.discounts) {
      tempItem.discounts.forEach((discount) => {
        delete discount.discount_amount;
      });

      if (!tempItem.combo_child_items || tempItem.combo_child_items.length === 0) {
        Object.keys(tempItem.addonGroups).forEach((addonGroupKey) => {
          const addonGroupItems = tempItem.addonGroups[addonGroupKey].items;
          Object.keys(addonGroupItems).forEach((addonItemKey) => {
            if (!!addonGroupItems[addonItemKey].discounts) {
              addonGroupItems[addonItemKey].discounts.forEach((discount) => {
                delete discount.discount_amount;
              });
            }
          });
        });
      }
    }
  });

  let catSubcatData = [];

  //Clean up the addon groups and remove unnecessary keys
  itemsListCopy.forEach((tempItem) => {
    delete tempItem.quantity;
    delete tempItem.entryKey;
    catSubcatData.push({
      id: tempItem.id,
      category: tempItem.category,
      subcategory: tempItem.subcategory,
      parentId: tempItem.parentId,
    });

    delete tempItem.category;
    delete tempItem.subcategory;
    delete tempItem.parentId;

    if (tempItem.addonGroups) {
      let addonGroups = jsonCopy(tempItem.addonGroups);
      Object.keys(addonGroups).forEach((addonGroupkey) => {
        Object.keys(addonGroups[addonGroupkey].items).forEach((addonItemKey) => {
          let addon = addonGroups[addonGroupkey].items[addonItemKey];
          if (addon) {
            delete addon.isFirstInstanceDefault;
            if (!addon.isSelected) {
              delete addon.quantity;
              delete addon.addonQuantity;
            } else {
              addon.addonQuantity = Number(addon.quantity || 1);
            }
          }
        });
      });
      tempItem.addonGroups = addonGroups;
    }
  });

  //calculate quantity
  itemsListCopy.forEach((tempItem) => {
    tempItem.quantity = getOccurrence(
      itemsListCopy,
      tempItem.id,
      JSON.stringify(tempItem.discounts),
      JSON.stringify(tempItem.addonGroups),
      JSON.stringify(tempItem.modifierGroups),
      tempItem.total_addon_discount,
      JSON.stringify(tempItem.combo_child_items),
      tempItem.specialInstructions
    );
  });

  function sortedStringify(item) {
    const orderedItem = Object.keys(item)
      .sort()
      .reduce((obj, key) => {
        obj[key] = item[key];
        return obj;
      }, {});

    return JSON.stringify(orderedItem);
  }

  //remove duplicates from itemsList
  itemsListCopy = removeDuplicates(itemsListCopy, (item) => sortedStringify(item));

  //assign new entry keys
  itemsListCopy.forEach((tempItem, index) => {
    tempItem.entryKey = Date.now() + index;
  });

  /** Consider the items already existing in the cart  */
  if (cartContext && itemsList.length > 0) {
    let currentCart = cartContext.value || [];

    currentCart.forEach((item) => {
      //Clean up the addon groups and remove unnecessary keys
      if (item.addonGroups) {
        let addonGroups = jsonCopy(item.addonGroups);
        Object.keys(addonGroups).forEach((addonGroupkey) => {
          Object.keys(addonGroups[addonGroupkey].items).forEach((addonItemKey) => {
            let addon = addonGroups[addonGroupkey].items[addonItemKey];
            if (addon) {
              delete addon.isFirstInstanceDefault;
              if (!addon.isSelected) {
                delete addon.quantity;
                delete addon.addonQuantity;
              } else {
                addon.addonQuantity = Number(addon.quantity || 1);
              }
            }
          });
        });
        item.addonGroups = addonGroups;
      }

      if (
        itemsListCopy.filter(
          (tempItem) =>
            tempItem.id === item.id &&
            JSON.stringify(tempItem.modifierGroups) === JSON.stringify(item.modifierGroups) &&
            JSON.stringify(tempItem.addonGroups) === JSON.stringify(item.addonGroups) &&
            JSON.stringify(tempItem.combo_child_items) === JSON.stringify(item.combo_child_items)
        ).length === 0
      ) {
        itemsListCopy.push(item);
      }
    });

    const listOfKeysToIgnore = [
      "quantity",
      "entryKey",
      "isFirstInstanceDefault",
      "positioningTemplateIndex",
      "type",
      "category",
      "subcategory",
      "parentId",
    ];

    const consolidatedList = consolidateAndSumQuantities(
      itemsListCopy,
      listOfKeysToIgnore,
      isAddonQuantityEnabled
    );

    consolidatedList.forEach((consolidatedItem) => {
      catSubcatData.forEach((catSubcatItem) => {
        if (consolidatedItem.id === catSubcatItem.id) {
          consolidatedItem.category = catSubcatItem.category;
          consolidatedItem.subcategory = catSubcatItem.subcategory;
          consolidatedItem.parentId = catSubcatItem.parentId;
        }
      });
    });

    await cartContext.updateCart(consolidatedList);
  }
  return itemsListCopy;
};

/**
 *
 * @param {Array} arr (required): An array of objects to be consolidated.
 * @param {Array} ignoreKeys (optional): An array of keys to ignore when trying to find duplicates.
 * @returns An array of objects that are the consolidated versions of the input objects, with identical objects merged and their "quantity" values summed.
 */
export const consolidateAndSumQuantities = (arr, ignoreKeys = []) => {
  const consolidatedArr = [];
  const lookupTable = {};

  function stringifyObject(obj) {
    const keys = Object.keys(obj)
      .filter((key) => !ignoreKeys.includes(key))
      .sort();
    const values = keys.map((key) => {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        return stringifyObject(obj[key]);
      }
      return obj[key];
    });
    return JSON.stringify(keys.concat(values));
  }

  arr.forEach((obj) => {
    const key = stringifyObject(obj);
    if (lookupTable[key]) {
      lookupTable[key].quantity += obj.quantity;
    } else {
      lookupTable[key] = obj;
      consolidatedArr.push(obj);
    }
  });

  return consolidatedArr;
};

/**
 * Recursively removes objects from one array that exist in another array.
 *
 * @param {Array} originalArray - The original array to remove objects from.
 * @param {Array} itemsToRemove - The array of items to remove from the original array.
 * @param {Function} [equalityFn] - (Optional) The function used to compare items for equality. If not specified, the strict equality operator (===) will be used.
 * @return {Array} Returns a new array with the specified items removed.
 */
export const removeItemsFromArray = (
  originalArray,
  itemsToRemove,
  equalityFn = (a, b) => a === b
) => {
  return originalArray.filter(
    (item) => !itemsToRemove.some((removeItem) => equalityFn(item, removeItem))
  );
};

/**
 * Recursively compares two objects for equality of nested properties.
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @param {Function} equalityFn - The function used to compare properties for equality.
 * @return {Boolean} Returns true if the two objects are equal, false otherwise.
 */
export const compareNestedProperties = (obj1, obj2, equalityFn) => {
  // Check if both objects are null or undefined
  if (obj1 === null || obj1 === undefined) {
    return obj2 === null || obj2 === undefined;
  }
  if (obj2 === null || obj2 === undefined) {
    return false;
  }

  // Check if the objects have the same type
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // Check if the objects are primitives
  if (typeof obj1 !== "object") {
    return equalityFn(obj1, obj2);
  }

  // Check if the objects are arrays
  if (Array.isArray(obj1)) {
    if (!Array.isArray(obj2) || obj1.length !== obj2.length) {
      return false;
    }
    return obj1.every((item, index) => compareNestedProperties(item, obj2[index], equalityFn));
  }

  // Compare properties recursively
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }
  return obj1Keys.every((key) => compareNestedProperties(obj1[key], obj2[key], equalityFn));
};

/**
 * The `separateItemsList` function takes a list of items as input, where each
 * item is an object that contains various properties including a 'quantity'
 * property. It returns a new list where each item is duplicated based on
 * its quantity property, and each duplicated item has a quantity of 1.
 *
 * @param {Array<Object>} itemsList - The list of items to separate.
 *    Each item is an object and should have a 'quantity' property indicating
 *    how many times it should be duplicated in the separated list. If the quantity
 *    property is missing, it is assumed to be 1.
 *    It's worth mentioning that the 'entryKey' property will be deleted from the objects.
 *
 * @returns {Array<Object>} A new list of items where each item is duplicated
 *    based on its quantity property and each duplicated item has a quantity of 1.
 *
 * @function
 */
export const separateItemsList = (itemsList) => {
  let separatedList = [];
  itemsList.forEach((item) => {
    let quantity = item.quantity || 1;
    for (let i = 0; i < quantity; i++) {
      let itemCopy = jsonCopy(item);
      delete itemCopy.entryKey;
      delete itemCopy.quantity;
      itemCopy.quantity = 1;
      separatedList.push(itemCopy);
    }
  });

  return separatedList;
};

/**
 *
 * @param {Number String} itemId - id of the item in question
 * @param {Object} menu - object containing the menu data
 * @returns the full item object from the menu, containing all the info related to an item
 */
export const getfavItemInfoFromMenu = (itemId, menu) => {
  const isThreeLevelMenu = menu.menuLevel === "threeLevels";
  const menuData = menu.data;
  let foundItem;
  if (isThreeLevelMenu) {
    //category based
    Object.keys(menuData).forEach((catKey) => {
      const subcategories = menuData[catKey].subcategories;
      Object.keys(subcategories).forEach((subcatKey) => {
        const items = subcategories[subcatKey].items;
        Object.keys(items).forEach((itemKey) => {
          if (items[itemKey].id === itemId) {
            foundItem = items[itemKey];
          }
        });
      });
    });
  } else {
    //subcateogry based
    Object.keys(menuData).forEach((subcatKey) => {
      const items = menuData[subcatKey].items;
      Object.keys(items).forEach((itemKey) => {
        if (items[itemKey].id === itemId) {
          foundItem = items[itemKey];
        }
      });
    });
  }

  return foundItem;
};

export const isOutOfStock = (item) => {
  return item["item_count"] !== "None" && Number(item["item_count"]) <= 0;
};
