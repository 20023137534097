import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";

//Helpers
import { formatTipConfig, getCartSubtotal } from "../../../_common/CartHelpers";
import { getCurrencySymbol, toDollars } from "../../../_common/helpers";

//Contexts
import OrderTypeContext from "../../OrderTypeContext";
import StoreContext from "../../StoreContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import CurrencyContext from "../../../App/CurrencyContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

//UI Components
import { TipButton } from "./TipButton";

//Assets
import { ReactComponent as IconXInCircle } from "../../../_common/icons/IconXInCircle.svg";

// CSS
import "./Tip.css";

export const Tip = (props) => {
  const {
    orderSummaryData,
    skin,
    updateStoredTipAmount,
    isCollapsed,
    tipHeader,
    isDeliveryIntegration,
  } = props;

  const appSettings = useContext(AppSettingsContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const storeContext = useContext(StoreContext);
  const orderTypeContext = useContext(OrderTypeContext);
  const currencyContext = useContext(CurrencyContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const isCurrencyExchangeEnabled =
    merchantConfigContext.merchant.I59 && merchantConfigContext.merchant.I59.length > 0;

  const activeOrderStore = storeContext.activeOrderStore;
  const vexilorConfig = activeOrderStore.vexilorConfig;
  const activeOrderType = orderTypeContext.value;

  const [courierTipAmount, setCourierTipAmount] = useState(0);

  const tipConfig =
    isDeliveryIntegration && !!vexilorConfig["delivery_tips_config"]
      ? vexilorConfig["delivery_tips_config"]
      : vexilorConfig["tips_config"];
  const formattedTipConfig = formatTipConfig(
    tipConfig,
    appLabels,
    activeOrderType,
    isDeliveryIntegration
  );

  const countryCode = merchantConfigContext.merchant.I58;
  const [activeTip, setActiveTip] = useState(null); //tracks the current active tip
  const [customTipAmount, setCustomTipAmount] = useState(""); //tacks the custom tip amount (if any)

  const updateCustomTipAmount = (value) => {
    setCustomTipAmount(value);
  };
  const updateActiveTip = (tipObject) => {
    setActiveTip(tipObject);

    if (tipObject.isCustomAmount) {
      updateCustomTipAmount(tipObject.displayName);
    } else {
      updateCustomTipAmount("");
      if (document.getElementById("customTipAmount")) {
        document.getElementById("customTipAmount").value = "";
      }
      if (document.getElementById("customTipAmountCourier")) {
        document.getElementById("customTipAmountCourier").value = "";
      }
    }
  };

  // on initial load, check if there is a stored Courier tip for delivery integration
  useEffect(() => {
    if (isDeliveryIntegration) {
      localforage.getItem(skin + "__storedCourierTip").then((storedTip) => {
        if (storedTip) {
          setCourierTipAmount(storedTip.tipAmount);
        } else {
          setCourierTipAmount(0);
        }
      });
    }
  }, []);
  const [totalAmountWithCurrencyConversion, setTotalAmountWithCurrencyConversion] = useState(0);

  //setup the active tip
  useEffect(() => {
    if (orderSummaryData && orderSummaryData !== "reset" && activeTip === null) {
      //if there is any stored tip set that as active tip, else select the default tip
      let key = isDeliveryIntegration ? "__storedCourierTip" : "__storedTip";

      localforage.getItem(skin + key).then((storedTip) => {
        if (storedTip) {
          const tipIndex = storedTip.index;
          if (tipIndex === "custom") {
            //handle custom tips
            updateActiveTip(storedTip);
            updateStoredTipAmount(orderSummaryData, storedTip, isDeliveryIntegration);
          } else {
            onClickTipButton(tipIndex, totalAmountWithCurrencyConversion);
          }
        } else {
          const defaultTip = formattedTipConfig.percentages.filter((tip) => tip.isDefault)[0];
          // if there is no default found due to invalid BE setup, fallback to 'no tip'
          onClickTipButton(defaultTip ? defaultTip.index : 0, totalAmountWithCurrencyConversion);
        }
      });
    }
  }, [orderSummaryData, activeTip, totalAmountWithCurrencyConversion]);

  useEffect(() => {
    if (orderSummaryData && orderSummaryData !== "reset") {
      if (
        isCurrencyExchangeEnabled &&
        currencyContext.currencyData[0] !== merchantConfigContext.merchant.I34
      ) {
        let orderTotal = orderSummaryData.orderTotal;
        let convertedOrderTotal = orderTotal * currencyContext.currencyData[1];
        if (!!orderSummaryData.tipAmount) {
          if (
            document.getElementById("customTipAmount") &&
            !!document.getElementById("customTipAmount").value
          ) {
            convertedOrderTotal += Number(orderSummaryData.tipAmount);
          } else {
            convertedOrderTotal +=
              Number(orderSummaryData.tipAmount) * currencyContext.currencyData[1];
          }
          setTotalAmountWithCurrencyConversion(convertedOrderTotal);
        }
      }
    }
  }, [orderSummaryData]);

  const onClickTipButton = (index, totalAmountWithCurrencyConversion) => {
    const selectedTip = formattedTipConfig.percentages[index];
    updateActiveTip(selectedTip);

    let subtotal = isDeliveryIntegration
      ? totalAmountWithCurrencyConversion > 0
        ? totalAmountWithCurrencyConversion
        : orderSummaryData.orderTotal +
          (orderSummaryData.tipAmount ? Number(orderSummaryData.tipAmount) : 0)
      : getCartSubtotal(orderSummaryData);

    const tipAmount = selectedTip.calcPercentage * subtotal;
    const displayPrice = parseFloat(tipAmount).toLocaleString(appLanguage, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const numericalValue = parseFloat(tipAmount).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const tipObjectToBeStored = {
      ...selectedTip,
      tipAmount: numericalValue,
      tipDisplayPrice: displayPrice,
    };

    if (isDeliveryIntegration) {
      setCourierTipAmount(numericalValue);
    }
    updateStoredTipAmount(orderSummaryData, tipObjectToBeStored, isDeliveryIntegration);
  };

  const applyCustomTipAmount = (value) => {
    if (!!value) {
      const tipAmount = parseFloat(value);
      const displayPrice = parseFloat(
        parseFloat(tipAmount).toLocaleString(appLanguage, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
      const tipObjectToBeStored = {
        tipAmount: displayPrice,
        index: "custom",
        displayName: tipAmount.toFixed(2),
        isCustomAmount: true,
        isDefault: false,
      };

      if (isDeliveryIntegration) {
        setCourierTipAmount(tipAmount);
      }
      updateActiveTip(tipObjectToBeStored);
      updateStoredTipAmount(orderSummaryData, tipObjectToBeStored, isDeliveryIntegration);
    }
  };

  const removeCustomTipAmount = () => {
    updateCustomTipAmount("");
    updateStoredTipAmount(orderSummaryData, {}, isDeliveryIntegration);
  };

  return (
    <>
      {activeTip && (
        <div
          className={`cart__tip-container ${
            isCollapsed
              ? " collapsable-section__collapsed-content"
              : " collapsable-section__expanded-content"
          }`}
        >
          {isDeliveryIntegration ? (
            <>
              {tipHeader && (
                <div className="cart__tip-title-wrapper">
                  <h2 className="cart__tip-title">
                    {tipHeader}{" "}
                    {toDollars(
                      currencyContext.currencyData[0] === merchantConfigContext.merchant.I34
                        ? `${appSettings["currency-symbol"] || "$"}`
                        : getCurrencySymbol(
                            currencyContext.currencyData[0],
                            `${appLanguage === "es-mx" ? "es" : appLanguage}-${countryCode}`
                          ),
                      appSettings["currency-symbol-side"],
                      courierTipAmount,
                      appLanguage,
                      true
                    )}
                  </h2>
                </div>
              )}
            </>
          ) : (
            <>{tipHeader && <h2 className="cart__tip-title">{tipHeader}</h2>}</>
          )}

          <div className="cart__tip-amounts">
            {formattedTipConfig &&
              formattedTipConfig.percentages.map((tip, index) => (
                <TipButton
                  label={tip.displayName}
                  isDefault={tip.isDefault}
                  percentage={tip.calcPercentage}
                  key={tip.displayName + "-" + index}
                  isSelected={tip.index === activeTip.index}
                  onClickTipButton={onClickTipButton}
                  totalAmountWithCurrencyConversion={totalAmountWithCurrencyConversion}
                  index={tip.index}
                />
              ))}
          </div>
          {formattedTipConfig.isCustomTipEnabled && (
            <div className="cart__tip-custom-amount-container">
              <div>
                <label
                  htmlFor={isDeliveryIntegration ? "customTipAmountCourier" : "customTipAmount"}
                  className="cart__tip-custom-amount-label"
                >
                  {appLabels["order"]["custom-amount"]}
                </label>
              </div>
              <div>
                <span className="cart__tip-custom-amount-currency-sign">
                  {isCurrencyExchangeEnabled && !window.location.href.includes("/gift-card")
                    ? `${
                        currencyContext.currencyData[0] === merchantConfigContext.merchant.I34
                          ? `${appSettings["currency-symbol"] || "$"}`
                          : getCurrencySymbol(
                              currencyContext.currencyData[0],
                              `${appLanguage === "es-mx" ? "es" : appLanguage}-${countryCode}`
                            )
                      }`
                    : `${appSettings["currency-symbol"] || "$"}`}
                </span>
                <input
                  aria-label={
                    appLabels["order"]["input-custom-tip-amount"] +
                    `${appSettings["currency-symbol"] || "$"}. ` +
                    appLabels["order"]["press-tab-to-add-tip"]
                  }
                  type="number"
                  min="0.00"
                  step="0.01"
                  id={isDeliveryIntegration ? "customTipAmountCourier" : "customTipAmount"}
                  className="cart__tip-custom-amount-input"
                  value={customTipAmount}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      applyCustomTipAmount(e.target.value);
                    }
                  }}
                  onChange={(e) => {
                    updateCustomTipAmount(e.target.value);
                  }}
                  onBlur={(e) => applyCustomTipAmount(e.target.value)}
                ></input>
                {activeTip.isCustomAmount && activeTip.tipAmount > 0 && (
                  <button
                    className="cart__tip-remove-custom-amount"
                    onClick={() => {
                      removeCustomTipAmount();
                      onClickTipButton(0);
                    }}
                    type="button"
                    aria-label={appLabels["order"]["remove-tip"]}
                  >
                    <IconXInCircle />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
