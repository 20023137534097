import { callAPI } from "../../_common/Api";
import localforage from "localforage";

export const getStore1306Config = async (skin, language, store) => {
  const reformattedStore = { ...store };

  try {
    let storedDeliveryAddress = await localforage.getItem(skin + "__userDeliveryAddress");

    const api1306Data = await callAPI(skin, "info_vxl_1306", {
      params: [
        language,
        "1306",
        "mqid",
        "mqpass",
        "OUTLET",
        store.storeId,
        "t",
        storedDeliveryAddress && storedDeliveryAddress.postal ? storedDeliveryAddress.postal : "",
      ],
      id: "1306",
    });

    if (api1306Data.result.I1 === "0") {
      const vexilorConfig = api1306Data.result.I2;

      let pickupIncrement = Number(vexilorConfig.pickup_inc_interval);
      let pickupMinInterval = Number(vexilorConfig.pickup_min_interval);

      if (Number(pickupIncrement) === 0) pickupIncrement = 15; // fallback increment
      if (Number(pickupMinInterval) === 0) pickupMinInterval = 30; // fallback min interval

      vexilorConfig.pickup_inc_interval = pickupIncrement;
      vexilorConfig.pickup_min_interval = pickupMinInterval;
      vexilorConfig.isUberDirectEnabled =
        !!vexilorConfig["delivery_integration"] &&
        vexilorConfig["delivery_integration"].includes("UBERDIRECT")
          ? true
          : false;

      reformattedStore.vexilorConfig = vexilorConfig;
      //
    } else if (api1306Data.error) {
      // 1306 API error
      console.error(
        `API 1306 | Outlet ID ${store.storeId}, ${store.name} | Error ${api1306Data.error.code} | ${api1306Data.error.message}`
      );
      reformattedStore["__isUnavailable"] = true;
      //
    }
  } catch (error) {
    // Generic fallback for the failed 1306 API call
    console.error(`API 1306 | Store level | ${error.message}`);
    reformattedStore["__isUnavailable"] = true;
  }

  return reformattedStore;
};
