import React, { useContext } from "react";
/** Contexts */
import AppLanguageContext from "../../App/AppLanguageContext";
import AppSettingsContext from "../../App/AppSettingsContext";

/** Helpers */
import { toDollars } from "../../_common/helpers";

export const RecentOrderOptionsList = (props) => {
  const { options, isAddonQuantityEnabled } = props;

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  return (
    <div className="recent-order__options">
      <ul className="recent-order__options-list">
        {options.map((option, optionIndex) =>
          option.items.map((optionItem, index) => (
            <React.Fragment key={optionItem.id + "-" + index}>
              <li className="recent-order__option">
                <span className="recent-order__option-item-name-price">
                  <span className="recent-order__option-item-name">
                    {" "}
                    {isAddonQuantityEnabled && <span>{optionItem.quantity || 1}x </span>}
                    {optionItem.name +
                      ((index < options.length - 1 && option.id) ||
                      (!option.id && optionIndex < options.length - 1)
                        ? ", "
                        : "")}
                  </span>
                  <span className="recent-order__option-item-price">
                    {optionItem.price > 0
                      ? `${toDollars(
                          appSettings["currency-symbol"],
                          appSettings["currency-symbol-side"],
                          optionItem.price,
                          appLanguage,
                          true
                        )}`
                      : ""}
                  </span>
                </span>

                {optionItem.modifierGroups && optionItem.modifierGroups.length > 0 && (
                  <ul className="recent-order__options-nested-list">
                    {optionItem.modifierGroups.map((nestedModifierGroup) =>
                      nestedModifierGroup.items.map((nestedMod, index) => (
                        <React.Fragment key={nestedMod.id + "-" + index}>
                          {" "}
                          <li>{nestedMod.name} </li>{" "}
                        </React.Fragment>
                      ))
                    )}
                  </ul>
                )}
              </li>
            </React.Fragment>
          ))
        )}
      </ul>
    </div>
  );
};
