import React, { useContext, useEffect, useState } from "react";
import localforage from "localforage";
import useWindowSize from "../../../_common/hooks/useWindowSize";

// Contexts
import AppLabelsContext from "../../../App/AppLabelsContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import StoreContext from "../../StoreContext";
import UserRoleContext from "../../../App/UserRoleContext";
import CurrencyContext from "../../../App/CurrencyContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

//Helpers
import { getCurrencySymbol, toDollars } from "../../../_common/helpers";

//UI Components
import { Tip } from "../Tip/Tip";
import { FormSubmit } from "../../../_common/Form/FormSubmit";
import { OrderTypeSpecialInstruction } from "../OrderTypeSpecialInstruction";
import IndividualLoginButton from "../IndividualLoginButton";

//Assets
import { ReactComponent as IconArrowDown } from "../../../_common/icons/IconArrowDown.svg";

//CSS
import "./UberDirectDeliverySettings.css";

const UberDirectDeliverySettings = (props) => {
  const { orderSummaryData, skin, updateStoredTipAmount, showCustomerInfo } = props;

  const deviceWidth = useWindowSize().width;

  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const storeContext = useContext(StoreContext);
  const userRoleContext = useContext(UserRoleContext);
  const currencyContext = useContext(CurrencyContext);
  const merchantConfigContext = useContext(MerchantConfigContext);

  const [orderTypeInstruction, setOrderTypeInstruction] = useState("");
  const [deliveryPreference, setDeliveryPreference] = useState("leave_at_door");
  const [cartTipAmount, setCartTipAmount] = useState("idle");

  const isDeliverySpecialInstructionEnabled =
    storeContext.activeOrderStore.vexilorConfig.delivery_special_instructions === "t";

  const isCurrencyExchangeEnabled =
    merchantConfigContext.merchant.I59 && merchantConfigContext.merchant.I59.length > 0;
  const countryCode = merchantConfigContext.merchant.I58;

  const onDeliveryPreferenceChange = (event) => {
    localforage.setItem(skin + "__deliveryPreference", event.target.value);
    setDeliveryPreference(event.target.value);
  };

  const updateOrderTypeSpecialInstructions = (value) => {
    setOrderTypeInstruction(value);
    localforage.setItem(skin + "__orderTypeSpecialInstruction", value);
  };

  useEffect(() => {
    localforage.setItem(skin + "__deliveryPreference", "leave_at_door");
  }, []);

  const [totalAmountWithCurrencyConversion, setTotalAmountWithCurrencyConversion] = useState(0);
  useEffect(() => {
    if (orderSummaryData && orderSummaryData !== "reset") {
      if (
        isCurrencyExchangeEnabled &&
        currencyContext.currencyData[0] !== merchantConfigContext.merchant.I34
      ) {
        let orderTotal = orderSummaryData.orderTotal;
        let convertedOrderTotal = orderTotal * currencyContext.currencyData[1];
        if (!!orderSummaryData.tipAmount) {
          if (
            document.getElementById("customTipAmount") &&
            !!document.getElementById("customTipAmount").value
          ) {
            convertedOrderTotal += Number(orderSummaryData.tipAmount);
          } else {
            convertedOrderTotal +=
              Number(orderSummaryData.tipAmount) * currencyContext.currencyData[1];
          }
          setTotalAmountWithCurrencyConversion(convertedOrderTotal);
        }
      }
    }
  }, [orderSummaryData, currencyContext]);

  useEffect(() => {
    localforage.getItem(skin + "__storedTip").then((tipObject) => {
      if (tipObject) {
        setCartTipAmount(Number(tipObject.tipAmount));
      } else {
        setCartTipAmount(orderSummaryData.tipAmount || 0);
      }
    });
  }, []);

  return (
    <>
      {userRoleContext.status === "guest" && (
        <IndividualLoginButton
          floatRight={true}
          customStyle={deviceWidth >= 660 ? { width: "70%" } : {}}
          noRightPadding={true}
        />
      )}
      <div className="uberdirect-delivery-settings-container">
        <h1 className="customer-info__form-heading">{appLabels["order"]["tip-your-courier"]}</h1>
        <p className="uberdirect-delivery-settings-message">
          {appLabels["order"]["tip-your-courier-blurb"].replace(
            "[order-total]",
            toDollars(
              isCurrencyExchangeEnabled && !window.location.href.includes("/gift-card")
                ? currencyContext.currencyData[0] === merchantConfigContext.merchant.I34
                  ? appSettings["currency-symbol"]
                  : getCurrencySymbol(
                      currencyContext.currencyData[0],
                      `${appLanguage === "es-mx" ? "es" : appLanguage}-${countryCode}`
                    )
                : appSettings["currency-symbol"],
              appSettings["currency-symbol-side"],
              totalAmountWithCurrencyConversion > 0
                ? totalAmountWithCurrencyConversion
                : orderSummaryData.orderTotal + cartTipAmount,
              appLanguage,
              totalAmountWithCurrencyConversion > 0 ? true : false
            )
          )}
        </p>

        {cartTipAmount !== "idle" && (
          <Tip
            orderSummaryData={{ ...orderSummaryData, tipAmount: cartTipAmount }}
            skin={skin}
            updateStoredTipAmount={updateStoredTipAmount}
            tipHeader={appLabels["order"]["courier-tip"]}
            isDeliveryIntegration={true}
          />
        )}

        <div className=" delivery-preference-wrapper form__field-wrapper">
          <label htmlFor={"delivery-preference"} className={`form__field-label`}>
            {appLabels["order"]["delivery-preference"]}
          </label>

          <select
            onChange={onDeliveryPreferenceChange}
            className="form__field"
            id={"delivery-preference"}
          >
            <option value={"leave_at_door"}>{appLabels["order"]["leave-at-door"]}</option>
            <option value={"meet_at_door"}>{appLabels["order"]["meet-at-door"]}</option>
          </select>
          <IconArrowDown />
        </div>
        <p className="delivery-preference-blurb">
          {deliveryPreference === "leave_at_door"
            ? appLabels["order"]["delivery-preference-blurb-leave-at-door"]
            : appLabels["order"]["delivery-preference-blurb-meet-at-door"]}
        </p>

        {isDeliverySpecialInstructionEnabled && (
          <OrderTypeSpecialInstruction
            label={appLabels["form"]["special-delivery-instructions-field-label"]}
            placeholder={appLabels["order"]["delivery-integration-instruction-placeholder"]}
            updateSpecialInstructions={updateOrderTypeSpecialInstructions}
            specialInstructions={orderTypeInstruction}
          />
        )}

        <FormSubmit
          buttonText={appLabels["order"]["order-contact-confirm"]}
          onSubmit={() => {
            showCustomerInfo();
          }}
          buttonWrapperClass={"delivery-intergration-submit-button-wrapper"}
        />
      </div>
    </>
  );
};
export default UberDirectDeliverySettings;
