// Libraries
import React, { useState, useContext, useEffect } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

// Custom hooks
import useWindowSize from "../../../../_common/hooks/useWindowSize";

// Helper functions
import {
  getDayOfWeekFromIndex,
  getMonthFromIndex,
} from "../../../Locations/helpers/isStoreOpenOrClosed";
import { getTimeAmPm, getTrueBusinessDate } from "../../../../Dashboard/DashboardOrder/dateHelpers";

// API helper functions
import { getAPIAccountInfo } from "./apiHelpers/getAPIAccountInfo";
import { removeAPICreditCard } from "./apiHelpers/removeAPICreditCard";
import { placeAPIOrder } from "./apiHelpers/placeAPIOrder";
import {
  updateLocationData,
  ensureOrderTimeMinInterval,
  isOrderTimePassed,
  isOrderTimeBeforeOpeningTime,
  isOrderTimeBlocked,
  isLateOrderAttempted,
  getOrderStoreAddressForDialogModal,
  isStoreClosedToday,
} from "../../../../_common/PaymentHelpers";
import { checkBillRequestStatus, generateBillsForItems } from "../../../Menu/Bill/BillHelpers";
import { placeUnifiedAPIOrder } from "./apiHelpers/placeUnifiedAPIOrder";

// Contexts
import UserRoleContext from "../../../../App/UserRoleContext";
import AppLanguageContext from "../../../../App/AppLanguageContext";
import MerchantConfigContext from "../../../../App/MerchantConfigContext";
import OrderTypeContext from "../../../../OnlineOrdering/OrderTypeContext";
import StoreContext from "../../../../OnlineOrdering/StoreContext";
import CartContext from "../../../../OnlineOrdering/Cart/CartContext";
import BrandingContext from "../../../../App/BrandingContext";
import OrderTimeContext from "../../../OrderTimeContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import BillContext from "../../../Menu/Bill/BillContext";
import AppSettingsContext from "../../../../App/AppSettingsContext";
import CurrencyContext from "../../../../App/CurrencyContext";

// Assets
import { ReactComponent as IconCheck } from "../../../../_common/icons/IconCheck.svg";
import { ReactComponent as IconCard } from "../../../../_common/icons/IconCard.svg";
import { ReactComponent as IconRevert } from "../../../../_common/icons/IconRevert.svg";

// UI components
import { PaymentMethodCreditCardIcons } from "./PaymentMethodCreditCardIcons";
import { Form } from "../../../../_common/Form/Form";
import { FormFieldset } from "../../../../_common/Form/FormFieldset";
import { FormInput } from "../../../../_common/Form/FormInput";
import { FormSelect } from "../../../../_common/Form/FormSelect";
import { FormCheckbox } from "../../../../_common/Form/FormCheckbox";
import { DialogModal } from "../../../../_common/DialogModal/DialogModal";

// CSS
import "./PaymentMethodCreditCardForm.css";
import PaymentOverlay from "../../PaymentOverlay";
import { LoadingSpinner } from "../../../../_common/LoadingSpinner";
import { CallApiDCPlaceorder } from "../../../../GiftCard/CallApiDCPlaceorder";
import GCCartContext from "../../../../GiftCard/GCCartContext";
import { checkDeliveryDateTime, nextDeliveryDateTime } from "../../../../GiftCard/giftCardHelpers";

export const PaymentMethodCreditCardForm = (props) => {
  const {
    rewards,
    promoCode,
    coupon,
    appliedGiftCards,
    activeType,
    payRemainderWithCreditCardExpanded,
    updateActiveCreditCardType,
    creditCardTypes,
    isDebitForm,
    isGCCheckout = false,
  } = props;

  const history = useHistory();
  const appSettings = useContext(AppSettingsContext);
  const billContext = useContext(BillContext);
  const billItems = billContext.value;
  const billDetails = billContext.details;

  const userRoleContext = useContext(UserRoleContext);
  const userStatus = userRoleContext.status;
  const loginToken = userRoleContext.loginToken;

  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;

  const storeContext = useContext(StoreContext);
  const activeOrderStore = storeContext.activeOrderStore;

  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  const cartContext = useContext(CartContext);
  const gcCartContext = useContext(GCCartContext);

  const brandingContext = useContext(BrandingContext);
  const secondaryColor = brandingContext["secondary-colour"];

  const isLoggedIn = userRoleContext.status === "logged-in";

  const currencyContext = useContext(CurrencyContext);
  const isCurrencyExchangeEnabled =
    merchantConfig.merchant.I59 && merchantConfig.merchant.I59.length > 0;

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const [customerId, setCustomerId] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";
  const isTimePickerEnabled = merchantConfig.merchant.I57 !== "" ? true : false;
  const isStadiumSectionHidden = appSettings["hide-stadium-section"] === "yes";
  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((storedCustomerInfo) => {
      if (storedCustomerInfo) {
        let tempCustomerInfo = storedCustomerInfo;

        localforage.getItem(skin + "__customerID").then((storedCustomerId) => {
          if (storedCustomerId) {
            tempCustomerInfo.id = storedCustomerId;
            setCustomerId(storedCustomerId);
            setCustomerInfo(tempCustomerInfo);
          } else {
            setCustomerInfo(storedCustomerInfo);
          }
        });
      } else {
        setCustomerInfo({});
      }
    });
  }, []);

  const [isCreditCardForm, setIsCreditCardForm] = useState(null);
  useEffect(() => {
    if (userStatus === "guest") {
      setIsCreditCardForm(true);
    }
  }, []);

  const [isStoredCreditCardInfo, setIsStoredCreditCardInfo] = useState(null);

  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);

  const [storedMaskedCCInfo, setStoredMaskedCCInfo] = useState(null);
  useEffect(() => {
    if (loginToken) {
      if (isDebitForm) {
        setIsStoredCreditCardInfo(false);
        setIsCreditCardForm(true);
        return;
      }

      // Retrieve account info from 950 API
      getAPIAccountInfo(skin, loginToken, appLanguage).then((apiStoredCreditCard) => {
        if (apiStoredCreditCard) {
          if (apiStoredCreditCard.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else if (apiStoredCreditCard.error) {
            setIsStoredCreditCardInfo(false);
            setIsCreditCardForm(true);
          } else {
            setStoredMaskedCCInfo(apiStoredCreditCard);
            setIsStoredCreditCardInfo(true);
            setIsCreditCardForm(false);
          }
        } else {
          setIsStoredCreditCardInfo(false);
          setIsCreditCardForm(true);
        }
      });
    }
  }, []);

  const orderTimeContext = useContext(OrderTimeContext);

  const getTimestamp = () => {
    if (orderTimeContext.value.value) {
      const date = new Date(
        ensureOrderTimeMinInterval(orderTimeContext.value.value, activeOrderStore, activeOrderType)
      );
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      const timestamp = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      return timestamp;
    } else {
      return false;
    }
  };

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  useEffect(() => {
    if (activeOrderType === "delivery") {
      localforage.getItem(skin + "__userDeliveryAddress").then((storedDeliveryAddress) => {
        if (storedDeliveryAddress) setDeliveryAddress(storedDeliveryAddress);
        else setDeliveryAddress(false);
      });
    }
  }, [activeOrderType]);

  const [orderTypeInstruction, setOrderTypeInstructions] = useState("None");
  const [tipAmount, setTipAmount] = useState(0);

  const [orderDate, setOrderDate] = useState(null);
  const [orderTime, setOrderTime] = useState(null);

  useEffect(() => {
    localforage.getItem(skin + "__orderTypeSpecialInstruction").then((orderInstruction) => {
      if (orderInstruction) {
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let orderInstructionCopy = orderInstruction;

            if (!isStadiumSectionHidden) {
              orderInstructionCopy += `, ${
                appLabels["order"]["section"]
              }:${parsedSchema.section.toUpperCase()}/`;
            }

            orderInstructionCopy += `${
              appLabels["order"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["order"]["seat-stadium"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(orderInstructionCopy);
          } else {
            setOrderTypeInstructions(orderInstruction);
          }
        });
      } else {
        //there is no order instruction set
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let sectionRowSeatInfo = "";
            if (!isStadiumSectionHidden) {
              sectionRowSeatInfo = `${
                appLabels["order"]["section"]
              }:${parsedSchema.section.toUpperCase()}/`;
            }
            sectionRowSeatInfo += `${appLabels["order"]["row"]}:${parsedSchema.row.toUpperCase()}/${
              appLabels["order"]["seat-stadium"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(sectionRowSeatInfo);
          }
        });
      }
    });

    localforage.getItem(skin + "__storedTip").then((storedTip) => {
      if (
        isCurrencyExchangeEnabled &&
        storedTip.isCustomAmount &&
        currencyContext.currencyData !== merchantConfig.merchant.I34
      ) {
        setTipAmount(
          parseFloat((storedTip.tipAmount / currencyContext.currencyData[1]).toFixed(2))
        );
      } else {
        setTipAmount(storedTip.tipAmount);
      }
    });

    localforage.getItem(skin + "__orderDate").then((orderDate) => {
      if (orderDate) {
        setOrderDate(orderDate.displayValue);
      }
    });

    localforage.getItem(skin + "__orderTime").then((orderTime) => {
      if (orderTime) {
        setOrderTime(orderTime.displayValue);
      }
    });
  }, []);

  const [showPaymentOverlay, setShowPaymentOverlay] = useState(false);
  const [headerLabel, setHeaderLabel] = useState(null);
  const [secondaryHeaderLabel, setSecondaryHeaderLabel] = useState(null);

  const placeOrder = (formData) => {
    setAPISubmitting(true);
    setHeaderLabel(appLabels["order"]["connecting"]);

    setTimeout(() => {
      setHeaderLabel(appLabels["order"]["hang-tight-processing-payment"]);
    }, 2000);

    setTimeout(() => {
      setHeaderLabel(appLabels["order"]["almost-done-passing-order-to-store"]);
    }, 4000);

    const timestamp = getTimestamp();

    const formValues = {};
    formData.forEach((formField) => (formValues[formField.name] = formField.value));

    const cartItems = cartContext.value;

    let giftCardNumberList = [];

    if (coupon) giftCardNumberList.push(coupon.code);

    if (appliedGiftCards && appliedGiftCards.length > 0) {
      appliedGiftCards.forEach((appliedGiftCard) => {
        giftCardNumberList.push([
          appliedGiftCard.fullCardNumber,
          appliedGiftCard.pin,
          appliedGiftCard.balance,
        ]);
      });
    }

    placeAPIOrder(
      skin,
      activeOrderStore,
      activeOrderType,
      customerInfo,
      rewards,
      promoCode,
      cartItems,
      giftCardNumberList,
      activeType,
      formValues,
      storedMaskedCCInfo && storedMaskedCCInfo.id,
      isCreditCardForm,
      timestamp,
      customerId,
      deliveryAddress,
      orderTypeInstruction,
      storeContext.activeOrderStoreTable,
      tipAmount,
      appLanguage,
      appLabels["general"],
      isStadiumSchema,
      isLoggedIn,
      loginToken,
      currencyContext,
      isCurrencyExchangeEnabled,
      merchantConfig
    ).then((apiPlacedOrderResult) => {
      setAPISubmitting(false);

      if (apiPlacedOrderResult) {
        if (apiPlacedOrderResult.isPlaced) {
          localforage.setItem(skin + "__completedOrderId", apiPlacedOrderResult.orderId);
          localforage.setItem(
            skin + "__usedPaymentMethod",
            `${isDebitForm ? "DEBIT" : "CREDITCARD"}${
              !!activeType ? ":" + activeType.toUpperCase() : ""
            }${giftCardNumberList.length > 0 ? " + GIFTCARD" : ""}`
          );
          if (apiPlacedOrderResult.pointsIssued) {
            localforage.setItem(skin + "__pointsIssued", apiPlacedOrderResult.pointsIssued);
          }

          if (apiPlacedOrderResult.mpiForm) {
            localStorage.setItem(skin + "__isComingFromMPI", "true");
            sessionStorage.setItem(skin + "__isPaymentProcessedExternally", "true");
            let dom = new DOMParser().parseFromString(apiPlacedOrderResult.mpiForm, "text/html");
            let newElement = dom.body.firstElementChild;
            document.body.appendChild(newElement);
            let formId = newElement.id;
            //submitting the form will perform the redirection
            document.getElementById(formId).submit();
          } else {
            localStorage.removeItem(skin + "__isComingFromMPI", "true");
            setIsAPISubmitValid(true);
          }
        } else {
          setShowPaymentOverlay(false);
          if (
            apiPlacedOrderResult.error &&
            apiPlacedOrderResult.error.includes(
              "Customer ID can only be used when a customer is logged in"
            )
          ) {
            userRoleContext.handleLoginTokenExpiration();
          } else {
            setAPIError(apiPlacedOrderResult.error);
            setIsAPISubmitValid(false);
          }
        }
      }
    });
  };

  // Scroll to the top of the expand/collapse toggle button after details panel is expanded
  useEffect(() => {
    if (payRemainderWithCreditCardExpanded) {
      const timer = setTimeout(() => payRemainderWithCreditCardExpanded(), 300);
      return () => clearTimeout(timer);
    }
  }, []);

  const showCreditCardForm = () => {
    setIsCreditCardForm(true);
    setIsStoredCreditCardInfo(false);
  };

  const showStoredCreditCardInfo = () => {
    setIsStoredCreditCardInfo(true);
    setIsCreditCardForm(false);
  };

  const [showStoringNewCardConfirmation, setShowStoringNewCardConfirmation] = useState(false);
  const [showSaveCreditCardLoginPopup, setShowSaveCreditCardLoginPopup] = useState(false);
  const onSaveCreditCardChange = (e) => {
    const select = e.target;
    if (userStatus === "guest" && select.checked) {
      /** show the popup to inform the user that they need to be logged in , in order to store their CC */
      setShowSaveCreditCardLoginPopup(true);
    } else {
      if (select.checked && storedMaskedCCInfo && isCreditCardForm) {
        setShowStoringNewCardConfirmation(true);
      }
    }
  };

  const uncheckSaveCreditCardOnPopupClose = () => {
    let saveCreditCard = document.getElementById("save-credit-card");
    if (userStatus === "guest" && saveCreditCard.checked) {
      /** show the popup to inform the user that they need to be logged in , in order to store their CC */
      saveCreditCard.checked = false;
    }
  };

  const removeStoredCard = () => {
    removeAPICreditCard(skin, loginToken, appLanguage).then((removedAPICreditCardResult) => {
      if (removedAPICreditCardResult && !removedAPICreditCardResult.error) {
        setShowStoringNewCardConfirmation(false);
      }
    });
  };

  const [isCreditCardEmpty, setIsCreditCardEmpty] = useState(true);
  const onCreditCardChange = (e) => {
    const input = e.target;
    if (input.value.length > 0) {
      setIsCreditCardEmpty(false);
    } else {
      setIsCreditCardEmpty(true);
    }
  };

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    const month = date.getMonth();
    const calDate = date.getDate();
    const day = date.getDay();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    const nameOfDay = getDayOfWeekFromIndex(day);
    const timestamp = `${
      nameOfDay.substr(0, 3).charAt(0).toUpperCase() + nameOfDay.substr(1, 2)
    } ${getMonthFromIndex(month)} ${calDate} at ${strTime}`;

    return (
      <>
        <br /> <br /> <span style={{ fontWeight: 700 }}>{timestamp} </span>
        <br /> <br />{" "}
      </>
    );
  };

  const updateOrderTime = () => {
    orderTimeContext.update({
      value: nextAvailableOrderTime,
      displayValue: getTimeAmPm(nextAvailableOrderTime),
      trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
      timeAtOrdering: new Date(),
    });
    setIsPassedOrderTime(false);
  };

  const [isLateOrderAttempt, setIsLateOrderAttempt] = useState(false);
  const [isPassedOrderTime, setIsPassedOrderTime] = useState(false);
  const [isOrderTimeBeforeOpening, setIsOrderTimeBeforeOpening] = useState(false);
  const [nextAvailableOrderTime, setNextAvailableOrderTime] = useState(null);
  const [orderTimeIsBlocked, setOrderTimeIsBlocked] = useState(false);
  const [isStoreClosed, setIsStoreClosed] = useState(false);
  const [passedDeliveryDateTime, setPassedDeliveryDateTime] = useState(false);
  const [invalidDeliveryDateGiftCardIndexes, setInvalidDeliveryDateGiftCardIndexes] = useState();

  const checkBillStatus = async (hostTransactionId) => {
    const billStatusData = await checkBillRequestStatus(
      appLanguage,
      skin,
      hostTransactionId,
      activeOrderStore.storeId
    );

    if (billStatusData) {
      return billStatusData;
    }
  };

  const processPayment = (formData, hostTransactionId) => {
    const formValues = {};
    formData.forEach((formField) => (formValues[formField.name] = formField.value));

    let giftCardNumberList = [];

    if (coupon) giftCardNumberList.push(coupon.code);

    if (appliedGiftCards && appliedGiftCards.length > 0) {
      appliedGiftCards.forEach((appliedGiftCard) => {
        giftCardNumberList.push([
          appliedGiftCard.fullCardNumber,
          appliedGiftCard.pin,
          appliedGiftCard.balance,
        ]);
      });
    }

    placeUnifiedAPIOrder(
      skin,
      activeOrderStore,
      activeOrderType,
      customerInfo,
      rewards,
      promoCode,
      giftCardNumberList,
      activeType,
      formValues,
      storedMaskedCCInfo && storedMaskedCCInfo.id,
      isCreditCardForm,
      orderTypeInstruction,
      tipAmount,
      appLanguage,
      appLabels["general"],
      hostTransactionId
    ).then((apiPlacedOrderResult) => {
      setAPISubmitting(false);

      if (apiPlacedOrderResult) {
        if (apiPlacedOrderResult.isPlaced) {
          localforage.setItem(skin + "__completedOrderId", apiPlacedOrderResult.orderId);
          if (apiPlacedOrderResult.mpiForm) {
            localStorage.setItem(skin + "__isComingFromMPI", "true");
            sessionStorage.setItem(skin + "__isPaymentProcessedExternally", "true");
            let dom = new DOMParser().parseFromString(apiPlacedOrderResult.mpiForm, "text/html");
            let newElement = dom.body.firstElementChild;
            document.body.appendChild(newElement);
            let formId = newElement.id;
            //submitting the form will perform the redirection
            document.getElementById(formId).submit();
          } else {
            localStorage.removeItem(skin + "__isComingFromMPI", "true");

            setIsAPISubmitValid(true);
            if (apiPlacedOrderResult.pointsIssued) {
              localforage.setItem(skin + "__pointsIssued", apiPlacedOrderResult.pointsIssued);
            }
          }
        } else {
          setShowPaymentOverlay(false);
          setAPIError(apiPlacedOrderResult.error);
          setIsAPISubmitValid(false);
        }
      }
    });
  };

  const [paymentProgressSpeed, setPaymentProgressSpeed] = useState(0);

  const payDineinBill = async (formData) => {
    let orderLineList = [];

    billItems.forEach((billItem) => {
      orderLineList.push(billItem["vxl_order_line_id"]);
    });

    const billAPIData = await generateBillsForItems(
      appLanguage,
      skin,
      billDetails["vxl_order_id"], // vexilor order id
      orderLineList, //order line id list
      "", //online order id
      "", //loyalty card
      activeOrderStore.storeId
    );

    setPaymentProgressSpeed(10000);
    setHeaderLabel(appLabels["order"]["preparing-bill-for-verification"]);
    setSecondaryHeaderLabel(appLabels["order"]["retrieving-bill-details"]);

    setTimeout(() => {
      setPaymentProgressSpeed(10000);
      setHeaderLabel(appLabels["order"]["retrieving-bill-details"]);
      setSecondaryHeaderLabel(appLabels["order"]["processing-your-bill"]);
    }, 10000);

    setTimeout(() => {
      setPaymentProgressSpeed(5000);
      setHeaderLabel(appLabels["order"]["processing-your-bill"]);
      setSecondaryHeaderLabel(appLabels["order"]["verifying-bill-details"]);
    }, 20000);

    setTimeout(() => {
      setPaymentProgressSpeed(5000);
      setHeaderLabel(appLabels["order"]["verifying-bill-details"]);
      setSecondaryHeaderLabel(appLabels["order"]["confirming-bill-details"]);
    }, 25000);

    if (billAPIData && billAPIData.status === 0) {
      const hostTransactionId = billAPIData.result.I2;

      setTimeout(async () => {
        setPaymentProgressSpeed(5000);
        setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
        setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

        const billStatusData = await checkBillStatus(hostTransactionId);

        if (billStatusData && billStatusData.status === 0) {
          /** if the bill status is complete */
          if (billStatusData.result.I2["request_status"] === "complete") {
            setAPISubmitting(true);

            setTimeout(() => {
              setPaymentProgressSpeed(3000);
              setHeaderLabel(appLabels["order"]["processing-your-payment"]);
              setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
            }, 3000);

            setTimeout(() => {
              setPaymentProgressSpeed(3000);
              setHeaderLabel(appLabels["order"]["all-done"]);
            }, 6000);

            processPayment(formData, hostTransactionId);
          } else if (
            billStatusData.result.I2["request_status"] === "pending" ||
            billStatusData.result.I2["request_status"] === "ready"
          ) {
            /** if bill status is not complete but it is pending or ready, wait three seconds and then check again */
            setPaymentProgressSpeed(5000);
            setHeaderLabel(appLabels["order"]["double-checking-your-bill"]);
            setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

            setTimeout(() => {
              setPaymentProgressSpeed(5000);
              setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
              setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

              checkBillStatus(hostTransactionId).then((billStatusDataSecondTry) => {
                if (billStatusDataSecondTry && billStatusDataSecondTry.status === 0) {
                  if (billStatusDataSecondTry.result.I2["request_status"] === "complete") {
                    setAPISubmitting(true);

                    setTimeout(() => {
                      setPaymentProgressSpeed(3000);
                      setHeaderLabel(appLabels["order"]["processing-your-payment"]);
                      setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
                    }, 3000);

                    setTimeout(() => {
                      setPaymentProgressSpeed(3000);
                      setHeaderLabel(appLabels["order"]["all-done"]);
                    }, 6000);

                    processPayment(formData, hostTransactionId);
                  } else if (
                    billStatusDataSecondTry.result.I2["request_status"] === "pending" ||
                    billStatusDataSecondTry.result.I2["request_status"] === "ready"
                  ) {
                    setPaymentProgressSpeed(5000);
                    setHeaderLabel(appLabels["order"]["double-checking-your-bill"]);
                    setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

                    setTimeout(() => {
                      setPaymentProgressSpeed(5000);
                      setHeaderLabel(appLabels["order"]["confirming-bill-details"]);
                      setSecondaryHeaderLabel(appLabels["order"]["processing-your-payment"]);

                      /** Second retry, in case the after the first retry bill was still not ready */
                      checkBillStatus(hostTransactionId).then((billStatusDataThirdTry) => {
                        if (billStatusDataThirdTry && billStatusDataThirdTry.status === 0) {
                          if (billStatusDataSecondTry.result.I2["request_status"] === "complete") {
                            setAPISubmitting(true);

                            setTimeout(() => {
                              setPaymentProgressSpeed(3000);
                              setHeaderLabel(appLabels["order"]["processing-your-payment"]);
                              setSecondaryHeaderLabel(appLabels["order"]["all-done"]);
                            }, 3000);

                            setTimeout(() => {
                              setPaymentProgressSpeed(3000);
                              setHeaderLabel(appLabels["order"]["all-done"]);
                            }, 6000);

                            processPayment(formData, hostTransactionId);
                          } else {
                            setPaymentProgressSpeed(0);
                            setShowPaymentOverlay(false);
                            setAPIError(appLabels["order"]["bill-api-error"]);
                          }
                        } else {
                          setPaymentProgressSpeed(0);
                          setShowPaymentOverlay(false);
                          setAPIError(appLabels["order"]["bill-api-error"]);
                        }
                      });
                    }, 5000);
                  } else {
                    setPaymentProgressSpeed(0);
                    setShowPaymentOverlay(false);
                    setAPIError(appLabels["order"]["bill-api-error"]);
                  }
                } else {
                  setPaymentProgressSpeed(0);
                  setShowPaymentOverlay(false);
                  setAPIError(appLabels["order"]["bill-api-error"]);
                }
              });
            }, 5000);
          } else {
            setPaymentProgressSpeed(0);
            setShowPaymentOverlay(false);
            setAPIError(appLabels["order"]["bill-api-error"]);
          }
        } else {
          setPaymentProgressSpeed(0);
          setShowPaymentOverlay(false);
          setAPIError(appLabels["order"]["bill-api-error"]);
        }
      }, 30000);
    } else {
      setPaymentProgressSpeed(0);
      setShowPaymentOverlay(false);
      setAPIError(appLabels["order"]["bill-api-error"]);
    }
  };

  const [showLoadingSpinnerWhenCallingAPIs, setShowLoadingSpinnerWhenCallingAPIs] = useState(false);
  const [showDoubleConfirmation, setShowDoubleConfirmation] = useState(false);

  const [formDataReference, setFormDataReference] = useState(null);
  const onCompleteOrderClick = async (formData) => {
    /** if it is dinein order type and user is on bill-payment screen, generate a bill for the items in the billContext and then use the unified bill payment */
    if (activeOrderType === "dinein" && window.location.href.includes("bill-payment")) {
      //generate bill, and call dc_vxl_pay_bill
      setShowPaymentOverlay(true);
      payDineinBill(formData);
      return;
    }
    setShowLoadingSpinnerWhenCallingAPIs(true);
    //update the location with the latest data, in case the store hours have been changed
    const updatedLocation = await updateLocationData(
      activeOrderStore,
      activeOrderType,
      skin,
      appLanguage
    );
    if (updatedLocation) {
      setShowLoadingSpinnerWhenCallingAPIs(false);

      let isStoreClosedForTheDay = isStoreClosedToday(updatedLocation, orderTimeContext);
      const orderTimeIsPassed = isOrderTimePassed(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        setNextAvailableOrderTime
      );
      const orderTimeIsBeforeOpening = isOrderTimeBeforeOpeningTime(
        updatedLocation,
        orderTimeContext
      );
      const orderTimeIsBlocked = isOrderTimeBlocked(
        updatedLocation,
        orderTimeContext,
        activeOrderType
      );
      const lateOrderAttempt = isLateOrderAttempted(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        skin,
        setNextAvailableOrderTime
      );

      if (lateOrderAttempt) {
        isStoreClosedForTheDay = true;
      }

      if (isStoreClosedForTheDay) {
        setIsStoreClosed(true);
        return;
      } else if (orderTimeIsPassed) {
        setIsPassedOrderTime(true);

        setAPISubmitting(false);
        setIsAPISubmitValid(false);
        setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
      } else if (orderTimeIsBeforeOpening) {
        setIsOrderTimeBeforeOpening(true);

        setAPISubmitting(false);
        setIsAPISubmitValid(false);
        setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
      } else if (orderTimeIsBlocked) {
        setOrderTimeIsBlocked(true);
        return;
      } else {
        if (activeOrderType === "pickup") {
          setFormDataReference(formData);
          setShowDoubleConfirmation(true);
        } else {
          setShowPaymentOverlay(true);
          placeOrder(formData); // call 1302
        }
      }
    } else {
      // if updatedLocation returns null, it means the selected location does not exist anymore
      history.push("/dashboard");
    }
  };

  const onConfirmClick = () => {
    setShowDoubleConfirmation(false);
    setShowPaymentOverlay(true);
    placeOrder(formDataReference); // call 1302
  };
  const onCompleteGCOrderClick = async (formData) => {
    localforage.getItem(skin + "__gcOrderSummary").then((orderSummary) => {
      let orderTotal = orderSummary.I2;
      const formValues = {};
      formData.forEach((formField) => (formValues[formField.name] = formField.value));

      let { invalidItemIndexes, pastDeliveryDateTime } = checkDeliveryDateTime(
        gcCartContext.value,
        !isTimePickerEnabled
      );
      setIsAPISubmitValid(false);
      if (!pastDeliveryDateTime) {
        CallApiDCPlaceorder(
          skin,
          appLanguage,
          gcCartContext.value,
          orderTotal,
          activeType,
          formValues,
          storedMaskedCCInfo && storedMaskedCCInfo.id,
          isCreditCardForm,
          isLoggedIn,
          loginToken,
          appLabels["general"],
          isTimePickerEnabled
        ).then((apiPlacedOrderResult) => {
          setAPISubmitting(false);

          if (apiPlacedOrderResult) {
            if (apiPlacedOrderResult.isPlaced) {
              localforage.setItem(skin + "__gcFinalOrderTotal", orderTotal);
              let gcCompletedOrderId = apiPlacedOrderResult.orderId;
              localforage.setItem(skin + "__gcCompletedOrderId", gcCompletedOrderId);

              if (apiPlacedOrderResult.mpiForm) {
                localStorage.setItem(skin + "__isComingFromMPI", "true");
                sessionStorage.setItem(skin + "__isPaymentProcessedExternally", "true");
                let dom = new DOMParser().parseFromString(
                  apiPlacedOrderResult.mpiForm,
                  "text/html"
                );
                let newElement = dom.body.firstElementChild;
                document.body.appendChild(newElement);
                let formId = newElement.id;
                //submitting the form will perform the redirection
                document.getElementById(formId).submit();
              } else {
                localStorage.removeItem(skin + "__isComingFromMPI", "true");
                setIsAPISubmitValid(true);
              }
            } else {
              setShowPaymentOverlay(false);
              if (
                apiPlacedOrderResult.error &&
                apiPlacedOrderResult.error.includes(
                  "Customer ID can only be used when a customer is logged in"
                )
              ) {
                userRoleContext.handleLoginTokenExpiration();
              } else {
                setAPIError(apiPlacedOrderResult.error);
                setIsAPISubmitValid(false);
              }
            }
          }
        });
      } else if (pastDeliveryDateTime) {
        setPassedDeliveryDateTime(true);
        setInvalidDeliveryDateGiftCardIndexes(invalidItemIndexes);
      }
    });
  };
  const declineTimeUpdate = () => {
    setPassedDeliveryDateTime(null);
    window.location.reload();
  };

  const updateDeliveryDateTime = () => {
    let updatedCartItems = [];
    let cartItemsCopy = gcCartContext.value;
    let nextAvailableTime = nextDeliveryDateTime(false, isTimePickerEnabled).timestamp;

    for (let i = 0; i < invalidDeliveryDateGiftCardIndexes.length; i++) {
      let index = invalidDeliveryDateGiftCardIndexes[i];
      let itemCopy = gcCartContext.value[index];
      itemCopy.deliveryDateTime = nextAvailableTime.toString();
      updatedCartItems.push(itemCopy);
      cartItemsCopy[index] = itemCopy;
    }

    gcCartContext.updateGCCart(cartItemsCopy);
    setPassedDeliveryDateTime(false);
    window.location.reload();
  };

  const deviceWidth = useWindowSize().width;
  const [isSmall, setIsSmall] = useState(true);
  useEffect(() => {
    if (deviceWidth >= 660) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  }, [deviceWidth]);

  const [isCustomerIdMissing, setIsCustomerIdMissing] = useState(false);
  useEffect(() => {
    if (isStoredCreditCardInfo && customerInfo) {
      if (!customerInfo.id) {
        setIsCustomerIdMissing(true);
      }
    }
  }, [isStoredCreditCardInfo, customerInfo]);

  return (
    <>
      {isStoredCreditCardInfo && (
        <>
          <div>
            <h2 className="credit-card-form__stored-credit-card-label">
              {appLabels["order"]["saved-credit-card-heading"]}
            </h2>
            <p className="credit-card-form__stored-credit-card">
              <IconCheck />
              <span>{storedMaskedCCInfo.maskedNumber}</span>
            </p>
            <div className="credit-card-form__stored-credit-card-expiry">
              {appLabels["general"]["expires-on"]}
              {": "}
              {storedMaskedCCInfo.expiry}
            </div>
          </div>
          <Form
            id="form--stored-credit-card"
            isFAB={true}
            submitButtonText={appLabels["order"]["submit-payment-button"]}
            submitButtonSuccessText={appLabels["order"]["submit-payment-button-processing"]}
            submitForm={isGCCheckout ? onCompleteGCOrderClick : onCompleteOrderClick}
            submitAPIError={apiError}
            resetAPIError={() => setAPIError("")}
            submitNavigateURL={
              window.location.href.includes("gift-card")
                ? "/gift-card/order-confirmation"
                : window.location.href.includes("bill-payment")
                ? "/online-ordering/payment-confirmation"
                : "/online-ordering/order-confirmation"
            }
            isAPISubmitValid={isAPISubmitValid}
            isAPISubmitting={isAPISubmitting}
            updateActiveCreditCardType={updateActiveCreditCardType}
            isAllowEmptySubmit={true}
            buttonWrapperClass={"form--credit-card__submit-button"}
            isBufferBeforeNavigationDisabled={true}
            isSavedCCForm={true}
          >
            <FormInput
              isMaskedInput={true}
              type="tel"
              label={appLabels["form"]["cvv"]}
              id="input--credit-card-cvv"
              name="credit-card-cvv"
              isRequired={true}
              isSmall={false}
              autocomplete={"on"}
            />
          </Form>
          <div className="expanded-payment-method__footer">
            <button
              onClick={showCreditCardForm}
              className="button expanded-payment-method__footer-button"
              style={{ color: secondaryColor }}
              type="button"
            >
              <IconCard aria-hidden="true" style={{ stroke: secondaryColor }} />
              <span>{appLabels["order"]["use-another-credit-card"]}</span>
            </button>
          </div>
        </>
      )}
      {isCreditCardForm && customerInfo !== null && (
        <>
          <PaymentMethodCreditCardIcons activeType={activeType} creditCardTypes={creditCardTypes} />
          {isDebitForm ? (
            <Form
              id="form--credit-card"
              className="form--credit-card"
              buttonWrapperClass={"form--credit-card__submit-button"}
              isFAB={true}
              submitButtonText={appLabels["order"]["submit-payment-button"]}
              submitButtonSuccessText={appLabels["order"]["submit-payment-button-processing"]}
              submitForm={isGCCheckout ? onCompleteGCOrderClick : onCompleteOrderClick}
              submitAPIError={apiError}
              resetAPIError={() => setAPIError("")}
              submitNavigateURL={
                window.location.href.includes("gift-card")
                  ? "/gift-card/order-confirmation"
                  : window.location.href.includes("bill-payment")
                  ? "/online-ordering/payment-confirmation"
                  : "/online-ordering/order-confirmation"
              }
              isAPISubmitValid={isAPISubmitValid}
              isAPISubmitting={isAPISubmitting}
              updateActiveCreditCardType={updateActiveCreditCardType}
              isBufferBeforeNavigationDisabled={true}
            >
              <FormFieldset id="fieldset--card-details" legend={appLabels["form"]["card-details"]}>
                <FormInput
                  isMaskedInput={true}
                  type="tel"
                  label={
                    isDebitForm
                      ? appLabels["form"]["card-number"]
                      : appLabels["form"]["credit-card-number"]
                  }
                  id="input--credit-card-number"
                  name="credit-card-number"
                  isRequired={true}
                  handleInputChangeFromParent={onCreditCardChange}
                  isSmall={isSmall}
                  autocomplete={"on"}
                />
                <FormInput
                  type="text"
                  label={appLabels["form"]["name-on-card"]}
                  placeholder="Jonathan Smith"
                  id="input--credit-card-name"
                  name="credit-card-name"
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={
                    customerInfo && customerInfo.firstName && customerInfo.lastName
                      ? customerInfo.firstName + " " + customerInfo.lastName
                      : ""
                  }
                  autocomplete={"on"}
                />
                <FormInput
                  isMaskedInput={true}
                  type="tel"
                  label={appLabels["form"]["expiry-date"]}
                  placeholder="mm/yy"
                  id="input--credit-card-expiry"
                  name="credit-card-expiry"
                  isRequired={true}
                  isSmall={true}
                  autocomplete={"on"}
                />
                <FormInput
                  isMaskedInput={true}
                  type="tel"
                  label={appLabels["form"]["cvv"]}
                  id="input--credit-card-cvv"
                  name="credit-card-cvv"
                  isRequired={true}
                  isSmall={true}
                  autocomplete={"on"}
                />
              </FormFieldset>
              <FormFieldset
                id="fieldset--billing-address"
                legend={appLabels["form"]["billing-address"]}
              >
                <FormInput
                  type="text"
                  label={appLabels["form"]["address-1"]}
                  id="input--first-address"
                  name="first-address"
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={
                    customerInfo && customerInfo.address ? customerInfo.address.split("||")[0] : ""
                  }
                  autocomplete={"on"}
                />
                <FormInput
                  type="text"
                  label={appLabels["form"]["address-2"]}
                  id="input--second-address"
                  name="second-address"
                  isSmall={isSmall}
                  defaultValue={
                    customerInfo &&
                    customerInfo.address &&
                    customerInfo.address.split("||").length > 1
                      ? customerInfo.address.split("||")[1]
                      : ""
                  }
                  autocomplete={"on"}
                />
                <FormSelect
                  id="select--country"
                  name="country"
                  label={appLabels["form"]["country"]}
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={customerInfo && customerInfo.country ? customerInfo.country : ""}
                  autocomplete={"on"}
                />
                <FormSelect
                  id="select--province"
                  name="province"
                  label={appLabels["form"]["province"]}
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={customerInfo && customerInfo.province ? customerInfo.province : ""}
                  autocomplete={"on"}
                />
                <FormInput
                  type="text"
                  label={appLabels["form"]["city"]}
                  id="input--city"
                  name="city"
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={customerInfo && customerInfo.city ? customerInfo.city : ""}
                  autocomplete={"on"}
                />
                <FormInput
                  isMaskedInput={true}
                  type="text"
                  label={appLabels["form"]["postal"]}
                  id="input--postal"
                  name="postal"
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={customerInfo && customerInfo.postal ? customerInfo.postal : ""}
                  autocomplete={"on"}
                />
              </FormFieldset>
            </Form>
          ) : (
            <Form
              id="form--credit-card"
              className="form--credit-card"
              buttonWrapperClass={"form--credit-card__submit-button"}
              isFAB={true}
              submitButtonText={appLabels["order"]["submit-payment-button"]}
              submitButtonSuccessText={appLabels["order"]["submit-payment-button-processing"]}
              submitForm={isGCCheckout ? onCompleteGCOrderClick : onCompleteOrderClick}
              submitAPIError={apiError}
              resetAPIError={() => setAPIError("")}
              submitNavigateURL={
                window.location.href.includes("gift-card")
                  ? "/gift-card/order-confirmation"
                  : window.location.href.includes("bill-payment")
                  ? "/online-ordering/payment-confirmation"
                  : "/online-ordering/order-confirmation"
              }
              isAPISubmitValid={isAPISubmitValid}
              isAPISubmitting={isAPISubmitting}
              updateActiveCreditCardType={updateActiveCreditCardType}
              isBufferBeforeNavigationDisabled={true}
            >
              <FormFieldset id="fieldset--card-details" legend={appLabels["form"]["card-details"]}>
                <FormInput
                  isMaskedInput={true}
                  type="tel"
                  label={
                    isDebitForm
                      ? appLabels["form"]["card-number"]
                      : appLabels["form"]["credit-card-number"]
                  }
                  id="input--credit-card-number"
                  name="credit-card-number"
                  isRequired={true}
                  handleInputChangeFromParent={onCreditCardChange}
                  isSmall={isSmall}
                  autocomplete={"on"}
                />
                <FormInput
                  type="text"
                  label={appLabels["form"]["name-on-card"]}
                  placeholder="Jonathan Smith"
                  id="input--credit-card-name"
                  name="credit-card-name"
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={
                    customerInfo && customerInfo.firstName && customerInfo.lastName
                      ? customerInfo.firstName + " " + customerInfo.lastName
                      : ""
                  }
                  autocomplete={"on"}
                />
                <FormInput
                  isMaskedInput={true}
                  type="tel"
                  label={appLabels["form"]["expiry-date"]}
                  placeholder="mm/yy"
                  id="input--credit-card-expiry"
                  name="credit-card-expiry"
                  isRequired={true}
                  isSmall={true}
                  autocomplete={"on"}
                />
                <FormInput
                  isMaskedInput={true}
                  type="tel"
                  label={appLabels["form"]["cvv"]}
                  id="input--credit-card-cvv"
                  name="credit-card-cvv"
                  isRequired={true}
                  isSmall={true}
                  autocomplete={"on"}
                />
                <FormCheckbox
                  label={appLabels["form"]["save-credit-card"]}
                  id="save-credit-card"
                  name="save-credit-card"
                  value="save-credit-card"
                  handleChangeFromParent={onSaveCreditCardChange}
                  isDisabled={isCreditCardEmpty}
                  fieldWrapperClass={" save-credit-card__field-wrapper--enabled"}
                />
              </FormFieldset>
              <FormFieldset
                id="fieldset--billing-address"
                legend={appLabels["form"]["billing-address"]}
              >
                <FormInput
                  type="text"
                  label={appLabels["form"]["address-1"]}
                  id="input--first-address"
                  name="first-address"
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={
                    customerInfo && customerInfo.address ? customerInfo.address.split("||")[0] : ""
                  }
                  autocomplete={"on"}
                />
                <FormInput
                  type="text"
                  label={appLabels["form"]["address-2"]}
                  id="input--second-address"
                  name="second-address"
                  isSmall={isSmall}
                  defaultValue={
                    customerInfo &&
                    customerInfo.address &&
                    customerInfo.address.split("||").length > 1
                      ? customerInfo.address.split("||")[1]
                      : ""
                  }
                  autocomplete={"on"}
                />
                <FormSelect
                  id="select--country"
                  name="country"
                  label={appLabels["form"]["country"]}
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={customerInfo && customerInfo.country ? customerInfo.country : ""}
                  autocomplete={"on"}
                />
                <FormSelect
                  id="select--province"
                  name="province"
                  label={appLabels["form"]["province"]}
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={customerInfo && customerInfo.province ? customerInfo.province : ""}
                  autocomplete={"on"}
                />
                <FormInput
                  type="text"
                  label={appLabels["form"]["city"]}
                  id="input--city"
                  name="city"
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={customerInfo && customerInfo.city ? customerInfo.city : ""}
                  autocomplete={"on"}
                />
                <FormInput
                  isMaskedInput={true}
                  type="text"
                  label={appLabels["form"]["postal"]}
                  id="input--postal"
                  name="postal"
                  isRequired={true}
                  isSmall={isSmall}
                  defaultValue={customerInfo && customerInfo.postal ? customerInfo.postal : ""}
                  autocomplete={"on"}
                />
              </FormFieldset>
            </Form>
          )}

          {storedMaskedCCInfo && (
            <div className="expanded-payment-method__footer">
              <button
                onClick={showStoredCreditCardInfo}
                className="button expanded-payment-method__footer-button"
                style={{ color: secondaryColor }}
                type="button"
              >
                <IconRevert style={{ stroke: secondaryColor }} aria-hidden="true" />
                <span>{appLabels["order"]["return-to-saved-credit-card"]}</span>
              </button>
            </div>
          )}
          {showStoringNewCardConfirmation && (
            <DialogModal
              message={
                appLabels["order"]["replacing-saved-credit-card-confirmation"].split(
                  "[card-number]"
                )[0] +
                " " +
                storedMaskedCCInfo.maskedNumber +
                "?"
              }
              confirmAction={removeStoredCard}
              isCancelConfirm={true}
              resetRemoveDialog={() => setShowStoringNewCardConfirmation(false)}
            />
          )}
        </>
      )}
      {showDoubleConfirmation && (
        <DialogModal
          message={getOrderStoreAddressForDialogModal(
            activeOrderStore,
            appLabels,
            orderDate,
            orderTime,
            activeOrderType,
            orderTimeContext,
            skin
          )}
          isConfirmText={true}
          isModifyText={appLabels["order"]["modify-location"]}
          isCancelConfirm={true}
          confirmAction={onConfirmClick}
          resetRemoveDialog={() => {
            history.push("/online-ordering/order-store");
          }}
          closeModalAction={() => {
            setShowDoubleConfirmation(!showDoubleConfirmation);
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}
      {isStoreClosed && (
        <DialogModal
          hideCloseButton={true}
          closeModalAction={() => {
            setIsStoreClosed(false);
          }}
          isCancelConfirm={false}
          hideConfirmButton={false}
          isCustomConfirmText={appLabels["order"]["pick-new-time"]}
          resetRemoveDialog={() => {
            history.push({
              pathname: "/dashboard",
              state: {
                showTimePanel: true,
                timeAtOrdering: orderTimeContext.value.timeAtOrdering,
                originalOrderTimeContext: orderTimeContext,
              },
            });
          }}
          message={
            <div>
              <h2>{appLabels["order"]["sorry-it-is-closing-time"]}</h2>
              <p>{appLabels["order"]["store-closed-message"]}</p>
            </div>
          }
        />
      )}
      {isLateOrderAttempt && (
        <DialogModal
          message={appLabels["order"]["order-too-close-to-closing-time-error"]}
          resetRemoveDialog={() => {
            setIsLateOrderAttempt(false);
            orderTimeContext.update({
              value: nextAvailableOrderTime,
              displayValue: getTimeAmPm(nextAvailableOrderTime),
              trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
            });
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}
      {isPassedOrderTime && (
        <DialogModal
          message={
            <div>
              {appLabels["order"]["select-order-time-passed-message"] + ": "}
              {formatOrderTime(nextAvailableOrderTime)}
              {appLabels["order"]["confirm-updated-order-time-message"]}
            </div>
          }
          resetRemoveDialog={() => setIsPassedOrderTime(false)}
          isCancelConfirm={true}
          confirmAction={updateOrderTime}
          isHTMLContent={true}
        />
      )}

      {isOrderTimeBeforeOpening && (
        <DialogModal
          message={appLabels["order"]["order-before-store-opening-time-error"]}
          resetRemoveDialog={() => {
            setIsOrderTimeBeforeOpening(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {orderTimeIsBlocked && (
        <DialogModal
          message={appLabels["order"]["order-time-is-blocked"]}
          resetRemoveDialog={() => {
            setOrderTimeIsBlocked(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {showLoadingSpinnerWhenCallingAPIs && <LoadingSpinner />}

      {showPaymentOverlay && headerLabel && (
        <PaymentOverlay
          headerLabels={headerLabel}
          paymentProgressSpeed={paymentProgressSpeed}
          secondaryHeaderLabel={secondaryHeaderLabel}
        />
      )}

      {showSaveCreditCardLoginPopup && (
        <DialogModal
          message={
            <div>
              <p>{`${appLabels["form"]["save-credit-card-login-error"]}`}</p>
              <div className="save-credit-card-popup-buttons-container">
                <button
                  type="button"
                  className="button dialog-modal__button button--primary button--primary-with-border button--primary-small"
                  onClick={(e) => {
                    sessionStorage.setItem(
                      skin + "__lastVisitedLink",
                      window.location.hash.split("#")[1]
                    );
                    sessionStorage.setItem(skin + "__autoOpenCCForm", true);
                    history.push("/register");
                  }}
                >
                  {appLabels["login-register"]["register"]}
                </button>
                <button
                  type="button"
                  className="button dialog-modal__button button--primary button--primary-small"
                  onClick={() => {
                    sessionStorage.setItem(
                      skin + "__lastVisitedLink",
                      window.location.hash.split("#")[1]
                    );
                    sessionStorage.setItem(skin + "__autoOpenCCForm", true);
                    history.push("/login-register");
                  }}
                >
                  {appLabels["login-register"]["sign-in"]}
                </button>
              </div>
            </div>
          }
          resetRemoveDialog={() => {
            setShowSaveCreditCardLoginPopup(false);
            uncheckSaveCreditCardOnPopupClose();
          }}
          hideConfirmButton={true}
          isHTMLContent={true}
        />
      )}

      {isCustomerIdMissing && (
        <DialogModal
          message={appLabels["order"]["missing-customer-id-error"]}
          resetRemoveDialog={() => {
            sessionStorage.setItem(skin + "__lastVisitedLink", window.location.hash.split("#")[1]);
            setIsCustomerIdMissing(false);
            userRoleContext.updateStatus("guest");
            userRoleContext.updateLoginToken();
            history.push("/login-register");
          }}
        />
      )}
      {passedDeliveryDateTime && isGCCheckout && (
        <DialogModal
          message={
            <div>
              <span>
                <b>
                  {appLabels["gift-card"]["invalid-delivery-date-time-gift-card-title-message"]}
                </b>
              </span>
              <br /> <br />
              {appLabels["gift-card"]["invalid-delivery-date-time-gift-card-message"]}
              <br /> <br />{" "}
              <span>
                <b>
                  {
                    nextDeliveryDateTime(true, isTimePickerEnabled, appLabels["gift-card"]["at"])
                      .displayValue
                  }
                </b>{" "}
              </span>
            </div>
          }
          resetRemoveDialog={declineTimeUpdate}
          isCancelConfirm={true}
          confirmAction={() => updateDeliveryDateTime()}
          isHTMLContent={true}
        />
      )}
    </>
  );
};
