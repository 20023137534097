// Libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import GCCartContext from "../GCCartContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import { CallApi1013 } from "../CallAPI1013";
import { Modal } from "../../_common/Modal/Modal";
import { objectToArray, toDollars } from "../../_common/helpers";
import { GCCartSummaryCollapsable } from "./GCCartSummaryCollapsable";
import GiftCardCartIndicator from "../../_common/GiftCardCartIndicator/GiftCardCartIndicator";
import AppLabelsContext from "../../App/AppLabelsContext";
import { GCCartItemsCollapsable } from "./GCCartItemsCollapsable";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import UserRoleContext from '../../App/UserRoleContext';
import { useModal } from "../../_common/Modal/useModal";
import { GCCustomerInfo } from "./GCCustomerInfo";
import { castToApiObject, getGiftCardCartSubTotal, nextDeliveryDateTime } from "../giftCardHelpers";
import { checkDeliveryDateTime } from "../giftCardHelpers";
import AppSettingsContext from "../../App/AppSettingsContext";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { GCCartDiscount } from "./GCCartDiscount";
import useWindowSize from "../../_common/hooks/useWindowSize";

export const GCCartCheckout = () => {
  const [formattedCartItems, setFormattedCartItems] = useState([])
  const [cartLineItems, setCartLineItems] = useState([])
  const [orderSummaryData, setOrderSummaryData] = useState()
  const [isRemovingAllItems, setIsRemovingAllItems] = useState(false)
  const [isPassedDeliveryDateTime, setIsPassedDeliveryDateTime] = useState(false); // used as flag to show dialog for past delivery date for a gift card
  const [exceededTotalAllowed, setExceededTotalAllowed] = useState(false) // used as flag to show dialog when total amount of gift cards is greater than allowed max
  const [invalidGiftCardIndex, setInvalidGiftCartIndex] = useState([]) // used as flag to show dialog when API 1013 returns status 293, not found article (aka gift card) in database
  const { isModal, toggleModal, closeAnimation } = useModal();
  const [storedPromoCode, setStoredPromoCode] = useState(null)
  const [preAppliedDiscounts, setPreAppliedDiscounts] = useState()
  const [coupon, setCoupon] = useState()
  const [isLoadingSummaryData, setIsLoadingSummaryData] = useState(false)
  const [invalidCartItems, setInvalidCardItems] = useState(false) // show dialog for status 293 from API, when a gift card in cart is no longer available or was not found
  const [invalidGiftCardDenomination, setInvalidGiftCardDenomination] = useState(false) // show dialog for status 301 from API, when a gift card in cart has denomination that is no longer valid
  const [invalidCartItemQuantity, setInvalidCartItemQuantity] = useState(false) // show dialog for status 306 from API, when number of gift cards in cart is not valid

  const cartContext = useContext(GCCartContext)
  const merchantConfigContext = useContext(MerchantConfigContext)
  const skin = merchantConfigContext.skin;
  const appLabels = useContext(AppLabelsContext)
  const appLanguage = useContext(AppLanguageContext);
  const appSettings = useContext(AppSettingsContext);
  const userRoleContext = useContext(UserRoleContext)
  const userStatus = userRoleContext.status
  const loginToken = userRoleContext.loginToken;
  const isTimePickerEnabled = merchantConfigContext.merchant.I57 !== "" ? true : false
  const history = useHistory()
  const { state } = useLocation()
  const deviceWidth = useWindowSize().width

  useEffect(() => {
    setIsLoadingSummaryData(true)

    let formattedLineItems = []
    let gcCartItems = cartContext.value
    let orderSubTotal = 0
    if (gcCartItems && gcCartItems.length > 0) {

      orderSubTotal = getGiftCardCartSubTotal(gcCartItems)

      gcCartItems = objectToArray(cartContext.value)
      gcCartItems.forEach((e, index) => {
        let formattedItem = castToApiObject(e, isTimePickerEnabled)
        gcCartItems[index] = formattedItem
        formattedLineItems.push(formattedItem)
      })

      setCartLineItems(gcCartItems)
      setFormattedCartItems(formattedLineItems)
      localforage.getItem(skin + "__gcStoredPromoCode").then((storedPromoCode) => {
        localforage.getItem(skin + "__gcStoredPromoDetails").then((storedPromoDetails) => {
          CallApi1013(
            skin,
            formattedLineItems,
            appLanguage,
            storedPromoCode || "",
            loginToken
          ).then((data1013) => {
            if (data1013.status === 301) {
              setInvalidGiftCardDenomination(true)
              setIsLoadingSummaryData(false)
            } else if (data1013.status === 293) {
              localforage.removeItem(skin + "__gcOrderSummary");
              setInvalidCardItems(true)
            } else if (data1013.status === 249 || data1013.status === 150 || data1013.status === 148) {
              userRoleContext.handleLoginTokenExpiration();
            } else if (data1013.status === 306) {
              setInvalidCartItemQuantity(true)
            }
            else if (data1013.status === 352) {
              /* 
              status code 352 indicates stored promo is not applicable to cart 
              item anymore.
              In this case call 1013 with no promo offer code
              */
              localforage.removeItem(skin + "__gcStoredPromoCode")
              localforage.removeItem(skin + "__gcStoredPromoDetails")
              setStoredPromoCode(null)
              CallApi1013(
                skin,
                formattedLineItems,
                appLanguage,
                "",
                loginToken
              ).then((data1013) => {
                if (data1013.status === 293) {
                  localforage.removeItem(skin + "__gcOrderSummary");
                  setInvalidCardItems(true)
                } else if (data1013.status === 249 || data1013.status === 150 || data1013.status === 148) {
                  userRoleContext.handleLoginTokenExpiration();
                } else if (data1013.status === 306) {
                  setInvalidCartItemQuantity(true)
                }
                else if (data1013.result.I2) {

                  data1013.result.orderSubTotal = orderSubTotal
                  localforage.setItem(skin + "__gcOrderSummary", data1013.result);

                  let tmpOrderSummary = { "orderTotal": Number(data1013.result.I2), "taxes": 0, "discount": data1013.result.I4 }
                  tmpOrderSummary.subTotal = orderSubTotal



                  if (data1013.result.I4 && data1013.result.I4.length > 0) {
                    let discountsList = []
                    let discountsKey = {}
                    let promoBonusKey = {}

                    tmpOrderSummary.discount.forEach(discount => {
                      if (discount[0] in discountsKey) {
                        discountsKey[discount[0]] = discountsKey[discount[0]] + Number(discount[2])
                        promoBonusKey[discount[0]] = promoBonusKey[discount[0]] + Number(discount[3])
                      } else {
                        discountsKey[discount[0]] = Number(discount[2])
                        promoBonusKey[discount[0]] = Number(discount[3])
                      }

                    })

                    let discountsTotal = []
                    for (const key in discountsKey) {
                      let discountAndTotal = { "name": key, "discount": discountsKey[key] }
                      discountsTotal.push(discountAndTotal)
                    }

                    let promoBonusTotal = []
                    for (const key in promoBonusKey) {
                      let promoBonusAndTotal = { "name": key, "promoBonus": promoBonusKey[key] }
                      promoBonusTotal.push(promoBonusAndTotal)
                    }

                    tmpOrderSummary.discount = discountsTotal
                    tmpOrderSummary.promoBonus = promoBonusTotal

                    if (storedPromoDetails && storedPromoDetails.code) {
                      data1013.result.I4.forEach((discount) => {
                        if (storedPromoDetails.promoDetails.promoName !== discount[0]) {
                          let appliedDiscount = {
                            name: discount[0],
                            type: discount[1],
                            discountValue: discount[2],
                          };
                          discountsList.push(appliedDiscount);
                        }
                      });
                      setPreAppliedDiscounts(discountsList);
                    } else {
                      data1013.result.I4.forEach((discount) => {
                        let appliedDiscount = {
                          name: discount[0],
                          type: discount[1],
                          discountValue: discount[2],
                        };
                        discountsList.push(appliedDiscount);
                      });
                      setPreAppliedDiscounts(discountsList);
                    }
                    let isStoredPromoCodeValid = false
                    data1013.result.I4.forEach((discount) => {
                      if (storedPromoDetails.promoDetails.promoName === discount[0]) {
                        isStoredPromoCodeValid = true
                      }
                    })

                    if (!isStoredPromoCodeValid) {
                      localforage.removeItem(skin + "__gcStoredPromoCode")
                      localforage.removeItem(skin + "__gcStoredPromoDetails")
                      setStoredPromoCode(null)
                    }

                  } else {
                    setPreAppliedDiscounts([])
                  }
                  setOrderSummaryData(tmpOrderSummary)
                  setIsLoadingSummaryData(false)
                }
              }).catch((error) => {
                setIsLoadingSummaryData(false)
                console.error(error)
              });
            }
            else if (data1013.result.I2) {

              data1013.result.orderSubTotal = orderSubTotal
              localforage.setItem(skin + "__gcOrderSummary", data1013.result);

              let tmpOrderSummary = { "orderTotal": Number(data1013.result.I2), "taxes": 0, "discount": data1013.result.I4 }
              tmpOrderSummary.subTotal = orderSubTotal



              if (data1013.result.I4 && data1013.result.I4.length > 0) {
                let discountsList = []
                let discountsKey = {}
                let promoBonusKey = {}
                tmpOrderSummary.discount.forEach(discount => {
                  if (discount[0] in discountsKey) {
                    discountsKey[discount[0]] = discountsKey[discount[0]] + Number(discount[2])
                    promoBonusKey[discount[0]] = promoBonusKey[discount[0]] + Number(discount[3])
                  } else {
                    discountsKey[discount[0]] = Number(discount[2])
                    promoBonusKey[discount[0]] = Number(discount[3])
                  }

                })
                let discountsTotal = []
                for (const key in discountsKey) {
                  let discountAndTotal = { "name": key, "discount": discountsKey[key] }
                  discountsTotal.push(discountAndTotal)
                }

                let promoBonusTotal = []
                for (const key in promoBonusKey) {
                  let promoBonusAndTotal = { "name": key, "promoBonus": promoBonusKey[key] }
                  promoBonusTotal.push(promoBonusAndTotal)
                }

                tmpOrderSummary.discount = discountsTotal
                tmpOrderSummary.promoBonus = promoBonusTotal

                if (storedPromoDetails && storedPromoDetails.code) {
                  data1013.result.I4.forEach((discount) => {
                    if (storedPromoDetails.promoDetails.promoName !== discount[0]) {
                      let appliedDiscount = {
                        name: discount[0],
                        type: discount[1],
                        discountValue: discount[2],
                      };
                      discountsList.push(appliedDiscount);
                    }
                  });
                  setPreAppliedDiscounts(discountsList);
                } else {
                  data1013.result.I4.forEach((discount) => {
                    let appliedDiscount = {
                      name: discount[0],
                      type: discount[1],
                      discountValue: discount[2],
                    };
                    discountsList.push(appliedDiscount);
                  });
                  setPreAppliedDiscounts(discountsList);
                }


              } else {
                setPreAppliedDiscounts([])
              }
              setOrderSummaryData(tmpOrderSummary)
              setIsLoadingSummaryData(false)
            }
          }).catch((error) => {
            setIsLoadingSummaryData(false)
            console.error(error)
          });
          // end call to 1013
        })
      })
    }
    else {
      history.push("/gift-card/purchase")
    }
  }, [])


  useEffect(() => {
    if (orderSummaryData === "reset" || orderSummaryData === null) {

      let formattedLineItems = []
      let gcCartItems = cartContext.value
      let orderSubTotal = 0
      if (gcCartItems && gcCartItems.length > 0) {
        orderSubTotal = getGiftCardCartSubTotal(gcCartItems)

        gcCartItems = objectToArray(cartContext.value)
        gcCartItems.forEach((e, index) => {
          let formattedItem = castToApiObject(e, isTimePickerEnabled)
          gcCartItems[index] = formattedItem
          formattedLineItems.push(formattedItem)
        })
        setCartLineItems(gcCartItems)
        setFormattedCartItems(formattedLineItems)
        localforage.getItem(skin + "__gcStoredPromoCode").then((storedPromoCode) => {
          localforage.getItem(skin + "__gcStoredPromoDetails").then((storedPromoDetails) => {
            CallApi1013(
              skin,
              formattedLineItems,
              appLanguage,
              storedPromoCode || "",
              loginToken
            ).then((data1013) => {
              if (data1013.status === 352) {
                localforage.removeItem(skin + "__gcStoredPromoCode")
                localforage.removeItem(skin + "__gcStoredPromoDetails")
                setStoredPromoCode(null)
                setOrderSummaryData("reset")
              } else if (data1013.status === 249 || data1013.status === 150 || data1013.status === 148) {
                userRoleContext.handleLoginTokenExpiration();
              } else if (data1013.status === 306) {
                setInvalidCartItemQuantity(true)
              }
              if (data1013.result.I2) {

                data1013.result.orderSubTotal = orderSubTotal
                localforage.setItem(skin + "__gcOrderSummary", data1013.result);

                let tmpOrderSummary = { "orderTotal": Number(data1013.result.I2), "taxTotal": 0, "discount": data1013.result.I4 }

                tmpOrderSummary.subTotal = orderSubTotal
                let discountsKey = {}
                let promoBonusKey = {}

                tmpOrderSummary.discount.forEach(discount => {
                  if (discount[0] in discountsKey) {
                    discountsKey[discount[0]] = discountsKey[discount[0]] + Number(discount[2])
                    promoBonusKey[discount[0]] = promoBonusKey[discount[0]] + Number(discount[3])
                  } else {
                    discountsKey[discount[0]] = Number(discount[2])
                    promoBonusKey[discount[0]] = Number(discount[3])
                  }

                })
                let discountsTotal = []
                for (const key in discountsKey) {
                  let discountAndTotal = { "name": key, "discount": discountsKey[key] }
                  discountsTotal.push(discountAndTotal)
                }

                let promoBonusTotal = []
                for (const key in promoBonusKey) {
                  let promoBonusAndTotal = { "name": key, "promoBonus": promoBonusKey[key] }
                  promoBonusTotal.push(promoBonusAndTotal)
                }

                tmpOrderSummary.discount = discountsTotal
                tmpOrderSummary.promoBonus = promoBonusTotal

                setOrderSummaryData(tmpOrderSummary)

                let isStoredPromoCodeValid = false
                data1013.result.I4.forEach((discount) => {
                  if (storedPromoDetails.promoDetails.promoName === discount[0]) {
                    isStoredPromoCodeValid = true
                  }
                })

                if (!isStoredPromoCodeValid) {
                  localforage.removeItem(skin + "__gcStoredPromoCode")
                  localforage.removeItem(skin + "__gcStoredPromoDetails")
                  setStoredPromoCode(null)
                }

              }
            }).catch((error) => console.error(error));
          })
        })
      }
      else {
        history.push("/gift-card/purchase")
      }
    }
  }, [orderSummaryData])

  useEffect(() => {
    if (state && state.from && state.from === "login-register") {
      showCustomerInfo();
      sessionStorage.removeItem(skin + "__gcRedirectToGCCheckout");
    }
  }, [state]);

  function removeAllItems() {
    cartContext.updateGCCart(null)
    localforage.removeItem(skin + "__gcOrderSummary");
    localforage.removeItem(skin + "__gcStoredPromoCode");
    localforage.removeItem(skin + "__gcStoredPromoDetails")
    setPreAppliedDiscounts([])
    setStoredPromoCode(null);
    setCoupon(null);
    setOrderSummaryData("reset")
    history.push("/gift-card/purchase")
  }

  function removeItem(item) {
    let newCart = [...cartContext.value]
    let indexToRemove = newCart.findIndex(i => i.entryKey === item)
    newCart.splice(indexToRemove, 1)
    cartContext.updateGCCart(newCart)
    setOrderSummaryData(null)
    if (!newCart || newCart.length === 0) {
      localforage.removeItem(skin + "__gcStoredPromoCode")
      localforage.removeItem(skin + "__gcStoredPromoDetails")
      setStoredPromoCode(null)
    }
  }

  const declineTimeUpdate = () => {
    setIsPassedDeliveryDateTime(null);
    history.push("/gift-card/purchase");
  };

  const updateDeliveryDateTime = () => {
    let updatedCartItems = []
    let cartItemsCopy = cartContext.value
    let nextAvailableTime = nextDeliveryDateTime(false, isTimePickerEnabled).timestamp

    for (let i = 0; i < invalidGiftCardIndex.length; i++) {
      let index = invalidGiftCardIndex[i]
      let itemCopy = cartContext.value[index]
      itemCopy.deliveryDateTime = nextAvailableTime.toString()
      updatedCartItems.push(itemCopy)
      cartItemsCopy[index] = itemCopy
    }

    cartContext.updateGCCart(cartItemsCopy)
    setIsPassedDeliveryDateTime(false)
    window.location.reload()
  }
  const handleCheckoutClick = () => {
    let maxTotalAmount = merchantConfigContext.merchant.I4[5]

    let { invalidItemIndexes, pastDeliveryDateTime } = checkDeliveryDateTime(cartContext.value, !isTimePickerEnabled)

    if (maxTotalAmount !== "" && orderSummaryData.subTotal > maxTotalAmount) {
      setExceededTotalAllowed(true)
    } else if (pastDeliveryDateTime) {
      setIsPassedDeliveryDateTime(true)
      setInvalidGiftCartIndex(invalidItemIndexes)
    }
    else if (userStatus === "guest") {
      sessionStorage.setItem(skin + "__gcRedirectToGCCheckout", "true");
      history.push("/login-register");
    } else {
      showCustomerInfo();
    }
  }

  const showCustomerInfo = () => {
    toggleModal();
  };

  /** Coupon, discounts and rewards */
  useEffect(() => {
    if (storedPromoCode == null) {
      localforage.getItem(skin + "__gcStoredPromoCode").then((retrievedPromoCode) => {
        if (retrievedPromoCode) setStoredPromoCode(retrievedPromoCode);
        else setStoredPromoCode("");
      });
    }
  }, [storedPromoCode]);

  useEffect(() => {
    if (coupon == null) {
      localforage.getItem(skin + "__gcCoupon").then((storedCoupon) => {
        if (storedCoupon) {
          setCoupon(storedCoupon);
        } else {
          setCoupon("");
        }
      });
    }
  }, [coupon]);

  const updateOrderSummary = async (promoCode, newCart, orderCoupon, storedPromoInfoParam = null) => {
    setIsLoadingSummaryData(true)
    let promoValue = "";
    if (!promoCode || promoCode !== "") promoValue = promoCode;
    else if (storedPromoCode) promoValue = storedPromoCode;
    else promoValue = "";

    if (cartContext.value.length === 0) {
      return;
    }
    if (promoValue === "") {
      localforage.removeItem(skin + "__storedPromoCode");
      localforage.removeItem(skin + "__storedPromoDetails");
      setStoredPromoCode(null)
      setPreAppliedDiscounts([])
    }
    //setOrderSummaryData("reset");
    let orderSubTotal = 0
    orderSubTotal = getGiftCardCartSubTotal(cartContext.value)

    const orderSummaryPromise = new Promise((resolve, reject) => {

      localforage.getItem(skin + "__gcStoredPromoDetails").then((storedPromoDetails) => {
        CallApi1013(
          skin,
          formattedCartItems,
          appLanguage,
          promoValue || "",
          loginToken
        ).then((data1013) => {
          if (data1013.status === 352) {
            localforage.removeItem(skin + "__gcStoredPromoCode")
            localforage.removeItem(skin + "__gcStoredPromoDetails")
            setStoredPromoCode(null)
            setOrderSummaryData("reset")
          } else if (data1013.status === 249 || data1013.status === 150 || data1013.status === 148) {
            userRoleContext.handleLoginTokenExpiration();
          } else if (data1013.status === 306) {
            setInvalidCartItemQuantity(true)
          }
          if (data1013.result.I2) {
            data1013.result.orderSubTotal = orderSubTotal
            localforage.setItem(skin + "__gcOrderSummary", data1013.result);

            let tmpOrderSummary = { "orderTotal": Number(data1013.result.I2), "taxes": 0, "discount": data1013.result.I4, "promoBonus": 0 }
            tmpOrderSummary.subTotal = orderSubTotal
            if (data1013.result.I4 && data1013.result.I4.length > 0) {
              let discountsList = []
              let discountsKey = {}
              let promoBonusKey = {}

              tmpOrderSummary.discount.forEach(discount => {

                if (discount[0] in discountsKey) {
                  discountsKey[discount[0]] = discountsKey[discount[0]] + Number(discount[2])
                  promoBonusKey[discount[0]] = promoBonusKey[discount[0]] + Number(discount[3])
                } else {
                  discountsKey[discount[0]] = Number(discount[2])
                  promoBonusKey[discount[0]] = Number(discount[3])
                }

              })
              let discountsTotal = []
              for (const key in discountsKey) {
                let discountAndTotal = { "name": key, "discount": discountsKey[key] }
                discountsTotal.push(discountAndTotal)
              }
              let promoBonusTotal = []
              for (const key in promoBonusKey) {
                let promoBonusAndTotal = { "name": key, "promoBonus": promoBonusKey[key] }
                promoBonusTotal.push(promoBonusAndTotal)
              }

              tmpOrderSummary.discount = discountsTotal
              tmpOrderSummary.promoBonus = promoBonusTotal

              if (storedPromoDetails || storedPromoInfoParam) {
                let promoOfferDetails = storedPromoInfoParam
                data1013.result.I4.forEach(discount => {
                  if (promoOfferDetails.promoDetails.promoName !== discount[0]) {
                    let appliedDiscount = {
                      "name": discount[0],
                      "type": discount[1],
                      "discountValue": discount[2],
                    }
                    discountsList.push(appliedDiscount)
                  }
                })
              } else {
                data1013.result.I4.forEach(discount => {
                  let appliedDiscount = {
                    "name": discount[0],
                    "type": discount[1],
                    "discountValue": discount[2],
                  }
                  discountsList.push(appliedDiscount)
                })
              }


              setPreAppliedDiscounts(discountsList)
            } else {
              setPreAppliedDiscounts(null)
            }
            setOrderSummaryData(tmpOrderSummary)
            data1013.result.orderSubTotal = orderSubTotal
            localforage.setItem(skin + "__gcOrderSummary", data1013.result)
            setIsLoadingSummaryData(false)
          } else {
            // if rewards were invalid for any reason, remove all rewards and show the error message to the user
            reject(data1013.result.message);
            setIsLoadingSummaryData(false)
          }
        });
      }).catch((error) => {
        console.error(error)
        setIsLoadingSummaryData(false)
      });
    })

    return orderSummaryPromise;

  };
  return (
    cartLineItems && cartLineItems.length > 0 ?
    <>
      <div className="cart-checkout__desktop-container cart-content-desktop gift-card-cart-content">

        <div>
          <GCCartItemsCollapsable
            setIsCartFAB={false}
            cartData={cartContext.value}
            itemsArray={cartLineItems}
            orderSummaryData={cartLineItems}
            isCalling1307={false}
            items={cartContext.value}
            updateItemQuantity={null}
            removeItem={removeItem}
            skin={skin}
            setIsRemovingAllItems={setIsRemovingAllItems}
            initiallyIsCollapsed={false}
            isOnCheckoutPage={true}
            subHeaderContent={
              <>
                <div className="cart-checkout__cart-items-collapsable__cart_indicator giftCardCartIndicatorIcon">
                  <GiftCardCartIndicator
                    quantity={cartContext.quantity}
                    isAnimated={false}
                    isFloating={false}
                    showShoppingBagIcon={true}
                    appLabels={appLabels}
                  />
                  <span>{appLabels["order"]["items-in-the-cart"]}</span>
                </div>
              </>
            }
          />
        </div>
          {!!cartContext.value.length && deviceWidth < 660 && (
            <>
              {isLoadingSummaryData ? <LoadingSpinner /> :
                <div className="cart-summary-tip-desktop gcCartDiscountContainer">
                  <GCCartDiscount
                    items={cartLineItems}
                    isAnimateIcon={true}
                    orderSummaryData={orderSummaryData}
                    setShowCheckoutButton={true}
                    isTaxIncluded={false}
                    setOrderSummaryData={setOrderSummaryData}
                    skin={skin}
                    isCalling1307={false}
                    formattedCartItems={formattedCartItems}
                    updateOrderSummary={updateOrderSummary}
                    storedPromoCode={storedPromoCode}
                    setStoredPromoCode={setStoredPromoCode}
                    preAppliedDiscounts={preAppliedDiscounts}
                  />

                </div>
              }

            </>
          )}
        {cartLineItems && cartLineItems.length > 0 && (
          <div>

            {!!cartContext.value.length && (
              <>
                {isLoadingSummaryData ? <LoadingSpinner /> :
                    <div className="cart-summary-tip-desktop gcCartSummary">
                    <GCCartSummaryCollapsable
                      items={cartLineItems}
                      isAnimateIcon={true}
                      orderSummaryData={orderSummaryData}
                      setShowCheckoutButton={true}
                      isTaxIncluded={false}
                      setOrderSummaryData={setOrderSummaryData}
                      skin={skin}
                      isCalling1307={false}
                      formattedCartItems={formattedCartItems}
                      updateOrderSummary={updateOrderSummary}
                        storedPromoCode={storedPromoCode}
                      setStoredPromoCode={setStoredPromoCode}
                      preAppliedDiscounts={preAppliedDiscounts}
                    />

                  </div>
                }

              </>
            )}
              {!!cartContext.value.length && deviceWidth >= 660 && (
              <>
                {isLoadingSummaryData ? <LoadingSpinner /> :
                    <div className="cart-summary-tip-desktop gcCartDiscountContainer">
                    <GCCartDiscount
                      items={cartLineItems}
                      isAnimateIcon={true}
                      orderSummaryData={orderSummaryData}
                      setShowCheckoutButton={true}
                      isTaxIncluded={false}
                      setOrderSummaryData={setOrderSummaryData}
                      skin={skin}
                      isCalling1307={false}
                      formattedCartItems={formattedCartItems}
                      updateOrderSummary={updateOrderSummary}
                      storedPromoCode={storedPromoCode}
                      setStoredPromoCode={setStoredPromoCode}
                      preAppliedDiscounts={preAppliedDiscounts}
                    />

                  </div>
                }

              </>
            )}
          </div>
        )}
        {isRemovingAllItems && (
          <DialogModal
            message={appLabels["general"]["remove-all-cart-items-confirmation-message"]}
            confirmAction={removeAllItems}
            isCancelConfirm={true}
            resetRemoveDialog={() => setIsRemovingAllItems(false)}
          />
        )}


      </div>
      {cartContext.value.length > 0 &&
        <div className="cart-content__desktop-checkout-buttons">
          <div className="desktop-cart__link-and-button-wrapper desktop-container single-button">
            <button
              className="button button--primary"
              type="button"
              onClick={() => handleCheckoutClick()}
              disabled={!orderSummaryData}
            >
              {appLabels["order"]["checkout"]}
            </button>
          </div>
        </div >
      }
      <Modal
        isModal={isModal}
        toggleModal={toggleModal}
        content={<GCCustomerInfo closeModal={toggleModal} />}
        type="fullscreen"
        isCloseButton={true}
        description="customer info for payment"
        closeAnimation={closeAnimation}
        isTransparent={true}
        wrapperClass="customer-info__modal modal-wrapper--apply-discounts modal--vertical-animation"

      />
      {isPassedDeliveryDateTime && (
        <DialogModal
          message={
            <div>
              <span><b>{appLabels["gift-card"]["invalid-delivery-date-time-gift-card-title-message"]}</b></span>
              <br /> <br />
              {appLabels["gift-card"]["invalid-delivery-date-time-gift-card-message"]}

                <br /> <br /> <span><b>{nextDeliveryDateTime(true, isTimePickerEnabled, appLabels["gift-card"]["at"]).displayValue}</b> </span>

            </div>
          }
          resetRemoveDialog={declineTimeUpdate}
          isCancelConfirm={true}
          confirmAction={() => updateDeliveryDateTime()}
          isHTMLContent={true}
        />
      )
      }
      {exceededTotalAllowed && (
        <DialogModal
          message={
            <div>
              {appLabels["gift-card"]["exceeded-gift-card-amount-message"] + " " + toDollars(appSettings["currency-symbol"],
                appSettings["currency-symbol-side"], merchantConfigContext.merchant.I4[5], appLanguage)}.
              <br /> <br /> <span><b>{appLabels["gift-card"]["exceeded-gift-card-amount-remove-message"]}</b>  </span>
            </div>
          }
          resetRemoveDialog={() => setExceededTotalAllowed(false)}
          confirmAction={() => setExceededTotalAllowed(false)}
          isHTMLContent={true}

        />
      )
      }
      {invalidCartItems && (
        <DialogModal
          message={appLabels["order"]["items-removed-from-menu-error-message"]}
          resetRemoveDialog={() => {
            cartContext.updateGCCart(null)
            history.push("/gift-card/purchase")
          }}
          isHTMLContent={true}
          confirmAction={() => {
            cartContext.updateGCCart(null)
            history.push("/gift-card/purchase")
          }}
        />
      )}

        {invalidGiftCardDenomination && (
        <DialogModal
            message={
              <div>
                <b> {appLabels["gift-card"]["denomination-error-heading"]}</b>
                <br /> <br /> <span>{appLabels["gift-card"]["denomination-error-text"]}</span>
              </div >}
          resetRemoveDialog={() => {
            cartContext.updateGCCart(null)
            history.push("/gift-card/purchase")
          }}
          isHTMLContent={true}
          confirmAction={() => {
            cartContext.updateGCCart(null)
            history.push("/gift-card/purchase")
          }}
        />
        )}
        {invalidCartItemQuantity && (
          <DialogModal
            message={appLabels["gift-card"]["order-limit-reached"]}
            resetRemoveDialog={() => {
              cartContext.updateGCCart(null)
              history.push("/gift-card/purchase")
            }}
            isHTMLContent={true}
            confirmAction={() => {
              cartContext.updateGCCart(null)
              history.push("/gift-card/purchase")
            }}
          />
        )} 
      </>
      :
      <div>
        <LoadingSpinner />
      </div>
  )
}