import React, { useContext, useEffect, useState } from "react";

import MerchantConfigContext from "../../../App/MerchantConfigContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import { MenuContext } from "../MenuContext";
import { useHistory, useParams } from "react-router-dom";
import { getAllMenuItems } from "../../Cart/SuggestedItems/SuggestedItemsHelpers";
import { MenuCategoryCard } from "../MenuCategoryCard/MenuCategoryCard";
import { useLocation } from "react-router";
import { ReactComponent as IconPlus } from "../../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../../_common/icons/IconMinus.svg";
import CartContext from "../../Cart/CartContext";
import { jsonCopy } from "../../../_common/helpers";

export const ItemLevelSuggestedItems = ({ reloadSuggestedItems = true, menuItem, isComboItem = false, storedSuggestedItemsList = null, parentParams = null, source = null }) => {
  const { skin } = useContext(MerchantConfigContext);

  const appLabels = useContext(AppLabelsContext);
  const menu = useContext(MenuContext);
  const history = useHistory();

  const [itemLevelSuggestedItems, setItemLevelSuggestedItems] = useState([]); // array of suggested item IDs
  const [suggestedItemDetails, setSuggestedItemDetails] = useState([]);
  const isCategoryBased = menu.menuLevel === "threeLevels";
  const [isSuggestedItemsExpanded, setIsSuggestedItemsExpanded] = useState(true)
  
  const cartContext = useContext(CartContext)
  useEffect(() => {
        setItemLevelSuggestedItems(menuItem.standardSuggestedItems);
  }, [menuItem]);

  const [oldCartContext, setOldCartContext] = useState()
  useEffect(() => {
    if (cartContext && !oldCartContext) {
      setOldCartContext(cartContext)
    }
  }, [cartContext])
  
  useEffect(() => {
    if (storedSuggestedItemsList) {
      setSuggestedItemDetails(storedSuggestedItemsList)
    }
  }, [storedSuggestedItemsList])
  
  const { parentItem } = useParams()
  useEffect(() => {
    // add menu level based params here
    if (suggestedItemDetails.length === 0){
      let allMenuItems = getAllMenuItems(isCategoryBased, menu.apiData);
      if (itemLevelSuggestedItems.length > 0) {
      
      let itemDetails = [];
      let cartDupesFound = 0
      let filteredItemLevelSuggestedItemIds = reloadSuggestedItems ? [] : itemLevelSuggestedItems
      if (cartContext && cartContext.value && cartContext.value.length > 0 && reloadSuggestedItems) {
        itemLevelSuggestedItems.forEach((item) => {
          let filteredItem = cartContext.value.filter((cartItem) => cartItem.id === item)
          if (filteredItem.length === 0) {
            filteredItemLevelSuggestedItemIds.push(item)
          } else {
            cartDupesFound = cartDupesFound + 1
          }
        })
      }
      if (filteredItemLevelSuggestedItemIds.length === 0 && cartDupesFound === 0 ) {
        filteredItemLevelSuggestedItemIds = itemLevelSuggestedItems
      }

      //filteredItemLevelSuggestedItemIds.push("100147792")
      filteredItemLevelSuggestedItemIds.forEach((suggestedItem) => {
        let foundItem = allMenuItems.find((item) => suggestedItem === item.id);
        if (foundItem && foundItem.id !== parentItem) {
          if (foundItem.item_count !== "0" && foundItem.itemActive === "t" && suggestedItem !== menuItem.id) {
            foundItem.subcategory = foundItem.subcategoryId
            foundItem.category = foundItem.categoryId
            foundItem.isSuggestedItem = true
            foundItem.suggestedItemType = "item-level"
            delete foundItem.subcategoryId
            delete foundItem.categoryId
            foundItem.isSuggestedItem = true
            itemDetails.push(foundItem);
          }
        }
      });

      setSuggestedItemDetails(itemDetails);
    }
  }
  }, [itemLevelSuggestedItems]);

  const location = useLocation();
  const [itemLocation, setItemLocation] = useState()

  useEffect(() => {
    if (!itemLocation) {
     setItemLocation(location.pathname)
   } 
  }, [])

  const updateActiveItem = (id, stateData = null) => {
    let suggestedItemFromArray = suggestedItemDetails.find((item) => item.id === id);
    menu.updateMenu({
      ...menu,
      activeTopCategory: suggestedItemFromArray.category,
      activeSubcategory: suggestedItemFromArray.parentId,
      activeItem: id,
    });
    let itemDestination;
    const isCategoryBased = menu.menuLevel === "threeLevels";
   
    if (isCategoryBased) {
      itemDestination = `/online-ordering/menu/category/${suggestedItemFromArray.category}/subcategory/${suggestedItemFromArray.parentId}/parentItem/${menuItem.id}/suggestedItem/${id}`;
    } else {
      itemDestination = `/online-ordering/menu/subcategory/${suggestedItemFromArray.parentId}/parentItem/${menuItem.id}/suggestedItem/${id}`;
    }

    let modifiers = null
    if(menuItem.modifierGroups){
      modifiers = menuItem.modifierGroups
    }

    let parentCategoryLink = null
    if (parentParams) {
      if(isCategoryBased){
        parentCategoryLink = `/online-ordering/menu/category/${parentParams.category}/subcategory/${parentParams.subcategory}`
      }
      else {
        parentCategoryLink = `/online-ordering/menu/subcategory/${parentParams.subcategory}`
      }
    } else if (window.location.href.includes("suggestedItem")) {
      
      parentCategoryLink =  JSON.parse(sessionStorage.getItem(skin + "__savedMenuItem-" + parentItem)).parentCategoryLink
    }
    
    sessionStorage.setItem(
      skin + "__savedMenuItem-"+menuItem.id,
      JSON.stringify({
        saved: jsonCopy(menuItem),
        backDestination:  itemLocation,
        suggestedItemPath: itemDestination,
        suggestedItemId: id,
        backDestinationItemName: menuItem.id,
        savedModifiers: jsonCopy(modifiers),
        itemRelatedSuggestedItems: suggestedItemDetails,
        stateData: stateData, // { "from" : source},
        source: source,
        parentCategoryLink: parentCategoryLink
      })
    );

    history.push({
      pathname: itemDestination,
      state: stateData,
    });
  };

  function setUpdateActiveSuggestedItem(updateActiveSuggestedItem) {
    if (updateActiveSuggestedItem) {
      updateActiveItem(updateActiveSuggestedItem.id,updateActiveSuggestedItem.state )
    }
  }
  const { state } = useLocation();
  useEffect(() => {
    if (state && state.from === "suggested-item-edit-page") {
      if (state.customizedItemId) {
        let targetItem = document.getElementById(state.customizedItemId);
        if (targetItem) {
          let modifyButton = targetItem.getElementById(
            state.customizedItemId + "-customzieItemButton"
          );
          if (modifyButton) {
            modifyButton.click();
          }
        }
      }
    }
  }, [state]);

  return suggestedItemDetails && suggestedItemDetails.length > 0 ? (
    <div className={`suggested-item-wrapper ${isComboItem ? "comboSuggestedItemWrapper" : ""}`}>
      <div
        className={`order-item__option-header space-between-wrapper space-between-wrapper--center-aligned giftCardRecipientRowHeader itemLevelSuggestedItemsHeading`}
        id={menuItem.id + "-suggestedItemsListHeader"}
      >
        <div className="heading-with-icon">
          {isSuggestedItemsExpanded ? 
          <IconMinus
            id="suggestedItemIconMinus"
            className={`icon--minus item-detail__option-note`}
            aria-label={"collapse-section"}
          />
:
          <IconPlus
            id="suggestedItemIconPlus"
            className={`icon--plus item-detail__option-note`}
            aria-label={"expand-section"}
          />
  }

          <h3 className="order-item__option-heading heading-with-icon ">
            <span>
              {appLabels["order"]["frequently-bought-together"]}
            </span>
          </h3>
        </div>
        <div className="item-detail__option-text-container">
          <p className="item-detail__option-note">({appLabels["general"]["optional"]})</p> 
        </div>
        <button
          className="item-detail__form__fieldset-legend-button"
          type="button"
          onClick={()=> setIsSuggestedItemsExpanded(!isSuggestedItemsExpanded)}
          aria-label=""
        ></button>
      </div>
      {isSuggestedItemsExpanded &&
        <div className="suggested-items-list">
          <ul className="menu__category-cards-grid">
            {suggestedItemDetails &&
              Object.keys(suggestedItemDetails).map((key, index) => (
                <MenuCategoryCard
                  key={`${suggestedItemDetails[key].id}-${index}`}
                  handleClick={updateActiveItem}
                  index={index}
                  itemData={{
                    ...suggestedItemDetails[key],
                    category: suggestedItemDetails[key].category,
                    subcategory: suggestedItemDetails[key].subcategory,
                    onlineQuantityLimit:
                      !!suggestedItemDetails[key]["online_qty_limit"] &&
                        suggestedItemDetails[key]["online_qty_limit"] !== "None"
                        ? suggestedItemDetails[key]["online_qty_limit"]
                        : null,
                  }}
                  isSuggestedItemCard={true}
                  isOrderLevelSuggestedItemCard = {state && state.source === "order-suggested-item-card" ? true : false}
                  setUpdateActiveSuggestedItem={setUpdateActiveSuggestedItem}
                />
              ))}
          </ul>
          
        </div>
      }
      <div id={menuItem.id +"-footerSuggestedItem"}></div>
    </div>
  ) : (
    ""
  );
};
