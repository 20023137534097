import React, { useContext } from "react";
import ReactDOM from "react-dom";
import countryToCurrency from "country-to-currency";
import { callGetApplePaySession } from "../../../../_common/Api";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import CurrencyContext from "../../../../App/CurrencyContext";

const PaymentMethodApplePayButton = (props) => {
  const {
    activeOrderStore,
    skin,
    orderTotal,
    validateOrderTime,
    appLanguage,
    submitApplePayOrder,
  } = props;

  const storeWithVexilorConfig = activeOrderStore.vexilorConfig;
  const allowedCardNetworks = storeWithVexilorConfig.payment_methods["CREDIT"]
    .map((allowedCards) => allowedCards[0])
    .filter((entry) => !entry.toLowerCase().includes("other"));
  const currencyCode =
    countryToCurrency[
      activeOrderStore.country === "UK" ? "GB" : activeOrderStore.country
    ].toString();

  const appLabels = useContext(AppLabelsContext);
  const currencyContext = useContext(CurrencyContext);

  const onClick = () => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      let request = {
        countryCode: activeOrderStore.country === "UK" ? "GB" : activeOrderStore.country,
        currencyCode: currencyCode,
        supportedNetworks: allowedCardNetworks,
        merchantCapabilities: ["supports3DS"],
        total: {
          label: activeOrderStore.name,
          type: "final",
          amount: Number(orderTotal / currencyContext.currencyData[1])
            .toFixed(2)
            .toString(),
        },
      };

      let session = new window.ApplePaySession(3, request);

      validateOrderTime().then((isOrderTimeValid) => {
        if (isOrderTimeValid) {
          session.begin();
        } else {
          return;
        }
      });

      session.onvalidatemerchant = (event) => {
        callGetApplePaySession(
          skin,
          "apple_pay_get_session",
          {
            params: [skin, "consumer", appLanguage, event.validationURL],
            id: "apple_pay_get_session",
          },
          activeOrderStore.storeId
        )
          .then((merchantSession) => {
            session.completeMerchantValidation(merchantSession.result);
          })
          .catch((err) => {
            console.error("Error fetching merchant session", err);
            session.abort();
          });
      };

      session.onpaymentmethodselected = (event) => {
        const update = {
          newTotal: {
            label: activeOrderStore.name,
            type: "final",
            amount: Number(orderTotal / currencyContext.currencyData[1])
              .toFixed(2)
              .toString(),
          },
        };
        session.completePaymentMethodSelection(update);
      };

      session.onpaymentauthorized = (event) => {
        const paymentToken = event.payment.token;

        const authorizationResult = {
          status: window.ApplePaySession.STATUS_SUCCESS,
          errors: [],
        };

        session.completePayment(authorizationResult);
        submitApplePayOrder(paymentToken);
      };

      session.oncancel = (event) => {
        console.error("Payment Session Cancelled");
      };
    }
  };

  return ReactDOM.createPortal(
    <div className="apple-pay-checkout-button__container">
      <button
        className="apple-pay-button apple-pay-button-black"
        aria-label={appLabels["order"]["pay-with-apple-pay"]}
        onClick={onClick}
        lang={appLanguage}
      ></button>
    </div>,
    document.body
  );
};

export default PaymentMethodApplePayButton;
