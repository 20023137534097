import React, { useContext } from "react";

/** UI Components */
import { ReactComponent as IconPlus } from "../icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../icons/IconMinus.svg";
import { ReactComponent as IconCheck } from "../icons/IconCheck.svg";

/** CSS */
import "./CollapsableSection.css";
import AppLabelsContext from "../../App/AppLabelsContext";

const CollapsableSection = (props) => {
  const {
    headerText,
    subHeaderText,
    isCollapsed,
    setIsCollapsed,
    id,
    customStyle,
    hasCheckbox,
    onCheckboxChange,
    groupIndex,
    isCheckboxDisabled,
    hasCheckmark,
    subHeaderContent,
    isContainerDisabled = false,
    hideMinusIcon = false,
  } = props;
  const appLabels = useContext(AppLabelsContext);

  const toggleCollapse = (e) => {
    const currentState = isCollapsed;
    setIsCollapsed(!currentState);

    const content = document.getElementById(id).nextElementSibling;

    if (!currentState) {
      content.style.maxHeight = null;
    } else {
      if (id === "cartItemsCollapsable" && window.location.href.includes("review-order")) {
        content.style.maxHeight = content.scrollHeight + 100 + "px";
      } else if (
        id === "suggestedItemsCollapsable" &&
        (window.location.href.includes("review-order") || window.location.href.includes("checkout"))
      ) {
        content.style.maxHeight = "100%";
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    }
  };

  const handleOnCheckBoxChange = (e) => {
    onCheckboxChange(e);
  };

  return (
    <>
      {hasCheckbox ? (
        <div
          className={`collapsable-section__with-checkbox ${
            isCollapsed ? " collapsable-section__white-border-bottom" : ""
          }`}
        >
          <button
            className="collapsable-section__header-container"
            onClick={toggleCollapse}
            id={id}
            style={customStyle ? customStyle : {}}
            disabled={isContainerDisabled}
            aria-label={
              isCollapsed
                ? appLabels["order"]["expand-section"] + " " + headerText
                : appLabels["order"]["collapse-section"] + " " + headerText
            }
          >
            {!hideMinusIcon && (
              <>
                {isCollapsed ? (
                  <IconPlus style={hasCheckmark ? { color: "#4caf52" } : {}} />
                ) : (
                  <IconMinus style={hasCheckmark ? { color: "#4caf52" } : {}} />
                )}
              </>
            )}
            <h4>
              {headerText}
              {subHeaderText && <span>{subHeaderText}</span>}
            </h4>
          </button>

          <div className="form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--checkbox billItemGroup__checkbox-wrapper">
            {hasCheckmark ? (
              <IconCheck />
            ) : (
              <>
                <input
                  type="checkbox"
                  className="form__field--checkbox-radio form__field--checkbox visually-hidden collapsable-section__input"
                  id={`${id}-${groupIndex}`}
                  onClick={(e) => handleOnCheckBoxChange(e)}
                  disabled={isCheckboxDisabled}
                ></input>
                <label
                  htmlFor={`${id}-${groupIndex}`}
                  className={`form__field-label--checkbox-radio form__field-label--checkbox collapsable-section__label `}
                >
                  {/*<span></span>*/}
                </label>
              </>
            )}
          </div>
        </div>
      ) : (
        <button
          className={`collapsable-section__header-container ${
            isCollapsed ? " collapsable-section__white-border-bottom" : ""
          }`}
          onClick={toggleCollapse}
          id={id}
          style={customStyle ? customStyle : {}}
          aria-label={
            isCollapsed
              ? appLabels["order"]["expand-section"] + " " + headerText
              : appLabels["order"]["collapse-section"] + " " + headerText
          }
        >
          {!hideMinusIcon && <>{isCollapsed ? <IconPlus /> : <IconMinus />}</>}
          <h2>
            {headerText}
            {subHeaderText && <span>{subHeaderText}</span>}
            {!subHeaderText && subHeaderContent && subHeaderContent}
          </h2>
        </button>
      )}
    </>
  );
};
export default CollapsableSection;
