//Libraries
import React from "react";
import { getTimeAmPm } from "../../Dashboard/DashboardOrder/dateHelpers";

//Helper functions
import { callAPI } from "../../_common/Api";
import { jsonCopy, toDollars } from "../../_common/helpers";
import { capitalizeWords } from "../../_common/stringHelpers";
import { getDayOfWeekFromIndex, getMinInterval } from "../Locations/helpers/isStoreOpenOrClosed";
import { getOrderStatus } from "../OrderStatus/OrderStatusHelpers";
import { getDeliveryInfo } from "../Delivery/DeliveryIntegrationHelpers";

const timestampToMilliseconds = (date) => {
  // replace is used for Safari to understand the date format
  return new Date(date.replace(/-/g, "/")).getTime();
};

export const sortByDate = (orderHistory) => {
  return orderHistory.sort(
    (item1, item2) => timestampToMilliseconds(item2.date) - timestampToMilliseconds(item1.date)
  );
};

export const getOrderHistory = async (
  skin,
  customerID,
  currencySymbol,
  currencySymbolSide,
  language,
  numberOfActiveOrders = 0,
  offset = 0, // if set to 10 - orders from 11-20 will be returned
  startDate = "", //example 2023-10-27 16:34:04
  endDate = "", //example 2023-11-27 16:34:04
  allLocationsContext = null,
  isOrderStatusEnabled,
  merchantConfigContext = null
) => {
  let loadedLocationContext = null;
  const api1304Data = await callAPI(skin, "vxl_1304", {
    params: [
      language,
      "1304",
      "mqid",
      "mqpass",
      customerID,
      startDate, //startDate
      endDate, //enddate
      offset, //offset
    ],
    id: "1304",
  });

  let orderHistory = api1304Data.result.I2;

  if (orderHistory && orderHistory.length > 0) {
    // Successfully retrieved recent orders

    sortByDate(orderHistory);
    const limitedOrderHistory = orderHistory; //.splice(0, ORDER_HISTORY_LIMIT + numberOfActiveOrders);
    let api1300Data = null;

    api1300Data = loadedLocationContext
      ? loadedLocationContext
      : await callAPI(skin, "info_vxl_1300", {
          params: [language, "1300", "mqid", "mqpass", limitedOrderHistory[0].order_type],
          id: "1300",
        });
    loadedLocationContext = api1300Data.result.I2;

    let prevOrderType = null;

    let hasExisitngStoreList = false;
    let pickUpStoresList = [];
    let deliveryStoresList = [];
    let dineInStoresList = [];

    if (limitedOrderHistory[0].order_type === "TAKE_OUT") {
      pickUpStoresList = loadedLocationContext;
    } else if (limitedOrderHistory[0].order_type === "DELIVERY") {
      deliveryStoresList = loadedLocationContext;
    } else if (limitedOrderHistory[0].order_type === "DINEIN") {
      dineInStoresList = loadedLocationContext;
    }

    let orderGroups = [];
    for (let i = 0; i < limitedOrderHistory.length; i++) {
      let orderAPI = limitedOrderHistory[i];
      if (!prevOrderType) {
        prevOrderType = orderAPI.order_type;
      }
      if (prevOrderType !== orderAPI.order_type) {
        prevOrderType = orderAPI.order_type;
        if (orderAPI.order_type === "TAKE_OUT" && pickUpStoresList && pickUpStoresList.length > 0) {
          loadedLocationContext = pickUpStoresList;
          hasExisitngStoreList = true;
        } else if (
          orderAPI.order_type === "DELIVERY" &&
          deliveryStoresList &&
          deliveryStoresList.length > 0
        ) {
          loadedLocationContext = deliveryStoresList;
          hasExisitngStoreList = true;
        } else if (
          orderAPI.order_type === "DINEIN" &&
          dineInStoresList &&
          dineInStoresList.length > 0
        ) {
          loadedLocationContext = dineInStoresList;
          hasExisitngStoreList = true;
        }

        if (!hasExisitngStoreList) {
          api1300Data = null;
          api1300Data = await callAPI(skin, "info_vxl_1300", {
            params: [language, "1300", "mqid", "mqpass", orderAPI.order_type],
            id: "1300",
          });

          if (api1300Data) {
            loadedLocationContext = api1300Data.result.I2;
            if (orderAPI.order_type === "TAKE_OUT") {
              pickUpStoresList = api1300Data.result.I2;
            } else if (orderAPI.order_type === "DELIVERY") {
              deliveryStoresList = api1300Data.result.I2;
            } else if (orderAPI.order_type === "DINEIN") {
              dineInStoresList = api1300Data.result.I2;
            }
          }
          hasExisitngStoreList = false;
        }
      }
      /* const itemDetails = await getOrderItemDetails(
        skin,
        orderAPI.id,
        orderAPI.storeLocation.id,
        language
      );*/

      const orderStores = loadedLocationContext;
      if (orderStores && orderStores.length > 0) {
        const currentOrderStore = orderStores.filter(
          (store) => store.outlet_id === orderAPI.storeLocation.id
        );

        let storeLocation;
        if (currentOrderStore && currentOrderStore[0]) {
          storeLocation = {
            storeName: currentOrderStore[0].store_name,
            id: currentOrderStore[0].outlet_id,
            streetAddress: currentOrderStore[0].store_address,
            city: currentOrderStore[0].store_city,
            province: currentOrderStore[0].store_province,
            country: currentOrderStore[0].store_country,
            postalCode: currentOrderStore[0].store_postal,
            phone: currentOrderStore[0].store_phone,
          };
        } else {
          storeLocation = {
            storeName: orderAPI.storeLocation.storeName,
            id: orderAPI.storeLocation.id,
            streetAddress: orderAPI.storeLocation.storeAddress,
            city: "",
            province: "",
            country: "",
            postalCode: "",
            phone: "",
            isDisabledStoreLocation: true,
          };
        }

        const orderGroupItem = {
          id: orderAPI.id,
          date: orderAPI.date,
          dateObject: new Date(orderAPI.date),
          //discountTotal: itemDetails.discountTotal,
          rawTotal: orderAPI.total_amount,
          total: toDollars(
            currencySymbol,
            currencySymbolSide,
            orderAPI.total_amount,
            language,
            true
          ), // total_amount includes taxes
          //items: itemDetails.items,
          //paymentDetails: itemDetails.paymentDetails,
          //rewardDetails: itemDetails.rewardDetails,
          orderType:
            orderAPI.order_type === "TAKE_OUT"
              ? "Pickup"
              : orderAPI.order_type === "DELIVERY"
              ? "Delivery"
              : "Dine-in", // itemDetails.orderType,
          orderAddress: orderAPI.storeLocation.storeAddress, // itemDetails.orderAddress,
          storeLocation: storeLocation,
          orderStatus: isOrderStatusEnabled
            ? await getOrderStatus(skin, language, orderAPI.id)
            : null,
          isFromDisabledStore: storeLocation.isDisabledStoreLocation || false,
        };

        /** For active orders call API 1342 to get the delivery information */
        if (
          orderGroupItem.orderStatus &&
          !orderGroupItem.orderStatus.isFinished &&
          merchantConfigContext.vexilor.isUberDirectEnabled
        ) {
          orderGroupItem.deliveryIntegrationInfo = await getDeliveryInfo(
            skin,
            language,
            orderAPI.storeLocation.id,
            orderAPI.id
          );
        }

        /** for delivery integratino orders check the status from API 1342 to see if the order is complete */
        if (
          orderGroupItem.deliveryIntegrationInfo &&
          (orderGroupItem.deliveryIntegrationInfo["del_status"].includes("Completed") ||
            orderGroupItem.deliveryIntegrationInfo["del_status"].includes("Cancelled"))
        ) {
          orderGroupItem.orderStatus.isFinished = true;
        }
        orderGroups.push(orderGroupItem);
      }
    }

    return Promise.all(orderGroups);
  } else {
    return [];
  }
};

export const getOrderItemDetails = async (skin, orderID, storeIdFrom1304, language) => {
  const api1305Data = await callAPI(
    skin,
    "vxl_1305",
    {
      params: [language, "1305", "mqid", "mqpass", orderID],
      id: "1305",
    },
    storeIdFrom1304
  );

  const orderItemDetails = api1305Data.result.I2;

  if (orderItemDetails) {
    // Successfully retrieved item details per order

    const items = [];
    const paymentDetails = [];
    const rewardDetails = [];
    const date = orderItemDetails.dateTime;
    const orderType =
      orderItemDetails.cart.delivery !== "False"
        ? "Delivery"
        : orderItemDetails.tableNum === "None"
        ? "Pickup"
        : "Dine-in";

    const discountTotal = orderItemDetails.cart.discountTotal;
    const orderAddress = orderItemDetails.shippingAddress;
    const taxesApplied = orderItemDetails.cart.taxTotals;
    let taxTotals = 0;
    if (taxesApplied.length > 0) {
      taxesApplied.forEach((tax) => {
        taxTotals = taxTotals + parseFloat(tax.tax_amount);
      });
    }
    const serviceChargeAmount = orderItemDetails.cart.serviceChargeAmount || null;

    orderItemDetails.cart.items.forEach((apiOrderItem) => {
      const item = {
        id: apiOrderItem.id,
        name: apiOrderItem.name,
        addons: apiOrderItem.addonGroups.length ? apiOrderItem.addonGroups : false,
        modifiers: apiOrderItem.modifierGroups.length ? apiOrderItem.modifierGroups : false,
        combo_child_items: apiOrderItem.items ? apiOrderItem.items : false,
        price: apiOrderItem.price,
        quantity: apiOrderItem.quantity,
      };

      items.push(item);
    });

    orderItemDetails.paymentList.forEach((apiOrderItemPayment) => {
      const payment = {
        paymentAmount: apiOrderItemPayment.paymentAmount,
        paymentTip: apiOrderItemPayment.paymentTip,
        paymentCard: apiOrderItemPayment.paymentCard.cardNumber,
        paymentType: apiOrderItemPayment.paymentType,
        pointsEarned: apiOrderItemPayment.points_issued,
      };

      paymentDetails.push(payment);
    });

    orderItemDetails.rewardList.forEach((apiOrderItemRewards) => {
      const rewards = {
        rewardName: apiOrderItemRewards.rewardName,
        pointsRedeemed: apiOrderItemRewards.rewardPrice,
        rewardCard: apiOrderItemRewards.reward_card_number,
      };

      rewardDetails.push(rewards);
    });

    return {
      date: date,
      items: items,
      discountTotal: discountTotal,
      paymentDetails: paymentDetails,
      rewardDetails: rewardDetails,
      orderType: orderType,
      orderAddress: orderAddress,
      taxTotals: taxTotals,
      serviceChargeAmount: serviceChargeAmount,
    };
  }
};
/**
 *
 * @param {Object} driverInfo - Object containing driver information from API 1342
 * @returns HTML structure of the Courier information
 */
export const getCourierDetails = (driverInfo, appLabels) => {
  return (
    <div className="courier-wrapper">
      <div className="courier-image-wrapper">
        <img src={driverInfo["photo_url"]} alt={appLabels["order"]["delivery-driver"]} />
      </div>
      <div className="courier-info-wrapper">
        <span className="courier-info-name">{driverInfo.name}</span>
        <div className="courier-vehicle">
          <span className="courier-vehicle-plate">
            {capitalizeWords(
              `${driverInfo["vehicle_color"]} ${driverInfo["vehicle_make"]} ${driverInfo["vehicle_model"]}`
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export const getOrderStoreAddress = (orderDetails, appLabels, fullInfo) => {
  const orderType = orderDetails.orderType;
  const orderAddress = orderDetails.orderAddress;
  const storeAddress = orderDetails.storeLocation;
  const orderStoreName = orderDetails.storeLocation.storeName;
  const url = window.location.href;
  const activeOrder = url.match("active-orders");

  return (
    <>
      {fullInfo && (
        <span className="order-status__order-details-date-address">
          <h4 className="order-status__order-details-date-address__header">
            {`${capitalizeWords(appLabels["order"]["order-type"])}:`}
          </h4>
          {` ${appLabels["order"][orderType.toLowerCase()]} `}
          <br />
          <h4 className="order-status__order-details-date-address__header">
            {appLabels["order"]["ordered-from"]}:
          </h4>
          <>
            {` ${decodeURI(orderStoreName)}`}
            {` (${decodeURI(storeAddress.streetAddress)}`}
            {storeAddress.city.length > 0 ? `, ${decodeURI(storeAddress.city)}` : ""}
            {storeAddress.province.length > 0
              ? `, ${storeAddress.province}, ${storeAddress.postalCode})`
              : ")"}
          </>
          {orderType === "Delivery" && orderAddress.street1 && (
            <>
              <br />
              <h4 className="order-status__order-details-date-address__header">
                {`${
                  activeOrder
                    ? capitalizeWords(appLabels["order"]["delivering-to-address"])
                    : appLabels["order"]["delivered-to"]
                }:`}
              </h4>
              {` ${decodeURI(orderAddress.street1)}`}
              {orderAddress.street2 && <>{`, ${decodeURI(orderAddress.street2)}`}</>}
              {`, ${decodeURI(orderAddress.city)}`}
              {window.innerWidth < 660 ? (
                <>
                  <br />
                </>
              ) : (
                ", "
              )}
              {`${orderAddress.stateCode.split("-")[1]}, ${orderAddress.zipCode}`}
            </>
          )}
        </span>
      )}
    </>
  );
};

export const getOutOfStockOrUnavailableItems = (outOfStockItemsList, appLabels) => {
  const outOfStockItems = outOfStockItemsList.filter((menuItem) => menuItem.itemOutOfStock);
  const limitedStockItems = outOfStockItemsList.filter((menuItem) => menuItem.itemUnavailable);

  return (
    <>
      <span className="order-status__order-details-date-address">
        <h2 className="order-status__order-details-date-address__header">
          {appLabels["order"]["oops"]}
        </h2>
        <br />
        {outOfStockItems.length > 0 && limitedStockItems.length <= 0 && (
          <p>{appLabels["order"]["reorder-limited-stock-items"]}:</p>
        )}
        {outOfStockItems.length <= 0 && limitedStockItems.length > 0 && (
          <p>{appLabels["order"]["reorder-items-no-longer-available"]}:</p>
        )}
        {outOfStockItems.length > 0 && limitedStockItems.length > 0 && (
          <p>{appLabels["order"]["reorder-items-out-of-stock-no-longer-available"]}:</p>
        )}
        <>
          {outOfStockItemsList.map((item, index) => (
            <React.Fragment key={item.name + "-" + index}>
              <li className="recent-order__item" style={{ listStyleType: "none" }}>
                <h2 className="recent-order__item-name-price">
                  <span className="recent-order__order-units">
                    {item.quantity - item.item_count}x
                  </span>
                  <span className="recent-order__item-name">{item.name}</span>
                </h2>
              </li>
            </React.Fragment>
          ))}
        </>
      </span>
    </>
  );
};

export const getUnavailableItems = (order, availableOrderItems) => {
  const unavailableItemsList = order.items.filter(
    ({ id: unavailableItemId }) =>
      !availableOrderItems.some(({ id: availableItemId }) => availableItemId === unavailableItemId)
  );

  const unavailableItemsWithoutDuplicates = removeDuplicatesAndUpdateCount(unavailableItemsList);

  const filteredUnavailableItems = unavailableItemsWithoutDuplicates.map((unavailableItem) => {
    return {
      id: unavailableItem.id,
      name: unavailableItem.name,
      item_count: 0,
      quantity: unavailableItem.quantity,
      itemUnavailable: true,
    };
  });

  return filteredUnavailableItems;
};

export const getOrderStoreAddressForDialogModal = (
  orderDetails,
  appLabels,
  fullInfo,
  nextAvailableOrderTime,
  foundStoreWithVexliorConfig,
  skin
) => {
  const orderType = orderDetails.orderType;
  const orderAddress = orderDetails.orderAddress;
  const orderStoreName = orderDetails.storeLocation.storeName;
  const storeAddress = orderDetails.storeLocation;
  const minInterval = getMinInterval(foundStoreWithVexliorConfig.vexilorConfig, orderType, skin);
  const orderTimeDisplayValue = foundStoreWithVexliorConfig.isOpen
    ? minInterval >= 1440 // if minimum interval is more than 24 hrs, do not use ASAP
      ? `${getTimeAmPm(nextAvailableOrderTime)}`
      : `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`
    : `${getTimeAmPm(nextAvailableOrderTime)}`;

  return (
    <>
      {fullInfo && (
        <span className="order-status__order-details-date-address">
          <h2 className="order-status__order-details-confirm-label">
            {appLabels["order"]["confirm-reorder-details"]}
          </h2>

          <h4 className="order-status__order-details-date-address__header">
            {`${capitalizeWords(appLabels["order"]["date-and-time"])}:`}&nbsp;
          </h4>
          {orderTimeDisplayValue.includes(appLabels["order"]["asap"])
            ? orderTimeDisplayValue
            : capitalizeWords(
                getDayOfWeekFromIndex(nextAvailableOrderTime.getDay(), appLabels).substr(0, 3)
              ) +
              " - " +
              orderTimeDisplayValue}
          <br />

          <h4 className="order-status__order-details-date-address__header">
            {`${capitalizeWords(appLabels["order"]["order-type"])}:`}
          </h4>
          {` ${appLabels["order"][orderType.toLowerCase()]} `}
          <br />

          <h4 className="order-status__order-details-date-address__header">
            {capitalizeWords(appLabels["order"]["ordering-from"])}:
          </h4>
          <>
            {` ${decodeURI(orderStoreName)}`}
            {` (${decodeURI(storeAddress.streetAddress)}`}
            {`, ${decodeURI(storeAddress.city)}`}
            {`, ${storeAddress.province}, ${storeAddress.postalCode})`}
          </>
          {orderType === "Delivery" && (
            <>
              <br />
              <h4 className="order-status__order-details-date-address__header">
                {capitalizeWords(appLabels["order"]["delivering-to-address"])}:
              </h4>
              {` ${decodeURI(orderAddress.street1)}`}
              {orderAddress.street2 && <>{`, ${decodeURI(orderAddress.street2)}`}</>}
              {`, ${decodeURI(orderAddress.city)}`}
              {window.innerWidth < 660 ? (
                <>
                  <br />
                </>
              ) : (
                ", "
              )}
              {`${orderAddress.stateCode.split("-")[1]}, ${orderAddress.zipCode}`}
            </>
          )}
        </span>
      )}
    </>
  );
};

export const getOrderStatusFromProgressBar = (order, appLabels) => {
  let orderStatus = order.OrderStatus;

  const isPickupOrder = order.orderType === "Pickup";

  const pastStatuses = order.orderStatus.allStatuses;

  /** Possible statuses from 1326 */
  const isPlaced = order.orderStatus.status === "placed";
  const isReceived = order.orderStatus.status === "order_received";
  const isPaymentProcessed = order.orderStatus.status === "payment_processed";
  const isPendingReview = order.orderStatus.status === "pending_review";

  const isCancelled = order.orderStatus.status === "cancelled";
  const isVoid = order.orderStatus.status === "void";
  const isRejected = order.orderStatus.status === "rejected";
  // const isNotApproved = isCancelled || isVoid || isRejected;

  const isProduced = order.orderStatus.status === "produced";
  const isReadyForPickup = order.orderStatus.status === "ready_for_pickup";
  const isClosed = order.orderStatus.status === "closed";
  const isDeliveryPending = order.orderStatus.status === "pending_delivery";
  const isDeliveryCompleted = order.orderStatus.status === "delivery_completed";
  const isCompleted = order.orderStatus.status === "completed";

  const isDeliveryReceived = order.orderStatus.status === "received";
  const isDeliveryAccepted = order.orderStatus.status === "accepted";
  const isDeliveryPickedUp = order.orderStatus.status === "pickedup";

  const isDeliveryCancelled = order.orderStatus.status === "delivery_cancelled";
  const isDeliveryCleared = order.orderStatus.status === "delivery_cleared";

  // const isReceivedCurrent =
  //   (isPlaced || isPaymentProcessed) &&
  //   !isReceived &&
  //   (pastStatuses.includes("placed") || pastStatuses.includes("payment_processed")) &&
  //   !pastStatuses.includes("order_received");

  // const isReceivedDone =
  //   isReceived ||
  //   isNotApproved ||
  //   isProduced ||
  //   isReadyForPickup ||
  //   isClosed ||
  //   isPendingReview ||
  //   isDeliveryPending ||
  //   isDeliveryCompleted ||
  //   isCompleted ||
  //   isDeliveryAccepted ||
  //   isDeliveryReceived ||
  //   isDeliveryPickedUp ||
  //   isDeliveryCancelled ||
  //   isDeliveryCleared ||
  //   pastStatuses.includes("order_received");

  // const isAcceptedCurrent =
  //   (isReceivedDone &&
  //     isPendingReview &&
  //     !isProduced &&
  //     !isNotApproved &&
  //     !isReadyForPickup &&
  //     !isClosed &&
  //     !isDeliveryCompleted &&
  //     !isCompleted &&
  //     !pastStatuses.includes("produced")) ||
  //   (isReceived && !pastStatuses.includes("pending_review"));

  const isAcceptedDone =
    (isProduced ||
      isReadyForPickup ||
      isClosed ||
      isDeliveryPending ||
      isDeliveryCompleted ||
      isCompleted ||
      isDeliveryAccepted ||
      isDeliveryReceived ||
      isDeliveryPickedUp ||
      isDeliveryCancelled ||
      isDeliveryCleared ||
      pastStatuses.includes("pending_review") ||
      pastStatuses.includes("produced")) &&
    !isPlaced &&
    !isPendingReview;

  const isPreparingCurrent =
    isAcceptedDone &&
    !isReadyForPickup &&
    !isClosed &&
    !isProduced &&
    !isDeliveryPending &&
    !isDeliveryCompleted &&
    !isCompleted &&
    !isDeliveryAccepted &&
    !isDeliveryReceived &&
    !isDeliveryCancelled &&
    !isDeliveryCleared &&
    !isDeliveryPickedUp &&
    !pastStatuses.includes("produced");

  // const isPreparingDone =
  //   isReadyForPickup ||
  //   isClosed ||
  //   isProduced ||
  //   isDeliveryPending ||
  //   isDeliveryCompleted ||
  //   isCompleted ||
  //   isDeliveryAccepted ||
  //   isDeliveryReceived ||
  //   isDeliveryCancelled ||
  //   isDeliveryCleared ||
  //   isDeliveryPickedUp ||
  //   pastStatuses.includes("produced");

  // const isReadyForPickupCurrent =
  //   (isPreparingDone &&
  //     !isReadyForPickup &&
  //     !isClosed &&
  //     !isDeliveryCompleted &&
  //     !isCompleted &&
  //     !isDeliveryPickedUp) ||
  //   isDeliveryPending ||
  //   (isClosed && !pastStatuses.includes("ready_for_pickup"));

  const isReadyForPickupDone =
    isReadyForPickup ||
    (isClosed && pastStatuses.includes("ready_for_pickup")) ||
    isDeliveryCompleted ||
    isCompleted ||
    isDeliveryPickedUp ||
    pastStatuses.includes("ready_for_pickup") ||
    (pastStatuses.includes("closed") && pastStatuses.includes("ready_for_pickup")) ||
    pastStatuses.includes("delivery_completed") ||
    pastStatuses.includes("completed") ||
    pastStatuses.includes("pickedup");

  // const isCompleteCurrent =
  //   (isReadyForPickup || isDeliveryPickedUp) && !isClosed && !pastStatuses.includes("closed");

  const isCompleteDone =
    (isClosed && pastStatuses.includes("ready_for_pickup") && isPickupOrder) ||
    isDeliveryCompleted ||
    isCompleted ||
    (pastStatuses.includes("closed") && isReadyForPickupDone && isPickupOrder) ||
    pastStatuses.includes("completed") ||
    pastStatuses.includes("delivery_completed");

  /**Placed */
  if (
    (isPlaced || isPaymentProcessed) &&
    !isReceived &&
    (pastStatuses.includes("placed") || pastStatuses.includes("payment_processed")) &&
    !pastStatuses.includes("order_received")
  ) {
    orderStatus = appLabels["account"]["order-placed"];
  }

  /**Received */
  if (isReceived) {
    orderStatus = appLabels["account"]["order-received"];
  }

  /**Pending Review */
  if (isPendingReview) {
    orderStatus = appLabels["account"]["order-reviewing"];
  }

  /**Accepted */
  if (isAcceptedDone) {
    orderStatus = appLabels["account"]["order-accepted"];
  }

  /**Cancelled */
  if (isCancelled) {
    orderStatus = appLabels["account"]["order-cancelled"];
  }

  /**Void */
  if (isVoid) {
    orderStatus = appLabels["account"]["order-void"];
  }

  /**Rejected */
  if (isRejected) {
    orderStatus = appLabels["account"]["order-rejected"];
  }

  /**Preparing */
  if (isPreparingCurrent) {
    orderStatus = appLabels["account"]["order-preparing"];
  }

  /**Prepared */
  if (isProduced && !pastStatuses.includes("ready_for_pickup")) {
    orderStatus = appLabels["account"]["order-prepared"];
  }

  /**Ready for Pickup */
  if (isPickupOrder && isProduced && pastStatuses.includes("ready_for_pickup")) {
    orderStatus = appLabels["account"]["order-ready-for-pickup"];
  }

  /**On Its Way - Delivery */
  if ((isClosed || pastStatuses.includes("closed")) && !isPickupOrder) {
    orderStatus = appLabels["account"]["order-on-its-way"];
  }

  /**On Its Way - Dine-In */
  if (!isPickupOrder && isProduced && pastStatuses.includes("ready_for_pickup")) {
    orderStatus = appLabels["account"]["order-on-its-way"];
  }

  /**Complete */
  if (isCompleteDone) {
    orderStatus = appLabels["account"]["order-complete"];
  }

  return orderStatus;
};

export const getTotalPointsRedeemed = (rewardDetails) => {
  let totalPointRedeemed = 0;

  rewardDetails.forEach((reward) => {
    totalPointRedeemed += parseInt(reward.pointsRedeemed) || 0;
  });

  return totalPointRedeemed;
};

/*const getOrderOccurrence = (array, value, addons, comboitems, modifiers) => {
  return array.filter(
    (item) =>
      JSON.stringify(item.id) === value &&
      JSON.stringify(item.addons) === addons &&
      JSON.stringify(item.combo_child_items) === comboitems &&
      JSON.stringify(item.modifiers) === modifiers
  ).length;
};*/

const getOrderOccurrence = (array, value, addons, comboitems, modifiers) => {
  return array.filter(
    (item) =>
      JSON.stringify(item.id) === value &&
      JSON.stringify(item.addonGroups) === addons &&
      JSON.stringify(item.combo_child_items) === comboitems &&
      JSON.stringify(item.modifierGroups) === modifiers
  ).length;
};

export const getOrderAddonOccurrence = (array, value, modifiers) => {
  return array.filter(
    (item) => JSON.stringify(item.id) === value && JSON.stringify(item.modifierGroups) === modifiers
  ).length;
};

export const getOrderModifierOccurrence = (array, value) => {
  return array.filter((item) => JSON.stringify(item.id) === value).length;
};

const removeOrderDuplicates = (data, key) => {
  return [...new Map(data.map((item) => [key(item), item])).values()];
};

export const removeDuplicatesAndUpdateCount = (itemsList, isAddonQuantityEnabled) => {
  itemsList.forEach((tempItem) => {
    tempItem.quantity = getOrderOccurrence(
      itemsList,
      JSON.stringify(tempItem.id),
      JSON.stringify(tempItem.addonGroups),
      JSON.stringify(tempItem.combo_child_items),
      JSON.stringify(tempItem.modifierGroups)
    );
  });

  if (isAddonQuantityEnabled) {
    itemsList.forEach((item) => {
      if (item.addons) {
        item.addons.forEach((addon) => {
          addon.items.forEach((addonItem) => {
            addonItem.quantity = getOrderAddonOccurrence(
              addon.items,
              JSON.stringify(addonItem.id),
              JSON.stringify(addonItem.modifierGroups)
            );
          });
        });
      }

      if (item.modifiers) {
        item.modifiers.forEach((modifier) => {
          modifier.items.forEach((modifierItem) => {
            modifierItem.quantity = getOrderModifierOccurrence(
              modifier.items,
              JSON.stringify(modifierItem.id)
            );
          });
        });
      }

      if (item.combo_child_items) {
        item.combo_child_items.forEach((childItem) => {
          if (childItem.addonGroups) {
            childItem.addonGroups.forEach((addonGroup) => {
              addonGroup.items.forEach((addonItem) => {
                addonItem.quantity = getOrderAddonOccurrence(
                  addonGroup.items,
                  JSON.stringify(addonItem.id),
                  JSON.stringify(addonItem.modifierGroups)
                );
              });
            });
          }

          if (childItem.modifierGroups) {
            childItem.modifierGroups.forEach((modifierGroup) => {
              modifierGroup.items.forEach((modifierItem) => {
                modifierItem.quantity = getOrderModifierOccurrence(
                  modifierGroup.items,
                  JSON.stringify(modifierItem.id)
                );
              });
            });
          }
        });
      }
    });

    let itemListWithOccurrence = jsonCopy(itemsList);

    /** Remove Duplicated addon and modifiers */
    itemListWithOccurrence.forEach((item) => {
      if (item.addons) {
        item.addons.forEach((addon) => {
          addon.items = removeOrderDuplicates(addon.items, (addon) => JSON.stringify(addon));
        });
      }
      if (item.modifiers) {
        item.modifiers.forEach((modifier) => {
          modifier.items = removeOrderDuplicates(modifier.items, (modifier) =>
            JSON.stringify(modifier)
          );
        });
      }

      if (item.combo_child_items) {
        item.combo_child_items.forEach((childItem) => {
          if (childItem.addonsGroups) {
            childItem.addonGroups.forEach((addonGroup) => {
              addonGroup.items = removeOrderDuplicates(addonGroup.items, (addonGroup) =>
                JSON.stringify(addonGroup)
              );
            });
          }

          if (childItem.modifierGroups) {
            childItem.addonGroups.forEach((modifierGroup) => {
              modifierGroup.items = removeOrderDuplicates(modifierGroup.items, (modifierGroup) =>
                JSON.stringify(modifierGroup)
              );
            });
          }
        });
      }
    });

    itemListWithOccurrence = removeOrderDuplicates(itemListWithOccurrence, (id) =>
      JSON.stringify(id)
    );

    return itemListWithOccurrence;
  } else {
    itemsList = removeOrderDuplicates(itemsList, (id) => JSON.stringify(id));
    return itemsList;
  }
};
